<template>
  <div class="change-picture-modal">
    <base-modal
      :show.sync="show"
      modal-classes="modal-dialog-top qr-modal-bottom"
      body-classes="p-0"
    >
      <template slot="header">Alterar Foto de Perfil</template>
      <template>
        <div class="container py-3">
          <div class="row">
            <div class="col-12 d-flex justify-content-center">
              <croppa
                placeholder="Selecione uma imagem"
                :placeholder-font-size="18"
                v-model="myCroppa"
                :width="windowSizing.width - 50"
                :height="windowSizing.width - 50"
              ></croppa>
            </div>
            <div class="col-12 mt-3">
              <base-button
                type="primary"
                class="w-100"
                @click="uploadCroppedImage"
                :disabled="carregando"
                >Alterar Avatar</base-button
              >
            </div>
          </div>
        </div>
      </template>
    </base-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'alterar-foto',
  computed: {
    ...mapGetters({
      profile: 'consumidor/getProfile',
      carregando: 'consumidor/getLoadingStatus'
    })
  },
  data: () => ({
    show: false,
    myCroppa: {}
  }),
  methods: {
    ...mapActions({
      uploadImagemPerfil: 'consumidor/uploadImagemPerfil',
      updateProfile: 'consumidor/updateProfile'
    }),
    async uploadCroppedImage() {
      this.myCroppa.generateBlob(
        async blob => {
          if (!blob) {
            return this.$swal({
              type: 'info',
              title: 'Ops...',
              text: 'Por favor selecione uma imagem para seu perfil!'
            })
          }
          const blobUrl = URL.createObjectURL(blob)
          const blobImg = await fetch(blobUrl).then(r => r.blob())
          const reader = new FileReader()
          reader.readAsDataURL(blobImg)
          reader.onloadend = async () => {
            const fotoBase64 = reader.result
            const respostaPerfil = await this.updateProfile({
              profile: { ...this.profile, fotoPerfil: fotoBase64 }
            })
            if ([200, 201].includes(respostaPerfil.status)) {
              this.$emit('emmitEvent', {
                event: 'gtm',
                data: {
                  event: 'fotoPerfilAlterada',
                  category: 'PerfilUsuario',
                  action: 'form',
                  label: 'Alterou foto do perfil'
                }
              })
              this.$swal({
                type: 'success',
                title: 'Sucesso',
                text: 'Perfil atualizado com sucesso!',
                onClose: () => {
                  this.myCroppa.remove()
                  this.show = false
                }
              })
            } else {
              this.$swal({
                type: 'error',
                title: 'Ops...',
                text: respostaPerfil.msg
              })
            }
          }
        },
        'image/jpeg',
        0.8
      )
    }
  },
  props: {
    showModal: {
      type: Boolean,
      description: 'Modal display boolean',
      default: false
    },
    windowSizing: {
      description: 'Window Size'
    }
  },
  watch: {
    show(val) {
      if (!val) {
        this.$emit('emmitModalEvent', {
          event: 'closeChangePictureModal'
        })
      }
    },
    showModal(val) {
      if (val) this.show = true
    }
  }
}
</script>
