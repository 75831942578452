<template>
  <base-pill-nav-bar :exibir.sync="show" :fecharNavBar="true" :exibirBackground="true" @fechar-nav-bar="fecharNavBar()">
    <!-- Título -->
    <template v-slot:header-pill-nav-bar>
      <p class="f-11 mt-4 mb-4" style="color: var(--text-color-highlight)">
        <strong>Alterar senha</strong>
      </p>
    </template>

    <!-- Body -->
    <template v-slot:body-pill-nav-bar>
      <section class="w-100 d-flex flex-column align-items-center mb-5">
        <!-- Senha atual -->
        <base-pill-input
          id="senha-atual"
          type="password"
          placeholder="Senha atual"
          :left-middle-pill="true"
          class="w-75 mb-3"
          :icons="['fas fa-lock']"
          :pillColors="['pill-dark']"
          :pillIconColors="['icon-light']"
          :leftMiddlePillRootColor="config.COR_DESTAQUE_CIRCUNFERENCIA_DOS_INPUTS"
          :leftMiddlePillIconRootColor="config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_DOS_INPUTS"
          :pillSize="['pill-average']"
          iconValidation="fas fa-info-circle"
          v-model="$v.currentPassword.$model"
          :valid="!$v.currentPassword.$invalid"
          :error="$v.currentPassword.$error"
          :errorMessageValidation="
            !$v.currentPassword.required
              ? 'Senha atual é requerida'
              : !$v.currentPassword.minLength
              ? 'Senha deve conter no mínimo 8 digitos'
              : 'Senha atual'
          "
        />
        <!-- Nova senha -->

        <div class="validacaoGrupo w-75">
          <base-pill-input
            id="nova-senha"
            type="password"
            placeholder="Nova senha"
            :left-middle-pill="true"
            class="w-100"
            :icons="['fas fa-lock']"
            :pillColors="['pill-dark']"
            :pillIconColors="['icon-light']"
            :leftMiddlePillRootColor="config.COR_DESTAQUE_CIRCUNFERENCIA_DOS_INPUTS"
            :leftMiddlePillIconRootColor="config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_DOS_INPUTS"
            :pillSize="['pill-average']"
            iconValidation="fas fa-info-circle"
            v-model="$v.newPassword.$model"
            :valid="!$v.newPassword.$invalid"
            :error="$v.newPassword.$error"
            :errorMessageValidation="
              !$v.newPassword.required
                ? 'O Campo nova senha é requerido'
                : !$v.newPassword.minLength
                ? 'Senha deve conter no mínimo 8 digitos'
                : 'Nova senha'
            "
          />
          <div class="flex-column d-flex pb-3 validacaoErro">
            <div
              class="aviso-senha d-flex aviso-senha-bg mt-3"
              :style="{ color: $v.newPassword.minLength ? 'green' : 'red' }"
            >
              <div class="d-flex align-items-center justify-content-center">
                <i v-if="$v.newPassword.minLength" class="fa fa-check"></i>
                <span v-if="!$v.newPassword.minLength" style="font-weight: bold">X</span>
              </div>
              Senha deve conter no mínimo 8 caracteres
            </div>
            <div
              class="aviso-senha d-flex aviso-senha-bg mb-1 mt-1"
              :style="{ color: $v.newPassword.containsUpperAndLower ? 'green' : 'red' }"
            >
              <div class="d-flex align-items-center">
                <i v-if="$v.newPassword.containsUpperAndLower" class="fa fa-check"></i
                ><span v-if="!$v.newPassword.containsUpperAndLower" style="font-weight: bold">X</span>
              </div>
              Senha deve conter letras maiúsculas e minúsculas
            </div>
            <div
              class="aviso-senha d-flex aviso-senha-bg"
              :style="{ color: $v.newPassword.containsNumbers ? 'green' : 'red' }"
            >
              <div class="d-flex align-items-center">
                <i v-if="$v.newPassword.containsNumbers" class="fa fa-check"></i
                ><span v-if="!$v.newPassword.containsNumbers" style="font-weight: bold">X</span>
              </div>
              Senha deve conter números
            </div>
          </div>
        </div>

        <!-- Confirmar nova senha -->
        <base-pill-input
          id="confirmar-nova-senha"
          type="password"
          placeholder="Confirmar Nova Senha"
          :left-middle-pill="true"
          class="w-75 mb-3"
          :icons="['fas fa-lock']"
          :pillColors="['pill-dark']"
          :pillIconColors="['icon-light']"
          :leftMiddlePillRootColor="config.COR_DESTAQUE_CIRCUNFERENCIA_DOS_INPUTS"
          :leftMiddlePillIconRootColor="config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_DOS_INPUTS"
          :pillSize="['pill-average']"
          iconValidation="fas fa-info-circle"
          v-model="$v.confirmPassword.$model"
          :valid="!$v.confirmPassword.$invalid"
          :error="$v.confirmPassword.$error"
          :errorMessageValidation="
            !$v.confirmPassword.required
              ? 'O Campo confirmação de senha é requerido'
              : !$v.confirmPassword.sameAs
              ? 'As senhas novas devem ser iguais'
              : 'Confirmação da nova senha'
          "
          @keydown.enter="checkForm"
        />
        <div v-if="$v.confirmPassword.$invalid && confirmPassword.length >= 1" class="row d-flex w-70 mb-3">
          <span class="aviso-senha aviso-senha-bg d-flex">
            <span style="font-weight: bold">X</span> A confirmação de senha deve ser igual
          </span>
        </div>
        <!-- Ações -->
        <base-pill-button
          :type="'light'"
          :class="'mt-3 mb-3'"
          :right-middle-pill="'fas fa-check-circle'"
          :pillColors="['pill-light']"
          :pillIconColors="['icon-dark']"
          :rightMiddlePillRootColor="config.COR_DESTAQUE_CIRCUNFERENCIA_DOS_BOTOES"
          :rightMiddlePillIconRootColor="config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_DOS_BOTOES"
          :pillSize="['pill-average']"
          :disabled="loading"
          @click="checkForm"
        >
          <template v-slot:pill-button-title>
            <span class="w-90 px-2 pl-3 text-center text-black">Alterar</span>
          </template>
        </base-pill-button>
      </section>
    </template>

    <!-- Footer -->
    <template v-slot:footer-pill-nav-bar>
      <img :src="config.INTRO_IMG_URL" class="w-25 mt-2 mb-2" alt="Logo Plataforma QR Sorteios" />
    </template>
  </base-pill-nav-bar>
</template>

<script>
import { minLength, required, sameAs } from 'vuelidate/lib/validators';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'alterar-senha',
  data: () => ({
    show: false,
    // form data
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  }),

  computed: {
    ...mapGetters({
      config: 'config/getConfig',
      loading: 'consumidor/getLoadingStatus',
    }),
  },

  methods: {
    ...mapActions({
      changePassword: 'consumidor/changePassword',
    }),
    clearForm() {
      this.currentPassword = '';
      this.newPassword = '';
      this.confirmPassword = '';
    },
    checkForm(e) {
      if (this.$v.$invalid) {
        this.$emit('emmitInfoModal', {
          event: 'modal-informacao-generica',
          data: {
            icone: 'fas fa-exclamation-circle',
            titulo: 'Ops...',
            mensagem: `Campos inválidos. Favor revisar.`,
            textoBotao: 'OK',
            onClose: () => this.$v.$touch(),
          },
        });
      } else {
        this.submitForm();
      }
    },
    submitForm() {
      this.changePassword({
        data: {
          currentPassword: this.currentPassword,
          newPassword: this.newPassword,
          confirmPassword: this.confirmPassword,
        },
      }).then((result) => {
        this.$emit('emmitEvent', {
          event: 'gtm',
          data: {
            event: 'senhaAlterada',
            category: 'perfilUsuario',
            action: 'form',
            label: 'Alterou a senha',
          },
        });
        if ([200].includes(result.status)) {
          this.$emit('emmitInfoModal', {
            event: 'modal-informacao-generica',
            data: {
              icone: 'fas fa-check-circle',
              titulo: 'Sucesso',
              mensagem: result.msg,
              textoBotao: 'OK',
            },
          });
          this.show = false;
          this.currentPassword = '';
          this.newPassword = '';
          this.confirmPassword = '';
        } else {
          this.$emit('emmitInfoModal', {
            event: 'modal-informacao-generica',
            data: {
              icone: 'fas fa-times-circle',
              titulo: 'Ops...',
              mensagem: result.status == 400 ? 'Senha atual esta incorreta!' : result.msg,
              textoBotao: 'OK',
            },
          });
        }
      });
    },
    fecharNavBar() {
      this.show = false;
    },
  },
  props: {
    showModal: {
      type: Boolean,
      description: 'Modal display boolean',
      default: false,
    },
  },
  watch: {
    show(val) {
      if (!val) {
        this.clearForm();
        this.$emit('emmitModalEvent', {
          event: 'closeChangePasswordModal',
        });
      }
    },
    showModal(val) {
      if (val) this.show = true;
    },
  },
  validations: {
    currentPassword: {
      required,
      minLength: minLength(6),
    },
    newPassword: {
      required,
      containsNumbers: (value) => /\d/.test(value),
      containsUpperAndLower: (value) => /^(?=.*[a-z])(?=.*[A-Z]).+$/.test(value),
      minLength: (value) => /^.{8,}$/.test(value),
    },
    confirmPassword: {
      required,

      sameAs: sameAs('newPassword'),
      containsNumbers: (value) => /\d/.test(value),
      containsUpperAndLower: (value) => /^(?=.*[a-z])(?=.*[A-Z]).+$/.test(value),
      minLength: (value) => /^.{8,}$/.test(value),
    },
  },
};
</script>

<style scoped lang="scss">
.aviso-senha {
  font-size: 15px;
  font-weight: 600;
  color: #fc3434;
}

.aviso-senha-bg {
  background-color: #ffffff;
  border-radius: 15px;
  padding: 8px;
}

.validacaoErro {
  visibility: hidden;
  max-height: 0;
}
.validacaoGrupo:focus-within .validacaoErro {
  visibility: visible;
  max-height: none;
}

.aviso-senha i,
.aviso-senha span,
.iconConfimacaoSenha {
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 490px) {
  .aviso-senha {
    font-size: 11px;
  }

  .aviso-senha-bg {
    padding: 6px;
  }
}
</style>
