import moment from 'moment';

class Validation {
  static DDDNumber(DDD) {
    let ValidDDDs = [
      11, 12, 13, 14, 15, 16, 17, 18, 19, 21, 22, 24, 27, 28, 31, 32, 33, 34, 35, 37, 38, 41, 42, 43, 44, 45, 46, 47,
      48, 49, 51, 53, 54, 55, 61, 62, 63, 64, 65, 66, 67, 68, 69, 71, 73, 74, 75, 77, 79, 81, 82, 83, 84, 85, 86, 87,
      88, 89, 91, 92, 93, 94, 95, 96, 97, 98, 99,
    ];
    return ValidDDDs.indexOf(parseInt(DDD)) < 0 ? false : true;
  }

  static phoneNumber(DDDphone) {
    let r;
    if (!DDDphone) return false;
    let re = new RegExp(/^[0-9]{10,11}$/);
    try {
      r = re.test(DDDphone);
    } catch (e) {
      r = false;
    }
    return r;
  }

  static formattedPhoneNumber(formattedPhone) {
    let r;
    // (99) ?9999-9999
    let re = new RegExp(/^\([1-9]{2}\) [2-9][0-9]{3,4}\-[0-9]{4}$/);
    try {
      r = re.test(formattedPhone);
    } catch (e) {
      r = false;
    }
    return r;
  }

  static text(text, minLength = 0, maxLength = 250) {
    let re = new RegExp('^[a-zA-ZÀ-ú ]{' + minLength.toString() + ',' + maxLength.toString() + '}$');
    return re.test(text);
  }

  static alphanumericText(text, minLength = 0, maxLength = 250) {
    let re = new RegExp('^[a-zA-ZÀ-ú0-9-_.,@ ]{' + minLength.toString() + ',' + maxLength.toString() + '}$');
    return re.test(text);
  }

  static personName(text) {
    if (text) {
      const re = text
        .toUpperCase()
        .trim()
        .replace(/\s{2,}/g, ' ')
        .match(/^[A-ZÀ-Ÿ][A-zÀ-ÿ']+\s([A-zÀ-ÿ']\s?)*[A-ZÀ-Ÿ][A-zÀ-ÿ']+$/);
      if (re) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  static abbreviatedName(text) {
    if (text) {
      const re = text
        .toUpperCase()
        .trim()
        .replace(/\s{2,}/g, ' ')
        .match(/^[A-ZÀ-Ÿ\d]+\s([A-zÀ-ÿ'\d]\s?)*[A-ZÀ-Ÿ\d][A-zÀ-ÿ'\d']+$/);
      if (re) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  static pureAlphanumericText(text, minLength = 0, maxLength = 250) {
    let re = new RegExp('^[a-zA-Z0-9]{' + minLength.toString() + ',' + maxLength.toString() + '}$');
    return re.test(text);
  }

  static onlyNumbersText(text, minLength = 0, maxLength = 250) {
    let re = new RegExp('^[0-9]{' + minLength.toString() + ',' + maxLength.toString() + '}$');
    return re.test(text);
  }

  static date_YYYY_MM_DD_HH_mm_ss(dateTime) {
    let s, r;
    if (dateTime.length != 19) return false;
    try {
      s = moment(dateTime).format('YYYY-MM-DD HH:mm:ss');
      r = s != 'Invalid date';
    } catch (e) {
      r = false;
    }
    return r;
  }

  static date_YYYY_MM_DD(date) {
    if (date) {
      let s, r;
      const nDate = date.split('/').reverse().join('-');
      if (nDate.length != 10) return false;
      try {
        s = moment(nDate).format('YYYY-MM-DD');
        r = s != 'Invalid date';
      } catch (e) {
        r = false;
      }
      return r;
    }
    return false;
  }

  static cpf(cpf) {
    if (cpf.length > 11) {
      return Validation.cnpj(cpf);
    }
    if (!cpf) return false;
    var i, add, rev;
    var cpff = cpf.replace(/[^\d]+/g, '');
    if (cpff == '') return false;
    if (
      cpff.length != 11 ||
      cpff == '00000000000' ||
      cpff == '11111111111' ||
      cpff == '22222222222' ||
      cpff == '33333333333' ||
      cpff == '44444444444' ||
      cpff == '55555555555' ||
      cpff == '66666666666' ||
      cpff == '77777777777' ||
      cpff == '88888888888' ||
      cpff == '99999999999'
    )
      return false;
    add = 0;
    for (i = 0; i < 9; i++) add += parseInt(cpff.charAt(i)) * (10 - i);
    rev = 11 - (add % 11);
    if (rev == 10 || rev == 11) rev = 0;
    if (rev != parseInt(cpff.charAt(9))) return false;
    add = 0;
    for (i = 0; i < 10; i++) add += parseInt(cpff.charAt(i)) * (11 - i);
    rev = 11 - (add % 11);
    if (rev == 10 || rev == 11) rev = 0;
    if (rev != parseInt(cpff.charAt(10))) return false;
    return true;
  }

  static cnpj(cnpj) {
    var i, add, rev;
    cnpj = cnpj.replace(/[^\d]+/g, '');
    if (cnpj == '') return false;
    if (cnpj.length != 14) return false;
    if (
      cnpj == '00000000000000' ||
      cnpj == '11111111111111' ||
      cnpj == '22222222222222' ||
      cnpj == '33333333333333' ||
      cnpj == '44444444444444' ||
      cnpj == '55555555555555' ||
      cnpj == '66666666666666' ||
      cnpj == '77777777777777' ||
      cnpj == '88888888888888' ||
      cnpj == '99999999999999'
    )
      return false;
    let tamanho = cnpj.length - 2;
    let numeros = cnpj.substring(0, tamanho);
    let digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) pos = 9;
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado != digitos.charAt(0)) return false;
    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado != digitos.charAt(1)) return false;
    return true;
  }

  static cnpjCpf(value) {
    return this.cpf(value) || this.cnpj(value);
  }

  static cpfCnpj(value) {
    return Validation.cpf(value) || Validation.cnpj(value);
  }

  static cep(cep) {
    let re = new RegExp(/[0-9]{5}-[0-9]{3}/);
    return re.test(cep);
  }

  static UF(uf) {
    if (uf.length != 2) return false;
    let re = new RegExp(/AC|AL|AP|AM|BA|CE|DF|ES|GO|MA|MT|MS|MG|PA|PB|PR|PE|PI|RJ|RN|RS|RO|RR|SC|SP|SE|TO/);
    return re.test(uf);
  }

  static email(email) {
    let re = new RegExp(/^[\w+.+-]+@\w+\.\w{2,}((?:\.\w{2,})?){2,4}$/);
    return re.test(email);
  }

  static NFEKey(chave /*, cnpj = null*/) {
    let retorno = false;
    let valores = [2, 3, 4, 5, 6, 7, 8, 9];
    let idx = 0;
    let soma = 0;
    let valorTemp, multTemp;
    let chaveSemDv = chave.substring(0, 43);
    let chaveValores = chaveSemDv.split('');
    let verificador = chave.substring(43, 44);
    // if (cnpj) {
    //     if (this.cnpj(cnpj)) {
    //         let cnpjChave = chave.substring(6, 20);
    //         if (cnpjChave != cnpj) {
    //             return retorno;
    //         }
    //     } else {
    //         return retorno;
    //     }
    // }
    if (!chave || chave.length != 44) {
      return retorno;
    } else {
      for (let i = chaveValores.length; i > 0; i--) {
        if (idx >= valores.length) idx = 0;
        valorTemp = parseInt(chaveValores[i - 1]);
        multTemp = valores[idx++];
        soma += valorTemp * multTemp;
      }
      let dv = 11 - (soma % 11);
      dv = dv == 11 || dv == 10 ? 0 : dv;

      if (dv == parseInt(verificador)) {
        retorno = true;
      } else {
        retorno = false;
      }
      return retorno;
    }
  }

  static retornaUF(codigoFiscal) {
    let uf = '';

    switch (codigoFiscal) {
      case '12':
        uf = 'AC';
        break;
      case '27':
        uf = 'AL';
        break;
      case '13':
        uf = 'AM';
        break;
      case '16':
        uf = 'AP';
        break;
      case '29':
        uf = 'BA';
        break;
      case '23':
        uf = 'CE';
        break;
      case '53':
        uf = 'DF';
        break;
      case '32':
        uf = 'ES';
        break;
      case '52':
        uf = 'GO';
        break;
      case '21':
        uf = 'MA';
        break;
      case '31':
        uf = 'MG';
        break;
      case '50':
        uf = 'MS';
        break;
      case '51':
        uf = 'MT';
        break;
      case '15':
        uf = 'PA';
        break;
      case '25':
        uf = 'PB';
        break;
      case '26':
        uf = 'PE';
        break;
      case '22':
        uf = 'PI';
        break;
      case '41':
        uf = 'PR';
        break;
      case '33':
        uf = 'RJ';
        break;
      case '24':
        uf = 'RN';
        break;
      case '11':
        uf = 'RO';
        break;
      case '14':
        uf = 'RR';
        break;
      case '43':
        uf = 'RS';
        break;
      case '42':
        uf = 'SC';
        break;
      case '28':
        uf = 'SE';
        break;
      case '35':
        uf = 'SP';
        break;
      case '17':
        uf = 'TO';
        break;
      default:
        uf = 'INVÁLIDA';
        break;
    }

    return uf;
  }

  static retornaTipoChaveFiscal(codigoFiscal) {
    if (codigoFiscal[20] + codigoFiscal[21] == '65') {
      return 'NFCe';
    } else if (codigoFiscal[20] + codigoFiscal[21] == '59') {
      return 'SAT';
    } else {
      return 'INVALIDO';
    }
  }

  static retornaTipoChaveFiscalBoolean(codigoFiscal) {
    if (codigoFiscal[20] + codigoFiscal[21] == '65') {
      return true;
    } else if (codigoFiscal[20] + codigoFiscal[21] == '59') {
      return true;
    } else {
      return false;
    }
  }

  // Método pra integração da Comper, recebe uma chave de acesso e caso ela contenha 55 irá pra integração
  // Caso contenha 65 irá para o Crawler
  static retornaIsChaveEcommerce(chaveDeAcesso) {
    return chaveDeAcesso[20] + chaveDeAcesso[21] == '55';
  }

  static espacoEmBranco(string) {
    if (string[0] == ' ' || string[string.length - 1] == ' ') return false;
    else return true;
  }
}

export default Validation;
