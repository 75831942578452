var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"campaign-info",class:{
      start: _vm.start,
      init: _vm.init,
    }},[_vm._m(0),_c('div',{staticClass:"campaign-data"},[_c('span',{staticClass:"campaign-authorization text-uppercase"},[_vm._v(" "+_vm._s(_vm.config.CAMPANHA_CERTIFICADO || '')+" ")]),_c('p',{staticClass:"text-uppercase campaign-title m-0",staticStyle:{"color":"var(--icon-color)"}},[_vm._v(" "+_vm._s(_vm.currentCampaign ? _vm.currentCampaign.descricao : '')+" ")])]),(!_vm.inProgress)?_c('div',{staticClass:"right-middle-pill pill-body-dark pill-size cursor-pointer",style:(_vm.config.COR_DESTAQUE_CIRCUNFERENCIA_CARDS_MODAIS
          ? `background-color: ${_vm.config.COR_DESTAQUE_CIRCUNFERENCIA_CARDS_MODAIS} !important;`
          : ''),on:{"click":_vm.initAnimation}},[_c('i',{staticClass:"fas fa-chevron-circle-right",style:(_vm.config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_CARDS_MODAIS
            ? `color: ${_vm.config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_CARDS_MODAIS} !important;`
            : '')})]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"campaign-icon",staticStyle:{"color":"var(--icon-color)"}},[_c('i',{staticClass:"fas fa-bullhorn"})])
}]

export { render, staticRenderFns }