<template>
  <div id="settings-menu">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'base-settings'
}
</script>