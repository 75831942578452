<template>
  <div id="bloco">
    <div
      id="moldura"
      :style="
        `width: ${larguraMoldura}; height: ${alturaMoldura}; background-color: ${bgColorMoldura}; ${
          selecionado ? 'box-shadow: 0 0 5px rgba(0, 0, 0, 0.8);' : ''
        }`
      "
    >
      <img id="icone" :src="imagem" :alt="imagemDescricao" />
    </div>
    <span
      id="titulo"
      :style="`font-size: ${tamanhoTitulo}; color: ${corTitulo}; font-weight: ${pesoTitulo}; width: ${larguraMoldura};`"
      >{{ titulo }}</span
    >
  </div>
</template>

<style scoped lang="scss">
#bloco {
  display: flex;
  flex-direction: column;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
}
#moldura {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  margin-bottom: 5px;
  margin-top: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
#icone {
  max-height: 90%;
  max-width: 80%;
}
#titulo {
  text-align: center;
  line-height: 1;
}
</style>

<script>
export default {
  name: 'botao-categoria',
  props: {
    titulo: {
      type: String,
      required: true,
      default: '',
      description: 'Propriedade que recebe o título que será colocado abaixo do botão',
    },
    imagem: {
      type: String,
      required: true,
      default: '',
      description: 'Propriedade que recebe a URL da imagem que será colocado no botão',
    },
    imagemDescricao: {
      type: String,
      required: false,
      default: '',
      description: 'Propriedade que recebe descrição da imagem que será colocado no botão',
    },
    larguraMoldura: {
      type: String,
      required: false,
      default: '50px',
      description: 'Propriedade que define a largura da moldura da imagem',
    },
    alturaMoldura: {
      type: String,
      required: false,
      default: '50px',
      description: 'Propriedade que define a altura da moldura da imagem',
    },
    bgColorMoldura: {
      type: String,
      required: false,
      default: 'white',
      description: 'Propriedade que define a cor de fundo da moldura da imagem',
    },
    tamanhoTitulo: {
      type: String,
      required: false,
      default: '12px',
      description: 'Propriedade que define a font-size do título',
    },
    corTitulo: {
      type: String,
      required: false,
      default: 'gray',
      description: 'Propriedade que define a cor da fonte do título',
    },
    pesoTitulo: {
      type: String,
      required: false,
      default: '700',
      description: 'Propriedade que define a font-weight do título',
    },
    selecionado: {
      type: Boolean,
      required: false,
      default: false,
      description: 'Propriedade que define se o botão está selecionado',
    },
  },
};
</script>
