<template>
  <!-- <router-link :id="destination" class="shadow" :to="{ name: destination }"> -->
  <a
    v-if="!this.config.MENU_NOVO"
    @click="animatedNavigateTo"
    href="javascript:void(0)"
    :id="destination"
    class="shadow"
    :style="`background-color: ${background};`"
    :class="{
      'router-link-active': this.$route.name == destination,
      'font-weight-bold': navBold == true,
    }"
  >
    <span
      class="item-icon"
      :style="
        `color: ${
          config.FORCAR_CORES_MENU && this.$route.name == destination
            ? this.config.TABELA_COR.find(cor => cor.nome == 'COR_BACKGROUND_FORCADO_BOTOES').cor
            : textColor
        };`
      "
    >
      <i :class="icon"></i>
    </span>
    <span
      class="item-label"
      :style="
        `color: ${
          config.FORCAR_CORES_MENU && this.$route.name == destination
            ? this.config.TABELA_COR.find(cor => cor.nome == 'COR_BACKGROUND_FORCADO_BOTOES').cor
            : textColor
        };`
      "
      >{{ label }}</span
    >
  </a>
  <div v-else :class="{ 'menu-flex': !this.config.CLIENTE_USA_INTEGRACAO && index == 1 }">
    <a
      @click="animatedNavigateTo"
      href="javascript:void(0)"
      :id="destination"
      class="item-menu-novo d-flex-center"
      :class="{
        'font-weight-bold': navBold == true,
      }"
    >
      <span class="item-icon" :style="`color: ${textColor};`">
        <img
          class="roleta-icon"
          v-if="destination == 'girosDaSorte'"
          src="https://qrsconfigurador.s3.amazonaws.com/APAS/imagens/c40322aa-9df7-48dd-b035-e6a4616ef5c6.png"
          alt="roleta"
        />
        <i v-else :class="icon"></i>
      </span>
      <span class="item-label" :style="`color: ${textColor};`">{{ label }}</span>
    </a>

    <!-- BOTÃO DE CADASTRAR DO APAS -->
    <a
      v-if="!this.config.CLIENTE_USA_INTEGRACAO && index == 1 && !this.block"
      class="cadastrar-cupom"
      @click="cadastrarCupom()"
    >
      <span class="item-icon">
        <i class="far fa-file-alt"></i>
      </span>
      <span class="item-label">CADASTRAR CUPOM</span>
    </a>
    <a
      v-if="!this.config.CLIENTE_USA_INTEGRACAO && index == 1 && this.block"
      class="cadastrar-cupom"
      @click="campaignMsg('Não há campanhas promocionais disponíveis no momento!', false)"
    >
      <span class="item-icon">
        <i class="far fa-file-alt"></i>
      </span>
      <span class="item-label">CADASTRAR CUPOM</span>
    </a>
  </div>
  <!-- </router-link> -->
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { Data } from '../../../lib/Helper';

export default {
  name: 'app-menu-item',

  computed: {
    ...mapGetters({
      perfil: 'consumidor/getProfile',
      config: 'config/getConfig',
      cupomConfig: 'cupom/getCouponsConfig',
      raspadinhasAltConfig: 'raspadinhaAlternativa/getRaspadinhasAltConfig',
      totalCupons: 'cupom/getDocumentosCouponsTotal',
      altScratches: 'raspadinhaAlternativa/getScratches',
    }),

    showMyMenuActions() {
      if (!this.campaign) {
        return true;
      } else {
        if (this.config.CLIENTE_USA_INTEGRACAO) return false;
        else return true;
      }
    },

    isProd: function() {
      if (typeof BuildInfo != 'undefined' && BuildInfo.packageName) {
        return this.config.VUE_APP_PROD_PACK_NAME == BuildInfo.packageName;
      } else {
        return location.href.startsWith(this.config.VUE_APP_PROD_BASE_URL);
      }
    },
  },

  data: () => ({
    block: false,
  }),

  methods: {
    ...mapActions({
      handleMenuSlide: 'ui/handleMenuSlide',
      openWrapperState: 'ui/openWrapperState',
    }),

    animatedNavigateTo() {
      this.openWrapperState(false);
      this.$router.push({ name: this.destination });
      this.handleMenuSlide(this.destination);
    },

    navigateTo(route) {
      this.$router.push({ name: route });
    },

    cadastrarCupom() {
      if (this.perfil && this.perfil.cadastroInicial) {
        this.$emit('openFinishSignUpModal', true);
      } else if (
        this.totalCupons >= this.cupomConfig.limitePorUsuario &&
        this.cupomConfig.limitePorUsuario != -1 &&
        this.altScratches.length >= this.raspadinhasAltConfig.limitePorUsuario &&
        this.raspadinhasAltConfig.limitePorUsuario != -1
      ) {
        this.$swal({
          type: 'error',
          title: 'Ops...',
          text: `Limite de cupons e ${this.config.ROLETA_PREMIADA ? 'giros' : 'raspadinhas'} atingido!`,
        });
      } else if (
        (this.totalCupons >= this.cupomConfig.limitePorUsuario && this.cupomConfig.limitePorUsuario != -1) ||
        (this.altScratches.length >= this.raspadinhasAltConfig.limitePorUsuario &&
          this.raspadinhasAltConfig.limitePorUsuario != -1)
      ) {
        this.$swal({
          type: 'info',
          title: 'Dica!',
          text: `${
            this.totalCupons >= this.cupomConfig.limitePorUsuario && this.cupomConfig.limitePorUsuario != -1
              ? 'Você já atingiu o limite total de números da sorte. Serão contabilizados apenas os pontos para o giro.'
              : 'Você já atingiu o limite total de giros. Serão contabilizados apenas os números da sorte.'
          }`,
          onClose: () => this.navigateTo('cadastrarCupom'),
        });
      } else {
        this.navigateTo('cadastrarCupom');
      }
    },

    carregaCampanhas: async function() {
      const wsUrl = this.isProd ? this.config.VUE_APP_PROD_WS_URL : this.config.VUE_APP_DEV_WS_URL;
      const currentDate = Data.dataHoraAtual();
      const url = `${wsUrl}/campanha/v1/campanhas/`;
      let campanhaRecuperada = null;
      await fetch(url)
        .then(response => response.json())
        .then(data => {
          data.retorno
            .filter(camp => {
              let initial = `${camp.dataInicial} 00:00:00`;
              let final = `${camp.dataFinalCompras} 23:59:00`;
              return camp.ativa && currentDate >= initial && currentDate <= final;
            })
            .forEach(camp => {
              campanhaRecuperada = camp;
            });
          campanhaRecuperada ? (this.block = false) : (this.block = true);
        })
        .catch(error => {
          console.error('Error:', error);
          this.block = true;
          this.campaignMsg(
            'Não foi possível recuperar campanhas promocionais disponíveis no momento, verifique sua conexão com a internet.',
            false
          );
        });
    },

    campaignMsg(msg, reload) {
      this.$swal({
        type: 'warning',
        title: 'Ops...',
        text: msg,
        //reiniciar?
        onClose: () => (reload ? location.reload() : {}),
      });
    },
  },

  // mounted() {
  //   this.carregaCampanhas()
  // },

  props: [
    'label',
    'icon',
    'destination',
    'background',
    'textColor',
    'navBold',
    'index',
    // 'isProd'
  ],
};
</script>

<style scoped>
.roleta-icon {
  filter: invert(100%) sepia(0%) saturate(7470%) hue-rotate(116deg) brightness(109%) contrast(109%);
  object-fit: scale-down;
  width: 29px;
  height: 33px;
  margin-top: -7px;
}
</style>
