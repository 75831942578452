var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-100 d-flex flex-column align-items-center"},[_vm._m(0),_c('div',{staticClass:"w-75 d-flex align-items-center justify-content-center mb-2"},[_c('p',{staticClass:"f-07 mb-3 text-center",staticStyle:{"color":"var(--text-color-highlight)"}},[_vm._v(" "+_vm._s(_vm.config.PRIMEIRO_ACESSO_MENSAGEM_INICIAL || 'Digite no campo abaixo o seu CPF para continuar:')+" ")])]),_c('div',{staticClass:"w-75 d-flex align-items-center justify-content-center mb-2"},[_c('base-pill-input',{staticClass:"w-100 mb-3",attrs:{"id":"email-usuario","type":"tel","autocomplete":false,"placeholder":_vm.config.TIPO_USUARIO == 'cpfCnpj'
          ? 'CPF/CNPJ'
          : _vm.config.TIPO_USUARIO == 'cnpj'
          ? 'CNPJ'
          : 'CPF',"left-middle-pill":true,"icons":['fas fa-user'],"pillColors":['pill-dark'],"pillIconColors":['icon-light'],"pillSize":['pill-average'],"iconValidation":"fas fa-info-circle","mask":_vm.config.TIPO_USUARIO == 'cpfCnpj'
          ? ['###.###.###-##', '##.###.###/####-##']
          : _vm.config.TIPO_USUARIO == 'cnpj'
          ? ['##.###.###/####-##']
          : ['###.###.###-##'],"masked":false,"valid":[11, 14].includes(_vm.cpf.length)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;!_vm.loading && [11, 14].includes(_vm.cpf.length)
          ? _vm.consultarConsumidor()
          : () => {}}},model:{value:(_vm.cpf),callback:function ($$v) {_vm.cpf=$$v},expression:"cpf"}})],1),_c('div',{staticClass:"w-75 d-flex align-items-center justify-content-between mb-3 pt-5 pb-5"},[_c('base-pill-button',{attrs:{"id":"esqueci-senha-voltar","type":'light',"left-middle-pill":'fas fa-chevron-circle-left',"pillColors":['pill-dark'],"pillIconColors":['icon-light'],"pillSize":['pill-average']},on:{"click":function($event){return _vm.voltarTela()}},scopedSlots:_vm._u([{key:"pill-button-title",fn:function(){return [_c('span',{staticClass:"w-90 pl-2 pr-3 text-center f-07 light-gray"},[_vm._v("Voltar")])]},proxy:true}])}),_c('base-pill-button',{attrs:{"id":"esqueci-senha-enviar","type":'dark',"right-middle-pill":'fas fa-chevron-circle-right',"pillColors":['pill-light'],"pillIconColors":['icon-dark'],"pillSize":['pill-average'],"disabled":_vm.loading || ![11, 14].includes(_vm.cpf.length)},on:{"click":_vm.consultarConsumidor},scopedSlots:_vm._u([{key:"pill-button-title",fn:function(){return [_c('span',{staticClass:"w-90 pl-3 pr-2 text-center f-07 light-gray"},[_vm._v("Consultar")])]},proxy:true}])})],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-75 d-flex align-items-center justify-content-center mb-2"},[_c('p',{staticClass:"f-11 mt-4 text-center",staticStyle:{"color":"var(--text-color-highlight)"}},[_c('strong',[_vm._v("Bem vindo!")])])])
}]

export { render, staticRenderFns }