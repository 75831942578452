import Vue from 'vue';

// Object initial state
const initialState = () => ({
  notificacoes: [],
  carregando: false,
});

// State object
const state = initialState();

// Getter object
const getters = {
  getNotificacoes(state) {
    return state.notificacoes;
  },
  getLoader(state) {
    return state.carregando;
  },
};

// Actions
const actions = {
  clearState({ commit }) {
    commit('RESET');
  },

  fetchNotificacoes({ commit }, { usuario, campanha }) {
    return new Promise(async resolve => {
      const api = new Vue.prototype.$httpClient();
      const url = `notificacao/v1/notificacoes/${campanha.ano}/${campanha.identificacao}/${usuario}/-1/1?enviado=true&metodosEnvio=PUSH`;

      commit('setLoader', true);

      const retorno = await api.send(url, 'GET', false, false, null);

      commit('setLoader', false);

      if (retorno.status === 200) {
        commit('setNotificacoes', retorno.data.retorno.lista);
        return resolve({
          status: 200,
          msg: retorno.data.mensagem,
          retorno: retorno.data.retorno
        });
      }

      return resolve({
        status: retorno.status || 500,
        msg: (retorno.data && retorno.data.mensagem)
          ? retorno.data.mensagem
          : 'Não foi possível obter as notificações, tente novamente'
      });
    });
  },
};

// Mutations
const mutations = {
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach(key => {
      state[key] = newState[key];
    });
  },
  setNotificacoes(state, data) {
    state.notificacoes = data;
  },
  setLoader(state, data) {
    state.carregando = data;
  },
};

// Exporting store module
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
