<template>
  <div id="myWrapper" class="app-wrapper container" :class="{ open: openWrapper }">
    <div id="app-sidebar" class="wrapper-flex-menu">
      <app-wrapper-item @click="openProfileModal" title="Perfil" />
      <app-wrapper-item
        v-if="config.MOSTRAR_NOTIFICACOES"
        @click="openNotificationSettingsModal"
        title="Notificações"
      />
      <app-wrapper-item @click="openChangePasswordModal" title="Alterar Senha" />
      <app-wrapper-item @click="openLogoutModal" title="Sair" v-if="!config.OCULTAR_SAIR" />
    </div>

    <div class="navigation-logos" v-if="exibirLogosPolgo">
      <a href="https://polgo.com.br" target="_blank" rel="noopener noreferrer">
        <img class="side-logo smalller" src="@/assets/images/logo-wrapper.png" alt="Sidebar logo" />
      </a>

      <img class="side-logo smalller" src="@/assets/images/logo-grupo.png" alt="Sidebar logo" />
    </div>

    <div class="big-close-button-wrapper">
      <button @click="openWrapperState(!openWrapper)">
        <i class="fas fa-chevron-left"></i>
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'app-wrapper-menu',

  computed: {
    ...mapGetters({
      config: 'config/getConfig',
      openWrapper: 'ui/getOpenWrapper',
    }),

    ocultaPerfil() {
      return this.config.OCULTA_PERFIL ? true : false;
    },

    exibirLogosPolgo() {
      return this.config && this.config.EXIBIR_LOGOS_POLGO;
    },
  },

  data: () => ({
    xDown: null,
    yDown: null,
  }),

  destroyed() {
    const els = [
      document.getElementById('myMenuActions'),
      document.getElementById('myWrapper'),
      document.getElementById('main'),
    ];
    els.forEach(el => {
      el
        ? (el.removeEventListener('touchstart', this.handleTouchStart, false),
          el.removeEventListener('touchmove', this.handleTouchMove, false))
        : null;
    });
  },

  methods: {
    ...mapActions({
      openWrapperState: 'ui/openWrapperState',
    }),
    openChangePasswordModal() {
      this.$emit('emmitModalEvent', {
        event: 'openChangePasswordModal',
      });
    },
    openNotificationSettingsModal() {
      this.$emit('emmitModalEvent', {
        event: 'openNotificationSettingsModal',
      });
    },
    openProfileModal() {
      this.$emit('emmitModalEvent', {
        event: 'openProfileModal',
      });
    },
    openLogoutModal() {
      this.$emit('emmitModalEvent', {
        event: 'openLogoutModal',
      });
    },
    navigateTo(route) {
      this.$router.push({ name: route });
    },
    // Gesture handler
    getTouches(evt) {
      return evt.touches || evt.originalEvent.touches;
    },
    handleTouchStart(evt) {
      const firstTouch = this.getTouches(evt)[0];
      this.xDown = firstTouch.clientX;
      this.yDown = firstTouch.clientY;
    },
    handleTouchMove(evt) {
      if (!this.xDown || !this.yDown) return;
      if (this.ocultaPerfil) return;

      const xUp = evt.touches[0].clientX;
      const yUp = evt.touches[0].clientY;

      const xDiff = this.xDown - xUp;
      const yDiff = this.yDown - yUp;

      if (Math.abs(xDiff) > Math.abs(yDiff)) {
        // Swipe left
        if (xDiff > 0) {
          this.openWrapperState(false);
        }
        // Swipe right
        else {
          if (['homeProduto', 'filtrosProduto', 'detalhesProduto', 'acompanharPedidos'].includes(this.$route.name)) {
            this.openWrapperState(false);
          } else {
            this.openWrapperState(true);
          }
        }
      }
      /* reset values */
      this.xDown = null;
      this.yDown = null;
    },
  },

  mounted() {
    const els = [
      document.getElementById('myMenuActions'),
      document.getElementById('myWrapper'),
      document.getElementById('main'),
    ];
    els.forEach(el => {
      el
        ? (el.addEventListener('touchstart', this.handleTouchStart, false),
          el.addEventListener('touchmove', this.handleTouchMove, false))
        : null;
    });
  },
};
</script>
