import Vue from 'vue';

// Object initial state
const initialState = () => ({
  vouchers: [],
  totalVouchers: 0,
  totalPaginas: 0,
  vouchersPedidos: [],
  totalVouchersPedidos: 0,
  totalPaginasPedidos: 0,
  provedores: [],
  totalProvedores: 0,
  totalPaginasProvedores: 0,
  carregando: false,
  erro: null,
});

// State object
const state = initialState();

// Getter object
const getters = {
  getVouchers(state) {
    return state.vouchers;
  },

  getTotalizador(state) {
    return state.totalVouchers;
  },

  getPaginas(state) {
    return state.totalPaginas;
  },
  
  getVouchersPedidos(state) {
    return state.vouchersPedidos;
  },
  
  getTotalizadorPedidos(state) {
    return state.totalVouchersPedidos;
  },
  
  getPaginasPedidos(state) {
    return state.totalPaginasPedidos;
  },

  getProvedores(state) {
    return state.provedores;
  },

  getTotalizadorProvedores(state) {
    return state.totalProvedores;
  },

  getPaginasProvedores(state) {
    return state.totalPaginasProvedores;
  },

  getLoader(state) {
    return state.carregando;
  },

  getErro(state) {
    return state.erro;
  },
};

// Actions
const actions = {
  carregarVouchers({ commit }, { paginacao }) {
    return new Promise(async resolve => {
      commit('setLoader', true);
      const comunicacao = new Vue.prototype.$httpClient();
      let endpoint = `/fidelidade/v1/vouchers/${paginacao.totalPorPagina}/${paginacao.paginaAtual}`;

      const res = await comunicacao.send(endpoint, 'GET');

      if ([200, 201].includes(res.status)) {
        commit('setVouchers', res.data.retorno.retorno);
        commit('setTotalizador', res.data.retorno.total);
        commit('setPaginas', res.data.retorno.paginas);
        commit('setErro', null);
      } else if ([404].includes(res.status)) {
        commit('setVouchers', []);
        commit('setTotalizador', 0);
        commit('setErro', null);
      } else {
        commit('setTotalizador', 0);
        commit(
          'setErro',
          res.data.mensagem || 'Ops.. ocorreu um erro na conexão com o servidor, tente novamente mais tarde!'
        );
      }
      commit('setLoader', false);
      resolve();
    });
  },
  carregarProvedoresVouchers({ commit }, { paginacao }) {
    return new Promise(async resolve => {
      commit('setLoader', true);
      const comunicacao = new Vue.prototype.$httpClient();
      let endpoint = `/fidelidade/v1/provedoresDeVoucher/${paginacao.totalPorPagina}/${paginacao.paginaAtual}`;

      const res = await comunicacao.send(endpoint, 'GET');

      if ([200, 201].includes(res.status)) {
        commit('setProvedores', res.data.retorno.retorno);
        commit('setTotalizadorProvedores', res.data.retorno.total);
        commit('setPaginasProvedores', res.data.retorno.paginas);
        commit('setErro', null);
      } else if ([404].includes(res.status)) {
        commit('setProvedores', []);
        commit('setTotalizadorProvedores', 0);
        commit('setErro', null);
      } else {
        commit('setTotalizadorProvedores', 0);
        commit(
          'setErro',
          res.data.mensagem || 'Ops.. ocorreu um erro na conexão com o servidor, tente novamente mais tarde!'
        );
      }
      commit('setLoader', false);
      resolve();
    });
  },
  carregarVouchersPedidos({ commit }, { paginacao }) {
    return new Promise(async resolve => {
      commit('setLoader', true);
      const comunicacao = new Vue.prototype.$httpClient();
      let endpoint = `/fidelidade/v1/pedidosVoucher/${paginacao.totalPorPagina}/${paginacao.paginaAtual}`;

      const res = await comunicacao.send(endpoint, 'GET', null, undefined, false);

      if ([200, 201].includes(res.status)) {
        commit('setVouchersPedidos', res.data.retorno.retorno);
        commit('setTotalizadorPedidos', res.data.retorno.total);
        commit('setPaginasPedidos', res.data.retorno.paginas);
        commit('setErro', null);
      } else if ([404].includes(res.status)) {
        commit('setVouchersPedidos', []);
        commit('setTotalizadorPedidos', 0);
        commit('setErro', null);
      } else {
        commit('setTotalizadorPedidos', 0);
        commit(
          'setErro',
          res.data.mensagem || 'Ops.. ocorreu um erro na conexão com o servidor, tente novamente mais tarde!'
        );
      }
      commit('setLoader', false);
      resolve();
    });
  },
  realizarPedidoVoucher({ commit }, { id, provedor }) {
    return new Promise(async resolve => {
      commit('setLoader', true);
      const comunicacao = new Vue.prototype.$httpClient();
      let endpoint = `/fidelidade/v1/pedidosVoucher/${provedor}/${id}`;

      const res = await comunicacao.send(endpoint, 'POST', null, undefined, false);
      if ([200, 201].includes(res.status)) {
        resolve({
          status: res.data.status,
          retorno: res.data.retorno,
          msg: res.data.mensagem,
        });
      } else {
        resolve({
          status: res.status || 500,
          retorno: res.data.retorno,
          msg: res.data.mensagem || 'Erro desconhecido ao realizar pedido!',
        });
      }
      commit('setLoader', false);
      resolve();
    });
  },
};

// Mutations
const mutations = {
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach(key => {
      state[key] = newState[key];
    });
  },

  setVouchers(state, data) {
    state.vouchers = data;
  },

  setTotalizador(state, data) {
    state.totalVouchers = data;
  },

  setPaginas(state, data) {
    state.totalPaginas = data;
  },

  setVouchersPedidos(state, data) {
    state.vouchersPedidos = data;
  },

  setTotalizadorPedidos(state, data) {
    state.totalVouchersPedidos = data;
  },

  setPaginasPedidos(state, data) {
    state.totalPaginasPedidos = data;
  },

  setProvedores(state, data) {
    state.provedores = data;
  },

  setTotalizadorProvedores(state, data) {
    state.totalProvedores = data;
  },

  setPaginasProvedores(state, data) {
    state.totalPaginasProvedores = data;
  },

  setLoader(state, data) {
    state.carregando = data;
  },

  setErro(state, data) {
    state.erro = data;
  },
};

// Exporting store module
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
