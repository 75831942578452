<template>
  <base-pill-nav-bar
    :exibir.sync="show"
    :fecharNavBar="!completarCadastro"
    :exibirBackground="true"
    @fechar-nav-bar="fecharNavBar()"
  >
    <template v-slot:title-nav-bar v-if="completarCadastro">
      <h4 class="w-90 text-center mt-4">Bem-vindo!</h4>
      <p class="w-90 text-center">
        <small>{{
          config.MENSAGEM_BOAS_VINDAS_COMPLETAR_CADASTRO ||
            'Bem-vindo! Verifique se todos os seus dados para contato estão completos.'
        }}</small>
      </p>
    </template>
    <template v-slot:header-pill-nav-bar>
      <p class="f-11 mt-4 mb-4" style="color: var(--text-color-highlight)">
        <strong>Complete seu cadastro</strong>
      </p>
    </template>

    <template v-slot:body-pill-nav-bar>
      <section class="w-100 d-flex flex-column align-items-center" v-if="!config.MODIFICAR_CAMPOS_PERFIL">
        <!-- Nome -->
        <base-pill-input
          id="nome-usuario"
          type="text"
          placeholder="Nome"
          :left-middle-pill="true"
          class="w-75 mb-3"
          :icons="['fas fa-user']"
          :pillColors="['pill-dark']"
          :leftMiddlePillRootColor="config.COR_DESTAQUE_CIRCUNFERENCIA_DOS_INPUTS"
          :leftMiddlePillIconRootColor="config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_DOS_INPUTS"
          :pillIconColors="['icon-light']"
          :pillSize="['pill-average']"
          iconValidation="fas fa-info-circle"
          v-model="$v.name.$model"
          :defaultValue="$v.name.$model"
          :valid="!$v.name.$invalid"
          :error="$v.name.$error"
        />
        <!-- CPF -->
        <base-pill-input
          id="cpf-cnpj-usuario"
          type="tel"
          placeholder="CPF"
          :left-middle-pill="true"
          class="w-75 mb-3"
          :icons="['fas fa-id-card']"
          :pillColors="['pill-dark']"
          :leftMiddlePillRootColor="config.COR_DESTAQUE_CIRCUNFERENCIA_DOS_INPUTS"
          :leftMiddlePillIconRootColor="config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_DOS_INPUTS"
          :pillIconColors="['icon-light']"
          :pillSize="['pill-average']"
          iconValidation="fas fa-info-circle"
          :mask="$v.cpf.$model.length > 11 ? ['##.###.###/####-##'] : ['###.###.###-###']"
          :masked="false"
          v-model="$v.cpf.$model"
          :defaultValue="$v.cpf.$model"
          :valid="!$v.cpf.$invalid"
          :error="$v.cpf.$error"
          :disabled="config.LOGIN_CPF"
        />
        <!-- RG -->
        <base-pill-input
          id="rg-usuario"
          type="text"
          placeholder="RG"
          :left-middle-pill="true"
          class="w-75 mb-3"
          :icons="['fas fa-id-card']"
          :pillColors="['pill-dark']"
          :leftMiddlePillRootColor="config.COR_DESTAQUE_CIRCUNFERENCIA_DOS_INPUTS"
          :leftMiddlePillIconRootColor="config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_DOS_INPUTS"
          :pillIconColors="['icon-light']"
          :pillSize="['pill-average']"
          iconValidation="fas fa-info-circle"
          :mask="['##.###.###-X']"
          :masked="false"
          v-model="$v.rg.$model"
          :defaultValue="$v.rg.$model"
          :valid="!$v.rg.$invalid"
          :error="$v.rg.$error"
        />
        <!-- Data nasc. -->
        <base-pill-input
          id="aniversario-usuario"
          type="text"
          placeholder="Data de aniversário"
          :left-middle-pill="true"
          class="w-75 mb-3"
          :icons="['fas fa-birthday-cake']"
          :pillColors="['pill-dark']"
          :leftMiddlePillRootColor="config.COR_DESTAQUE_CIRCUNFERENCIA_DOS_INPUTS"
          :leftMiddlePillIconRootColor="config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_DOS_INPUTS"
          :pillIconColors="['icon-light']"
          :pillSize="['pill-average']"
          iconValidation="fas fa-info-circle"
          :mask="['##/##/####']"
          :masked="true"
          v-model="$v.birth.$model"
          :defaultValue="$v.birth.$model"
          :valid="!$v.birth.$invalid"
          :error="$v.birth.$error"
        />
        <!-- Gênero -->
        <base-pill-select
          id="genero-usuario"
          title="Gênero"
          class="w-75 mb-3"
          selectPillClass="select-pill-primary"
          selectBodyClass="select-body-light"
          selectSize="average"
          :options="genderOptions"
          v-model="$v.gender.$model"
          :error="$v.gender.$error"
        />
        <!-- E-mail -->
        <base-pill-input
          id="email-usuario"
          type="email"
          placeholder="E-mail"
          :left-middle-pill="true"
          class="w-75 mb-3"
          :icons="['fas fa-envelope']"
          :pillColors="['pill-dark']"
          :leftMiddlePillRootColor="config.COR_DESTAQUE_CIRCUNFERENCIA_DOS_INPUTS"
          :leftMiddlePillIconRootColor="config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_DOS_INPUTS"
          :pillIconColors="['icon-light']"
          :pillSize="['pill-average']"
          iconValidation="fas fa-info-circle"
          v-model="$v.email.$model"
          :defaultValue="$v.email.$model"
          :valid="!$v.email.$invalid"
          :error="$v.email.$error"
        />
        <!-- CEP e UF -->
        <!-- <div class="d-flex flex-row justify-content-between w-75"> -->
        <base-pill-input
          id="cep-usuario"
          type="tel"
          placeholder="CEP"
          :left-middle-pill="true"
          class="w-75 mb-3"
          :icons="['fas fa-map-marker-alt']"
          :pillColors="['pill-dark']"
          :leftMiddlePillRootColor="config.COR_DESTAQUE_CIRCUNFERENCIA_DOS_INPUTS"
          :leftMiddlePillIconRootColor="config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_DOS_INPUTS"
          :pillIconColors="['icon-light']"
          :pillSize="['pill-average']"
          iconValidation="fas fa-info-circle"
          :mask="['#####-###']"
          :masked="true"
          v-model="$v.cep.$model"
          :defaultValue="$v.cep.$model"
          :valid="!$v.cep.$invalid"
          :error="$v.cep.$error"
        />

        <base-pill-select
          id="uf-usuario"
          title="Estado"
          class="w-75 mb-3"
          selectPillClass="select-pill-primary"
          selectBodyClass="select-body-light"
          selectSize="average"
          :options="ufs"
          v-model="$v.state.$model"
          :defaultOption="$v.state.$model"
          :error="$v.state.$error"
          @change="buscaCidades(ufs.filter(e => $v.state.$model == e.value)[0].idIBGE)"
        />
        <!-- </div> -->
        <!-- Rua -->
        <base-pill-input
          id="rua-usuario"
          type="text"
          placeholder="Rua"
          :left-middle-pill="true"
          class="w-75 mb-3"
          :icons="['fas fa-map-marked-alt']"
          :pillColors="['pill-dark']"
          :leftMiddlePillRootColor="config.COR_DESTAQUE_CIRCUNFERENCIA_DOS_INPUTS"
          :leftMiddlePillIconRootColor="config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_DOS_INPUTS"
          :pillIconColors="['icon-light']"
          :pillSize="['pill-average']"
          iconValidation="fas fa-info-circle"
          v-model="$v.street.$model"
          :defaultValue="$v.street.$model"
          :valid="!$v.street.$invalid"
          :error="$v.street.$error"
        />
        <!-- Número e Bairro -->
        <div class="d-flex flex-row justify-content-between w-75">
          <base-pill-input
            id="numero-endereco-usuario"
            type="tel"
            placeholder="N°"
            :left-middle-pill="true"
            class="w-40 mb-3"
            :icons="['fas fa-home']"
            :pillColors="['pill-dark']"
            :leftMiddlePillRootColor="config.COR_DESTAQUE_CIRCUNFERENCIA_DOS_INPUTS"
            :leftMiddlePillIconRootColor="config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_DOS_INPUTS"
            :pillIconColors="['icon-light']"
            :pillSize="['pill-average']"
            iconValidation="fas fa-info-circle"
            :mask="['######']"
            :masked="false"
            v-model="$v.number.$model"
            :defaultValue="$v.number.$model"
            :valid="!$v.number.$invalid"
            :error="$v.number.$error"
          />

          <base-pill-input
            id="bairro-usuario"
            type="text"
            placeholder="Bairro"
            :left-middle-pill="true"
            class="w-55 mb-3"
            :icons="['fas fa-map-marked-alt']"
            :pillColors="['pill-dark']"
            :leftMiddlePillRootColor="config.COR_DESTAQUE_CIRCUNFERENCIA_DOS_INPUTS"
            :leftMiddlePillIconRootColor="config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_DOS_INPUTS"
            :pillIconColors="['icon-light']"
            :pillSize="['pill-average']"
            iconValidation="fas fa-info-circle"
            v-model="$v.neighborhood.$model"
            :defaultValue="$v.neighborhood.$model"
            :valid="!$v.neighborhood.$invalid"
            :error="$v.neighborhood.$error"
          />
        </div>
        <!-- Cidade e complemento -->
        <base-pill-input
          id="cidade-usuario"
          type="text"
          placeholder="Cidade"
          :left-middle-pill="true"
          class="w-75 mb-3"
          :icons="['fas fa-map-marked-alt']"
          :pillColors="['pill-dark']"
          :leftMiddlePillRootColor="config.COR_DESTAQUE_CIRCUNFERENCIA_DOS_INPUTS"
          :leftMiddlePillIconRootColor="config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_DOS_INPUTS"
          :pillIconColors="['icon-light']"
          :pillSize="['pill-average']"
          iconValidation="fas fa-info-circle"
          v-model="$v.city.$model"
          :defaultValue="$v.city.$model"
          :valid="!$v.city.$invalid"
          :error="$v.city.$error"
          listId="nomeCidade"
          :dataList="true"
          :listOptions="listaCidades"
        />

        <base-pill-input
          id="complemento-endereco-usuario"
          type="text"
          placeholder="Complemento"
          :left-middle-pill="true"
          class="w-75 mb-3"
          :icons="['fas fa-map-marked-alt']"
          :pillColors="['pill-dark']"
          :leftMiddlePillRootColor="config.COR_DESTAQUE_CIRCUNFERENCIA_DOS_INPUTS"
          :leftMiddlePillIconRootColor="config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_DOS_INPUTS"
          :pillIconColors="['icon-light']"
          :pillSize="['pill-average']"
          iconValidation="fas fa-info-circle"
          v-model="complement"
          :defaultValue="complement"
          :valid="true"
        />
        <!-- Pergunta e opções -->
        <base-pill-select
          v-if="config.MODULO_PERGUNTA_E_RESPOSTA"
          id="pergunta-opcoes-usuario"
          :title="config.PERGUNTA_AO_CONSUMIDOR"
          class="w-75 mb-3"
          selectPillClass="select-pill-primary"
          selectBodyClass="select-body-light"
          selectSize="average"
          :options="opcoesResposta"
          :defaultOption="resposta || respostaAnterior"
          v-model="resposta"
        />
        <!-- Extra -->
        <base-pill-input
          v-if="config.CONSUMIDOR_CAMPO_EXTRA_ATIVO"
          id="extra"
          type="text"
          :placeholder="config.CONSUMIDOR_CAMPO_EXTRA_NOME || 'Extra'"
          :left-middle-pill="true"
          class="w-75 mb-3"
          :icons="['fas fa-user-tie']"
          :pillColors="['pill-dark']"
          :leftMiddlePillRootColor="config.COR_DESTAQUE_CIRCUNFERENCIA_DOS_INPUTS"
          :leftMiddlePillIconRootColor="config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_DOS_INPUTS"
          :pillIconColors="['icon-light']"
          :pillSize="['pill-average']"
          iconValidation="fas fa-info-circle"
          :valid="true"
          v-model="extra"
          :defaultValue="extra"
        />

        <base-pill-button
          :type="'light'"
          :class="'mt-3 mb-3'"
          :right-middle-pill="'fas fa-check-circle'"
          :pillColors="['pill-light']"
          :pillIconColors="['icon-dark']"
          :rightMiddlePillRootColor="config.COR_DESTAQUE_CIRCUNFERENCIA_DOS_BOTOES"
          :rightMiddlePillIconRootColor="config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_DOS_BOTOES"
          :pillSize="['pill-average']"
          :disabled="loading"
          @click="checkForm"
        >
          <template v-slot:pill-button-title>
            <span class="w-90 px-2 pl-3 text-center text-black">Salvar</span>
          </template>
        </base-pill-button>
      </section>

      <section class="w-100 d-flex flex-column align-items-center" v-else>
        <div
          class="d-flex flex-row justify-content-center w-75 mb-3"
          v-if="
            ['cpfCnpj', 'cnpj'].includes(config.TIPO_USUARIO) &&
              config.CAMPOS_OBRIGATORIOS_PERFIL.includes('cnpjCpf') &&
              !config.LOGIN_CPF
          "
        >
          <base-pill-select
            id="pessoa-usuario"
            title="Pessoa Física ou Jurídica"
            class="w-100"
            selectPillClass="select-pill-primary"
            selectBodyClass="select-body-light"
            selectSize="average"
            :options="typeOfPersonOptions"
            v-model="typeOfPerson"
            v-if="config.CAMPOS_OBRIGATORIOS_PERFIL"
          />
        </div>
        <!-- Nome -->
        <div
          class="d-flex flex-row justify-content-center w-75 mb-3"
          v-if="config.CAMPOS_OBRIGATORIOS_PERFIL && config.CAMPOS_OBRIGATORIOS_PERFIL.includes('nome')"
        >
          <base-pill-input
            id="nome-usuario"
            type="text"
            placeholder="Nome"
            :left-middle-pill="true"
            class="w-100"
            :icons="['fas fa-user']"
            :pillColors="['pill-dark']"
            :leftMiddlePillRootColor="config.COR_DESTAQUE_CIRCUNFERENCIA_DOS_INPUTS"
            :leftMiddlePillIconRootColor="config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_DOS_INPUTS"
            :pillIconColors="['icon-light']"
            :pillSize="['pill-average']"
            iconValidation="fas fa-info-circle"
            v-model="$v.name.$model"
            :defaultValue="$v.name.$model"
            :valid="!$v.name.$invalid"
            :error="$v.name.$error"
            v-if="config.CAMPOS_OBRIGATORIOS_PERFIL.includes('nome')"
          />
        </div>
        <!-- CPF e RG -->
        <div
          class="d-flex flex-row justify-content-between w-75"
          v-if="
            config.CAMPOS_OBRIGATORIOS_PERFIL &&
              (config.CAMPOS_OBRIGATORIOS_PERFIL.includes('cnpjCpf') ||
                config.CAMPOS_OBRIGATORIOS_PERFIL.includes('rg'))
          "
        >
          <!-- Campo CPF -->
          <div
            class="d-flex flex-row justify-content-center w-45 mb-3"
            :class="{
              'w-100': !config.CAMPOS_OBRIGATORIOS_PERFIL.includes('rg'),
              'w-45': config.CAMPOS_OBRIGATORIOS_PERFIL.includes('rg'),
            }"
            v-if="
              config.CAMPOS_OBRIGATORIOS_PERFIL.includes('cnpjCpf') &&
                !documentoEstrangeiroAtivo &&
                !documentoJaCadastrado
            "
          >
            <base-pill-input
              id="cpf-cnpj-usuario"
              type="text"
              :placeholder="cnpjCpfLabel"
              :left-middle-pill="true"
              class="w-100"
              :icons="['fas fa-id-card']"
              :pillColors="['pill-dark']"
              :leftMiddlePillRootColor="config.COR_DESTAQUE_CIRCUNFERENCIA_DOS_INPUTS"
              :leftMiddlePillIconRootColor="config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_DOS_INPUTS"
              :pillIconColors="['icon-light']"
              :pillSize="['pill-average']"
              iconValidation="fas fa-info-circle"
              :mask="cnpjCpfMask"
              :masked="false"
              :disabled="config.LOGIN_CPF"
              v-model="$v.cpf.$model"
              :defaultValue="$v.cpf.$model"
              :valid="!$v.cpf.$invalid"
              :error="$v.cpf.$error"
            />
          </div>
          <!-- Campo RG -->
          <div
            class="d-flex flex-row justify-content-center w-45 mb-3"
            :class="{
              'w-100': !config.CAMPOS_OBRIGATORIOS_PERFIL.includes('cnpjCpf'),
              'w-45': config.CAMPOS_OBRIGATORIOS_PERFIL.includes('cnpjCpf'),
            }"
            v-if="config.CAMPOS_OBRIGATORIOS_PERFIL.includes('rg')"
          >
            <base-pill-input
              id="cpf-cnpj-usuario"
              type="text"
              placeholder="RG"
              :left-middle-pill="true"
              class="w-100"
              :icons="['fas fa-id-card']"
              :pillColors="['pill-dark']"
              :leftMiddlePillRootColor="config.COR_DESTAQUE_CIRCUNFERENCIA_DOS_INPUTS"
              :leftMiddlePillIconRootColor="config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_DOS_INPUTS"
              :pillIconColors="['icon-light']"
              :pillSize="['pill-average']"
              iconValidation="fas fa-info-circle"
              :mask="['##.###.###-X']"
              :masked="false"
              v-model="$v.rg.$model"
              :defaultValue="$v.rg.$model"
              :valid="!$v.rg.$invalid"
              :error="$v.rg.$error"
            />
          </div>
        </div>
        <!-- Campo doc. estrangeiro -->
        <div
          v-if="config.CONTEM_ESTRANGEIRO && documentoEstrangeiroAtivo"
          class="d-flex flex-row justify-content-between w-75 mb-3"
        >
          <base-pill-input
            id="documento-estrangeiro-usuario"
            type="tel"
            placeholder="Documento"
            :left-middle-pill="true"
            class="w-100"
            :icons="['fas fa-id-card']"
            :pillColors="['pill-dark']"
            :leftMiddlePillRootColor="config.COR_DESTAQUE_CIRCUNFERENCIA_DOS_INPUTS"
            :leftMiddlePillIconRootColor="config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_DOS_INPUTS"
            :pillIconColors="['icon-light']"
            :pillSize="['pill-average']"
            iconValidation="fas fa-info-circle"
            v-model="documentoNumero"
            :defaultValue="documentoNumero"
            :mask="'##########'"
            :masked="false"
            :valid="true"
          />
        </div>
        <!-- Botão switch de campo estrangeiro -->
        <div
          v-if="config.CONTEM_ESTRANGEIRO && !documentoJaCadastrado"
          class="d-flex flex-row justify-content-between w-75 mb-3"
        >
          <div class="d-flex flex-row align-items-center justify-content-end w-100">
            <small
              class="d-flex align-items-center"
              :class="{ disabled: documentoJaCadastrado }"
              style="font-size: 0.7rem; padding-right: 10px"
              >Estrangeiro</small
            >
            <base-pill-toggle-button
              toggleSize="toggle-button-average"
              :disabled="documentoJaCadastrado"
              @click="alternarCampo()"
            />
          </div>
        </div>
        <!-- Telefone de contato -->
        <div
          v-if="config.CAMPOS_OBRIGATORIOS_PERFIL && config.CAMPOS_OBRIGATORIOS_PERFIL.includes('cnpjCpf')"
          class="d-flex flex-row justify-content-center w-75 mb-3"
        >
          <base-pill-input
            id="telefone-contato-usuario"
            type="tel"
            :placeholder="
              config.VERIFICAR_CELULAR_CADASTRO
                ? `Número de celular`
                : `Telefone contato ${config.TXT_NAO_OBRIGATORIO_CONTATO ? '(Não obrigatório)' : ''}`
            "
            :left-middle-pill="true"
            class="w-100"
            :icons="['fas fa-phone']"
            :pillColors="['pill-dark']"
            :leftMiddlePillRootColor="config.COR_DESTAQUE_CIRCUNFERENCIA_DOS_INPUTS"
            :leftMiddlePillIconRootColor="config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_DOS_INPUTS"
            :pillIconColors="['icon-light']"
            :pillSize="['pill-average']"
            iconValidation="fas fa-info-circle"
            :mask="['(##)#####-####']"
            :masked="false"
            :disabled="!config.LOGIN_CPF"
            v-model="$v.telefoneContato.$model"
            :defaultValue="$v.telefoneContato.$model"
            :valid="config.VERIFICAR_CELULAR_CADASTRO ? verificadorTelefoneContatoValido : !$v.telefoneContato.$invalid"
            :error="config.VERIFICAR_CELULAR_CADASTRO ? verificadorTelefoneContatoValido : $v.telefoneContato.$error"
            @input="verificaNumeroTelefoneContato"
          />
        </div>
        <!-- Data nsc. e Gênero -->
        <div
          class="d-flex flex-row justify-content-between w-75 mb-3"
          v-if="
            config.CAMPOS_OBRIGATORIOS_PERFIL &&
              (config.CAMPOS_OBRIGATORIOS_PERFIL.includes('dataNascimento') ||
                config.CAMPOS_OBRIGATORIOS_PERFIL.includes('genero'))
          "
        >
          <!-- Data nasc. -->
          <base-pill-input
            id="data-nascimento-usuario"
            type="tel"
            placeholder="Data Nascimento"
            :left-middle-pill="true"
            :icons="['fas fa-birthday-cake']"
            :pillColors="['pill-dark']"
            :leftMiddlePillRootColor="config.COR_DESTAQUE_CIRCUNFERENCIA_DOS_INPUTS"
            :leftMiddlePillIconRootColor="config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_DOS_INPUTS"
            :pillIconColors="['icon-light']"
            :pillSize="['pill-average']"
            iconValidation="fas fa-info-circle"
            :mask="['##/##/####']"
            :masked="true"
            v-model="$v.birth.$model"
            :defaultValue="$v.birth.$model"
            :valid="!$v.birth.$invalid"
            :error="$v.birth.$error"
            :class="config.CAMPOS_OBRIGATORIOS_PERFIL.includes('genero') ? 'w-45' : 'w-100'"
            v-if="config.CAMPOS_OBRIGATORIOS_PERFIL.includes('dataNascimento')"
          />
          <!-- Gênero -->
          <base-pill-select
            id="genero-usuario"
            title="Gênero"
            selectPillClass="select-pill-primary"
            selectBodyClass="select-body-light"
            selectSize="average"
            :options="genderOptions"
            v-model="$v.gender.$model"
            :defaultOption="$v.gender.$model"
            :error="$v.gender.$error"
            :class="config.CAMPOS_OBRIGATORIOS_PERFIL.includes('dataNascimento') ? 'w-50' : 'w-100'"
            v-if="config.CAMPOS_OBRIGATORIOS_PERFIL.includes('genero')"
          />
        </div>
        <!-- E-mail -->
        <div
          class="d-flex flex-row justify-content-between w-75 mb-3"
          v-if="config.CAMPOS_OBRIGATORIOS_PERFIL && config.CAMPOS_OBRIGATORIOS_PERFIL.includes('email')"
        >
          <base-pill-input
            id="email-usuario"
            type="email"
            placeholder="E-mail"
            :left-middle-pill="true"
            class="w-100"
            :icons="['fas fa-envelope']"
            :pillColors="['pill-dark']"
            :leftMiddlePillRootColor="config.COR_DESTAQUE_CIRCUNFERENCIA_DOS_INPUTS"
            :leftMiddlePillIconRootColor="config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_DOS_INPUTS"
            :pillIconColors="['icon-light']"
            :pillSize="['pill-average']"
            iconValidation="fas fa-info-circle"
            v-model="$v.email.$model"
            :defaultValue="$v.email.$model"
            :valid="!$v.email.$invalid"
            :error="$v.email.$error"
            v-if="config.CAMPOS_OBRIGATORIOS_PERFIL.includes('email')"
          />
        </div>
        <!-- CNPJ LOJA -->
        <div class="d-flex flex-row justify-content-between w-75 mb-3" v-if="config.ATIVA_CAMPO_PRIMEIRA_LOJA">
          <base-pill-input
            id="primeiraLoja"
            type="text"
            :placeholder="'CNPJ da loja que realizou a primeira compra'"
            :left-middle-pill="true"
            class="w-100"
            :icons="['fas fa-cart-arrow-down']"
            :pillColors="['pill-dark']"
            :mask="['##.###.###/####-##']"
            :leftMiddlePillRootColor="config.COR_DESTAQUE_CIRCUNFERENCIA_DOS_INPUTS"
            :leftMiddlePillIconRootColor="config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_DOS_INPUTS"
            :pillIconColors="['icon-light']"
            :pillSize="['pill-average']"
            iconValidation="fas fa-info-circle"
            :valid="cnpjValido(primeiraLoja)"
            :error="'Deve ser um CNPJ válido'"
            v-model="primeiraLoja"
            :defaultValue="primeiraLoja"
            :disabled="primeiraLojaJaPreenchida"
          />
        </div>
        <!-- Pergunta e opções -->
        <div class="d-flex flex-row justify-content-between w-75 mb-3" v-if="config.MODULO_PERGUNTA_E_RESPOSTA">
          <base-pill-select
            v-if="config.MODULO_PERGUNTA_E_RESPOSTA"
            id="pergunta-opcoes-usuario"
            :title="config.PERGUNTA_AO_CONSUMIDOR"
            class="w-100"
            selectPillClass="select-pill-primary"
            selectBodyClass="select-body-light"
            selectSize="average"
            :options="opcoesResposta"
            :defaultOption="resposta || respostaAnterior"
            v-model="resposta"
          />
        </div>
        <!-- Extra -->
        <div class="d-flex flex-row justify-content-between w-75 mb-3" v-if="config.CONSUMIDOR_CAMPO_EXTRA_ATIVO">
          <base-pill-input
            id="extra"
            type="text"
            :placeholder="config.CONSUMIDOR_CAMPO_EXTRA_NOME || 'Extra'"
            :left-middle-pill="true"
            class="w-100"
            :icons="['fas fa-user-tie']"
            :pillColors="['pill-dark']"
            :leftMiddlePillRootColor="config.COR_DESTAQUE_CIRCUNFERENCIA_DOS_INPUTS"
            :leftMiddlePillIconRootColor="config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_DOS_INPUTS"
            :pillIconColors="['icon-light']"
            :pillSize="['pill-average']"
            iconValidation="fas fa-info-circle"
            :valid="true"
            v-model="extra"
            :defaultValue="extra"
          />
        </div>
        <!-- Dinâmica Instagram -->
        <div class="d-flex flex-row justify-content-between w-75 mb-3" v-if="config.DINAMICA_INSTAGRAM">
          <base-pill-input
            id="id-instagram"
            type="text"
            placeholder="Seu @ do instagram"
            :left-middle-pill="true"
            class="w-100"
            :icons="['fab fa-instagram']"
            :pillColors="['pill-dark']"
            :leftMiddlePillRootColor="config.COR_DESTAQUE_CIRCUNFERENCIA_DOS_INPUTS"
            :leftMiddlePillIconRootColor="config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_DOS_INPUTS"
            :pillIconColors="['icon-light']"
            :pillSize="['pill-average']"
            iconValidation="fas fa-info-circle"
            :valid="true"
            v-model="identificacaoDinamicaInstagram"
            :defaultValue="identificacaoDinamicaInstagram"
          />
        </div>
        <!-- Botão switch de campo se aceita receber notificacao -->
        <div
          v-if="config.CONCORDAR_RECEBER_MENSAGENS && !config.OCULTAR_RECEBER_MENSAGENS_EDITAR_PERFIL"
          class="d-flex flex-row justify-content-between w-75 mb-3"
        >
          <div class="d-flex flex-row align-items-center justify-content-end w-100">
            <small class="d-flex align-items-center" style="font-size: 0.7rem; padding-right: 10px"
              >Aceito receber informações e comunicações</small
            >
            <base-pill-toggle-button
              toggleSize="toggle-button-average"
              :checked="aceitaNotificacao"
              @click="alternarCampoNotificacao()"
            />
          </div>
        </div>
        <!-- Endereço -->
        <div
          class="d-flex flex-column justify-content-center w-75"
          v-if="config.CAMPOS_OBRIGATORIOS_PERFIL && config.CAMPOS_OBRIGATORIOS_PERFIL.includes('endereco')"
        >
          <!-- CEP e UF -->
          <div class="d-flex flex-row justify-content-center w-100 mt-2">
            <p style="color: var(--text-color-highlight)">
              <strong>Endereço</strong>
            </p>
          </div>
          <!-- CEP -->
          <base-pill-input
            id="cep-usuario"
            type="tel"
            placeholder="CEP"
            :left-middle-pill="true"
            class="w-100 mb-3"
            :icons="['fas fa-map-marker-alt']"
            :pillColors="['pill-dark']"
            :leftMiddlePillRootColor="config.COR_DESTAQUE_CIRCUNFERENCIA_DOS_INPUTS"
            :leftMiddlePillIconRootColor="config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_DOS_INPUTS"
            :pillIconColors="['icon-light']"
            :pillSize="['pill-average']"
            iconValidation="fas fa-info-circle"
            :mask="['#####-###']"
            :masked="true"
            v-model="$v.cep.$model"
            :defaultValue="$v.cep.$model"
            :valid="!$v.cep.$invalid"
            :error="$v.cep.$error"
          />
          <!-- UF -->
          <base-pill-select
            id="uf-usuario"
            title="Estado"
            class="w-100 mb-3"
            selectPillClass="select-pill-primary"
            selectBodyClass="select-body-light"
            selectSize="average"
            :options="ufs"
            v-model="$v.state.$model"
            :defaultValue="$v.state.$model"
            :defaultOption="$v.state.$model"
            :error="$v.state.$error"
            @change="buscaCidades(ufs.filter(e => $v.state.$model == e.value)[0].idIBGE)"
          />
          <!-- Rua -->
          <base-pill-input
            id="rua-usuario"
            type="text"
            placeholder="Rua"
            :left-middle-pill="true"
            class="w-100 mb-3"
            :icons="['fas fa-map-marked-alt']"
            :pillColors="['pill-dark']"
            :leftMiddlePillRootColor="config.COR_DESTAQUE_CIRCUNFERENCIA_DOS_INPUTS"
            :leftMiddlePillIconRootColor="config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_DOS_INPUTS"
            :pillIconColors="['icon-light']"
            :pillSize="['pill-average']"
            iconValidation="fas fa-info-circle"
            v-model="$v.street.$model"
            :defaultValue="$v.street.$model"
            :valid="!$v.street.$invalid"
            :error="$v.street.$error"
          />
          <!-- Número e Bairro -->
          <div class="d-flex flex-row justify-content-between w-100">
            <base-pill-input
              id="numero-endereco-usuario"
              type="tel"
              placeholder="N°"
              :left-middle-pill="true"
              class="w-40 mb-3"
              :icons="['fas fa-home']"
              :pillColors="['pill-dark']"
              :leftMiddlePillRootColor="config.COR_DESTAQUE_CIRCUNFERENCIA_DOS_INPUTS"
              :leftMiddlePillIconRootColor="config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_DOS_INPUTS"
              :pillIconColors="['icon-light']"
              :pillSize="['pill-average']"
              iconValidation="fas fa-info-circle"
              :mask="['######']"
              :masked="false"
              v-model="$v.number.$model"
              :defaultValue="$v.number.$model"
              :valid="!$v.number.$invalid"
              :error="$v.number.$error"
            />

            <base-pill-input
              id="bairro-usuario"
              type="text"
              placeholder="Bairro"
              :left-middle-pill="true"
              class="w-55 mb-3"
              :icons="['fas fa-map-marked-alt']"
              :pillColors="['pill-dark']"
              :leftMiddlePillRootColor="config.COR_DESTAQUE_CIRCUNFERENCIA_DOS_INPUTS"
              :leftMiddlePillIconRootColor="config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_DOS_INPUTS"
              :pillIconColors="['icon-light']"
              :pillSize="['pill-average']"
              iconValidation="fas fa-info-circle"
              v-model="$v.neighborhood.$model"
              :defaultValue="$v.neighborhood.$model"
              :valid="!$v.neighborhood.$invalid"
              :error="$v.neighborhood.$error"
            />
          </div>
          <!-- Cidade e complemento -->
          <base-pill-input
            id="cidade-usuario"
            type="text"
            placeholder="Cidade"
            :left-middle-pill="true"
            class="w-100 mb-3"
            :icons="['fas fa-map-marked-alt']"
            :pillColors="['pill-dark']"
            :leftMiddlePillRootColor="config.COR_DESTAQUE_CIRCUNFERENCIA_DOS_INPUTS"
            :leftMiddlePillIconRootColor="config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_DOS_INPUTS"
            :pillIconColors="['icon-light']"
            :pillSize="['pill-average']"
            iconValidation="fas fa-info-circle"
            v-model="$v.city.$model"
            :defaultValue="$v.city.$model"
            :valid="!$v.city.$invalid"
            :error="$v.city.$error"
            listId="nomeCidade"
            :dataList="true"
            :listOptions="listaCidades"
          />

          <base-pill-input
            id="complemento-endereco-usuario"
            type="text"
            placeholder="Complemento"
            :left-middle-pill="true"
            class="w-100 mb-3"
            :icons="['fas fa-map-marked-alt']"
            :pillColors="['pill-dark']"
            :leftMiddlePillRootColor="config.COR_DESTAQUE_CIRCUNFERENCIA_DOS_INPUTS"
            :leftMiddlePillIconRootColor="config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_DOS_INPUTS"
            :pillIconColors="['icon-light']"
            :pillSize="['pill-average']"
            iconValidation="fas fa-info-circle"
            v-model="complement"
            :defaultValue="complement"
            :valid="true"
          />
        </div>
        <!-- Tela Pic Pay -->
        <div class="d-flex flex-row justify-content-between w-75" v-if="config.TELAS_PICPAY">
          <div class="col-6">
            <p class="mb-1 mt-2 title-picpay" style="color: var(--text-color-highlight)">
              <strong>Você é cliente PicPay?</strong>
            </p>
            <base-pill-toggle-button
              toggleSize="toggle-button-average"
              :checked="clientePicPay"
              @click="alternarCampoClientePicPay()"
            />
            <div v-if="clientePicPay" class="mt-2 row">
              <base-pill-input
                id="id-picpay"
                type="text"
                placeholder="Informe seu usuário PicPay"
                :left-middle-pill="true"
                class="w-100"
                :icons="['fas fa-at']"
                :pillColors="['pill-dark']"
                :leftMiddlePillRootColor="config.COR_DESTAQUE_CIRCUNFERENCIA_DOS_INPUTS"
                :leftMiddlePillIconRootColor="config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_DOS_INPUTS"
                :pillIconColors="['icon-light']"
                :pillSize="['pill-average']"
                iconValidation="fas fa-info-circle"
                :valid="true"
                v-model="identificacaoDinamica"
                :defaultValue="identificacaoDinamica"
                v-tooltip.top="
                  `Caso você não saiba seu usuário PicPay, entre no seu APP PicPay e clique em configurações ou no ícone “Engrenagem” para encontrar o seu “@”`
                "
              />
            </div>
          </div>
          <div
            class="col-6 d-flex align-content-center justify-content-center align-items-center flex-column"
            v-if="!config.OCULTAR_MENSAGEM_PICPAY_COMPLETAR_CADASTRO"
          >
            <div class="card-picpay">
              <div style="text-align: center; line-height: 1">
                Clientes
                <img src="@/assets/images/logo-picpay.png" alt="Logo PicPay" style="max-height: 1.3rem" />
                ganham
              </div>
              <div style="display: flex; justify-content: center; margin-top: 5px; line-height: 1; align-items: center">
                <div style="color: #00cc73; font-weight: 900; font-size: 2.2rem; margin-right: 5px">2X</div>
                <div>números<br />da sorte!</div>
              </div>
            </div>
            <span class="small text-center"><small>Caso ainda não seja cliente PicPay clique aqui</small></span>
            <a
              class="btn button-radius font-weight-bold text-uppercase w-100 shadow-sm mt-2 btn-picpay wBtnPicPay"
              @click="abrirJanelaBrowser('https://picpay.onelink.me/R25q/157b4e38')"
              >QUERO SER PICPAY!</a
            >
          </div>
        </div>
        <!-- Actions -->
        <div
          class="w-75 d-flex align-items-center mt-3 mb-3"
          :class="{
            'justify-content-center': !config.EXIBIR_BOTAO_EXCLUIR_LOGIN,
            'justify-content-between': config.EXIBIR_BOTAO_EXCLUIR_LOGIN,
          }"
        >
          <base-pill-button
            :type="'dark'"
            :right-middle-pill="'fas fa-times'"
            :pillColors="['pill-primary']"
            :pillIconColors="['icon-light']"
            :rightMiddlePillRootColor="'var(--red)'"
            :rightMiddlePillIconRootColor="'#fff'"
            :pillSize="['pill-small']"
            @click="modalConfirmarExclusao = true"
            v-if="config.EXIBIR_BOTAO_EXCLUIR_LOGIN"
          >
            <template v-slot:pill-button-title>
              <span class="w-100 f-08 px-2 pl-2 text-center text-white">Excluir conta</span>
            </template>
          </base-pill-button>

          <base-pill-button
            :type="'light'"
            :right-middle-pill="'fas fa-check-circle'"
            :pillColors="['pill-light']"
            :pillIconColors="['icon-dark']"
            :rightMiddlePillRootColor="config.COR_DESTAQUE_CIRCUNFERENCIA_DOS_BOTOES"
            :rightMiddlePillIconRootColor="config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_DOS_BOTOES"
            :pillSize="['pill-average']"
            :disabled="loading"
            @click="validacaoSimplificada"
          >
            <template v-slot:pill-button-title>
              <span class="w-90 px-2 pl-3 text-center text-black">Salvar</span>
            </template>
          </base-pill-button>
        </div>
      </section>

      <base-modal :show.sync="modalConfirmarExclusao" modal-classes="modal-dialog-top qr-modal" body-classes="p-0">
        <template slot="header">Excluir conta</template>
        <template>
          <b-container>
            <div class="row mt-5 mb-3">
              <div class="col-12 mb-4 text-center">
                <h4 class="text-primary">
                  <strong>Deseja excluir sua conta?</strong>
                </h4>

                <p class="px-4 mb-0">
                  Ao prosseguir, sua conta será inativada e você ficará impossibilitado de realizar login ou receber
                  qualquer tipo de premiação.
                </p>
              </div>

              <div class="col-12 d-flex">
                <base-button type="danger" class="w-100 mr-4" @click="modalConfirmarExclusao = false">
                  <strong>Voltar</strong>
                </base-button>

                <base-button type="primary" class="w-100" @click="excluirConta">
                  <strong class="text-white">Confirmar</strong>
                </base-button>
              </div>
            </div>
          </b-container>
        </template>
      </base-modal>

      <!-- Modal de confirmar número de celular -->
      <base-modal
        :show.sync="exibirModalAutenticacaoTemporaria"
        modal-classes="modal-dialog-top qr-modal"
        body-classes="p-0"
      >
        <template>
          <b-container>
            <div class="row d-flex justify-content-center m-4">
              <div class="w-75 d-flex flex-column align-items-center justify-content-center mb-3">
                <div class="row mr-2 ml-2 mb-3">
                  <span class="f-11 text-center diminuir-texto"
                    >Para alterar seu perfil, precisamos que confirme o número de seu celular, para isso, insira o
                    código que foi enviado para o número
                    <span class="font-weight-bold"> {{ formataTelefone(telefoneContato) }}</span> via
                    {{ textoExibicaoModalConfirmarCelular }}:</span
                  >
                </div>

                <base-pill-temporary-code
                  class="w-100"
                  v-model="codigoAutenticacaoTemporaria"
                  :key="contadorCodigoAutenticacaoTemporaria"
                />

                <span class="w-100 ml-2 mr-2 text-center text-black f-09 mt-3"
                  >Não recebeu o código? Clique no botão abaixo para reenviar.</span
                >
              </div>
              <!-- Reenviar -->
              <base-pill-button
                :type="'light'"
                :right-middle-pill="'fas fa-chevron-circle-right'"
                :pillColors="['pill-light']"
                :pillIconColors="['icon-dark']"
                :rightMiddlePillRootColor="config.COR_DESTAQUE_CIRCUNFERENCIA_DOS_BOTOES"
                :rightMiddlePillIconRootColor="config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_DOS_BOTOES"
                :pillSize="['pill-average']"
                :disabled="enviandoRequisicaoConfirmarCelular || tempoInicial > 0"
                @click="reenviarRequisicaoConfirmarCelular"
              >
                <template v-slot:pill-button-title>
                  <span class="w-90 pl-3 pr-2 text-center text-black f-07"
                    >Reenviar {{ tempoInicial > 0 ? `(${tempoInicial})` : '' }}</span
                  >
                </template>
              </base-pill-button>
            </div>
          </b-container>
        </template>
      </base-modal>
    </template>

    <template v-slot:footer-pill-nav-bar>
      <img :src="config.INTRO_IMG_URL" class="w-25 mt-2 mb-2" alt="Logo Plataforma QR Sorteios" />
    </template>
  </base-pill-nav-bar>
</template>

<script>
import { required, minLength, sameAs } from 'vuelidate/lib/validators';
import { mapActions, mapGetters } from 'vuex';
import { BRASIL_STATES, Validation } from '../../lib/Helper';
import { redirect } from '../../lib/Storage';
import GERADOR_CPF from 'cpf';

export default {
  name: 'perfil',

  beforeMount() {
    this.httpClient = new this.$httpClient();
    this.opcoesResposta =
      this.config.MODULO_PERGUNTA_E_RESPOSTA &&
      this.config.LISTA_OPCOES_DE_RESPOSTAS &&
      this.config.LISTA_OPCOES_DE_RESPOSTAS.length
        ? this.config.LISTA_OPCOES_DE_RESPOSTAS.map(e => {
            return {
              value: e,
              desc: e,
            };
          })
        : [];
  },

  // mounted() {
  //   console.log('mounted');
  //   let contagem = 0;
  //   let tempo = setInterval(() => {
  //     contagem++;
  //     if (this.profile && this.profile.endereco && this.profile.endereco.estado && this.ufs) {
  //       let estado = this.profile.endereco.estado;
  //       this.buscaCidades(this.ufs.filter(e => estado == e.value)[0].idIBGE);
  //     }
  //     if (contagem >= 6) {
  //       clearInterval(tempo);
  //     }
  //   }, 1000);
  // },

  beforeDestroy() {
    this.limparIntervaloTempo();
    this.telefoneContatoAoAbrirPerfil = null;
  },

  computed: {
    ...mapGetters({
      config: 'config/getConfig',
      profile: 'consumidor/getProfile',
      loading: 'consumidor/getLoadingStatus',
      consumidorCupom: 'cupom/getConsumidorCupom',
    }),

    YYYY_MM_DD() {
      return this.birth
        .split('/')
        .reverse()
        .join('-');
    },

    typeOfPersonOptions() {
      return [
        { desc: 'Pessoa Física', value: 'fisica' },
        { desc: 'Pessoa Jurídica', value: 'juridica' },
      ];
    },

    cnpjCpfLabel() {
      return ['cpfCnpj', 'cnpj'].includes(this.config.TIPO_USUARIO) && this.typeOfPerson == 'juridica' ? 'CNPJ' : 'CPF';
    },

    cnpjCpfMask() {
      return ['cpfCnpj', 'cnpj'].includes(this.config.TIPO_USUARIO) && this.typeOfPerson == 'juridica'
        ? ['##.###.###/####-##']
        : ['###.###.###-##'];
    },

    isProd: function() {
      if (typeof BuildInfo != 'undefined' && BuildInfo.packageName) {
        return this.config.VUE_APP_PROD_PACK_NAME == BuildInfo.packageName;
      } else {
        return location.href.startsWith(this.config.VUE_APP_PROD_BASE_URL);
      }
    },
  },

  data: () => ({
    show: false,
    ufs: BRASIL_STATES,
    genderOptions: [
      { value: 'masculino', desc: 'Masculino' },
      { value: 'feminino', desc: 'Feminino' },
      { value: 'outro', desc: 'Outro' },
    ],
    opcoesResposta: [],
    resposta: '',
    respostaAnterior: '',
    extra: '',
    primeiraLoja: '',
    primeiraLojaJaPreenchida: false,
    httpClient: null,
    // form data
    name: '',
    cpf: '',
    rg: '',
    birth: '',
    telefoneContato: '',
    gender: null,
    typeOfPerson: 'fisica',
    email: '',
    // form data address
    street: '',
    number: '',
    state: null,
    neighborhood: '',
    city: '',
    complement: '',
    cep: '',
    aceitaNotificacao: false,
    identificacaoDinamica: '@',
    clientePicPay: false,
    identificacaoDinamicaInstagram: '@',
    semInstagramAntes: false,
    documentoEstrangeiroAtivo: false,
    documentoNumero: '',
    documentoJaCadastrado: false,
    listaCidades: [],

    modalConfirmarExclusao: false,

    telefoneContatoJaUtilizado: false,
    verificadorTelefoneContatoValido: false,
    exibirModalAutenticacaoTemporaria: false,
    textoExibicaoModalConfirmarCelular: null,

    codigoAutenticacaoTemporaria: '',
    contadorCodigoAutenticacaoTemporaria: 0,
    tempoInicial: 30,
    intervaloReenviarConfirmacaoCelular: null,
    enviandoRequisicaoConfirmarCelular: false,
    telefoneContatoAoAbrirPerfil: null,
  }),

  methods: {
    ...mapActions({
      updateProfile: 'consumidor/updateProfile',
      obterConsumidorCupom: 'cupom/obterConsumidorCupom',
      signOut: 'autenticacao/signOut',
      alterarDinamicaCupom: 'cupom/alterarDinamicaCupom',
      enviarDeviceID: 'consumidor/enviarDeviceID',
      switchSettingsMenuState: 'ui/switchSettingsMenuState',
      excluirAcc: 'consumidor/excluirConta',
      solicitarCodigoAutenticacaoTemporaria: 'autenticacao/solicitarCodigoAutenticacaoTemporaria',
      verificaCodigoAutenticacaoTemporaria: 'autenticacao/verificaCodigoAutenticacaoTemporaria',
      verificaTelefoneContato: 'consumidor/verificaTelefoneContatoExiste',
    }),

    cnpjValido: function(cnpj) {
      return Validation.cpfCnpj(cnpj);
    },

    async excluirConta() {
      const res = await this.excluirAcc({ usuario: this.cpf });

      if (res.status !== 200) {
        this.$emit('emmitInfoModal', {
          event: 'modal-informacao-generica',
          data: {
            icone: 'fas fa-times-circle',
            titulo: 'Ops...',
            mensagem: res.msg,
            textoBotao: 'OK',
          },
        });
        return;
      }

      this.emitirModalInformacao({
        icone: 'fas fa-thumbs-up',
        titulo: 'Sucesso!',
        mensagem: res.msg,
        textoBoato: 'OK',
      });

      this.show = false;

      setTimeout(() => {
        this.signOut();
        this.$router.push({ name: 'autenticacao' });
      }, 300);
    },

    buscaCidades: async function(idEstado) {
      this.listaCidades = [];
      const apiIBGE = `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${idEstado}/municipios`;
      await fetch(apiIBGE)
        .then(response => response.json())
        .then(data => {
          this.listaCidades = data.filter(e => e.nome).map(e => e.nome);
        })
        .catch(error => {
          console.error('Error:', error);
        });
    },

    preencheCidade: function(id) {
      const apiIBGE = `https://servicodados.ibge.gov.br/api/v1/localidades/municipios/${id}`;
      fetch(apiIBGE)
        .then(response => response.json())
        .then(data => {
          return data.nome;
        })
        .catch(error => {
          console.error('Error:', error);
        });
    },

    alternarCampo: function() {
      if (this.documentoEstrangeiroAtivo) {
        this.documentoEstrangeiroAtivo = false;
        this.documentoNumero = this.profile.documentoVariavel;
        this.cpf = '';
      } else {
        this.documentoEstrangeiroAtivo = true;
        this.documentoNumero = this.cpf = '';
      }
    },

    alternarCampoNotificacao: function() {
      if (this.aceitaNotificacao) {
        this.aceitaNotificacao = false;
      } else {
        this.aceitaNotificacao = true;
      }
    },

    alternarCampoClientePicPay: function() {
      if (
        this.clientePicPay &&
        (!this.consumidorCupom.dinamicaCupom ||
          !this.consumidorCupom.dinamicaCupom.find(e => e.dinamica.toUpperCase() == 'PICPAY'))
      ) {
        this.clientePicPay = false;
      } else {
        this.clientePicPay = true;
      }
    },

    autoFillForm() {
      if (this.profile) {
        this.name = this.profile.nome || '';
        this.email = this.profile.email || '';

        if (this.config.LOGIN_CPF) this.telefoneContato = this.profile.telefoneContato;

        if (
          this.config.MODULO_PERGUNTA_E_RESPOSTA &&
          this.config.PERGUNTA_AO_CONSUMIDOR &&
          this.config.LISTA_OPCOES_DE_RESPOSTAS
        ) {
          this.respostaAnterior = this.resposta = this.profile.resposta || '';
          // this.opcoesResposta = this.opcoesResposta.filter(
          //   (e) => e.value !== this.respostaAnterior
          // )
        }

        if (this.config.CONSUMIDOR_CAMPO_EXTRA_ATIVO) {
          this.extra = this.extra || this.profile.extra;
        }

        if (!this.config.LOGIN_CPF) this.telefoneContato = this.profile.telefone;

        if (this.config.LOGIN_CPF) {
          this.cpf = this.profile.telefone;
        } else {
          this.cpf = this.profile.cpf;
        }

        if (['cpfCnpj', 'cnpj'].includes(this.config.TIPO_USUARIO) && this.profile.cpf.length == 14) {
          this.typeOfPerson = 'juridica';
        } else {
          this.typeOfPerson = 'fisica';
          this.cpf = this.profile.telefone || '';
        }

        if (this.cpf.length == 14) {
          this.typeOfPerson = 'juridica';
        }

        if (this.config.LOGIN_CPF) {
          this.cpf = this.profile.telefone;
        } else {
          this.cpf = this.profile.cpf;
        }

        this.birth = (this.profile.dataNascimento || '')
          .split('-')
          .reverse()
          .join('/');
        this.gender = this.profile.genero || '';
        this.rg = this.profile.rg || '';

        if (this.profile.endereco) {
          this.street = this.profile.endereco.endereco || '';
          this.neighborhood = this.profile.endereco.bairro || '';
          this.number = this.profile.endereco.numero || '';
          this.state = this.profile.endereco.estado || '';
          this.cep = this.profile.endereco.cep || '';
          this.complement = this.profile.endereco.complemento || '';
          this.city = this.profile.endereco.cidade || '';
        }

        if (this.profile.documentoVariavel) {
          this.documentoEstrangeiroAtivo = true;
          this.documentoJaCadastrado = true;
          this.documentoNumero = this.profile.documentoVariavel;
        }

        if (this.profile.primeiraLoja && this.profile.primeiraLoja.cnpjCpf) {
          this.primeiraLojaJaPreenchida = true;
          this.primeiraLoja = this.profile.primeiraLoja.cnpjCpf;
        }

        if (this.config.CONCORDAR_RECEBER_MENSAGENS) {
          this.aceitaNotificacao = this.profile.aceitaNotificacao;
        }
        this.obterConsumidorCupom({
          telefone: this.profile.telefone,
        }).then(response => {
          if ([200, 201].includes(response.status)) {
            this.telefoneContatoAoAbrirPerfil = this.consumidorCupom.telefoneContato;
            if (this.consumidorCupom.dinamicaCupom.find(e => e.dinamica.toUpperCase() == 'PICPAY')) {
              this.clientePicPay = true;
              this.identificacaoDinamica = this.consumidorCupom.dinamicaCupom.find(
                e => e.dinamica.toUpperCase() == 'PICPAY'
              ).identificacao;
            } else {
              this.clientePicPay = false;
            }

            if (this.consumidorCupom.dinamicaCupom.find(e => e.dinamica.toUpperCase() == 'INSTAGRAM')) {
              this.identificacaoDinamicaInstagram = this.consumidorCupom.dinamicaCupom.find(
                e => e.dinamica.toUpperCase() == 'INSTAGRAM'
              ).identificacao;
            } else {
              this.semInstagramAntes = true;
            }
          }
        });
      }
    },

    abrirJanelaBrowser(url) {
      window.qrsorteios.openBrowser(url, '_system');
    },

    verificaIdade() {
      let data = new Date();
      let nascimento = new Date(this.YYYY_MM_DD);
      let idade = data.getFullYear() - nascimento.getFullYear();
      if (
        new Date(data.getFullYear(), data.getMonth(), data.getDate()) <
        new Date(data.getFullYear(), nascimento.getMonth(), nascimento.getDate() + 1)
      )
        idade--;

      if (idade >= 18) {
        return true;
      } else {
        return false;
      }
    },

    clearForm() {
      this.name = '';
      this.birth = '';
      this.cpf = '';
      this.documentoNumero = '';
      this.rg = '';
      this.gender = null;
      this.resposta = '';
      this.extra = '';
      this.email = '';
      this.street = '';
      this.number = '';
      (this.state = null), (this.neighborhood = '');
      this.city = '';
      this.complement = '';
      this.cep = '';
    },

    checkForm(e) {
      if (this.$v.$invalid) {
        this.emitirModalInformacao({
          icone: 'fas fa-exclamation-circle',
          titulo: 'Ops...',
          mensagem: 'Perfil atualizado com sucesso!',
          textoBoato: 'OK',
        });
        this.$v.$touch();
      } else {
        this.submitForm();
      }
    },

    submitForm() {
      //Verificacao identificacao dinamica preenchida
      if (
        this.config.TELAS_PICPAY &&
        this.clientePicPay &&
        (!this.identificacaoDinamica ||
          this.identificacaoDinamica.length < 3 ||
          !this.identificacaoDinamica.startsWith('@') ||
          this.identificacaoDinamica.trim().match(/\s/))
      ) {
        return this.emitirModalInformacao({
          icone: 'fas fa-exclamation-circle',
          titulo: 'Ops...',
          mensagem: 'Erro na validação, por favor preencha o usuário do PicPay corretamente.',
          textoBoato: 'OK',
        });
      }

      const profile = {
        nome: this.name.trim().replace(/\s{2,}/g, ' '),
        cpf: this.cpf,
        telefone: this.profile.telefone,
        dataNascimento: this.YYYY_MM_DD,
        rg: this.rg,
        telefoneContato: this.telefoneContato,
        email: this.email,
        genero: this.gender,
        endereco: {
          endereco: this.street
            .replace(/"/gi, "'")
            .replace(/ü/gi, 'u')
            .replace(/[ªº°°¹²³()\[\]{}?!=#$%¨*~^;<>|\\\/£¢¬§*°]/gi, ''),
          numero: this.number,
          complemento: this.complement,
          bairro: this.neighborhood.replace(/[ªº°°¹²³]/gi, ''),
          cidade: this.city,
          cep: this.cep,
          estado: this.state,
        },
        documentoVariavel: this.documentoNumero,
        documentoEstrangeiroJaCadastrado: this.documentoJaCadastrado,
        aceitaNotificacao: this.aceitaNotificacao,
        resposta: this.resposta,
        extra: this.extra,
        primeiraLoja: this.primeiraLoja,
      };

      this.updateProfile({
        profile,
      }).then(response => {
        this.contadorCodigoAutenticacaoTemporaria++;
        this.exibirModalAutenticacaoTemporaria = false;
        if ([200, 201].includes(response.status)) {
          this.$emit('emmitEvent', {
            event: 'gtm',
            data: {
              event: 'perfilAtualizado',
              category: 'PerfilUsuario',
              action: 'form',
              label: 'Alterou informações do perfil',
              value: profile.cpf,
            },
          });
          if (this.config.TELAS_PICPAY && this.clientePicPay && !this.config.DINAMICA_INSTAGRAM) {
            let dataDinamica = {
              telefone: this.profile.telefone,
              dinamicaCupom: [
                {
                  dinamica: 'PICPAY',
                  identificacao: this.identificacaoDinamica.trim(),
                  multiplicador: this.config.MULTIPLICADOR_DINAMICA_CUPOM,
                },
              ],
            };
            this.alterarDinamicaCupom({ dataDinamica });
          } else if (
            this.config.TELAS_PICPAY &&
            this.clientePicPay &&
            this.config.DINAMICA_INSTAGRAM &&
            this.semInstagramAntes
          ) {
            let dataDinamica = {
              telefone: this.profile.telefone,
              dinamicaCupom: [
                {
                  dinamica: 'PICPAY',
                  identificacao: this.identificacaoDinamica.trim(),
                  multiplicador: this.config.MULTIPLICADOR_DINAMICA_CUPOM,
                },
                {
                  dinamica: 'INSTAGRAM',
                  identificacao: this.identificacaoDinamicaInstagram.trim(),
                  multiplicador: this.config.MULTIPLICADOR_DINAMICA_INSTAGRAM,
                },
              ],
            };
            this.alterarDinamicaCupom({ dataDinamica }).then(response => {
              if ([200, 201].includes(response.status)) {
                window.open(this.config.LINK_INSTAGRAM, '_blank');
              }
            });
          } else if (
            (!this.config.TELAS_PICPAY || !this.clientePicPay) &&
            this.config.DINAMICA_INSTAGRAM &&
            this.semInstagramAntes
          ) {
            let dataDinamica = {
              telefone: this.profile.telefone,
              dinamicaCupom: [
                {
                  dinamica: 'INSTAGRAM',
                  identificacao: this.identificacaoDinamicaInstagram.trim(),
                  multiplicador: this.config.MULTIPLICADOR_DINAMICA_INSTAGRAM,
                },
              ],
            };
            this.alterarDinamicaCupom({ dataDinamica }).then(response => {
              if ([200, 201].includes(response.status)) {
                window.open(this.config.LINK_INSTAGRAM, '_blank');
              }
            });
          }

          if (this.profile.aceitaNotificacao && window.qrsorteios.deviceId) {
            const ferramentasNotificacao = this.config.FERRAMENTA_ENVIO_NOTIFICACOES.split(',')
              .map(e => e.trim())
              .filter(e => e);

            if (ferramentasNotificacao.includes('sns') && typeof cordova !== 'undefined') {
              this.enviarDeviceID({
                data: {
                  id: window.qrsorteios.deviceId,
                  descricao: window.qrsorteios.device,
                  usuario: this.profile.telefone,
                  servicoNotificador: 'sns',
                  tipoDispositivo: cordova.platformId,
                },
              }).then(res => {
                if (![200, 201].includes(res.status)) {
                  this.emitirModalInformacao({
                    icone: 'fas fa-exclamation-circle',
                    titulo: 'Ops...',
                    mensagem: res.msg,
                    textoBoato: 'OK',
                  });
                }
              });
            }

            if (ferramentasNotificacao.includes('onesignal') && typeof cordova === 'undefined') {
              this.enviarDeviceID({
                data: {
                  id: window.qrsorteios.deviceId,
                  descricao: window.qrsorteios.device,
                  usuario: this.profile.telefone,
                  servicoNotificador: 'onesignal',
                  tipoDispositivo: 'pwa',
                },
              }).then(res => {
                if (![200, 201].includes(res.status)) {
                  this.emitirModalInformacao({
                    icone: 'fas fa-exclamation-circle',
                    titulo: 'Ops...',
                    mensagem: res.msg,
                    textoBoato: 'OK',
                  });
                }
              });
            }
          }

          this.emitirModalInformacao({
            icone: 'fas fa-thumbs-up',
            titulo: 'Sucesso!',
            mensagem: this.config.MENSAGEM_PADRAO_SUCESSO_PERFIL_ATUALIZADO,
            textoBoato: 'OK',
          });

          this.show = false;
          this.emitirEventosReload();
          this.switchSettingsMenuState(false);

          const redirectMecanism = new redirect(['cupomEscaneado', 'emailVerificado']);
          const redirectRoutes = redirectMecanism.checkRedirectStatus();
          const foundRoute = redirectRoutes.find(r => r.redirect);
          if (foundRoute) {
            console.log(foundRoute.path);
            this.$router.push({ path: foundRoute.path });
          }
        } else {
          this.$emit('emmitInfoModal', {
            event: 'modal-informacao-generica',
            data: {
              icone: 'fas fa-times-circle',
              titulo: 'Ops...',
              mensagem: response.msg,
              textoBotao: 'OK',
              acao: `onClose: () => {
                console.log('aaa');
              }`,
            },
          });
          this.emitirModalInformacao({
            icone: 'fas fa-exclamation-circle',
            titulo: 'Ops...',
            mensagem: response.msg,
            textoBotao: 'OK',
            acao: () => {
              if (response.msg == ' Usuário não autorizado.') {
                setTimeout(() => {
                  this.signOut();
                  this.$router.push({ name: 'autenticacao' });
                }, 300);
              }
            },
          });
        }
      });
    },
    emitirEventosReload() {
      // Atualizando informações do aplicativo
      // emitir eventos do quiz...
      if (this.config.USUARIO_CUPONS) {
        this.$emit('emmitEvent', {
          event: 'reloadCoupons',
        });
      }
      if (this.config.USUARIO_RASPADINHA_ALTERNATIVA) {
        this.$emit('emmitEvent', {
          event: 'reloadAltScratches',
        });
      }
      if (this.config.USUARIO_RASPADINHA) {
        this.$emit('emmitEvent', {
          event: 'reloadScratches',
        });
      }
      if (this.config.USUARIO_CONTA_CORRENTE) {
        this.$emit('emmitEvent', {
          event: 'reloadCheckingAccount',
        });
      }
      if (true) {
        this.$emit('emmitEvent', {
          event: 'reloadUserQuestionsQuiz',
        });
      }

      this.$emit('emmitEvent', {
        event: 'reloadCashback',
      });
    },

    async validacaoSimplificada() {
      // Alterando para pessoas estrangeiras
      if (
        this.config.CONTEM_ESTRANGEIRO &&
        this.documentoEstrangeiroAtivo &&
        !this.documentoJaCadastrado &&
        !this.config.LOGIN_CPF
      ) {
        this.cpf = GERADOR_CPF.generate(false);
      }

      if (this.config && this.config.CAMPOS_OBRIGATORIOS_PERFIL) {
        if (this.config.CAMPOS_OBRIGATORIOS_PERFIL.includes('endereco') && this.city && this.$v.city.cidadeValida) {
          this.formataCidade();
        }
        const invalidFields = [];
        let formularioPreenchido = true;
        let menorIdade = false;
        let campoInvalido = null;

        if (this.config.VERIFICAR_CELULAR_CADASTRO) {
          if (!this.verificadorTelefoneContatoValido || !this.telefoneContato) {
            invalidFields.push('Número do celular');

            if (invalidFields.length) {
              campoInvalido = invalidFields.join(', ');

              return this.emitirModalInformacao({
                icone: 'fas fa-exclamation-circle',
                titulo: 'Ops...',
                mensagem: `Campo inválido: ${campoInvalido}`,
                textoBoato: 'OK',
              });
            }
          }
        }

        if (this.config.CAMPOS_OBRIGATORIOS_PERFIL.includes('nome') && !this.name) {
          formularioPreenchido = false;
          invalidFields.push('Nome');
        }
        if (this.config.CONTEM_ESTRANGEIRO && !this.documentoNumero && this.documentoEstrangeiroAtivo) {
          formularioPreenchido = false;
          invalidFields.push('Documento');
        }
        if (this.config.CAMPOS_OBRIGATORIOS_PERFIL.includes('cnpjCpf') && !this.cpf) {
          formularioPreenchido = false;
          invalidFields.push('CPF/CNPJ');
        }
        if (this.config.CAMPOS_OBRIGATORIOS_PERFIL.includes('rg') && !this.rg) {
          formularioPreenchido = false;
          invalidFields.push('RG');
        }
        if (this.config.CAMPOS_OBRIGATORIOS_PERFIL.includes('dataNascimento') && !this.birth) {
          formularioPreenchido = false;
          invalidFields.push('Data de Nascimento');
        }
        if (this.config.VERIFICAR_IDADE_USUARIO && !this.verificaIdade()) {
          formularioPreenchido = false;
          menorIdade = true;
        }
        if (this.config.LOGIN_CPF && !this.telefoneContato && !this.config.TXT_NAO_OBRIGATORIO_CONTATO) {
          formularioPreenchido = false;
          invalidFields.push(this.config.VERIFICAR_CELULAR_CADASTRO ? 'Número de celular' : 'Telefone de Contato');
        }
        if (this.config.CAMPOS_OBRIGATORIOS_PERFIL.includes('genero') && !this.gender) {
          formularioPreenchido = false;
          invalidFields.push('Gênero');
        }
        if (this.config.MODULO_PERGUNTA_E_RESPOSTA && this.config.PERGUNTA_AO_CONSUMIDOR && !this.resposta) {
          formularioPreenchido = false;
          invalidFields.push(this.config.PERGUNTA_AO_CONSUMIDOR);
        }
        if (this.config.ATIVA_CAMPO_PRIMEIRA_LOJA && (!this.primeiraLoja || !this.cnpjValido(this.primeiraLoja))) {
          formularioPreenchido = false;
          invalidFields.push('Primeira loja');
        }
        if (
          this.config.CAMPOS_OBRIGATORIOS_PERFIL.includes('endereco') &&
          (!this.cep || !this.street || !this.neighborhood || !this.state || !this.number || !this.city)
        ) {
          formularioPreenchido = false;
          invalidFields.push('Endereço');
        }
        if (this.config.CAMPOS_OBRIGATORIOS_PERFIL.includes('email') && !this.email) {
          formularioPreenchido = false;
          invalidFields.push('E-Mail');
        }
        if (!formularioPreenchido && menorIdade) {
          return this.emitirModalInformacao({
            icone: 'fas fa-exclamation-circle',
            titulo: 'Ops...',
            mensagem: 'Usuário menor de idade. Por favor acione o responsável para completar o cadastro.',
            textoBoato: 'OK',
          });
        } else if (!formularioPreenchido) {
          let invalidFieldsString = invalidFields.join(', ');
          return this.emitirModalInformacao({
            icone: 'fas fa-exclamation-circle',
            titulo: 'Ops...',
            mensagem: 'Erro na validação, por favor preencha todos os campos: ' + invalidFieldsString,
            textoBoato: 'OK',
          });
        } else if (
          this.config.CAMPOS_OBRIGATORIOS_PERFIL.includes('endereco') &&
          this.city &&
          !this.$v.city.cidadeValida &&
          this.listaCidades.length
        ) {
          return this.emitirModalInformacao({
            icone: 'fas fa-exclamation-circle',
            titulo: 'Ops...',
            mensagem: `"${this.city}" é inválido em "${this.state}". Verifique se a cidade está digitada corretamente para o estado selecionado.`,
            textoBoato: 'OK',
          });
        } else if (
          this.config.VERIFICAR_CELULAR_CADASTRO &&
          this.telefoneContato !== this.telefoneContatoAoAbrirPerfil
        ) {
          const res = await this.solicitarCodigoAutenticacaoTemporariaUsuario();
          if (res) {
            this.setarTextoMetodoEnvioConfirmacaoCelular();
            this.exibirModalAutenticacaoTemporaria = true;
          }
          return;
        } else {
          this.submitForm();
        }
      }
    },

    enviarFormularioSimplificado() {
      const objetoParaEnviar = {};
      this.config.CAMPOS_OBRIGATORIOS_PERFIL.forEach(campo => {
        switch (campo) {
          case 'nome':
            objetoParaEnviar.nome = this.name;
            break;
          case 'cnpjCpf':
            objetoParaEnviar.cpf = this.cpf;
            break;
          case 'rg':
            objetoParaEnviar.rg = this.rg;
            break;
          case 'dataNascimento':
            objetoParaEnviar.dataNascimento = this.dataNascimento;
            break;
          case 'genero':
            objetoParaEnviar.genero = this.genero;
            break;
          case 'email':
            objetoParaEnviar.email = this.email;
            break;
        }
      });

      objetoParaEnviar.telefone = this.profile.telefone;

      this.updateProfile({
        profile: objetoParaEnviar,
      }).then(response => {
        if ([200, 201].includes(response.status)) {
          this.$emit('emmitEvent', {
            event: 'gtm',
            data: {
              event: 'perfilAtualizado',
              category: 'PerfilUsuario',
              action: 'form',
              label: 'Alterou informações do perfil',
              value: profile.cpf,
            },
          });
          this.emitirModalInformacao({
            icone: 'fas fa-thumbs-up',
            titulo: 'Sucesso',
            mensagem: 'Seu perfil foi atualizado com sucesso!',
            textoBoato: 'OK',
          });

          this.show = false;
        } else {
          this.emitirModalInformacao({
            icone: 'fas fa-exclamation-circle',
            titulo: 'Ops...',
            mensagem: response.msg,
            textoBoato: 'OK',
          });
        }
      });
    },

    redirecionarTelaInicial() {
      this.emitirEventosReload();
      this.$router.push({
        name: this.config.ESCANEAR_REDIRECIONAMENTO || this.telasConfiguraveis[0].destino,
      });
    },

    formataCidade() {
      let padraoCidade = this.$v.city.$model;
      let cidadeEscolhida;
      if (this.listaCidades.length) {
        let temCidade = this.listaCidades.some(e => {
          let teste = e;
          teste = teste.replace(/[àáâãä]/gi, 'a');
          teste = teste.replace(/[ç]/gi, 'c');
          teste = teste.replace(/[èéêë]/gi, 'e');
          teste = teste.replace(/[ìíîï]/gi, 'i');
          teste = teste.replace(/[òóôõö]/gi, 'o');
          teste = teste.replace(/[ùúûü]/gi, 'u');
          const regexCidade = new RegExp(`${teste}`, 'i');
          cidadeEscolhida = e;
          return regexCidade.test(padraoCidade);
        });
        if (temCidade) {
          this.$v.city.$model = cidadeEscolhida;
        }
      }
    },

    validaCidade() {
      if (this.listaCidades.length) {
        let padraoCidade = this.$v.city.$model;
        padraoCidade = padraoCidade.replace(/[àáâãä]/gi, 'a');
        padraoCidade = padraoCidade.replace(/[ç]/gi, 'c');
        padraoCidade = padraoCidade.replace(/[èéêë]/gi, 'e');
        padraoCidade = padraoCidade.replace(/[ìíîï]/gi, 'i');
        padraoCidade = padraoCidade.replace(/[òóôõö]/gi, 'o');
        padraoCidade = padraoCidade.replace(/[ùúûü]/gi, 'u');
        padraoCidade = padraoCidade.replace(/[-]/g, ' ');

        return this.listaCidades.some(e => {
          let teste = e;

          teste = teste.replace(/[àáâãä]/gi, 'a');
          teste = teste.replace(/[ç]/gi, 'c');
          teste = teste.replace(/[èéêë]/gi, 'e');
          teste = teste.replace(/[ìíîï]/gi, 'i');
          teste = teste.replace(/[òóôõö]/gi, 'o');
          teste = teste.replace(/[ùúûü]/gi, 'u');
          teste = teste.replace(/[-]/g, ' ');

          const regexCidade = new RegExp(`${teste}`, 'i');
          return regexCidade.test(padraoCidade);
        });
      } else {
        return true;
      }
    },

    emitirModalInformacao({ icone, titulo, mensagem, textoBotao, acao }) {
      this.$emit('emmitInfoModal', {
        event: 'modal-informacao-generica',
        data: {
          icone,
          titulo,
          mensagem,
          textoBotao,
          acao,
        },
      });
    },

    fecharNavBar() {
      this.show = false;
    },

    verificaNumeroTelefoneContato() {
      if (this.telefoneContatoJaUtilizado && this.config.VERIFICAR_CELULAR_CADASTRO) {
        this.verificadorTelefoneContatoValido = false;
      } else if (this.config.VERIFICAR_CELULAR_CADASTRO) {
        this.verificadorTelefoneContatoValido = this.verificaNumeroDeCelularValido(this.telefoneContato);
      } else {
        this.verificadorTelefoneContatoValido = false;
      }
    },

    verificaNumeroDeCelularValido(numeroCelular) {
      const regexValidador = /^[1-9]{2}[9][6789]\d{7}$/;
      try {
        if (!numeroCelular) return false;

        return regexValidador.test(numeroCelular);
      } catch (err) {
        return false;
      }
    },

    formataTelefone(tel) {
      if (!tel) return;

      tel = tel.replace(/\D/g, '');
      tel = tel.replace(/^(\d{2})(\d)/g, '($1) $2');
      tel = tel.replace(/(\d)(\d{4})$/, '$1-$2');
      return tel;
    },

    setarTextoMetodoEnvioConfirmacaoCelular() {
      if (this.config.METODO_ENVIO_CONFIRMAR_CELULAR === 'sms') {
        this.textoExibicaoModalConfirmarCelular = 'SMS';
      }

      if (this.config.METODO_ENVIO_CONFIRMAR_CELULAR === 'whatsapp') {
        this.textoExibicaoModalConfirmarCelular = 'WhatsApp';
      }
    },

    reenviarRequisicaoConfirmarCelular() {
      this.solicitarCodigoAutenticacaoTemporariaUsuario();
    },

    async solicitarCodigoAutenticacaoTemporariaUsuario() {
      if (this.tempoInicial > 0 && this.tempoInicial < 30) {
        this.exibirModalAutenticacaoTemporaria = true;
        return;
      }

      this.limparIntervaloTempo();
      this.tempoInicial = 30;
      this.contadorReenviarConfirmacaoCelular();

      this.enviandoRequisicaoConfirmarCelular = true;
      const resposta = await this.solicitarCodigoAutenticacaoTemporaria({
        usuario: this.telefoneContato,
        isProd: this.isProd,
      });
      this.enviandoRequisicaoConfirmarCelular = false;

      const { status, msg } = resposta;

      if (status === 200) {
        return true;
      }

      this.emitirModalInformacao({
        icone: 'fas fa-exclamation-circle',
        titulo: 'Ops...',
        mensagem:
          msg ||
          'Erro ao solicitar confirmação de número de celular, tente novamente ou verifique sua conexão com a rede!',
        textoBoato: 'OK',
      });
    },

    limparIntervaloTempo() {
      clearInterval(this.intervaloReenviarConfirmacaoCelular);
    },

    contadorReenviarConfirmacaoCelular() {
      if (this.tempoInicial > 0 && this.tempoInicial <= 30) {
        this.intervaloReenviarConfirmacaoCelular = setInterval(() => {
          this.tempoInicial--;

          if (this.tempoInicial <= 0) {
            clearInterval(this.intervaloReenviarConfirmacaoCelular);
            return;
          }
        }, 1000);
      }
    },

    async verificaCodigoAutenticacaoTemporariaUsuario() {
      const resposta = await this.verificaCodigoAutenticacaoTemporaria({
        codigo: this.codigoAutenticacaoTemporaria,
        usuario: this.telefoneContato,
        isProd: this.isProd,
      });

      const { status, retorno, msg } = resposta;

      if (status == 200 && retorno.codigoValido) {
        this.submitForm();
        return;
      }

      this.emitirModalInformacao({
        icone: 'fas fa-exclamation-circle',
        titulo: 'Ops...',
        mensagem:
          msg == 'Erro ao verificar o código de autenticação temporária: Código de autenticação inválido/expirado.'
            ? 'Tente novamente, pois o código de verificação está inválido/expirado.'
            : msg,
        textoBoato: 'OK',
      });
      this.contadorCodigoAutenticacaoTemporaria += 1;
    },

    async verificaTelefoneContatoAntesAlterar() {
      const resultado = await this.verificaTelefoneContato({
        telefoneContato: this.telefoneContato,
        telefoneContatoAtual: this.telefoneContatoAoAbrirPerfil || this.telefoneContato,
      });

      if (resultado.status === 400) {
        this.telefoneContatoJaUtilizado = true;

        this.emitirModalInformacao({
          icone: 'fas fa-exclamation-circle',
          titulo: 'Ops...',
          mensagem: 'Já existe outra conta cadastrada com este mesmo número de celular!',
          textoBoato: 'OK',
        });
        return;
      }

      this.telefoneContatoJaUtilizado = false;
    },
  },

  props: {
    showModal: {
      type: Boolean,
      description: 'Modal display boolean',
      default: false,
    },
    completarCadastro: {
      type: Boolean,
      description: 'Propriedade que define se este componente sera, ou não, utilizado para completar cadastro.',
      default: false,
    },
  },

  watch: {
    show(val) {
      if (!val) {
        this.clearForm();
        this.$emit('emmitModalEvent', {
          event: 'closeProfileModal',
        });
      }
    },

    showModal(val) {
      if (val) {
        this.show = true;
        this.autoFillForm();
      }
    },

    cep: async function(val) {
      if (val.length == 9) {
        const viacep = `https://viacep.com.br/ws/${val}/json/`;
        await fetch(viacep)
          .then(response => response.json())
          .then(data => {
            if (!data.erro) {
              if (data.logradouro) this.street = data.logradouro;
              if (data.bairro) this.neighborhood = data.bairro;
              this.state = data.uf;
              this.city = this.preencheCidade(data.ibge) || data.localidade;
              this.buscaCidades(this.ufs.filter(e => data.uf == e.value)[0].idIBGE);
            }
          })
          .catch(error => {
            console.error('Error:', error);
          });
      }
    },

    codigoAutenticacaoTemporaria: async function(codigo) {
      if (codigo && codigo.length === 6) {
        await this.verificaCodigoAutenticacaoTemporariaUsuario();
      }
    },

    async telefoneContato() {
      if (this.verificadorTelefoneContatoValido || this.telefoneContato.length >= 11) {
        await this.verificaTelefoneContatoAntesAlterar();
      }
    },
  },

  validations() {
    return {
      name: {
        required,
        minLength: minLength(3),
      },

      cpf: {
        required,
        minLength: minLength(11),
        isCpf: Validation.cpf,
      },

      birth: this.config.VERIFICAR_IDADE_USUARIO
        ? {
            required,
            minLength: minLength(10),
            isMaiorDeIdade: this.verificaIdade,
          }
        : { required, minLength: minLength(10) },

      telefoneContato: this.config.LOGIN_CPF
        ? {
            required,
            minLength: minLength(10),
          }
        : { minLength: minLength(10) },

      rg: {
        required,
        minLength: minLength(4),
      },

      gender: {
        required,
      },

      email: {
        required,
        minLength: minLength(3),
        isEmail: Validation.email,
      },

      street: {
        required,
        minLength: minLength(5),
      },

      cep: {
        required,
        isCep: Validation.cep,
        minLength: minLength(8),
      },

      number: {
        required,
      },

      neighborhood: {
        required,
        minLength: minLength(5),
      },

      city: {
        required,
        minLength: minLength(3),
        cidadeValida: this.validaCidade,
      },

      state: {
        required,
        minLength: minLength(2),
      },
    };
  },
};
</script>
