<template>
  <div class="zoom-container" @wheel.prevent="zoom" @mousedown="startDrag" @mouseup="endDrag" @mouseleave="endDrag"
    @touchstart="startTouchDrag" @touchmove="touchDrag" @touchend="endDrag" @dblclick="toggleZoom" @dragstart.prevent>
    <img :src="imageSrc" :style="{
    transform: `scale(${zoomLevel}) translate(${position.x}px, ${position.y}px)`,
  }" />
  </div>
</template>

<script>
export default {
  props: {
    imageSrc: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      zoomLevel: 1,
      dragging: false,
      start: { x: 0, y: 0 },
      position: { x: 0, y: 0 },
    };
  },
  methods: {
    zoom(event) {
      const zoomIntensity = 0.1;
      if (event.deltaY < 0) {
        this.zoomLevel += zoomIntensity;
      } else {
        this.zoomLevel -= zoomIntensity;
        if (this.zoomLevel < 1) this.zoomLevel = 1;
      }
    },
    toggleZoom() {
      this.zoomLevel = this.zoomLevel > 1 ? 1 : 2;
    },
    startDrag(event) {
      this.dragging = true;
      this.setStart(event.clientX, event.clientY);
      document.addEventListener('mousemove', this.drag);
    },
    startTouchDrag(event) {
      this.dragging = true;
      const touches = event.touches[0];
      this.setStart(touches.clientX, touches.clientY);
      this.$el.addEventListener('touchmove', this.touchDrag);
    },
    setStart(x, y) {
      this.start.x = x - this.position.x;
      this.start.y = y - this.position.y;
    },
    drag(event) {
      if (!this.dragging) return;
      this.setPosition(event.clientX, event.clientY);
    },
    touchDrag(event) {
      if (!this.dragging) return;
      const touches = event.touches[0];
      this.setPosition(touches.clientX, touches.clientY);
    },
    setPosition(x, y) {
      this.position.x = x - this.start.x;
      this.position.y = y - this.start.y;
    },
    endDrag() {
      this.dragging = false;
      document.removeEventListener('mousemove', this.drag);
      this.$el.removeEventListener('touchmove', this.touchDrag);
    },
  },
};
</script>

<style>
.zoom-container {
  overflow: hidden;
  cursor: grab;
  display: inline-block;
}

img {
  transition: transform 0.2s ease-in-out;
}

img:active {
  cursor: grabbing;
}
</style>
