<template>
  <nav class="base-pill-nav-bar">
    <transition name="fade" appear>
      <!-- Adicionar clique de close no overlay -->
      <div
        class="pill-nav-bar-overlay"
        :class="exibirBackground ? 'background-overlay' : ''"
        @click="fecharNavBar ? fechar() : () => {}"
        v-if="exibir"
      ></div>
    </transition>

    <transition name="slide" appear>
      <div class="pill-nav-bar" :class="pillNavBarClasses" v-if="exibir">
        <div class="title-nav-bar">
          <slot name="title-nav-bar"></slot>
        </div>
        <div class="close-pill-nav-bar" v-if="fecharNavBar">
          <button class="button-pill-nav-bar-close" @click="fecharNavBar ? fechar() : () => {}" v-if="fecharNavBar" />
        </div>
        <div class="container-pill-nav-bar">
          <div class="header-pill-nav-bar">
            <slot name="header-pill-nav-bar"></slot>
          </div>
          <div class="body-pill-nav-bar">
            <slot name="body-pill-nav-bar"></slot>
          </div>
          <div class="footer-pill-nav-bar">
            <slot name="footer-pill-nav-bar"></slot>
          </div>
        </div>
      </div>
    </transition>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'BasePillNavBar',
  props: {
    exibir: {
      type: Boolean,
      required: true,
      default: false,
      description: 'Propriedade que define se a modal deve ser exibida ou não',
    },
    fecharNavBar: {
      type: Boolean,
      required: false,
      default: true,
      description:
        'Propriedade que define se o botão de close (X) deve ser exibido, ou se será possível fechar ao clicar fora dos limites da modal',
    },
    exibirBackground: {
      type: Boolean,
      required: false,
      default: true,
      description: 'Propriedade que define se o background deve ser exibido ou não',
    },
    pillNavBarClasses: {
      type: String,
      required: false,
      default: null,
      description: 'Define classes para o elemento pill-nav-bar',
    },
  },
  beforeMount() {},
  data: () => {
    return {};
  },
  computed: {
    ...mapGetters({
      getTheNavBarState: 'thenavbar/getTheNavBarState',
    }),
  },
  methods: {
    fechar() {
      this.$emit('fechar-nav-bar', {
        dados: {
          navBar: {
            fechar: true,
          },
        },
      });
    },
  },
};
</script>

<style lang="scss" src="./style.scss"></style>
