<template>
  <!-- App main wrapper -->
  <div
    ref="qrsorteios"
    class="qrsorteios"
    :class="[
      {
        'main-height':
          !this.$route.meta.requiresAuth || (!this.$route.meta.showMenu && !this.$route.meta.showBottomLogo),
        'height-menu-novo': this.$route.meta.showMenu && this.config.MENU_NOVO,
        authenticated: this.$route.meta.requiresAuth,
        imgFundo: this.imgFundo,
      },
      cssCliente,
    ]"
    :style="
      `
      --color-primary:${
        config.TABELA_COR.find(cor => cor.nome == 'COR_PRIMARIA').cor != 'null'
          ? config.TABELA_COR.find(cor => cor.nome == 'COR_PRIMARIA').cor
          : '#355da1'
      };
      --color-secondary:${
        config.TABELA_COR.find(cor => cor.nome == 'COR_SECUNDARIA').cor != 'null'
          ? config.TABELA_COR.find(cor => cor.nome == 'COR_SECUNDARIA').cor
          : '#c13d5a'
      };
      --color-bg-menu-novo:${config.TABELA_COR.find(cor => cor.nome == 'COR_BG_MENU_NOVO').cor};
      --color-bg-userbar-novo:${config.TABELA_COR.find(cor => cor.nome == 'COR_BG_USERBAR_NOVO').cor};
      --color-bg-btn-menu-novo:${config.TABELA_COR.find(cor => cor.nome == 'COR_BG_BTN_MENU_NOVO').cor};
      --color-bg-accordion-sorteios:${config.TABELA_COR.find(cor => cor.nome == 'BG_ACCORDION_SORTEIOS').cor};
      --color-bg-accordion-faq:${config.TABELA_COR.find(cor => cor.nome == 'BG_ACCORDION_FAQ').cor};
      --icon-color:${
        config.TABELA_COR.find(cor => cor.nome == 'COR_ICON_CARD').cor != 'null'
          ? config.TABELA_COR.find(cor => cor.nome == 'COR_ICON_CARD').cor
          : config.TABELA_COR.find(cor => cor.nome == 'COR_SECUNDARIA').cor
      };
      --color-primary-button:${
        config.TABELA_COR.find(cor => cor.nome == 'COR_PRIMARIA_BOTAO').cor != 'null'
          ? config.TABELA_COR.find(cor => cor.nome == 'COR_PRIMARIA_BOTAO').cor
          : '#355da1'
      };
      --color-secondary-button:${
        config.TABELA_COR.find(cor => cor.nome == 'COR_SECUNDARIA_BOTAO').cor != 'null'
          ? config.TABELA_COR.find(cor => cor.nome == 'COR_SECUNDARIA_BOTAO').cor
          : '#c13d5a'
      };
      --color-login:${
        config.TABELA_COR.find(cor => cor.nome == 'COR_TEXTO_LOGIN').cor != 'null'
          ? config.TABELA_COR.find(cor => cor.nome == 'COR_TEXTO_LOGIN').cor
          : '#000'
      };
      --color-text:${
        config.TABELA_COR.find(cor => cor.nome == 'COR_TEXTO').cor != 'null'
          ? config.TABELA_COR.find(cor => cor.nome == 'COR_TEXTO').cor
          : '#ffffff'
      };
      --color-text-button:${
        config.TABELA_COR.find(cor => cor.nome == 'COR_TEXTO_BOTAO').cor != 'null'
          ? config.TABELA_COR.find(cor => cor.nome == 'COR_TEXTO_BOTAO').cor
          : '#ffffff'
      };
      --color-titulo-lgpd:${config.TABELA_COR.find(cor => cor.nome == 'COR_TITULO_LGPD').cor};
      --color-secondary-text-button:${
        config.TABELA_COR.find(cor => cor.nome == 'COR_SECUNDARIA_TEXTO_BOTAO').cor != 'null'
          ? config.TABELA_COR.find(cor => cor.nome == 'COR_SECUNDARIA_TEXTO_BOTAO').cor
          : '#ffffff'
      };
      --color-highlight:${config.TABELA_COR.find(cor => cor.nome == 'COR_DESTAQUE').cor};
      --color-cadastrar-cupom-button:${
        config.TABELA_COR.find(cor => cor.nome == 'COR_CADASTRAR_CUPOM').cor != 'null'
          ? config.TABELA_COR.find(cor => cor.nome == 'COR_CADASTRAR_CUPOM').cor
          : config.TABELA_COR.find(cor => cor.nome == 'COR_DESTAQUE').cor
      };
      --color-texto-cadastrar-cupom-button:${
        config.TABELA_COR.find(cor => cor.nome == 'COR_CADASTRAR_CUPOM').cor != 'null'
          ? config.TABELA_COR.find(cor => cor.nome == 'COR_CADASTRAR_CUPOM').cor
          : config.TABELA_COR.find(cor => cor.nome == 'COR_PRIMARIA').cor
      };
      --color-text-highlight:${config.TABELA_COR.find(cor => cor.nome == 'COR_TEXTO_DESTAQUE').cor};
      --color-text-card-home:${config.TABELA_COR.find(cor => cor.nome == 'COR_TEXTO_CARD_TELA_INICIAL').cor};
      --logo-url: url('${config.CAMPANHA_IMG_URL}');
      --intro-url: url('${config.INTRO_IMG_URL}');
      --spin-border: url('${config.BORDA_ROLETA_IMAGEM}');
      --spin-pointer: url('${config.CENTRO_ROLETA_IMAGEM}');
      --img-fundo: url('${config.FUNDO_IMG_URL}');
      --img-fundo-login: url('${config.FUNDO_IMG_LOGIN}');
      --img-fundo-roleta: url('${config.FUNDO_ROLETA_IMG_URL}');
      --img-segunda-logo-login: url('${config.SEGUNDA_IMG_LOGO_LOGIN}');
      --color-primary-gradient-login:${config.TABELA_COR.find(cor => cor.nome == 'PRIMEIRA_COR_GRADIENT_LOGIN').cor};
      --color-secondary-gradient-login:${config.TABELA_COR.find(cor => cor.nome == 'SEGUNDA_COR_GRADIENT_LOGIN').cor};
      --color-data-raspadinha:${
        config.TABELA_COR.find(cor => cor.nome == 'COR_DATA_RASPADINHA')
          ? config.TABELA_COR.find(cor => cor.nome == 'COR_DATA_RASPADINHA').cor
          : 'null'
      };
      --color-fundo-icone-raspadinha:${
        config.TABELA_COR.find(cor => cor.nome == 'COR_FUNDO_ICONE_RASPADINHA')
          ? config.TABELA_COR.find(cor => cor.nome == 'COR_FUNDO_ICONE_RASPADINHA').cor
          : 'null'
      };
      --color-icone-raspadinha:${
        config.TABELA_COR.find(cor => cor.nome == 'COR_ICONE_RASPADINHA')
          ? config.TABELA_COR.find(cor => cor.nome == 'COR_ICONE_RASPADINHA').cor
          : 'null'
      };
      --color-fundo-icone-resgatar:${
        config.TABELA_COR.find(cor => cor.nome == 'COR_FUNDO_ICONE_RESGATAR')
          ? config.TABELA_COR.find(cor => cor.nome == 'COR_FUNDO_ICONE_RESGATAR').cor
          : 'null'
      };
      --color-icone-resgatar:${
        config.TABELA_COR.find(cor => cor.nome == 'COR_ICONE_RESGATAR')
          ? config.TABELA_COR.find(cor => cor.nome == 'COR_ICONE_RESGATAR').cor
          : 'null'
      };
      --color-fundo-icone-resgatado:${
        config.TABELA_COR.find(cor => cor.nome == 'COR_FUNDO_ICONE_RESGATADO')
          ? config.TABELA_COR.find(cor => cor.nome == 'COR_FUNDO_ICONE_RESGATADO').cor
          : 'null'
      };
      --color-icone-resgatado:${
        config.TABELA_COR.find(cor => cor.nome == 'COR_ICONE_RESGATADO')
          ? config.TABELA_COR.find(cor => cor.nome == 'COR_ICONE_RESGATADO').cor
          : 'null'
      };
      --color-fundo-icone-raspado:${
        config.TABELA_COR.find(cor => cor.nome == 'COR_FUNDO_ICONE_RASPADO')
          ? config.TABELA_COR.find(cor => cor.nome == 'COR_FUNDO_ICONE_RASPADO').cor
          : 'null'
      };
      --color-icone-raspado:${
        config.TABELA_COR.find(cor => cor.nome == 'COR_ICONE_RASPADO')
          ? config.TABELA_COR.find(cor => cor.nome == 'COR_ICONE_RASPADO').cor
          : 'null'
      };
      --color-botao-girar-roleta:${
        config.TABELA_COR.find(cor => cor.nome == 'COR_BOTAO_GIRAR_ROLETA_INICIO') &&
        config.TABELA_COR.find(cor => cor.nome == 'COR_BOTAO_GIRAR_ROLETA_FIM')
          ? 'linear-gradient(45deg,' +
            config.TABELA_COR.find(cor => cor.nome == 'COR_BOTAO_GIRAR_ROLETA_INICIO').cor +
            ' 0%, ' +
            config.TABELA_COR.find(cor => cor.nome == 'COR_BOTAO_GIRAR_ROLETA_FIM').cor +
            ' 100% )'
          : 'linear-gradient(45deg, rgba(206, 144, 27, 1) 0%, rgba(249, 220, 126, 1) 100%)'
      };
      --color-borda-girar-roleta:${
        config.TABELA_COR.find(cor => cor.nome == 'COR_BOTAO_GIRAR_ROLETA_INICIO') &&
        config.TABELA_COR.find(cor => cor.nome == 'COR_BOTAO_GIRAR_ROLETA_FIM')
          ? 'radial-gradient(circle at top left, ' +
            config.TABELA_COR.find(cor => cor.nome == 'COR_BOTAO_GIRAR_ROLETA_FIM').cor +
            ' 0%, ' +
            config.TABELA_COR.find(cor => cor.nome == 'COR_BOTAO_GIRAR_ROLETA_INICIO').cor +
            ' 100%)'
          : 'radial-gradient(circle at top left, rgba(249, 220, 126, 1) 0%, rgba(206, 144, 27, 1) 100%)'
      };
    `
    "
  >
    <!-- Animação Logo -->
    <div v-if="showSplash" class="splash" :class="{ hide: hideSplash }">
      <div class="logo grow"></div>
    </div>
    <!-- Animação Campanha -->
    <div v-if="showIntro" class="splash intro" :class="{ hide: startIntro, finish: finishIntro }">
      <div class="logo grow"></div>
    </div>
    <!-- App options button -->
    <app-options
      :profile="profile"
      :campaign="currentCampaign"
      @emmitModalEvent="emmitModalEvent"
      v-if="this.$route.meta.showOptions && !this.config.USERBAR_NOVO"
    />
    <app-notifications
      v-if="this.$route.meta.showOptions && config.MOSTRAR_NOTIFICACOES && !this.config.USERBAR_NOVO"
      @emmitModalEvent="emmitModalEvent"
    />
    <!-- App main content -->
    <main id="main" :class="{ 'main-alternative-height': alterarTamanhoMain }">
      <!-- Aviso de ambiente de teste -->
      <div class="w-100 d-flex justify-content-center align-items-center bg-danger" v-if="!isProd">
        <span class="text-light font-weight-bolder w-80 text-center"
          >Você está em um ambiente de testes e homologação.</span
        >
      </div>
      <!-- Exibindo a versão -->
      <the-app-version v-if="config.EXIBIR_NUMERO_VERSAO_CANTO_SUPERIOR" />
      <div
        class="link-voltar"
        :style="this.$route.name == 'TotalPontos' ? 'display: block' : 'display: none;'"
        @click="$router.go(-1)"
      >
        {{ '◀ Voltar' }}
      </div>
      <!-- Campaign logo -->
      <div v-if="this.$route.meta.showTopLogo && this.config.LOGO_LOGIN_IMG_URL" class="campaing-logo">
        <img :src="config.LOGO_LOGIN_IMG_URL" alt="Campaign logo" id="primeiraImg" />
        <img
          v-if="this.config.SEGUNDA_IMG_LOGO_LOGIN"
          :src="config.SEGUNDA_IMG_LOGO_LOGIN"
          alt="Campaign logo"
          id="segundaImg"
          style="display: none"
        />
      </div>
      <div v-if="this.$route.meta.showTopLogo && !this.config.LOGO_LOGIN_IMG_URL" class="campaing-logo">
        <img :src="config.CAMPANHA_IMG_URL" alt="Campaign logo" id="primeiraImg" />
        <img
          v-if="this.config.SEGUNDA_IMG_LOGO_LOGIN"
          :src="config.SEGUNDA_IMG_LOGO_LOGIN"
          alt="Campaign logo"
          id="segundaImg"
          style="display: none"
        />
      </div>
      <!-- Logos lado a lado -->
      <!-- <div
        v-if="this.$route.meta.exibirLogosConjuntoTopo"
        class="logos-conjunto"
      >
        <img
          :src="config.CAMPANHA_IMG_URL"
          alt="Campaign logo"
          id="primeiraImg"
        />
        <img
          v-if="config.INTRO_IMG_URL"
          :src="config.INTRO_IMG_URL"
          alt="Logo de introdução"
          id="segundaImg"
          style="display: none"
        />
      </div> -->
      <!-- App Userbar -->
      <app-userbar
        :profile="profile"
        :campaign="currentCampaign"
        @emmitModalEvent="emmitModalEvent"
        v-if="this.$route.meta.showUserbar"
      />
      <!-- App Wrapper -->
      <app-wrapper-menu @emmitModalEvent="emmitModalEvent" v-if="this.$route.meta.showUserbar" class="pt-5" />
      <!-- Vou router wrapper (signed in) -->
      <transition :name="currentTransition" mode="out-in">
        <router-view
          @emmitEvent="emmitEvent"
          @emmitInfoModal="emmitInfoModal"
          :profile="profile"
          :campaign="currentCampaign"
          :openWrapper="openWrapper"
          :window="window"
          :isProd="isProd"
        />
      </transition>
      <!-- QRSorteios logo if not menu -->
      <div v-if="showBottomLogo" class="qr-logo">
        <img :src="config.INTRO_IMG_URL" alt="QR logo" />
      </div>
    </main>
    <!-- Background lock -->
    <div id="background-lock" v-if="backgroundLock" @click="switchSettingsMenuState(false)"></div>
    <!-- App menu -->
    <app-menu
      v-if="this.$route.meta.showMenu"
      :windowSize="window"
      @emmitEvent="emmitEvent"
      @emmitModalEvent="emmitModalEvent"
      :campaign="currentCampaign"
      :isDesktop="isDesktop"
    />
    <!-- Modular modals -->
    <alterar-foto
      @emmitModalEvent="emmitModalEvent"
      :showModal="modalControl.changePicture"
      v-if="this.$route.meta.requiresAuth"
      :windowSizing="window"
    />
    <alterar-senha
      @emmitModalEvent="emmitModalEvent"
      @emmitInfoModal="emmitInfoModal"
      :showModal="modalControl.changePassword"
      v-if="this.$route.meta.requiresAuth"
    />
    <notificacoes
      @emmitModalEvent="emmitModalEvent"
      :showModal="modalControl.notificationSettings"
      :campanha="currentCampaign"
      v-if="this.$route.meta.requiresAuth"
    />
    <perfil
      @emmitModalEvent="emmitModalEvent"
      @emmitInfoModal="emmitInfoModal"
      @emmitEvent="emmitEvent"
      :showModal="modalControl.profile"
      :completarCadastro="modalControl.profileFinishSignup"
      v-if="this.$route.meta.requiresAuth"
    />
    <detalhes-documento-fiscal
      @emmitModalEvent="emmitModalEvent"
      @emmitEvent="emmitEvent"
      :showModal="modalControl.detalhesDocumentoFiscal"
      v-if="this.$route.meta.requiresAuth"
    />
    <aceites
      @emmitModalEvent="emmitModalEvent"
      @emmitInfoModal="emmitInfoModal"
      @emmitEvent="emmitEvent"
      v-if="this.$route.meta.requiresAuth"
    />
    <desconectar
      @emmitModalEvent="emmitModalEvent"
      @emmitEvent="emmitEvent"
      :showModal="modalControl.logout"
      v-if="this.$route.meta.requiresAuth && !config.OCULTAR_SAIR"
    />
    <completar-cadastro
      @emmitModalEvent="emmitModalEvent"
      :showModal="modalControl.finishSignUp"
      v-if="this.$route.meta.requiresAuth"
    />

    <!-- Modal genérica -->
    <base-pill-modal
      :openModal="modalControl.modalInformacao.generica.ativa"
      :pills="[true, false]"
      :pillColors="['pill-dark']"
      :headerIcons="[modalControl.modalInformacao.generica.icone]"
      :closeOverlay="true"
      @fechar-modal="
        modalControl.modalInformacao.generica.ativa = false;
        modalControl.modalInformacao.generica.acao();
      "
    >
      <template v-slot:pill-modal-body>
        <div class="w-100 d-flex flex-column align-items-center mb-3">
          <h6 class="w-75 text-align-center text-center text-black">
            <strong v-html="modalControl.modalInformacao.generica.titulo"></strong>
          </h6>
          <p class="w-75 text-align-center text-center text-black">
            <small
              ><strong>
                {{ modalControl.modalInformacao.generica.mensagem }}
              </strong></small
            >
          </p>
        </div>
      </template>
      <template v-slot:pill-modal-footer>
        <div class="w-100 d-flex flex-row justify-content-center">
          <base-pill-button
            :type="'light'"
            :class="'mt-3'"
            :right-middle-pill="'fas fa-check-circle'"
            :pillColors="['pill-dark']"
            :rightMiddlePillRootColor="config.COR_DESTAQUE_CIRCUNFERENCIA_DOS_BOTOES"
            :rightMiddlePillIconRootColor="config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_DOS_BOTOES"
            :pillSize="['pill-average']"
            @click="
              modalControl.modalInformacao.generica.ativa = false;
              modalControl.modalInformacao.generica.acao();
            "
          >
            <template v-slot:pill-button-title>
              <span class="w-90 px-2 text-center text-black text-uppercase"
                ><strong>{{ modalControl.modalInformacao.generica.textoBotao || 'OK' }}</strong></span
              >
            </template>
          </base-pill-button>
        </div>
      </template>
    </base-pill-modal>
  </div>
</template>

<script>
import AlterarFoto from './views/modals/AlterarFoto';
import AlterarSenha from './views/modals/AlterarSenha';
import CompletarCadastro from './views/modals/CompletarCadastro';
import Desconectar from './views/modals/Desconectar';
import Notificacoes from './views/modals/Notificacoes';
import Perfil from './views/modals/Perfil';
import DetalhesDocumentoFiscal from './views/modals/DetalhesDocumentoFiscal';
import Aceites from './views/modals/Aceites';
import { mapActions, mapGetters } from 'vuex';
import { authentication } from './lib/Authentication';
import { HttpClient } from './lib/HttpClient';
import { campaign, redirect } from './lib/Storage';
import { Data, DadosDispositivo } from './lib/Helper';
import firebase from 'firebase/app';
import 'firebase/analytics';

window.popStateDetected = false;
window.addEventListener('popstate', () => {
  window.popStateDetected = true;
});

export default {
  name: 'App',

  metaInfo() {
    return {
      title: this.config.TITULO_PAGINA || 'Polgo',
      meta: [{ property: 'og:title', content: this.config.TITULO_PAGINA || 'Polgo' }],
    };
  },

  async updated() {
    // Verifica se a página foi voltada
    if (window.popStateDetected) {
      const podeMostrarModal =
        this.profile.cadastroInicial ||
        (this.config.LOGIN_CPF && !this.profile.telefoneContato && !this.config.TXT_NAO_OBRIGATORIO_CONTATO);
      // Verifica se pode mostrar e se os campos não estão preenchidos (ainda não completou cadastro)
      if (podeMostrarModal && !this.camposObrigatoriosPreenchidos) {
        window.popStateDetected = false;
        window.location.reload();
      }
    }
    const respostaCedulas = await this.carregarCedulasQuiz({
      campanha: this.currentCampaign,
      cpf: this.profile.cpf,
    });
    if (respostaCedulas.status == 200) {
      const responderDepois = localStorage.getItem('responderDepois');
      if (!responderDepois) {
        this.$router.push({ name: 'quiz' });
      }
    }
  },

  head: {
    script: function() {
      return this.config.ID_GOOGLE_ANALYTICS !== 'vazio'
        ? [
            {
              src: `https://www.googletagmanager.com/gtag/js?id=${this.config.ID_GOOGLE_ANALYTICS}`,
              async: true,
            },
          ]
        : [];
    },
  },

  async beforeMount() {
    if (this.config.TAG_SCRIPT_PERSONALIZADO !== 'vazio') {
      let tagScript = document.createElement('script');
      tagScript.innerHTML = this.config.TAG_SCRIPT_PERSONALIZADO;
      document.body.appendChild(tagScript);

      if (this.config.TAG_NOSCRIPT_PERSONALIZADO !== 'vazio') {
        let tagNoScript = document.createElement('noscript');
        tagNoScript.innerHTML = this.config.TAG_NOSCRIPT_PERSONALIZADO;
        document.body.appendChild(tagNoScript);
      }
    }

    var firebaseConfigArray = this.config.GA_FIREBASE_CONFIG;
    if (firebaseConfigArray && this.config.GA_FIREBASE && typeof cordova != 'undefined') {
      var firebaseConfig = firebaseConfigArray[0];
      if (typeof firebase != 'undefined') {
        firebase.initializeApp(firebaseConfig);
        firebase.analytics();
      } else {
        console('não foi possível conectar o firebase');
      }
    }

    console.log(`Google Analytics ID: ${this.config.ID_GOOGLE_ANALYTICS}`);

    if (this.config.ID_GOOGLE_ANALYTICS !== 'vazio') {
      let googleAnalyticsScriptTag = document.createElement('script');
      googleAnalyticsScriptTag.innerHTML = `
        console.log("Inserindo script do Google Analytics pelo beforeMount")
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', '${this.config.ID_GOOGLE_ANALYTICS}');
      `;
      document.body.appendChild(googleAnalyticsScriptTag);
    }

    // Verificando se já existe token de login.
    if (localStorage.getItem('login')) {
      const loginData = JSON.parse(localStorage.getItem('login'));

      if (loginData.token) {
        await this.authenticate({
          user: loginData.usuario,
          password: null,
          type: loginData.tipo,
          token: loginData.token,
        });
      }
    }
  },

  components: {
    AlterarFoto,
    AlterarSenha,
    CompletarCadastro,
    Desconectar,
    Notificacoes,
    Aceites,
    Perfil,
    DetalhesDocumentoFiscal,
  },

  computed: {
    ...mapGetters({
      showIntro: 'ui/getCampaignAnimationState',
      openWrapper: 'ui/getOpenWrapper',
      config: 'config/getConfig',
      cupomConfig: 'cupom/getCouponsConfig',
      raspadinhasAltConfig: 'raspadinhaAlternativa/getRaspadinhasAltConfig',
      profile: 'consumidor/getProfile',
      currentCampaign: 'campanha/getCampaign',
      backgroundLock: 'ui/getBackgroundLockState',
      currentTransition: 'ui/getTransition',
      telasConfiguraveis: 'config/obterTelasConfiguraveis',
      lgpdNotificacoes: 'lgpd/getNotificacoes',
      primeiroAcessoDados: 'consumidor/getPrimeiroAcesso',
      emailLogin: 'consumidor/getEmailLogin',
      configuracaoDocumentosFiscais: 'documentoFiscal/getConfiguracaoDocumentosFiscais',
    }),

    alterarTamanhoMain() {
      return ['homeProduto', 'filtrosProduto', 'detalhesProduto'].includes(this.$route.name);
    },

    cssCliente() {
      return this.config.CSS_PERSONALIZADO ? this.config.CSS_PERSONALIZADO : '';
    },

    imgFundo() {
      if (this.config.FUNDO_IMG_URL) return true;
      else return false;
    },

    isProd: function() {
      if (typeof BuildInfo != 'undefined' && BuildInfo.packageName) {
        // Tratativa pra poder fazer o app bater na API de TESTES mesmo que o nome do pacote seja o de produção
        // Basta adicionar o ID do device na lista de ids do configurador
        if (typeof cordova != 'undefined' && cordova.platformId == 'ios') {
          const deviceId = device.uuid;

          const indexOfDeviceId = this.config.LISTA_TESTERS_IDS_IOS.indexOf(deviceId);

          if (indexOfDeviceId !== -1) {
            this.$swal({
              type: 'info',
              text: 'Mudando para ambiente de testes',
            });

            console.log(
              `Mudando pra bater no ambiente de testes porque o ID do Device está na lista de ids dos testers\n ID DO DEVICE: ${deviceId}`
            );

            return false;
          }
        }

        return this.config.VUE_APP_PROD_PACK_NAME == BuildInfo.packageName;
      } else {
        return location.href.startsWith(this.config.VUE_APP_PROD_BASE_URL);
      }
    },

    isDesktop() {
      const mobile = [/Android/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i];
      const isMobileBrowser = mobile.some(u => navigator.userAgent.match(u));
      const isApp = !location.protocol.includes('http');

      return !isMobileBrowser && !isApp;
    },

    showBottomLogo() {
      if (!this.config.INTRO_IMG_URL) {
        return false;
      } else {
        return !this.$route.meta.showMenu && this.$route.meta.showBottomLogo;
      }
    },
  },

  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },

  // Animations controllers
  data: () => ({
    camposObrigatoriosPreenchidos: false,
    numeroScript: 2,

    // Start splash
    hideSplash: false,
    showSplash: false,
    // Intro Splash
    startIntro: false,
    finishIntro: false,
    // modals
    modalControl: {
      profile: false,
      profileFinishSignup: false,
      detalhesDocumentoFiscal: false,
      changePassword: false,
      changePicture: false,
      notificationSettings: false,
      logout: false,
      finishSignUp: false,

      // Modais que servem como simples "alertas"
      modalInformacao: {
        generica: {
          ativa: false,
          icone: '',
          titulo: '',
          mensagem: '',
          textoBotao: '',
          acao: () => {},
        },
      },
    },
    // Window
    window: {
      width: 0,
      height: 0,
    },
    APPLICATION_VERSION: APPLICATION_VERSION,
    logouAgora: false,
    integracaoGTM: false,
    usuarioCadastrado: false,
  }),

  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },

  // Timeouts for removing the animation content after finished
  mounted() {
    const login = localStorage.getItem('login');
    if (!login) {
      if (['intro', 'authentication'].includes(this.$route.name)) {
        this.showSplash = true;
        setTimeout(() => {
          this.hideSplash = true;
        }, 1500),
          setTimeout(() => {
            this.showSplash = false;
          }, 2600);
      }
    } else {
      this.campaignAnimationState(true);
    }

    const app = location.protocol.includes('http');
    let obrigarVersao = false;
    if (typeof cordova != 'undefined') {
      if (cordova.platformId == 'android') {
        obrigarVersao = this.config.OBRIGAR_VERSAO_ANDROID;
      } else if (cordova.platformId == 'ios') {
        obrigarVersao = this.config.OBRIGAR_VERSAO_IOS;
      }
    }

    if (!app && obrigarVersao && this.config.VERSAO_ATUAL > this.APPLICATION_VERSION) {
      let urlAndroid = this.config.URL_LOJAS.filter(e => e.loja == 'android').map(e => e.url);
      let urlIos = this.config.VUE_APP_PROD_BASE_URL;

      this.$swal({
        type: 'warning',
        title: 'Atualização necessária',
        html: `Por favor, baixe a atualização necessária para continuar. <br /> <button onclick="window.qrsorteios.vue.$root.$emit('atualizarVersao', '${urlAndroid}*-*-*${urlIos}')" type="button" class="swal2-confirm swal2-styled" aria-label="" style="display: inline-block; border-left-color: rgb(48, 133, 214); border-right-color: rgb(48, 133, 214);">Ok</button>`,
        confirmButtonText: `Ok`,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
    }

    if (this.config.GOOGLE_GTM_ID && this.config.GOOGLE_GTM_ID != 'vazio') {
      this.integracaoGTM = true;
    }

    window.document.title = this.config.TITULO_PAGINA || 'Polgo';

    const metaDescription = document.createElement('meta');
    metaDescription.setAttribute('name', 'description');
    metaDescription.setAttribute('content', this.config.DESCRICAO_PAGINA);
    window.document.head.appendChild(metaDescription);

    const link = document.querySelector("link[rel*='icon']");

    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = this.config.FAVICON_PAGINA;

    document.getElementsByTagName('head')[0].appendChild(link);
    document.querySelector('meta[name="theme-color"]').content =
      this.config.TABELA_COR.find(cor => cor.nome == 'COR_PRIMARIA').cor != 'null'
        ? this.config.TABELA_COR.find(cor => cor.nome == 'COR_PRIMARIA').cor
        : '#355da1';

    // document.querySelector('meta[name="og-image"]').content = this.config.FAVICON_PAGINA
    // document.querySelector('meta[name="og-title"]').content = this.config.TITULO_PAGINA || 'QRSorteios'

    this.habilitarIntegracaoOneSignal();
  },

  methods: {
    ...mapActions({
      campaignAnimationState: 'ui/campaignAnimationState',
      openWrapperState: 'ui/openWrapperState',
      signIn: 'autenticacao/signIn',
      signOut: 'autenticacao/signOut',
      loadProfile: 'consumidor/fetchProfile',
      alterarNotificacao: 'consumidor/alterarNotificacao',
      atualizarEmail: 'consumidor/updateProfile',
      limparPrimeiroAcesso: 'consumidor/limparPrimeiroAcesso',
      enviarDeviceID: 'consumidor/enviarDeviceID',
      setCampaign: 'campanha/setStorageCampaign',
      setClosedCampaign: 'campanha/setClosedCampaign',
      loadCampaign: 'campanha/fetchCampaign',
      loadCoupons: 'cupom/fetchUserCoupons',
      loadScratchs: 'raspadinha/fetchScratches',
      listarDocumentosPorUsuario: 'documentoFiscal/listarDocumentosPorUsuario',
      obterConfiguracaoDocumentoFiscal: 'documentoFiscal/obterConfiguracaoDocumentoFiscal',
      obterSaldoCashback: 'cashback/consultarSaldo',
      consultarExtrato: 'cashback/consultarExtrato',
      loadAltScratchs: 'raspadinhaAlternativa/fetchScratches',
      loadCheckingAccountValue: 'contaCorrente/fetchChechingAccountValue',
      switchSettingsMenuState: 'ui/switchSettingsMenuState',
      setTransition: 'ui/commitTransition',
      handleMenuSlide: 'ui/handleMenuSlide',
      salvarNotificacoes: 'lgpd/salvarNotificacoes',
      fetchRaspadinhasAltConfig: 'raspadinhaAlternativa/fetchRaspadinhasAltConfig',
      fetchCupomConfig: 'cupom/fetchCouponsConfig',
      obterConsumidorCupom: 'cupom/obterConsumidorCupom',
      fetchCouponsPorPeriodo: 'cupom/fetchCouponsPorPeriodo',
      carregarCedulasQuiz: 'questionario/fetchCedulas',
      salvarCedulas: 'questionario/salvarCedulas',
      // Integração Comper
      setIntegracaoComper: 'integracaoComper/setIsIntegracaoComperAction',

      responderDps(payload) {
        this.$router.push(payload.event);
      },
    }),

    async authenticate(data) {
      const loginFromPrevSession = localStorage.getItem('login');
      // caso já tenha uma sessão salva, faz a validação as credenciais para continuar
      if (loginFromPrevSession) {
        const wsUrl = this.isProd ? this.config.VUE_APP_PROD_WS_URL : this.config.VUE_APP_DEV_WS_URL;
        const l = JSON.parse(loginFromPrevSession);

        const casoErro = data => {
          let mensagem = this.config.MENSAGEM_ERRO_AUTENTICACAO_SESSAO_SALVA;
          if (!mensagem && data && data.data && data.data.mensagem) {
            mensagem = data.data.mensagem;
          }

          this.signOut();

          this.$swal({
            type: 'error',
            title: 'Ops...',
            text: mensagem || 'Ocorreu um erro ao verificar suas credenciais de login. Tente novamente mais tarde.',
          });
        };

        const auth = new authentication(l.token, l.usuario, '', l.tipo, new HttpClient(wsUrl, l.token), null, casoErro);

        // this.loadAppData(loginFromPrevSession);

        const verificacao = await auth.start();
        if (verificacao.error) {
          this.signOut();

          this.config.LINK_REDIRECIONAR_DESLOGADO
            ? (window.location.href = this.config.LINK_REDIRECIONAR_DESLOGADO)
            : this.$router.push({ name: 'intro' }); // força o redirecionamento para a tela inicial

          return null;
        } else {
          return this.loadAppData(loginFromPrevSession);
        }
      }

      const response = await this.signIn({
        user: data.user,
        password: data.password,
        type: data.type,
        token: data.token || null,
        tokenFacebook: data.tokenFacebook || null,
        isProd: this.isProd,
      });
      if ([200, 201].includes(response.status)) {
        // Load application data (user)
        const login = localStorage.getItem('login');

        if (login) {
          this.logouAgora = true;

          if (this.integracaoGTM) {
            this.emitirEventoGTM({
              event: 'loginRealizado',
              category: 'LoginUsuario',
              action: 'form',
              label: 'Realizou login',
              value: data.user,
            });
          }

          this.loadAppData(login, response.loginAnimation);
        } else {
          this.signOut();

          this.config.LINK_REDIRECIONAR_DESLOGADO
            ? (window.location.href = this.config.LINK_REDIRECIONAR_DESLOGADO)
            : this.$swal({
                type: 'error',
                title: 'Ops...',
                text: 'Ocorreu um erro durante sua tentativa de login! Tente novamente mais tarde.',
              });

          // força o redirecionamento para a tela inicial
          this.$router.push({ name: 'intro' });
        }
      } else {
        this.signOut();

        const mensagem = response.msg.match(/Login inativo/)
          ? this.config.MENSAGEM_LOGIN_INATIVO || response.msg
          : response.msg;

        this.config.LINK_REDIRECIONAR_DESLOGADO
          ? (window.location.href = this.config.LINK_REDIRECIONAR_DESLOGADO)
          : (this.modalControl.modalInformacao.generica.ativa = true);
        this.modalControl.modalInformacao.generica.icone = 'fas fa-exclamation-circle';
        this.modalControl.modalInformacao.generica.titulo = 'Ops...';
        this.modalControl.modalInformacao.generica.mensagem = mensagem;
        this.modalControl.modalInformacao.generica.textoBotao = 'OK';

        // força o redirecionamento para a tela inicial
        this.$router.push({ name: 'intro' });
      }
    },
    emitirEventoGTM(data) {
      if (this.integracaoGTM) {
        this.$gtm.trackEvent(data);
      }
    },

    async emmitEvent(evt) {
      switch (evt.event) {
        case 'usuarioCadastrado':
          this.usuarioCadastrado = true;
          break;
        case 'gtm':
          this.emitirEventoGTM(evt.data);
          break;
        // Profile and Auth events
        case 'auth':
          this.authenticate(evt.data);
          break;
        // Auth event with Query params
        case 'authQueryParams':
          this.authenticate(evt.data);
          // ALERTA! -> Suprimindo qualquer erro que possa vir a ser lançado, devido ao redirecionamento consecutivo, de A,para B e depois C
          //this.$router.push({ name: evt.data.route }).catch(() => {});
          break;
        case 'updateVerifiedEmailStatus':
          authentication.changeVerifiedEmailStatus(evt.data.verifiedEmail);
          break;
        case 'logout':
          this.signOut();
          //localStorage.setItem('carrinho', JSON.stringify(this.carrinho))
          localStorage.removeItem('associado');
          localStorage.removeItem('listProdutos');
          localStorage.removeItem('carrinho');
          this.usuarioCadastrado = false;

          this.config.LINK_REDIRECIONAR_DESLOGADO
            ? (window.location.href = this.config.LINK_REDIRECIONAR_DESLOGADO)
            : this.$router.push({ name: 'autenticacao' });
          break;
        // User data events
        case 'reloadCoupons':
          await this.loadCoupons({
            campaign: this.currentCampaign,
            user: this.profile.telefone,
          });
          break;
        case 'reloadConfiguracoesCupons':
          await this.recarregarConfiguracoesDocsFiscais();
          await this.recarregarConfiguracoesCupons();
          break;
        case 'reloadScratches':
          await this.loadScratchs();
          break;
        case 'reloadAltScratches':
          await this.loadAltScratchs({
            campaign: this.currentCampaign,
            user: this.profile.cpf || '',
          });
          break;
        case 'reloadCheckingAccount':
          await this.loadCheckingAccountValue({
            campaign: this.currentCampaign,
            profile: this.profile,
          });
          break;
        case 'reloadCashback':
          await this.obterSaldoCashback({
            usuario: this.profile.cpf,
          });
          await this.consultarExtrato({
            usuario: this.profile.cpf,
            paginacao: {
              totalPorPagina: 9,
              paginaAtual: 1,
            },
          });
          break;
        case 'openDetalhesDocumentoFiscal':
          this.modalControl.detalhesDocumentoFiscal = true;
          break;
        case 'closeDetalhesDocumentoFiscal':
          this.modalControl.detalhesDocumentoFiscal = false;
          break;
        case 'reloadUserQuestionsQuiz':
          await this.carregarCedulasQuiz({
            campanha: this.currentCampaign,
            cpf: this.profile.cpf,
          });
          break;
        // Transition Events
        case 'cardTransition':
          this.handleTransition(evt.data.page, evt.data.origin);
          break;
        case 'campaignAnimation':
          this.campaignAnimationState(true);
          break;
        // Default action (no action)
        default:
          break;
      }
    },

    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },

    handleTransition(page, origin) {
      const obj = this.telasConfiguraveis;
      const route = obj ? obj.find(o => o.destino == origin) : null;
      if (route) {
        if ((route.proximo && page > 1) || (!route.anterior && route.proximo && page >= 1)) {
          this.setTransition('none');
          this.handleMenuSlide(route.proximo);
          this.$router.push({ name: route.proximo });
          setTimeout(() => {
            this.setTransition('slide-left');
          }, 500);
        }
        if ((route.anterior && page < 1) || (!route.proximo && route.anterior && page < 1)) {
          this.setTransition('none');
          this.handleMenuSlide(route.anterior);
          this.$router.push({ name: route.anterior });
          setTimeout(() => {
            this.setTransition('slide-left');
          }, 500);
        }
      }
    },

    emmitModalEvent(evt) {
      switch (evt.event) {
        // Modal events
        case 'openProfileModal':
          this.modalControl.profile = true;
          break;
        case 'openProfileModalToFinishSignUp':
          this.modalControl.profile = true;
          this.modalControl.profileFinishSignup = true; // propriedade que indica se elementos do modal de primeiro acesso devem ser exibidos
          break;
        case 'closeProfileModal':
          this.modalControl.profile = false;
          this.modalControl.profileFinishSignup = false;
          break;
        case 'openNotificationSettingsModal':
          this.modalControl.notificationSettings = true;
          break;
        case 'closeNotificationSettingsModal':
          this.modalControl.notificationSettings = false;
          break;
        case 'openChangePictureModal':
          this.modalControl.changePicture = true;
          break;
        case 'closeChangePictureModal':
          this.modalControl.changePicture = false;
          break;
        case 'openFinishSignUpModal':
          this.modalControl.finishSignUp = true;
          break;
        case 'closeFinishSignUpModal':
          this.modalControl.finishSignUp = false;
          break;
        case 'openChangePasswordModal':
          this.modalControl.changePassword = true;
          break;
        case 'closeChangePasswordModal':
          this.modalControl.changePassword = false;
          break;
        case 'openLogoutModal':
          this.modalControl.logout = true;
          break;
        case 'closeLogoutModal':
          this.modalControl.logout = false;
          break;
        // Default action (no action)
        default:
          break;
      }
    },

    emmitInfoModal(evt) {
      switch (evt.event) {
        case 'modal-informacao-generica':
          this.modalControl.modalInformacao.generica.ativa = true;
          this.modalControl.modalInformacao.generica.icone = evt.data.icone;
          this.modalControl.modalInformacao.generica.titulo = evt.data.titulo;
          this.modalControl.modalInformacao.generica.mensagem = evt.data.mensagem;
          this.modalControl.modalInformacao.generica.textoBotao = evt.data.textoBotao;
          this.modalControl.modalInformacao.generica.acao(evt.data.acao);
          break;
        default:
          break;
      }
    },

    async loadAppData(login, loginAnimation) {
      // Default fallback action
      const defaultFallback = msg =>
        this.$swal({
          type: 'info',
          title: 'Ops...',
          text: msg,
          onClose: () => location.reload(),
        });
      // Adicionar campo aceitaNotificacao caso conta criar agora
      if (this.lgpdNotificacoes) {
        await this.alterarNotificacao({
          data: {
            telefone: JSON.parse(login).usuario,
            aceitaNotificacao: true,
          },
        }).then(res => {
          if (![200, 201].includes(res.status)) {
            console.log(res.data.retorno);
            this.$swal({
              type: 'error',
              title: 'Ops...',
              html: res.msg + '<br/>' + res.data.retorno,
            });
          }
        });
        this.salvarNotificacoes({ notificacoes: false });
      }
      // Loads user profile
      const profileResponse = await this.loadProfile({
        login: JSON.parse(login),
      });
      if (this.primeiroAcessoDados && this.emailLogin && this.profile) {
        const email = this.emailLogin;
        if (email) {
          const respostaPerfil = await this.atualizarEmail({
            profile: { ...this.profile, email: email },
          });
          if ([200, 201].includes(respostaPerfil.status)) {
            console.log('Atualizado com sucesso');
            await this.limparPrimeiroAcesso();
          } else {
            console.log('Erro ao atualizar', respostaPerfil);
          }
        }
      }

      // Enviar device ID
      if (
        this.logouAgora &&
        this.profile.aceitaNotificacao &&
        window.qrsorteios.deviceId &&
        !this.profile.cadastroInicial
      ) {
        const ferramentasNotificacao = this.config.FERRAMENTA_ENVIO_NOTIFICACOES.split(',')
          .map(e => e.trim())
          .filter(e => e);

        if (ferramentasNotificacao.includes('sns') && typeof cordova !== 'undefined') {
          this.enviarDeviceID({
            data: {
              id: window.qrsorteios.deviceId,
              descricao: window.qrsorteios.device,
              usuario: this.profile.telefone,
              servicoNotificador: 'sns',
              tipoDispositivo: cordova.platformId,
            },
          }).then(res => {
            if (![200, 201].includes(res.status)) {
              this.emitirModalInformacao({
                icone: 'fas fa-exclamation-circle',
                titulo: 'Ops...',
                mensagem: res.msg,
                textoBoato: 'OK',
              });
            }
          });
        }

        if (ferramentasNotificacao.includes('onesignal') && typeof cordova === 'undefined') {
          this.enviarDeviceID({
            data: {
              id: window.qrsorteios.deviceId,
              descricao: window.qrsorteios.device,
              usuario: this.profile.telefone,
              servicoNotificador: 'onesignal',
              tipoDispositivo: 'pwa',
            },
          }).then(res => {
            if (![200, 201].includes(res.status)) {
              this.emitirModalInformacao({
                icone: 'fas fa-exclamation-circle',
                titulo: 'Ops...',
                mensagem: res.msg,
                textoBoato: 'OK',
              });
            }
          });
        }
      }

      if ([200].includes(profileResponse.status)) {
        if (campaign.getCampaign()) {
          // Campaign and current date
          const currentCampaign = campaign.getCampaign();
          const currentDate = Data.dataHoraAtual();

          if (
            currentDate >= `${currentCampaign.dataInicial} 00:00:00` &&
            currentCampaign.ativa &&
            currentDate <= `${currentCampaign.dataFinal} 23:59:00`
          ) {
            this.setCampaign({ campaign: currentCampaign });
            this.loadUserData(loginAnimation);

            // Recarregando campanha
            const campaignResponse = await this.loadCampaign({ currentDate });
            if (![200].includes(campaignResponse.status)) {
              localStorage.removeItem('login');
              campaign.removeCampaign();
              return defaultFallback(campaignResponse.msg);
            }
          } else if (
            // Verificando se alguma campanha foi encerranda e ainda está ativa, salvando no localstorage
            currentDate >= `${currentCampaign.dataInicial} 00:00:00` &&
            currentCampaign.ativa &&
            currentDate > `${currentCampaign.dataFinal} 23:59:00`
          ) {
            this.setClosedCampaign({ closedCampaign: currentCampaign });
            // loadUserData aqui

            // Recarregando campanha
            const campaignResponse = await this.loadCampaign({ currentDate });
            if (![200].includes(campaignResponse.status)) {
              localStorage.removeItem('login');
              campaign.removeCampaign();
              // Removendo campanhas inativas do local storage, caso exista
              if (localStorage.getItem('ClosedCampaign')) {
                localStorage.removeItem('ClosedCampaign');
              }
              //return defaultFallback(campaignResponse.msg)
            }
            this.loadUserData(loginAnimation);
          } else {
            localStorage.removeItem('login');
            if (localStorage.getItem('ClosedCampaign')) {
              localStorage.removeItem('ClosedCampaign');
            }
          }
        } else {
          const currentDate = Data.dataHoraAtual();
          const campaignResponse = await this.loadCampaign({ currentDate });
          if (![200].includes(campaignResponse.status)) {
            localStorage.removeItem('login');
            campaign.removeCampaign();
            return defaultFallback(campaignResponse.msg);
          } else {
            // Caso a campanha esteja encerrada, ela também será salva no local storage, desede que esteja ativa (campanha.store)
            this.loadUserData(loginAnimation);
          }
        }
        // Errors fallback
        if (![200].includes(profileResponse.status)) {
          localStorage.removeItem('login');
          campaign.removeCampaign();
          return defaultFallback('Não foi possível carregar seu perfil!');
        }

        this.setIntegracaoComper({
          campaign: this.currentCampaign,
          profile: this.profile,
        });

        this.obterConsumidorCupom({
          telefone: this.profile.telefone,
        });
      }
      if (this.config.POPUP_POS_LOGIN[0].mensagem) {
        this.$swal({
          type: `${this.config.POPUP_POS_LOGIN[0].icone}`,
          title: `${this.config.POPUP_POS_LOGIN[0].titulo}`,
          html: `${this.config.POPUP_POS_LOGIN[0].mensagem}`,
          confirmButtonText: `Ok`,
          showConfirmButton: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
      }
      const respostaCedulas = await this.carregarCedulasQuiz({
        campanha: this.currentCampaign,
        cpf: this.profile.cpf,
      });
      const responderDepois = localStorage.getItem('responderDepois');
      if (respostaCedulas.status == 200 && !responderDepois) {
        this.$router.push({ name: 'quiz' });
      }
    },

    async loadUserData(loginAnimation) {
      // Cupons
      if (this.config.USUARIO_CUPONS) {
        this.loadCoupons({
          campaign: this.currentCampaign,
          user: this.profile.telefone,
        });
      }
      // Raspadinhas
      if (this.config.USUARIO_RASPADINHA) {
        this.loadScratchs();
      }
      // Raspadinhas Alternativas
      //if (this.config.USUARIO_RASPADINHA_ALTERNATIVA) {
      //  this.loadAltScratchs({
      //    campaign: this.currentCampaign,
      //    user: this.profile.cpf || '',
      //  });
      //}
      // Conta corrente
      if (this.config.USUARIO_CONTA_CORRENTE) {
        this.loadCheckingAccountValue({
          campaign: this.currentCampaign,
          profile: this.profile,
        });
      }
      // Carregar config do cupom e da raspadinha
      this.fetchRaspadinhasAltConfig({
        campaign: this.currentCampaign,
      });
      this.recarregarConfiguracoesCupons();
      this.recarregarConfiguracoesDocsFiscais();

      // Em caso de algoritimo de redirecionamento fazer um else para o if de cima e implementa lo aqui
      const redirectMecanism = new redirect(['cupomEscaneado', 'emailVerificado']);
      const redirectRoutes = redirectMecanism.checkRedirectStatus();
      const foundRoute = redirectRoutes.find(r => r.redirect);
      const routeEscanear = `/${this.currentCampaign.ano}-${this.currentCampaign.identificacao}-`;
      let reg = new RegExp(`${routeEscanear}.+`);
      let noRedirect = false;
      if (foundRoute) {
        if (this.profile.cadastroInicial && reg.test(foundRoute.path)) noRedirect = true;
        else noRedirect = false;
      }
      // Verificando páginas pendentes de redirecionamento e redirecionando para tela desejada do app
      if (loginAnimation) {
        if (!foundRoute) {
          this.campaignAnimationState(true);

          let tela = null;
          if (this.usuarioCadastrado === true) {
            tela = this.config.TELA_REDIRECIONAR;
            if (this.config.TELA_REDIRECIONAR === null) {
              tela = this.telasConfiguraveis[0].destino;
            }
          } else {
            tela = this.telasConfiguraveis[0].destino;
          }

          // const tela = this.telasConfiguraveis[0].destino;

          if (this.$route.name != tela) this.$router.push({ name: tela });
        } else if (!noRedirect) {
          this.$router.push({ path: foundRoute.path });
        } else {
          this.campaignAnimationState(true);
          const tela = this.telasConfiguraveis[0].destino || 'contaCorrente';
          if (this.$route.name != tela) this.$router.push({ name: tela });
        }
      } else {
        if (foundRoute && !noRedirect) {
          this.$router.push({ path: foundRoute.path });
        }
      }

      const arrayCamposObrigatoriosPreenchidos = [];
      this.config.CAMPOS_OBRIGATORIOS_PERFIL.forEach(campo => {
        if (campo === 'endereco') {
          arrayCamposObrigatoriosPreenchidos.push(this.profile[campo].cep !== '');
          return;
        }

        arrayCamposObrigatoriosPreenchidos.push(this.profile[campo] !== '');
      });

      const camposObrigatoriosPreenchidos = arrayCamposObrigatoriosPreenchidos.every(Boolean);

      if (camposObrigatoriosPreenchidos && this.profile.cadastroInicial) {
        const respostaPerfil = await this.atualizarEmail({
          profile: { ...this.profile, cadastroInicial: false },
        });
        if ([200, 201].includes(respostaPerfil.status)) {
          console.log('Atualizado com sucesso');
          await this.limparPrimeiroAcesso();
        } else {
          console.log('Erro ao atualizar', respostaPerfil);
        }
      }

      // Modal de completar cadastro
      setTimeout(() => {
        this.camposObrigatoriosPreenchidos = camposObrigatoriosPreenchidos;
        if (
          this.profile.cadastroInicial ||
          (this.config.LOGIN_CPF && !this.profile.telefoneContato && !this.config.TXT_NAO_OBRIGATORIO_CONTATO)
        )
          this.modalControl.finishSignUp = !camposObrigatoriosPreenchidos;
      }, 1000);
    },

    async recarregarConfiguracoesDocsFiscais() {
      await this.obterConfiguracaoDocumentoFiscal({
        campanha: this.currentCampaign,
      });

      if (
        this.configuracaoDocumentosFiscais &&
        this.configuracaoDocumentosFiscais.configuracoes &&
        this.configuracaoDocumentosFiscais.configuracoes.some(e => e.tipo == 'NaoFiscal' && e.quantidadeLimite > 0)
      ) {
        await this.listarDocumentosPorUsuario({
          campanha: this.currentCampaign,
          usuario: this.profile.telefone,
          tipoDocumento: 'NaoFiscal',
          limitePorUsuario: this.configuracaoDocumentosFiscais.configuracoes.find(e => e.tipo == 'NaoFiscal')
            .quantidadeLimite,
        });
      }
    },

    async recarregarConfiguracoesCupons() {
      await this.fetchCupomConfig({
        campaign: this.currentCampaign,
      });

      if (this.cupomConfig && this.cupomConfig.limitePorUsuario > 0 && this.cupomConfig.tempoLimite >= 0) {
        await this.fetchCouponsPorPeriodo({
          campanha: this.currentCampaign,
          consumidor: this.profile.telefone,
          periodo: {
            inicial: Data.obterDataPorPeriodo(this.cupomConfig.tempoLimite + 1),
            final: Data.dataHoraAtual(),
          },
        });
      } else if (this.cupomConfig && this.cupomConfig.limitePorUsuario > 0) {
        await this.fetchCouponsPorPeriodo({
          campanha: this.currentCampaign,
          consumidor: this.profile.telefone,
        });
      }
    },

    alertaMobile() {
      //   this.$swal({
      //     type: 'info',
      //     title: 'Ops :(',
      //     html: `Nossos aplicativos estão sendo liberados nas lojas Google e Apple. Enquanto isso, os cadastros dos cupons fiscais devem ser finalizados através de um computador desktop ou notebook pelo navegador de internet no endereço <b>www.aniversariocomper.com.br</b>.`,
      //     showConfirmButton: false,
      //     allowEscapeKey: false,
      //     allowOutsideClick: false
      //   })
    },

    habilitarIntegracaoOneSignal() {
      const oneSignalConfigurado = config => config && typeof config === 'string' && config !== 'null';

      const habilitarOneSignal = this.isProd
        ? oneSignalConfigurado(this.config.ONESIGNAL_APP_ID)
        : oneSignalConfigurado(this.config.ONESIGNAL_APP_ID_TEST);

      if (habilitarOneSignal) {
        const aplicacaoID = this.isProd ? this.config.ONESIGNAL_APP_ID : this.config.ONESIGNAL_APP_ID_TEST;
        const dispositivo = DadosDispositivo.obterDados();

        window.qrsorteios.device = `${dispositivo.navegadorWeb} ${dispositivo.versaoNavegadorWeb} - ${dispositivo.distribuidoraNavegador} - ${dispositivo.sistemaOperacional} ${dispositivo.versaoSistemaOperacional}`;

        const scriptOneSignal = document.createElement('script');
        scriptOneSignal.innerHTML = `
          window.OneSignal = window.OneSignal || [];
          if (typeof cordova == 'undefined') {
            let os = OneSignal.push(function () {
              OneSignal.init({
                appId: "${aplicacaoID}",
                notifyButton: {
                  enable: true,
                },
                allowLocalhostAsSecureOrigin: true,
                // subdomainName: "qrapas",
              });
            });

            OneSignal.push(function() {
              OneSignal.getUserId(function(userID) {
                window.qrsorteios.deviceId = userID;
                console.log("@@@ Obteve o OneSignal userID");
              });
            });

            OneSignal.push(function() {
              OneSignal.on('notificationDisplay', function(evento) {
                console.log('@@@ Notificação OneSignal exibida', evento);
              });
            });
          
            OneSignal.showNativePrompt();

            console.log('@@@ One Signal: ok');
          }
        `;

        document.body.append(scriptOneSignal);
      }
    },
  },

  watch: {
    // Login intro
    showIntro(val) {
      const login = localStorage.getItem('login');
      if (val) {
        setTimeout(() => {
          this.startIntro = true;
        }, 400);
        setTimeout(() => {
          this.finishIntro = true;
        }, 1300);
        setTimeout(() => {
          this.campaignAnimationState(false);
        }, 2000);
      } else {
        this.startIntro = false;
        this.finishIntro = false;
      }
    },
  },
};
</script>

<style lang="scss">
@import './assets/styles/main';

.main-alternative-height {
  height: 80% !important;
}
</style>
