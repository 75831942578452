<template>
  <div class="w-100 d-flex flex-column align-items-center">
    <!-- Título -->
    <div class="w-75 d-flex align-items-center justify-content-center mb-2">
      <p class="f-11 mt-4 text-center" style="color: var(--text-color-highlight)">
        <strong>Redefinir senha via SMS</strong>
      </p>
    </div>

    <!-- Carregando -->
    <div v-if="carregando" class="w-75 d-flex flex-column align-items-center justify-content-center mb-3 f-09">
      <div class="loading mb-3"></div>
      <span>Carregando...</span>
    </div>

    <!-- Subtítulo -->
    <div class="w-75 d-flex flex-column align-items-center justify-content-center mb-3" v-if="usuarioGerouSMS">
      <div class="row mr-2 ml-2 mb-3">
        <span class="f-11 text-center diminuir-texto" v-if="usuarioJaEnviouSMS"
          >Insira o código que foi enviado via SMS:</span
        >
        <span class="f-11 text-center diminuir-texto" v-else
          >Insira o código que foi enviado para o número {{ telefoneContatoMascarado }} via SMS:</span
        >
      </div>

      <base-pill-temporary-code class="w-100" v-model="codigoTemporario" :key="contadorCodigoTemporarioSMS" />

      <span class="w-100 ml-2 mr-2 text-center text-black f-09 mt-3"
        >Não recebeu o SMS? Clique no botão abaixo para reenviar.</span
      >
    </div>

    <!-- Reenviar -->
    <base-pill-button
      v-if="usuarioGerouSMS"
      id="esqueci-senha-enviar"
      :type="'light'"
      :right-middle-pill="'fas fa-chevron-circle-right'"
      :pillColors="['pill-light']"
      :pillIconColors="['icon-dark']"
      :rightMiddlePillRootColor="config.COR_DESTAQUE_CIRCUNFERENCIA_DOS_BOTOES"
      :rightMiddlePillIconRootColor="config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_DOS_BOTOES"
      :pillSize="['pill-average']"
      :disabled="carregando || tempoInicial > 0"
      @click="validarCondicoesEnvioSMS"
    >
      <template v-slot:pill-button-title>
        <span class="w-90 pl-3 pr-2 text-center text-black f-07"
          >Reenviar SMS {{ tempoInicial > 0 ? `(${tempoInicial})` : '' }}</span
        >
      </template>
    </base-pill-button>

    <!-- Voltar -->
    <div
      class="
        w-75
        d-flex
        align-items-center
        justify-content-center
        pt-5
      "
    >
      <base-pill-button
        id="esqueci-senha-voltar"
        :type="'light'"
        :left-middle-pill="'fas fa-chevron-circle-left'"
        :pillColors="['pill-dark']"
        :pillIconColors="['icon-light']"
        :leftMiddlePillRootColor="config.COR_DESTAQUE_CIRCUNFERENCIA_DOS_BOTOES"
        :leftMiddlePillIconRootColor="config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_DOS_BOTOES"
        :pillSize="['pill-average']"
        @click="voltarTela()"
      >
        <template v-slot:pill-button-title>
          <span class="w-90 pl-2 pr-3 text-center f-07 text-black">Voltar</span>
        </template>
      </base-pill-button>
    </div>
  </div>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators';
import { mapActions, mapGetters } from 'vuex';
import { Data } from '../../../lib/Helper';

export default {
  name: 'TheEsqueciSenhaViaSMS',
  computed: {
    ...mapGetters({
      config: 'config/getConfig',
      carregando: 'autenticacao/isLoading',
      campanhaAtual: 'campanha/getCampaign',
      getTempoRestanteEnviarSms: 'autenticacao/getTempoRestante',
    }),
  },
  data: () => ({
    usuarioJaEnviouSMS: false,
    tempoInicial: 300,
    form: {
      usuario: '',
    },
    codigoTemporario: '',
    usuarioEnviadoSMS: '',
    permitirReenvioSms: false,
    contadorCodigoTemporarioSMS: 0,
    contadorTimer: 0,
    usuariosQueGeraramCodigo: [],
    usuarioGerouSMS: false,

    telefoneContatoMascarado: '',

    modais: {
      codigoSMS: false,
    },
  }),

  async beforeMount() {
    const currentDate = Data.dataHoraAtual();
    const wsUrl = this.isProd ? this.config.VUE_APP_PROD_WS_URL : this.config.VUE_APP_DEV_WS_URL;
    await this.loadCampaign({ currentDate, wsUrl });

    if (this.usuario) {
      this.form.usuario = this.usuario;
      this.validarCondicoesEnvioSMS();
    }
  },

  methods: {
    ...mapActions({
      gerarTokenTemporario: 'autenticacao/esqueciMinhaSenha',
      enviarCodigoAleatorio: 'autenticacao/enviarCodigoAleatorioSMS',
      solicitarEnvioDoSms: 'autenticacao/esqueciMinhaSenhaViaSms',
      loadCampaign: 'campanha/fetchCampaign',
    }),
    contadorTempoReenviarSMS() {
      let intervalo;

      if (this.tempoInicial > 0 && this.tempoInicial <= 300) {
        intervalo = setInterval(() => {
          this.tempoInicial--;

          if (this.tempoInicial <= 0) {
            clearInterval(intervalo);
            return;
          }
        }, 1000);
      }
    },
    async validarCondicoesEnvioSMS() {
      // Verifica se o cpf/cnpj inserido está no formato correto
      if (this.$v.$invalid) {
        this.emitirModalInformacao({
          icone: 'fas fa-exclamation-circle',
          titulo: 'Ops...',
          mensagem: 'Há erros de validação! Por favor revise o preenchimento do formulário.',
          textoBoato: 'OK',
        });
        return;
      }
      this.usuarioGerouSMS = false;
      // this.usuarioGerouSMS = this.verificaSeUsuarioJaEnviouCodigoViaSMS();
      this.contadorCodigoTemporarioSMS++;

      // if (this.usuarioGerouSMS) return (this.modais.codigoSMS = true);
      await this.enviarFormulario();
    },
    async enviarFormulario() {
      let mensagemErro = null;

      const enviarSms = await this.solicitarEnvioDoSmsRedefinirSenha();

      const msgErroTempo = enviarSms.msg.includes('segundo(s).') ? true : false;

      if (enviarSms.status == 200 && enviarSms.retorno) {
        this.usuarioJaEnviouSMS = false;
        this.telefoneContatoMascarado = enviarSms.retorno.numeroTelefone;

        const tempoRestanteUsuario = enviarSms.retorno.segundosRestantes;

        this.usuariosQueGeraramCodigo.push({ usuario: this.form.usuario, tempoRestanteUsuario: tempoRestanteUsuario });

        this.usuarioGerouSMS = true;
        this.contadorCodigoTemporarioSMS++;
        // this.modais.codigoSMS = true;
        this.permitirReenvioSms = true;
        this.tempoInicial = 300;
        this.contadorTempoReenviarSMS();
      } else if (enviarSms.status == 400 && msgErroTempo) {
        this.usuarioJaEnviouSMS = true;
        const mensagem = enviarSms.msg;
        const regex = /por mais (\d+) segundo\(s\)/; // Expressão regular para encontrar o número de segundos
        const match = mensagem.match(regex); // Procura por correspondências na mensagem
        const segundos = parseInt(match[1]); // Extrai o valor numérico encontrado
        this.tempoInicial = segundos;
        this.contadorTempoReenviarSMS();
        this.usuarioGerouSMS = true;

        // this.emitirModalInformacao({
        //   icone: 'fas fa-exclamation-circle',
        //   titulo: 'Ops...',
        //   mensagem: enviarSms.msg,
        //   textoBoato: 'OK',
        // });
        // this.modais.codigoSMS = true;
      } else {
        mensagemErro = this.verificaMsgErroSms(enviarSms.msg);
        this.emitirModalInformacao({
          icone: 'fas fa-exclamation-circle',
          titulo: 'Ops...',
          mensagem: mensagemErro,
          textoBoato: 'OK',
          acao: () => {
            this.voltarTela();
          },
        });
      }
    },

    verificaMsgErroSms(mensagem) {
      if (mensagem.includes('Login não encontrado para este usuário.')) return 'Esse login não existe.';
      if (mensagem.includes('Não foi encontrado número de telefone para esse usuário.'))
        return 'Não foi encontrado número de telefone para esse usuário.';
      if (mensagem.includes('O número de telefone vinculado a essa conta não é um número de celular válido.'))
        return 'O número de telefone vinculado a essa conta é inválido.';

      return mensagem;
    },

    async enviarCodigoAleatorioRecebidoViaSMS() {
      this.usuarioEnviadoSMS = this.form.usuario;
      const resposta = await this.enviarCodigoAleatorio({
        codigo: this.codigoTemporario,
        usuario: this.usuarioEnviadoSMS,
        isProd: this.isProd,
      });

      const { status, retorno, msg } = resposta;

      if ([200, 201].includes(status)) return (location.href = `${retorno.linkRedefinirSenha}`);

      this.emitirModalInformacao({
        icone: 'fas fa-exclamation-circle',
        titulo: 'Ops...',
        mensagem:
          msg == 'Erro ao gerar token para recuperação de senha: Código temporário inválido/expirado.'
            ? 'Tente novamente, pois o código temporário está inválido/expirado.'
            : msg,
        textoBoato: 'OK',
      });
      this.contadorCodigoTemporarioSMS += 1;
    },

    voltarTela() {
      this.$emit('voltar');
    },

    async reenviarCodigoTemporario() {
      const reenviarSms = await this.solicitarEnvioDoSmsRedefinirSenha();

      if (reenviarSms.status == 200 && reenviarSms.retorno) {
        this.usuarioGerouSMS = true;
        this.permitirReenvioSms = true;
        this.contadorCodigoTemporarioSMS++;

        this.emitirModalInformacao({
          icone: 'fas fa-thumbs-up',
          titulo: 'Sucesso!',
          mensagem: 'O SMS foi reenviado com sucesso!',
          textoBoato: 'OK',
        });
      } else {
        this.emitirModalInformacao({
          icone: 'fas fa-exclamation-circle',
          titulo: 'Ops...',
          mensagem: reenviarSms.msg,
          textoBoato: 'OK',
        });
      }
    },

    emitirModalInformacao({ icone, titulo, mensagem, textoBotao, acao }) {
      this.$emit('emmitInfoModal', {
        event: 'modal-informacao-generica',
        data: {
          icone,
          titulo,
          mensagem,
          textoBotao,
          acao,
        },
      });
    },

    verificaSeUsuarioJaEnviouCodigoViaSMS() {
      const possuiUsuario = this.usuariosQueGeraramCodigo.some(usuario => usuario.usuario === this.form.usuario);
      if (possuiUsuario) {
        return true;
      }
      return false;
    },

    async solicitarEnvioDoSmsRedefinirSenha() {
      const dados = await this.solicitarEnvioDoSms({
        usuario: this.form.usuario,
        campanha: this.campanhaAtual || this.campaign,
        isProd: this.isProd,
      });
      return dados;
    },
  },

  watch: {
    codigoTemporario: async function(codigo) {
      if (codigo && codigo.length === 6) {
        await this.enviarCodigoAleatorioRecebidoViaSMS();
      }
    },

    'form.usuario': function(usuarioForm) {
      this.usuarioGerouSMS = this.usuariosQueGeraramCodigo.find(usuario => {
        if (usuario.usuario === usuarioForm) {
          return true;
        }
        return false;
      });
    },
  },

  props: ['campaign', 'isProd', 'usuario'],

  // props: {
  //   isProd: {
  //     type: Boolean,
  //     required: true,
  //   },
  //   campaign: {
  //     required: true,
  //   },
  // },

  validations: {
    form: {
      usuario: {
        required,
        minLength: minLength(10),
      },
    },
  },
};
</script>

<style scoped lang="scss">
.opcao-reenviar-sms {
  text-decoration: underline;
  cursor: pointer;
}

@media screen and (max-width: 575.98px) {
  .diminuir-texto {
    font-size: 1rem !important;
  }

  .diminuir-titulo {
    font-size: 1.3rem !important;
  }
}
</style>
