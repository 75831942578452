<template>
  <div class="pill-product-group">
    <div class="container container-produto mt-2">
      <div class="row justify-content-center">
        <div class="col-12 col-md-12 col-lg-12 col-xl-12">
          <div class="card" :style="[styleCard]">
            <div
              class="pill-product pt-1"
              :class="[classesArray]"
              :disabled="disabled"
              style="background-color: rgb(250, 250, 250)"
            >
              <slot name="pill-product-title" toolTipMessage=""></slot>
            </div>

            <div
              @click="handleClick"
              class="bg-image hover-overlay ripple ripple-surface ripple-surface-light"
              data-mdb-ripple-color="light"
            >
              <img
                :src="urlImageProduct || `${require('@/assets/images/img-insdisponivel.png')}`"
                style="cursor: pointer; height: 16vh; min-width: 100%; margin: auto; object-fit: contain"
                class="img-fluid"
              />
            </div>
            <hr class="my-0 mx-3" />
            <div class="card-body user-select-none">
              <div class="d-flex justify-content-center px-2 py-1 mb-2 font-weight-bold">
                <div
                  class="text-secondary text-center fonte-descricao-produto"
                  style="min-height: 30px; max-height: 30px; font-size: 0.7rem"
                >
                  {{ nomeProduto }}
                </div>
              </div>

              <div class="d-flex justify-content-center">
                <button
                  type="button"
                  @click="
                    handleClickAdicaDeProdutoNoCarrinho(valorProduct, saldoUsuario, saldoTotalUsuarioNaoDisponivel)
                  "
                  @mouseover="mostrarTextoMensagemBotao = true"
                  @mouseleave="mostrarTextoMensagemBotao = false"
                  :disabled="produtoNoCarrinho || estoqueZerado"
                  :class="{
                    'estilo-produto-invalido': valorProduct > saldoUsuario || estoqueZerado,
                    'estilo-produto-valido': valorProduct <= saldoUsuario,
                    'estilo-produto-pendente': valorProduct == saldoTotalUsuarioNaoDisponivel,
                    'btn-primary': !mostrarTextoMensagemBotao,
                  }"
                  class="btn px-5 py-1 mb-2 h5"
                >
                  Eu quero
                </button>
              </div>

              <div
                class="texto-mensagem-botao"
                style="font-size: 0.6rem"
                :class="{
                  'mostrar-elemento': mostrarTextoMensagemBotao,
                  'estilo-produto-invalido-label': valorProduct > saldoUsuario || estoqueZerado,
                  'estilo-produto-valido-label': valorProduct <= saldoUsuario,
                }"
              >
                <span v-if="estoqueZerado">Produto sem estoque</span>
                <span v-else-if="valorProduct == saldoTotalUsuarioNaoDisponivel && this.config.EXIBIR_SALDO_COMO_PONTUACAO"" style="text-align: center"
                  >Ainda existem pontos a serem disponibilizados</span
                >
                <span v-else-if="valorProduct == saldoTotalUsuarioNaoDisponivel && !this.config.EXIBIR_SALDO_COMO_PONTUACAO"" style="text-align: center"
                  >Ainda existe cashback a ser disponibilizado</span
                >
                <span v-else-if="valorProduct > saldoUsuario && this.config.EXIBIR_SALDO_COMO_PONTUACAO"
                  >Pontos Insuficientes</span
                >
                <span v-else-if="valorProduct > saldoUsuario && !this.config.EXIBIR_SALDO_COMO_PONTUACAO"
                  >Cashback Insuficiente</span
                >
                <span v-else-if="produtoNoCarrinho">Produto já está no carrinho</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'BasePillProduct',
  data() {
    return {
      styleCard: {
        borderColor: this.pillBorderColors ? this.pillBorderColors : '',
        borderRadius: this.arredondado ? '20px' : '4px',
        boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.2)',
      },
      mostrarTextoMensagemBotao: false,
    };
  },
  props: {
    type: {
      type: String,
      required: false,
      default: 'primary',
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    disabledMessage: {
      type: String,
      required: false,
    },

    marcaProduct: {
      type: String,
      required: false,
      description: 'Marca',
    },

    setorProduct: {
      type: String,
      required: false,
      description: 'setor',
    },

    valorProduct: {
      type: String,
      required: false,
      description: 'valor',
    },

    urlImageProduct: {
      type: String,
      required: false,
      description: 'Picture product',
    },

    leftMiddlePill: {
      type: String,
      required: false,
      description: 'Pill at left button',
    },

    rightMiddlePill: {
      type: String,
      required: false,
      description: 'Pill at rigth button',
    },

    pillColors: {
      type: Array,
      required: false,
      description: 'Pill classes',
    },

    pillBorderColors: {
      type: [String, Array],
      required: false,
      default: '',
      description: 'Propriedade que define a cor das bordas do botão (se declarada)',
    },

    pillIconColors: {
      type: Array,
      required: false,
      description:
        'Propriedade que define as cores dos ícones de cada pill, respectivamente, de acordo com as cores do elemento pai',
    },

    pillSize: {
      type: Array,
      required: false,
      description: 'Pill classes',
    },

    leftMiddlePillRootColor: {
      type: String,
      required: false,
      default: '',
      description: 'Propriedade que define alguma cor capaz de sobrescrever a cor declarada na pílula',
    },

    leftMiddlePillIconRootColor: {
      type: String,
      required: false,
      default: '',
      description: 'Propriedade que define alguma cor capaz de sobrescrever a cor declarada no ícone pílula',
    },

    rightMiddlePillRootColor: {
      type: String,
      required: false,
      default: '',
      description: 'Propriedade que define alguma cor capaz de sobrescrever a cor declarada na pílula',
    },

    rightMiddlePillIconRootColor: {
      type: String,
      required: false,
      default: '',
      description: 'Propriedade que define alguma cor capaz de sobrescrever a cor declarada no ícone pílula',
    },

    saldoUsuario: {
      type: Number,
      required: false,
      default: 0,
      description: 'Propriedade que define o valor do saldo do usuário.',
    },

    saldoTotalUsuarioNaoDisponivel: {
      type: Number,
      required: false,
      default: 0,
      description:
        'Propriedade que define o saldo total do usuario somando o saldo disponivel e o saldo ainda não disponivel',
    },

    arredondado: {
      type: Boolean,
      required: false,
      default: true,
      description: 'Propriedade que define o estilo do card, se é arredondado ou não.',
    },

    nomeProduto: {
      type: String,
      required: false,
      default: '',
      description: 'Propriedade que define a descrição do produto.',
    },

    produtoNoCarrinho: {
      type: Boolean,
      required: false,
      default: 'false',
      description: 'Propriedade que define se produto já foi adicionado no carrinho do usuário.',
    },

    estoqueZerado: {
      type: Boolean,
      required: false,
      default: 'false',
      description: 'Propriedade que define se produto está sem estoque.',
    },
  },

  computed: {
    ...mapGetters({
      config: 'config/getConfig',
    }),

    buttonTypeClass() {
      return this.type !== 'none' ? `pill-product-${this.type}` : '';
    },

    classesArray() {
      return [this.buttonTypeClass, { disabled: this.disabled }];
    },
  },

  methods: {
    handleClick(event) {
      if (!this.disabled) {
        this.$emit('click', event);
      } else {
        this.$swal.fire({
          icon: 'warning',
          title: 'Ops...',
          text: this.disabledMessage,
        });
      }
    },

    handleClickAdicaDeProdutoNoCarrinho(valorProduct, saldoUsuario, saldoTotalUsuarioNaoDisponivel, event) {
      if (valorProduct <= saldoUsuario) {
        this.$emit('clickBotaoCarrinho', event);
      } else if (valorProduct <= saldoTotalUsuarioNaoDisponivel && this.config.EXIBIR_SALDO_COMO_PONTUACAO) {
        this.$swal({
          type: 'warning',
          title: 'Ops...',
          text: `Ainda existem pontos a serem disponibilizados!`,
        });
      }else if (valorProduct <= saldoTotalUsuarioNaoDisponivel && !this.config.EXIBIR_SALDO_COMO_PONTUACAO) {
        this.$swal({
          type: 'warning',
          title: 'Ops...',
          text: `Ainda existe cashback a ser disponibilizado!`,
        });
      }
    },
  },
};
</script>
<style lang="scss" src="./style.scss"></style>
