import Vue from 'vue'
import { Text } from '../../lib/Helper'

// Initial state
const initialState = () => ({
  associates: [],
  loading: false,
  totalPages: 2,
  total: 0,
  todosAssociados: []
})

// State object
const state = initialState()

// Getters
const getters = {
  getAssociates(state) {
    return state.associates
  },
  loadingState(state) {
    return state.loading
  },
  getTotalPages(state) {
    return state.totalPages
  },
  getTotal(state) {
    return state.total
  },
  getAssociatesToSelectInput(state) {
    return state.associates.map(associado => ({
      desc: associado.nome,
      value: associado.nome,
      cnpj: associado.cnpjCpf
    }))
  },
  getTodosAssociados(state) {
    return state.todosAssociados
  }
}

// Actions
const actions = {
  clearState({ commit }) {
    commit('RESET')
  },
  fetchAssociatesList({ commit }, { campaign, pagination, filter }) {
    return new Promise(async resolve => {
      const api = new Vue.prototype.$httpClient()
      commit('setLoading', true)
      const response = await api.send(
        `/campanha/v1/associados/${campaign.ano}/${campaign.identificacao}/cadastro`,
        'POST',
        {
          totalPorPagina: pagination.perPage,
          paginaAtual: pagination.page,
          filtroNome: filter.toUpperCase(),
          filtroAtivo: true
        },
        null,
        false
      )
      if ([200, 201].includes(response.status)) {
        commit('setAssociates', response.data.retorno.retorno)
        commit('setTotal', response.data.retorno.total)
        commit('setTotalPages', response.data.retorno.paginas)
        commit('setLoading', false)
        resolve({
          status: 200,
          msg: 'Lista de associados atualizada'
        })
      } else {
        commit('setLoading', false)
        resolve({
          status: response.status,
          msg:
            response.data.mensagem ||
            'Ocorreu um erro desconhecido durante sua requisição!'
        })
      }
    })
  },
  fetchTodosAssociados({ commit }, { paginacao, filtro }) {
    return new Promise(async resolve => {
      const fitroFormatado = Text.serializarParams(filtro)
      const api = new Vue.prototype.$httpClient()
      commit('setLoading', true)
      const response = await api.send(
        `/associado/v1/associados/listarAssociados/${paginacao.totalPorPagina}/${paginacao.paginaAtual}?${fitroFormatado}`,
        'GET',
        null,
        null,
        false
      )
      if ([200, 201].includes(response.status)) {
        commit('setTodosAssociados', response.data.retorno.retorno)
        commit('setTotal', response.data.retorno.total)
        commit('setTotalPages', response.data.retorno.paginas)
        commit('setLoading', false)
        resolve({
          status: 200,
          msg: 'Lista de associados obtida com sucesso!'
        })
      } else {
        commit('setTodosAssociados', [])
        commit('setLoading', false)
        resolve({
          status: response.status,
          msg:
            response.data.mensagem ||
            'Ocorreu um erro desconhecido durante sua requisição!'
        })
      }
    })
  },
  obterLojasMaisProximas({ commit }, { latitude, longitude, isProd }) {
    return new Promise(async (resolve) => {
      const config = Vue.prototype.$storeConfig.config();
      const urlBase = isProd ? config.VUE_APP_PROD_WS_URL : config.VUE_APP_DEV_WS_URL;
      const api = new Vue.prototype.$httpClient();
      const url = `${urlBase}/associado/v1/associados/listarAssociadosMaisProximos?latitude=${latitude}&longitude=${longitude}`;
      const resposta = await api.send(url, 'GET');
      if ([200, 201].includes(resposta.status)) {
        resolve({
          status: 200,
          msg: 'Associados mais próximas recuperados!',
          retorno: resposta.data.retorno,
        });
      } else {
        resolve({
          status: resposta.status || 500,
          msg: resposta.data.mensagem || 'Você pode estar off-line. Tente novamente mais tarde.',
        });
      }
    });
  },
}

// Mutations
const mutations = {
  RESET(state) {
    const newState = initialState()
    Object.keys(newState).forEach(key => {
      state[key] = newState[key]
    })
  },
  setAssociates(state, data) {
    state.associates = [...data]
  },
  setLoading(state, data) {
    state.loading = data
  },
  setTotalPages(state, data) {
    state.totalPages = data
  },
  setTotal(state, data) {
    state.total = data
  },
  setTodosAssociados(state, data) {
    state.todosAssociados = data
  }
}

// Module exports
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
