<template>
  <div
    :class="{
      start: start,
      init: init,
    }"
    class="campaign-info"
  >
    <span class="campaign-icon" style="color: var(--icon-color)">
      <i class="fas fa-bullhorn"></i>
    </span>
    <div class="campaign-data">
      <span class="campaign-authorization text-uppercase">
        {{ config.CAMPANHA_CERTIFICADO || '' }}
      </span>
      <p style="color: var(--icon-color)" class="text-uppercase campaign-title m-0">
        {{ currentCampaign ? currentCampaign.descricao : '' }}
      </p>
    </div>
    <!-- <span class="cursor-pointer" v-if="!inProgress" @click="initAnimation">
      <i class="fas fa-chevron-right"></i>
    </span> -->
    <div
      class="right-middle-pill pill-body-dark pill-size cursor-pointer"
      :style="
        config.COR_DESTAQUE_CIRCUNFERENCIA_CARDS_MODAIS
          ? `background-color: ${config.COR_DESTAQUE_CIRCUNFERENCIA_CARDS_MODAIS} !important;`
          : ''
      "
      v-if="!inProgress"
      @click="initAnimation"
    >
      <i
        class="fas fa-chevron-circle-right"
        :style="
          config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_CARDS_MODAIS
            ? `color: ${config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_CARDS_MODAIS} !important;`
            : ''
        "
      ></i>
    </div>
    <!-- <span
			v-if="!inProgress"
			class="campaign-expand bg-secondary cursor-pointer"
		>
			<i class="text-white fas fa-chevron-up"></i>
		</span> -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'campaign-info',
  computed: {
    ...mapGetters({
      config: 'config/getConfig',
      currentCampaign: 'campanha/getCampaign',
    }),
  },
  data: () => ({
    start: false,
    init: false,
    inProgress: false,
  }),
  methods: {
    ...mapActions({
      handleMenuSlide: 'ui/handleMenuSlide',
    }),
    navigateTo(route) {
      this.$router.push({ name: route });
    },
    initAnimation() {
      this.inProgress = true;
      this.start = true;
      setTimeout(() => {
        this.init = true;
      }, 300);
      setTimeout(() => {
        this.finish = true;
      }, 550);
      setTimeout(() => {
        this.inProgress = false;
        this.start = false;
        this.init = false;
        this.navigateTo('campanha');
        this.handleMenuSlide('campanha');
      }, 1200);
    },
  },
};
</script>
