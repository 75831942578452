import Vue from 'vue'

// Initial state
const initialState = () => ({})

// State object
const state = initialState()

// Getters
const getters = {
  getConfig() {
    return Vue.prototype.$storeConfig.config()
  },
  obterTelasConfiguraveis() {

    const retornaTelasConfiguraveis = (config) =>{
      const listaAtual = config.RENDERIZACAO_MENU.filter(c => {
          if(c.destino === 'vitrineProdutos'){
            if(config.HABILITAR_VITRINE_PRODUTOS_FIDELIDADE == true){
              return c;
            }
          }else{
            return c;
          }          
        });

        return listaAtual;
    };


    if (getters.getConfig()) {
      const configuracoes = retornaTelasConfiguraveis(getters.getConfig()) 
      if (!configuracoes) return null
      const telas = configuracoes.map((c, i) => {
        if (i == 0) {
          return {
            ...c,
            anterior: null,
            proximo: configuracoes[i + 1].destino
          }
        }
        if (i == configuracoes.length - 1) {
          return {
            ...c,
            anterior: configuracoes[i - 1].destino,
            proximo: null
          }
        }
        return {
          ...c,
          anterior: configuracoes[i - 1].destino,
          proximo: configuracoes[i + 1].destino
        }
      })
      return telas
    } else {
      return null
    }
  }
}

// Actions
const actions = {
  clearState({ commit }) {
    commit('RESET')
  }
}

// Mutations
const mutations = {
  RESET(state) {
    const newState = initialState()
    Object.keys(newState).forEach(key => {
      state[key] = newState[key]
    })
  }
}

// Module exports
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
