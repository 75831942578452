var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"qr-input"},[_c('div',{staticClass:"form-group",class:[
      { 'input-group': _vm.hasIcon },
      { 'has-danger': _vm.error },
      { focused: _vm.focused },
      { 'has-label': _vm.label || _vm.$slots.label },
      { 'has-success': _vm.valid === true },
      { 'has-danger': _vm.valid === false },
      { 'has-value': _vm.hasValue }
    ]},[_vm._t("label",function(){return [(_vm.label)?_c('label',{staticClass:"form-control-label",class:_vm.labelClasses},[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.required)?_c('span',[_vm._v("*")]):_vm._e()]):_vm._e()]}),(_vm.addonLeftIcon || _vm.$slots.addonLeft)?_c('div',{staticClass:"input-group-prepend mr-0"},[_c('span',{staticClass:"input-group-text input-bg addon-left-radius",class:_vm.addonLeftClasses},[_vm._t("addonLeft",function(){return [_c('i',{class:_vm.addonLeftIcon})]})],2)]):_vm._e(),_vm._t("default",function(){return [_c('select',_vm._g(_vm._b({staticClass:"form-control",class:[
          { 'is-valid': _vm.valid === true },
          { 'is-invalid': _vm.valid === false },
          _vm.inputClasses
        ],attrs:{"aria-describedby":"addon-left"},domProps:{"value":_vm.value}},'select',_vm.$attrs,false),_vm.listeners),[(_vm.defaultOption)?_c('option',{staticStyle:{"color":"#000"},attrs:{"selected":""},domProps:{"value":_vm.defaultOption}},[_vm._v(" "+_vm._s(_vm.defaultOption)+" ")]):_vm._e(),_vm._l((_vm.options),function(op,i){return _c('option',{key:i,staticStyle:{"color":"#000"},attrs:{"disabled":op.disabled,"hidden":op.hidden},domProps:{"value":op.value}},[_vm._v(" "+_vm._s(op.desc)+" ")])})],2)]},null,_vm.slotData)],2),_vm._t("helpBlock",function(){return [(_vm.error)?_c('div',{staticClass:"text-danger invalid-feedback",class:{ 'mt-2': _vm.hasIcon },staticStyle:{"display":"block"}},[_vm._t("errorBlock")],2):_vm._e()]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }