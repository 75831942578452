<template>
  <div class="qr-input">
    <div
      class="form-group"
      :class="[
        { 'input-group': hasIcon },
        { 'has-danger': error },
        { focused: focused },
        { 'has-label': label || $slots.label },
        { 'has-success': valid === true },
        { 'has-danger': valid === false },
        { 'has-value': hasValue }
      ]"
    >
      <slot name="label">
        <label v-if="label" class="form-control-label" :class="labelClasses">
          {{ label }}
          <span v-if="required">*</span>
        </label>
      </slot>
      <div
        v-if="addonLeftIcon || $slots.addonLeft"
        class="input-group-prepend mr-0"
      >
        <span
          class="input-group-text input-bg addon-left-radius"
          :class="addonLeftClasses"
        >
          <slot name="addonLeft">
            <i :class="addonLeftIcon"></i>
          </slot>
        </span>
      </div>
      <slot v-bind="slotData">
        <select
          :value="value"
          v-on="listeners"
          v-bind="$attrs"
          class="form-control"
          :class="[
            { 'is-valid': valid === true },
            { 'is-invalid': valid === false },
            inputClasses
          ]"
          aria-describedby="addon-left"
        >
          <option
            style="color:#000;"
            v-if="defaultOption"
            :value="defaultOption"
            selected
          >
            {{ defaultOption }}
          </option>
          <option
            style="color:#000;"
            v-for="(op, i) in options"
            :key="i"
            :value="op.value"
            :disabled="op.disabled"
            :hidden="op.hidden"
          >
            {{ op.desc }}
          </option>
        </select>
      </slot>
    </div>
    <slot name="helpBlock">
      <div
        class="text-danger invalid-feedback"
        style="display: block"
        :class="{ 'mt-2': hasIcon }"
        v-if="error"
      >
        <slot name="errorBlock"></slot>
      </div>
    </slot>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  name: 'base-select',
  props: {
    required: {
      type: Boolean,
      description: 'Whether input is required (adds an asterix *)'
    },
    valid: {
      type: Boolean,
      description: 'Whether is valid',
      default: undefined
    },
    label: {
      type: String,
      description: 'Input label (text before input)'
    },
    error: {
      type: Boolean,
      description: 'Input error (below input)'
    },
    labelClasses: {
      type: String,
      description: 'Input label css classes'
    },
    addonLeftClasses: {
      type: String,
      description: 'Addon-left css classes'
    },
    inputClasses: {
      type: String,
      description: 'Input css classes'
    },
    defaultOption: {
      type: String,
      description: 'Default option display'
    },
    options: {
      type: Array,
      description: 'Options array: { value: Any, desc: String, hidden: boolean}'
    },
    value: {
      type: [String, Number],
      description: 'Input value',
      default: ''
    },
    addonLeftIcon: {
      type: String,
      description: 'Addont left icon'
    }
  },
  data() {
    return {
      focused: false
    }
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: this.updateValue,
        focus: this.onFocus,
        blur: this.onBlur
      }
    },
    slotData() {
      return {
        focused: this.focused,
        ...this.listeners
      }
    },
    hasIcon() {
      const { addonLeft } = this.$slots
      return addonLeft !== undefined || this.addonLeftIcon !== undefined
    },
    hasValue() {
      if (this.value) {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    updateValue(evt) {
      let value = evt.target.value
      this.$emit('input', value)
    },
    onFocus(value) {
      this.focused = true
      this.$emit('focus', value)
    },
    onBlur(value) {
      this.focused = false
      this.$emit('blur', value)
    }
  }
}
</script>
