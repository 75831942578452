<template>
  <div class="w-100 d-flex flex-column align-items-center">
    <!-- Título -->
    <div
      class="
        w-75
        d-flex
        flex-row
        align-items-center
        justify-content-center
        mb-3
      "
    >
      <p
        class="f-11 mt-4 mb-4 text-center"
        style="color: var(--text-color-highlight)"
      >
        <strong>Termos</strong>
      </p>
    </div>

    <!-- Regulamento -->
    <div class="w-75 d-flex align-items-center justify-content-center mb-3">
      <base-pill-check-box
        id="regulamento-link"
        name="input-regulamento-link"
        class="w-100"
        :pillSize="'pill-average'"
        :selectedValue="regulamento"
        @click="alternarAceiteRegulamento()"
        :campoSwitch="true"
        v-tooltip.top="`Li e aceito as condições descritas no regulamento da ${config.NOME_PROMOCAO}`"
      >
        <template v-slot:pill-check-box-title>
          <label class="pl-5 pr-3 mb-0 f-065 text-black" for="regulamento">
            Li e aceito as condições descritas no
            <span @click="navigateTo('regulamento')" style="cursor: pointer">
              <u>regulamento da {{ config.NOME_PROMOCAO }}</u>
            </span>
            <span class="red"> *</span>
          </label>
        </template>
      </base-pill-check-box>
    </div>

    <!-- Termos de aceite e política de privacidade -->
    <div class="w-75 d-flex align-items-center justify-content-center mb-3">
      <base-pill-check-box
        id="politica-termos-link"
        name="input-politica-termos-link"
        class="w-100"
        :pillSize="'pill-average'"
        :selectedValue="politicaTermos"
        :campoSwitch="true"
        @click="alternarPoliticaTermos()"
        v-tooltip.top="
          config.HABILITAR_TERMOS_ACEITE_LGPDS
            ? 'Li e estou ciente dos Termos de Política de Privacidade e Termos de Aceite'
            : 'Li e estou ciente da Política de Privacidade'
        "
      >
        <template v-slot:pill-check-box-title>
          <label class="ml-5 pr-3 mb-0 f-065 text-black" for="regulamento">
            Li e estou ciente da
            <span
              @click="
                config.POLITICA_PRIVACIDADE_PERSONALIZADA
                  ? navigateTo('politicaPrivacidadePersonalizada')
                  : navigateTo('politicaPrivacidade')
              "
              style="cursor: pointer"
            >
              <u>Política de Privacidade</u>
            </span>
            <span @click="navigateTo('termos')" style="cursor: pointer" v-if="config.HABILITAR_TERMOS_ACEITE_LGPDS">
              e <u>Termos de Aceite</u> </span
            ><span class="red"> *</span>
          </label>
        </template>
      </base-pill-check-box>
    </div>

    <!-- Aviso de privacidade -->
    <div class="w-75 d-flex align-items-center justify-content-center mb-3"
      v-if="config.HABILITAR_TERMOS_AVISO_PRIVACIDADE"
    >
      <base-pill-check-box
        id="termos-aviso-privacidade"
        name="termos-aviso-privacidade"
        class="w-100"
        :pillSize="'pill-average'"
        :selectedValue="avisoPrivacidade"
        :campoSwitch="true"
        @click="alternarAvisoPrivacidade()"
        v-tooltip.top="'Li e estou ciente do Aviso de Privacidade'"
      >
        <template v-slot:pill-check-box-title>
          <label class="ml-5 pr-3 mb-0 f-065 text-black" for="aviso-privacidade">
            Li e estou ciente do
            <span
              @click="navigateTo('avisoPrivacidade')"
              style="cursor: pointer"
            >
              <u>Aviso de Privacidade</u>
            </span>
            <span class="red"> *</span>
          </label>
        </template>
      </base-pill-check-box>
    </div>

    <!-- Permissões | Mensagens -->
    <div
      class="w-75 d-flex align-items-center justify-content-center mb-3"
      v-if="config.CONCORDAR_RECEBER_MENSAGENS"
    >
      <base-pill-check-box
        id="mensagens-link"
        name="input-mensagens-link"
        class="w-100"
        :pillSize="'pill-average'"
        :selectedValue="notificacoes"
        :campoSwitch="true"
        @click="alternarNotificacoes()"
        v-tooltip.top="config.TEXTO_RECEBER_MENSAGENS"
      >
        <template v-slot:pill-check-box-title>
          <label class="pl-5 pr-3 mb-0 f-065 text-black" for="notificacoes">
            {{ config.TEXTO_RECEBER_MENSAGENS }}
          </label>
        </template>
      </base-pill-check-box>
    </div>

    <!-- Pergunta -->
    <div
      class="
        w-75
        d-flex
        flex-column
        align-items-center
        justify-content-center
        mb-3
      "
      v-if="pergunta"
    >
      <p
        class="w-100 f-09 mt-4 mb-4 text-center"
        style="color: var(--text-color-highlight)"
      >
        <strong>{{ pergunta }}</strong>
      </p>
      <div class="w-100 d-flex align-items-center justify-content-between mb-3">
        <div
          class="
            w-45
            d-flex
            flex-column
            align-items-center
            justify-content-center
            mb-3
          "
          v-for="(resposta, index) in respostas"
          :key="index"
        >
          <base-pill-check-box
            :id="resposta.valorOpcao"
            name="grupo-inputs-respostas-lgpd"
            type="radio"
            class="w-100"
            :pillSize="'pill-average'"
            :campoSwitch="true"
            @click="alternarRespostaPergunta(resposta.valorOpcao)"
            :selectedValue="respostaPergunta"
          >
            <template v-slot:pill-check-box-title>
              <label
                class="pl-5 pr-3 mb-0 f-065 text-black"
                :for="resposta.valorOpcao"
              >
                {{ resposta.textoOpcao }}
              </label>
            </template>
          </base-pill-check-box>
        </div>
      </div>
    </div>

    <!-- Informações -->
    <div
      class="w-75 d-flex align-items-center justify-content-center pb-3"
      :class="pergunta ? 'pt-3' : 'pt-5'"
    >
      <span class="f-065" style="color: var(--text-color-highlight)"
        ><span class="red">*</span> Campos com preenchimento obrigatório</span
      >
    </div>

    <!-- Ações -->
    <div
      class="w-75 d-flex align-items-center justify-content-between"
      :class="pergunta ? 'pb-4' : 'pb-5'"
    >
      <base-pill-button
        id="lgpd-voltar"
        :type="'light'"
        :left-middle-pill="'fas fa-chevron-circle-left'"
        :pillColors="['pill-dark']"
        :pillIconColors="['icon-light']"
        :leftMiddlePillRootColor="config.COR_DESTAQUE_CIRCUNFERENCIA_DOS_BOTOES"
        :leftMiddlePillIconRootColor="config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_DOS_BOTOES"
        :pillSize="['pill-average']"
        @click="voltarTela()"
      >
        <template v-slot:pill-button-title>
          <span class="w-90 pl-2 pr-3 text-center f-07 text-black">Voltar</span>
        </template>
      </base-pill-button>
      <base-pill-button
        id="lgpd-voltar"
        v-if="!this.block"
        :type="'light'"
        :right-middle-pill="'fas fa-chevron-circle-right'"
        :pillColors="['pill-light']"
        :pillIconColors="['icon-dark']"
        :rightMiddlePillRootColor="config.COR_DESTAQUE_CIRCUNFERENCIA_DOS_BOTOES"
        :rightMiddlePillIconRootColor="config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_DOS_BOTOES"
        :pillSize="['pill-average']"
        @click="validadeTermos"
      >
        <template v-slot:pill-button-title>
          <span class="w-90 pl-3 pr-2 text-center f-07 text-black"
            >Continuar</span
          >
        </template>
      </base-pill-button>

      <base-pill-button
        id="lgpd-voltar"
        v-else
        :type="'light'"
        :right-middle-pill="'fas fa-chevron-circle-right'"
        :pillColors="['pill-light']"
        :pillIconColors="['icon-dark']"
        :rightMiddlePillRootColor="config.COR_DESTAQUE_CIRCUNFERENCIA_DOS_BOTOES"
        :rightMiddlePillIconRootColor="config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_DOS_BOTOES"
        :pillSize="['pill-average']"
        @click="informarCampanhaNaoDisponivel()"
      >
        <template v-slot:pill-button-title>
          <span class="w-90 pl-3 pr-2 text-center f-07 text-black"
            >Continuar</span
          >
        </template>
      </base-pill-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { Data } from '@/lib/Helper'

export default {
  name: 'TheLgpd',
  created() {
    // Escondendo a logo
    let logoQR, logoCampanha, menuInferior
    let intervalo = setInterval(() => {
      logoQR = document.querySelector('.qr-logo')
      logoCampanha = document.querySelector('.campaing-logo')
      menuInferior = document.querySelector('.fixed-bottom')
      if (menuInferior && logoQR && logoCampanha) {
        logoCampanha.style.display = 'none'
        logoQR.style.display = 'none'
        menuInferior.style.paddingBottom = '1rem'
      }
      if (menuInferior && logoQR && logoCampanha) {
        clearInterval(intervalo)
      }
    }, 100)
  },

  beforeMount() {
    const configPergunta = this.config.PERGUNTA_PRE_CADASTRO

    if (configPergunta.length >= 1) {
      this.pergunta = configPergunta[0].textoOpcao

      for (let i = 1; i <= configPergunta.length - 1; i++) {
        this.respostas.push(configPergunta[i])
      }
    }
  },

  mounted() {
    this.carregaCampanhas()
  },

  computed: {
    ...mapGetters({
      config: 'config/getConfig',
      lgpdPoliticaTermos: 'lgpd/getPolitica',
      lgpdRegulamento: 'lgpd/getRegulamento',
      lgpdNotificacoes: 'lgpd/getNotificacoes',
      lgpdAvisoPrivacidade: 'lgpd/getAvisoPrivacidade'
    }),

    regulamento: {
      get() {
        return this.lgpdRegulamento
      },
      set(value) {
        this.salvarRegulamento({ regulamento: value })
      }
    },

    politicaTermos: {
      get() {
        return this.lgpdPoliticaTermos
      },
      set(value) {
        this.salvarPolitica({ politica: value })
      }
    },

    notificacoes: {
      get() {
        return this.lgpdNotificacoes
      },
      set(value) {
        this.salvarNotificacoes({ notificacoes: value })
      }
    },

    avisoPrivacidade: {
      get() {
        return this.lgpdAvisoPrivacidade
      },
      set(value) {
        this.salvarAvisoPrivacidade({ avisoPrivacidade: value })
      }
    }
  },

  data: () => ({
    pergunta: '',
    respostas: [],
    respostaPergunta: '',

    block: true
  }),

  methods: {
    ...mapActions({
      salvarPolitica: 'lgpd/salvarPolitica',
      salvarRegulamento: 'lgpd/salvarRegulamento',
      salvarNotificacoes: 'lgpd/salvarNotificacoes',
      salvarAvisoPrivacidade: 'lgpd/salvarAvisoPrivacidade'
    }),

    carregaCampanhas: async function () {
      const wsUrl = this.isProd
        ? this.config.VUE_APP_PROD_WS_URL
        : this.config.VUE_APP_DEV_WS_URL
      const currentDate = Data.dataHoraAtual()
      const url = `${wsUrl}/campanha/v1/campanhas/`
      let campanhaRecuperada = null
      await fetch(url)
        .then((response) => response.json())
        .then((data) => {
          data.retorno
            .filter((camp) => {
              let initial = `${camp.dataInicial} 00:00:00`
              let final = `${camp.dataFinalCadastros} 23:59:00`
              return (
                camp.ativa && currentDate >= initial && currentDate <= final
              )
            })
            .forEach((camp) => {
              campanhaRecuperada = camp
            })
          campanhaRecuperada ? (this.block = false) : (this.block = true)
        })
        .catch((error) => {
          console.error('Error:', error)
          this.block = true
          this.informarCampanhaNaoEncontrada()
        })
    },
    informarCampanhaNaoEncontrada() {
      this.$emit('alternar-modal', {
        event: 'informar-campanha-nao-encontrada'
      })
    },
    informarCampanhaNaoDisponivel() {
      this.$emit('alternar-modal', {
        event: 'informar-campanha-nao-disponivel-nao-ativa'
      })
    },

    navigateTo(name) {
      this.$router.push({ name })
    },

    navigateBack() {
      this.$router.go(-1)
    },

    voltarTela() {
      this.$emit('alternar-exibicao', {
        event: 'alternar-tela-lgpd'
      })
    },

    handleTransition(evt) {
      this.$emit('emmitEvent', {
        event: 'cardTransition',
        data: {
          page: evt.data.page,
          origin: evt.data.origin
        }
      })
    },

    alternarPoliticaTermos() {
      this.politicaTermos = !this.politicaTermos
      if (this.politicaTermos) {
        this.$emit('emmitEvent', {
          event: 'gtm',
          data: {
            event: 'aceitePoliticaTermos',
            category: 'CadastroUsuario',
            action: 'click',
            label: 'Clicou para aceitar política e termos'
          }
        })
      }
    },
    alternarAceiteRegulamento() {
      this.regulamento = !this.regulamento
      if (this.regulamento) {
        this.$emit('emmitEvent', {
          event: 'gtm',
          data: {
            event: 'aceiteRegulamento',
            category: 'CadastroUsuario',
            action: 'click',
            label: 'Clicou para aceitar o regulamento'
          }
        })
      }
    },
    alternarNotificacoes() {
      this.notificacoes = !this.notificacoes
    },
    alternarRespostaPergunta(resposta) {
      this.respostaPergunta = resposta
    },
    alternarAvisoPrivacidade() {
      this.avisoPrivacidade = !this.avisoPrivacidade
    },
    validadeTermos() {
      if (this.lgpdPoliticaTermos && this.lgpdRegulamento && (this.config.HABILITAR_TERMOS_AVISO_PRIVACIDADE ? this.lgpdAvisoPrivacidade : true)) {
        if (
          this.pergunta &&
          this.respostaPergunta != this.respostas[0].valorOpcao
        ) {
          return this.informarErrosLGPD(
            'Ops...',
            'Você escolheu a resposta incorreta, tente novamente'
          )
        }
        // fechando essa tela
        this.$emit('alternar-exibicao', {
          event: 'alternar-tela-lgpd'
        })
        // abrindo a tela de cadastro
        this.$emit('alternar-exibicao', {
          event: 'alternar-tela-cadastro'
        })
      } else {
        let texto = ''
        if (!this.lgpdRegulamento) {
          texto += 'Regulamento'
        }
        if (!this.lgpdPoliticaTermos) {
          texto += `${texto ? ', ': ''} Política de privacidade${ this.config.HABILITAR_TERMOS_ACEITE_LGPDS ? ' e Termos de aceite' : ''}`
        }
        if (this.config.HABILITAR_TERMOS_AVISO_PRIVACIDADE && !this.lgpdAvisoPrivacidade) {
          texto += `${texto ? ', ': ''} Aviso de privacidade`
        }
        
        this.informarErrosLGPD(
          'Ops...',
          'Você não concordou em: ' + texto
        )
      }
    },
    informarErrosLGPD(titulo, mensagem) {
      this.$emit('alternar-modal', {
        event: 'informar-erros-lgpd',
        data: {
          titulo,
          mensagem
        }
      })
    }
  },

  props: {
    isProd: {
      type: Boolean,
      required: true
    }
  }
}
</script>
<style scoped>
.container-lgpd {
  height: 50vh !important;
}
.informacao-termos button {
  font-size: 16px !important;
}
.espacamento-maior {
  padding-bottom: 8rem !important;
}
</style>
