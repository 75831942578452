const moment_pt_BR_SP = require('moment-timezone')

export default class DataHelper {
  /**
   * Retorna a data atual no formato YYYY-MM-DD
   */
  static dataAtual() {
    return moment_pt_BR_SP
      .tz('America/Sao_Paulo')
      .locale('pt-BR')
      .format('YYYY-MM-DD')
  }

  /**
   * Retorna a hora atual no formato HH:mm:ss
   */
  static horaAtual() {
    return moment_pt_BR_SP
      .tz('America/Sao_Paulo')
      .locale('pt-BR')
      .format('HH:mm:ss')
  }

  /**
   * Retorna a data e hora atual no formato YYYY-MM-DD HH:mm:ss
   */
  static dataHoraAtual() {
    return moment_pt_BR_SP
      .tz('America/Sao_Paulo')
      .locale('pt-BR')
      .format('YYYY-MM-DD HH:mm:ss')
  }

  /**
   * Retorna a data e hora no formato DD/MM/YYYY HH:mm:ss
   */
  static dataHoraFormatada(dataHora) {
    return moment_pt_BR_SP(dataHora)
      .tz('America/Sao_Paulo')
      .locale('pt-BR')
      .format('DD/MM/YYYY HH:mm:ss')
  }

  /**
   * Retorna a data e hora anterior de acordo com o intervalo no formato DD/MM/YYYY HH:mm:ss iniciando em 00:00:00 do próximo dia
   */
  static obterDataPorPeriodo(intervalo) {
    const momento = moment_pt_BR_SP
      .tz('America/Sao_Paulo')
      .locale('pt-BR')
      .subtract(intervalo - 1, 'days')
      .format('YYYY-MM-DD')
    return `${momento} 00:00:00`;
  }

  /**
   * Retorna a data resultante do acréscimo entre a data informada e a quantidade de dias.
   * @param {string} data - data ou dataHora na qual a quantidade de dias sera acrescentada.
   * @param {number} numeroDias - numero de dias a somar na data informada.
   */
  static incrementaDiasData(data, numeroDias) {
    let limite = moment_pt_BR_SP(data).add(numeroDias, 'days').format('YYYY-MM-DD');
    return limite;
  }
}
