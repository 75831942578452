const CryptoJS = require('crypto-js');

class CryptoAWS {
  constructor() {}

  static encrypto(text) {
    return CryptoJS.AES.encrypt(text, 'secretkeynscpwa').toString();
  }

  static decrypt(ciphertext) {
    let bytes = '';
    try {
      bytes = CryptoJS.AES.decrypt(ciphertext, 'secretkeynscpwa').toString(CryptoJS.enc.Utf8);
    } catch (err) {
      // console.error(err);
      return bytes;
    }
    return bytes;
  }

  static encryptObject(textObject) {
    return CryptoJS.AES.encrypt(JSON.stringify(textObject), 'secretkeynsc').toString();
  }

  static decryptObject(textObject) {
    const bytes = CryptoJS.AES.decrypt(textObject, 'secretkeynsc');
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }
}

export default CryptoAWS;
