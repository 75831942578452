<template>
  <div class="fundo">
    <div class="p-2 text-center d-flex flex-column text-light">
      <div>
        <img class="logotipo" src="@/assets/images/polgo-logotipo.svg" alt="Polgo logo" />
      </div>
      Não foi possível encontrar a URL solicitada
      <div>{{ msgErro }}</div>
      <button class="btn-recarregar-pagina" @click="recarregarPagina">Tentar novamente</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'erro-configuracao',
  props: ['msgErro'],
  created() {
    try {
      caches.keys().then(function(names) {
        for (let name of names) caches.delete(name);
      });
      localStorage.clear();
    } catch (e) {
      console.log('erro ao deletar cache:', e);
    }
  },
  methods: {
    recarregarPagina() {
      window.location.reload();
    },
  },
};
</script>
<style lang="scss" scoped>
.fundo {
  background-color: blueviolet;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.logotipo {
  // max-height: 100%;
  width: 500px;
  max-width: 100%;
}

.btn-recarregar-pagina {
  background: white;
  color: blueviolet;
  border: none;
  padding: 10px;
  margin: 10px;
  border-radius: 5px;
}
// }
</style>
