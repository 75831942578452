<template>
  <div
    class="base-pill-mini-card"
  >
    <!-- Pill -->
    <div class="pill-mini-card-header" @click="handleClick">
        <div class="left-middle-pill pill-size cursor-pointer" :class="[pillColors[0]]" :style=" config.COR_DESTAQUE_CIRCUNFERENCIA_CARDS_MODAIS ? `background-color: ${config.COR_DESTAQUE_CIRCUNFERENCIA_CARDS_MODAIS} !important;` : ''" v-if="leftPillIcon" v-tooltip.left="toolTipMessage">
            <i :class="leftPillIcon" :style=" config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_CARDS_MODAIS ? `color: ${config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_CARDS_MODAIS} !important;` : ''"></i>
        </div>
        <div class="w-100"></div>
        <div class="right-middle-pill pill-size cursor-pointer" :class="[pillColors[1]]" :style=" config.COR_DESTAQUE_CIRCUNFERENCIA_CARDS_MODAIS ? `background-color: ${config.COR_DESTAQUE_CIRCUNFERENCIA_CARDS_MODAIS} !important;` : ''" v-if="rightPillIcon" v-tooltip.left="toolTipMessage">
            <i :class="rightPillIcon" :style=" config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_CARDS_MODAIS ? `color: ${config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_CARDS_MODAIS} !important;` : ''"></i>
        </div>
    </div>

    <!-- Body -->
    <slot name="body-pill-mini-card">
        <div class="w-100"></div>
    </slot>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'BasePillMiniCard',
  props: {
    leftPillIcon: {
        type: String,
        required: false,
        default: '',
        description: 'ícone a ser exibido na pill do lado esquerdo'
    },
    rightPillIcon: {
        type: String,
        required: false,
        default: '',
        description: 'ícone a ser exibido na pill do lado direito'
    },
    pillColors: {
        type: Array,
        required: false,
        default: ['pill-body-primary', 'pill-body-primary'],
        description: 'Proprieadde que define a cor das pílulas, da esquerda para a direita'
    },
    toolTipMessage: {
        type: String,
        required: false,
        default: '',
        description: 'Mensagem a ser exibida quando para no tooltip'
    }
  },
  computed: {
    ...mapGetters({
      config: 'config/getConfig',
      currentCampaign: 'campanha/getCampaign'
    })
  },
  methods: {
    handleClick(event) {
      this.$emit('click', event)
    },
  }
}
</script>
<style lang="scss" src="./style.scss"></style>
