const campaignKey = 'QRSorteios@activeCampaign';

class Campaign {
  static saveCampaign(campaign) {
    let currentCampaign = campaign;
    localStorage.setItem(campaignKey, currentCampaign);
  }

  static getCampaign() {
    if (localStorage.getItem(campaignKey)) {
      return JSON.parse(localStorage.getItem(campaignKey));
    } else {
      return null;
    }
  }

  static removeCampaign() {
    if (localStorage.getItem(campaignKey)) {
      localStorage.removeItem(campaignKey);
      return true;
    } else {
      return false;
    }
  }
}

export default Campaign;
