import globalComponents from './globalComponents'
import globalDirectives from './globalDirectives'
import infinityScroll from 'vue-infinite-scroll'
import Croppa from 'vue-croppa'
import VueQrcodeReader from 'vue-qrcode-reader'
import VueSweetalert2 from 'vue-sweetalert2'
import BootstrapVue from 'bootstrap-vue'
import Vuelidate from 'vuelidate'
import VTooltip from 'v-tooltip'
import VueCarousel from 'vue-carousel'
import { HttpClient } from '../lib/HttpClient'
import { Text } from '../lib/Helper'
import { storeConfig } from '../lib/Storage'
import 'sweetalert2/dist/sweetalert2.min.css'
import 'vue-croppa/dist/vue-croppa.css'
import VueParticles from 'vue-particles'
import { CryptoAWS } from '../lib/Cryptography';
import html2canvas from 'html2canvas';
import VueMeta from 'vue-meta';

export default {
  install(Vue) {
    Vue.use(BootstrapVue)
    Vue.use(Croppa)
    Vue.use(VueSweetalert2)
    Vue.use(globalComponents)
    Vue.use(globalDirectives)
    Vue.use(Vuelidate)
    Vue.use(VTooltip, { defaultTrigger: 'hover focus click' })
    Vue.use(infinityScroll)
    Vue.use(VueCarousel)
    Vue.use(VueQrcodeReader)
    Vue.use(VueParticles)
    Vue.use(VueMeta)
    Vue.prototype.$httpClient = HttpClient
    Vue.prototype.$text = Text
    Vue.prototype.$storeConfig = storeConfig
    Vue.prototype.$crypto = CryptoAWS
    Vue.prototype.$html2canvas = html2canvas
  }
}
