<template>
  <div class="notification-settings-modal">
    <base-modal
      :show.sync="show"
      modal-classes="modal-dialog-bottom qr-modal-bottom-notificacao"
      modalContentClasses="animate-bottom"
      modalContentOutClasses="animate-out-bottom"
      body-classes=""
    >
      <template slot="header">Notificações</template>
      <template>
        <ul class="notification-list">
          <div v-if="carregando">
            <div class="animationLoading">
              <div id="three" class="three"></div>
              <div id="five"></div>
              <div id="six"></div>
            </div>
            <div class="animationLoading">
              <div id="three"></div>
              <div id="five"></div>
              <div id="six"></div>
            </div>
            <div class="animationLoading">
              <div id="three"></div>
              <div id="five"></div>
              <div id="six"></div>
            </div>
            <div class="animationLoading">
              <div id="three"></div>
              <div id="five"></div>
              <div id="six"></div>
            </div>
            <div class="animationLoading">
              <div id="three"></div>
              <div id="five"></div>
              <div id="six"></div>
            </div>
            <div class="animationLoading">
              <div id="three"></div>
              <div id="five"></div>
              <div id="six"></div>
            </div>
            <div class="animationLoading">
              <div id="three"></div>
              <div id="five"></div>
              <div id="six"></div>
            </div>
          </div>

          <div
            v-else-if="listaNotificacoes.length"
            :key="notificacao.id"
            v-for="notificacao in listaNotificacoes"
            class="notification-fundo"
          >
            <notification-item
              :date="formatarDataHora(notificacao.dataHoraEnvio || notificacao.dataHora)"
              :title="notificacao.titulo"
              :text="notificacao.mensagem || ''"
              :imgUrl="notificacao.urlImagem || ''"
              :type="notificacao.tipo || ''"
            />
          </div>

          <p v-else class="sem-notificacao">Você ainda não tem nenhuma notificação</p>
        </ul>
      </template>
    </base-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { Data } from '../../lib/Helper';

export default {
  name: 'notificacoes',

  props: {
    campanha: {
      type: Object,
      description: 'Objeto com informações da campanha promocional',
      required: true,
    },
    showModal: {
      type: Boolean,
      description: 'Modal display boolean',
      default: false,
    },
  },

  data: () => ({
    show: false,
    usuario: null,
  }),

  beforeMount() {},

  mounted() {
    const login = JSON.parse(localStorage.getItem('login'));
    this.usuario = login.usuario;
  },

  computed: {
    ...mapGetters({
      listaNotificacoes: 'notificacoes/getNotificacoes',
      carregando: 'notificacoes/getLoader',
    }),
  },

  methods: {
    ...mapActions({
      fetchNotificacoes: 'notificacoes/fetchNotificacoes',
    }),

    formatarDataHora(valor) {
      return valor ? Data.dataHoraFormatada(valor) : '';
    },

    buscarNotificacoes() {
      this.fetchNotificacoes({
        usuario: this.usuario,
        campanha: {
          ano: this.campanha.ano,
          identificacao: this.campanha.identificacao,
        },
      });
    },

    fecharNavBar() {
      this.show = false;
    },
  },

  watch: {
    show(val) {
      if (!val)
        this.$emit('emmitModalEvent', {
          event: 'closeNotificationSettingsModal',
        });
    },
    showModal(val) {
      if (val) {
        this.show = true;
        this.buscarNotificacoes();
      }
    },
  },
};
</script>
<style>
.qr-modal-bottom-notificacao {
  position: fixed;
  top: 80px !important;
  right: 0;
  left: 0;
  bottom: 0;
  margin: 0;
  width: 100% !important;
  max-width: 1200px !important;
}

.qr-modal-bottom-notificacao .modal-content {
  border-radius: 20px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  margin-bottom: 0px;
  padding-bottom: 0px;
  overflow: hidden;
}

.notification-list {
  overflow: auto;
  height: 80.7vh;
  max-height: 80.7vh;
  min-height: 80.7vh;
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 70px !important;
}
.modal-open .modal {
  overflow: hidden !important;
}
.modal-header {
  font-weight: bold;
  border-bottom: none !important;
  margin-bottom: -40px !important;
  padding-bottom: -40px !important;
  z-index: 99999;
}

.sem-notificacao {
  font-size: 2rem;
  color: #cccccc;
  width: 100% !important;
  margin: auto auto;
  text-align: center;
  text-justify: center;
}

.animationLoading {
  height: 100px !important;
  padding-bottom: 0px !important;
  margin-bottom: 0px !important;
  border-bottom: 1px solid #cccccc;
}

#three {
  left: 0px !important;
  top: 27px !important;
  width: 15% !important;
}

li.notification-item .texts {
  margin-top: 20px;
}

@media (max-width: 600px) {
  .qr-modal-bottom-notificacao {
    margin: 0px !important;
  }
}

@media (min-width: 1209px) {
  li.notification-item .c-text {
    font-size: 1rem !important;
  }

  li.notification-item .c-date {
    font-size: 1.1rem !important;
  }

  li.notification-item .c-title {
    font-size: 1.2rem !important;
  }
}

@media (max-width: 1208px) {
  li.notification-item .c-text {
    font-size: 1rem !important;
  }

  li.notification-item .c-date {
    font-size: 1rem !important;
  }

  li.notification-item .c-title {
    font-size: 1.1rem !important;
  }
  .sem-notificacao {
    font-size: 1.3rem;
  }
}
</style>
