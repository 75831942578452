// Initial state
const initialState = () => ({
	data: []
})

// State object
const state = initialState()

// Getters
const getters = {
	getAceites(state) {
		return state.data
	},
}

// Actions
const actions = {
	clearState({ commit }) { commit('RESET') },
	salvarAceites({ commit }, value ) {
		commit('setAceites', value)
	},
}

// Mutations
const mutations = {
	RESET(state) {
		const newState = initialState();
		Object.keys(newState).forEach(key => {
			state[key] = newState[key]
		})
	},
	setAceites(state, data) {
		state.data = data
	},
}

// Module exports
export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}