import axios from 'axios'

const initialState = () => ({
  isIntegracaoComper: null,
  isComper: null,
  loading: false
})

const state = initialState()

const getters = {
  getIsIntegracaoComper: state => state.isIntegracaoComper,

  getIsComper: state => state.isComper,

  getLoadingComper: state => state.loading
}

const actions = {
  setIsIntegracaoComperAction({ commit }, { campaign, profile }) {
    const isIntegracaoComper =
      campaign.identificacao == 'COMPER' && profile.endereco.estado == 'SC'

    const isComper = campaign.identificacao == 'COMPER'

    commit('setIsIntegracaoComperMutation', isIntegracaoComper)
    commit('setIsComper', isComper)
  },

  escanearCupomEcommerce(
    { commit },
    { baseUrl, nroDoc, dataCompra, cpfConsumidor }
  ) {
    return new Promise(async resolve => {
      const api = axios.create({
        baseURL: baseUrl
      })

      const url = `integrarDocumentoFiscal?ecommerce=S&nroDoc=${nroDoc}&dataCompra=${dataCompra}&cpfConsumidor=${cpfConsumidor}`

      commit('setLoadingState', true)

      await api
        .post(url)
        .then(response => {
          console.log(response)

          commit('setLoadingState', false)

          if (response.data.statusCode == 200) {
            resolve({
              status: 200,
              mensagem:
                'Cupom escaneado com sucesso, vamos atualizar o seu número de cupons'
            })
          }
        })
        .catch(err => {
          const response = err.response

          console.log(response)

          commit('setLoadingState', false)

          if (response.data.statusCode == 500) {
            resolve({
              status: 500,
              mensagem:
                'Parece que tivemos um problema no nosso servidor, tente novamente em alguns minutos'
            })
          } else {
            resolve({
              status: response.data.statusCode,
              mensagem: response.data.mensagem
            })
          }
        })
    })
  },

  escanearCupomLojaFisica(
    { commit },
    { baseUrl, nroDoc, dataCompra, cpfConsumidor, caixa, loja }
  ) {
    return new Promise(async resolve => {
      const api = axios.create({
        baseURL: baseUrl
      })

      const url = `integrarDocumentoFiscal?ecommerce=N&nroDoc=${nroDoc}&dataCompra=${dataCompra}&cpfConsumidor=${cpfConsumidor}&caixa=${caixa}&loja=${loja}`

      commit('setLoadingState', true)

      await api
        .post(url)
        .then(response => {
          console.log(response)

          commit('setLoadingState', false)

          if (response.data.statusCode == 200) {
            resolve({
              status: 200,
              mensagem:
                'Cupom escaneado com sucesso, vamos atualizar o seu número de cupons'
            })
          }
        })
        .catch(err => {
          const response = err.response

          console.log(response)

          commit('setLoadingState', false)

          if (response.data.statusCode == 500) {
            resolve({
              status: 500,
              mensagem:
                'Parece que tivemos um problema no nosso servidor, tente novamente em alguns minutos'
            })
          } else {
            resolve({
              status: response.data.statusCode,
              mensagem: response.data.mensagem
            })
          }
        })
    })
  }
}

const mutations = {
  setIsIntegracaoComperMutation(state, data) {
    state.isIntegracaoComper = data
  },

  setIsComper(state, data) {
    state.isComper = data
  },

  setLoadingState(state, data) {
    state.loading = data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
