<template>
  <div class="w-100 d-flex flex-column align-items-center">
    <!-- Título -->
    <div
      class="
        w-75
        d-flex
        flex-row
        align-items-center
        justify-content-center
        mb-3
      "
    >
      <p class="f-11 mt-4 text-center" style="color: var(--text-color-highlight)">
        <strong>Aceites</strong>
      </p>
    </div>
    <div class="w-80 d-flex flex-column align-items-center mb-4">
      <p class="f-8 text-center" style="color: var(--text-color-highlight)">
        <strong>Parece que você ainda não tem cadastro.</strong>
        <br />
        Para continuar você precisa revisar os seguintes termos:
      </p>
    </div>

    <!-- Aceites -->
    <div
      class="w-90 d-flex align-items-center justify-content-center mb-3"
      v-for="(aceite, index) in aceites"
      :key="index"
    >
      <base-pill-check-box
        id="aceite-link"
        :name="aceite.nomeTermo"
        class="w-100"
        :pillSize="'pill-large'"
        :selectedValue="aceite.aceito"
        @click="alternarAceites(index)"
        :campoSwitch="true"
        v-tooltip.top="aceite.texto"
      >
        <template v-slot:pill-check-box-title class="p-4">
          <label class="px-2 mb-0 f-065 text-black" :for="aceite.nomeTermo">
            <span v-html="aceite.texto"></span>
            <span class="red" v-if="aceite.obrigatorio"> *</span>
          </label>
        </template>
      </base-pill-check-box>
    </div>

    <!-- Pergunta -->
    <div
      class="
        w-75
        d-flex
        flex-column
        align-items-center
        justify-content-center
        mb-3
      "
      v-if="pergunta"
    >
      <p class="w-100 f-09 mt-4 mb-4 text-center" style="color: var(--text-color-highlight)">
        <strong>{{ pergunta }}</strong>
      </p>
      <div class="w-100 d-flex align-items-center justify-content-between mb-3">
        <div
          class="
            w-45
            d-flex
            flex-column
            align-items-center
            justify-content-center
            mb-3
          "
          v-for="(resposta, index) in respostas"
          :key="index"
        >
          <base-pill-check-box
            :id="resposta.valorOpcao"
            name="grupo-inputs-respostas-lgpd"
            type="radio"
            class="w-100"
            :pillSize="'pill-average'"
            :campoSwitch="true"
            @click="alternarRespostaPergunta(resposta.valorOpcao)"
            :selectedValue="respostaPergunta"
          >
            <template v-slot:pill-check-box-title>
              <label class="pl-5 pr-3 mb-0 f-065 text-black" :for="resposta.valorOpcao">
                {{ resposta.textoOpcao }}
              </label>
            </template>
          </base-pill-check-box>
        </div>
      </div>
    </div>

    <!-- Informações -->
    <div class="w-75 d-flex align-items-center justify-content-center pb-3 pt-3">
      <span class="f-065" style="color: var(--text-color-highlight)"
        ><span class="red">*</span> Campos com preenchimento obrigatório</span
      >
    </div>

    <!-- Ações -->
    <div class="w-75 d-flex align-items-center justify-content-between" :class="pergunta ? 'pb-4' : 'pb-5'">
      <base-pill-button
        id="lgpd-voltar"
        :type="'light'"
        :left-middle-pill="'fas fa-chevron-circle-left'"
        :pillColors="['pill-dark']"
        :pillIconColors="['icon-light']"
        :leftMiddlePillRootColor="config.COR_DESTAQUE_CIRCUNFERENCIA_DOS_BOTOES"
        :leftMiddlePillIconRootColor="config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_DOS_BOTOES"
        :pillSize="['pill-average']"
        @click="voltarTela()"
      >
        <template v-slot:pill-button-title>
          <span class="w-90 pl-2 pr-3 text-center f-07 text-black">Voltar</span>
        </template>
      </base-pill-button>
      <base-pill-button
        id="lgpd-voltar"
        v-if="!this.block"
        :type="'light'"
        :right-middle-pill="'fas fa-chevron-circle-right'"
        :pillColors="['pill-light']"
        :pillIconColors="['icon-dark']"
        :rightMiddlePillRootColor="config.COR_DESTAQUE_CIRCUNFERENCIA_DOS_BOTOES"
        :rightMiddlePillIconRootColor="config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_DOS_BOTOES"
        :pillSize="['pill-average']"
        @click="validadeTermos"
      >
        <template v-slot:pill-button-title>
          <span class="w-90 pl-3 pr-2 text-center f-07 text-black">Continuar</span>
        </template>
      </base-pill-button>

      <base-pill-button
        id="lgpd-voltar"
        v-else
        :type="'light'"
        :right-middle-pill="'fas fa-chevron-circle-right'"
        :pillColors="['pill-light']"
        :pillIconColors="['icon-dark']"
        :rightMiddlePillRootColor="config.COR_DESTAQUE_CIRCUNFERENCIA_DOS_BOTOES"
        :rightMiddlePillIconRootColor="config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_DOS_BOTOES"
        :pillSize="['pill-average']"
        @click="informarCampanhaNaoDisponivel()"
      >
        <template v-slot:pill-button-title>
          <span class="w-90 pl-3 pr-2 text-center f-07 text-black">Continuar</span>
        </template>
      </base-pill-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { Data } from '@/lib/Helper';

export default {
  name: 'TheAceites',
  created() {
    // Escondendo a logo
    let logoQR, logoCampanha, menuInferior;
    let intervalo = setInterval(() => {
      logoQR = document.querySelector('.qr-logo');
      logoCampanha = document.querySelector('.campaing-logo');
      menuInferior = document.querySelector('.fixed-bottom');
      if (menuInferior && logoQR && logoCampanha) {
        logoCampanha.style.display = 'none';
        logoQR.style.display = 'none';
        menuInferior.style.paddingBottom = '1rem';
      }
      if (menuInferior && logoQR && logoCampanha) {
        clearInterval(intervalo);
      }
    }, 100);
  },

  beforeMount() {
    const configPergunta = this.config.PERGUNTA_PRE_CADASTRO;

    if (configPergunta.length >= 1) {
      this.pergunta = configPergunta[0].textoOpcao;

      for (let i = 1; i <= configPergunta.length - 1; i++) {
        this.respostas.push(configPergunta[i]);
      }
    }
  },

  mounted() {
    this.carregaCampanhas();
    this.carregaAceites();
  },

  computed: {
    ...mapGetters({
      config: 'config/getConfig',
      aceites: 'aceites/getAceites',
    }),
  },

  data: () => ({
    pergunta: '',
    respostas: [],
    respostaPergunta: '',
    block: true,
  }),

  methods: {
    ...mapActions({
      salvarAceites: 'aceites/salvarAceites',
    }),

    carregaCampanhas: async function() {
      const wsUrl = this.isProd ? this.config.VUE_APP_PROD_WS_URL : this.config.VUE_APP_DEV_WS_URL;
      const currentDate = Data.dataHoraAtual();
      const url = `${wsUrl}/campanha/v1/campanhas/`;
      let campanhaRecuperada = null;
      await fetch(url)
        .then(response => response.json())
        .then(data => {
          data.retorno
            .filter(camp => {
              let initial = `${camp.dataInicial} 00:00:00`;
              let final = `${camp.dataFinalCadastros} 23:59:00`;
              return camp.ativa && currentDate >= initial && currentDate <= final;
            })
            .forEach(camp => {
              campanhaRecuperada = camp;
            });
          campanhaRecuperada ? (this.block = false) : (this.block = true);
        })
        .catch(error => {
          console.error('Error:', error);
          this.block = true;
          this.informarCampanhaNaoEncontrada();
        });
    },
    carregaAceites: async function() {
      const wsUrl = this.isProd ? this.config.VUE_APP_PROD_WS_URL : this.config.VUE_APP_DEV_WS_URL;
      const url = `${wsUrl}/consumidor/v1/aceites/`;
      await fetch(url)
        .then(response => response.json())
        .then(data => {
          this.salvarAceites(
            data.retorno.map(aceite => {
              return { ...aceite, aceito: false };
            })
          );

          this.$emit('emmitEvent', {
            event: 'gtm',
            data: {
              event: 'alternouAceites',
              category: 'CadastroUsuario',
              action: 'click',
              label: `Novos aceites carregados`,
            },
          });
        })
        .catch(error => {
          console.error('Error:', error);
          this.block = true;
        });
    },
    informarCampanhaNaoEncontrada() {
      this.$emit('alternar-modal', {
        event: 'informar-campanha-nao-encontrada',
      });
    },
    informarCampanhaNaoDisponivel() {
      this.$emit('alternar-modal', {
        event: 'informar-campanha-nao-disponivel-nao-ativa',
      });
    },

    navigateTo(name) {
      this.$router.push({ name });
    },

    navigateBack() {
      this.$router.go(-1);
    },

    voltarTela() {
      this.$emit('alternar-exibicao', {
        event: 'alternar-tela-aceites',
      });
    },

    handleTransition(evt) {
      this.$emit('emmitEvent', {
        event: 'cardTransition',
        data: {
          page: evt.data.page,
          origin: evt.data.origin,
        },
      });
    },

    alternarRespostaPergunta(resposta) {
      this.respostaPergunta = resposta;
    },
    alternarAceites(index) {
      this.aceites[index].aceito = !this.aceites[index].aceito;
      this.salvarAceites(this.aceites);
      this.$emit('emmitEvent', {
        event: 'gtm',
        data: {
          event: 'alternouAceites',
          category: 'CadastroUsuario',
          action: 'click',
          label: `Alternou o aceite ${this.aceites[index].nomeTermo} para ${
            this.aceites[index].aceito ? '' : 'não '
          }aceito`,
        },
      });
    },
    validadeTermos() {
      for (let i = 0; i < this.aceites.length; i++) {
        if (this.aceites[i].obrigatorio && !this.aceites[i].aceito) {
          return this.informarErroAceite('Ops...', 'O aceite de ' + this.aceites[i].nomeTermo + ' é obrigatório');
        }
      }

      if (this.pergunta && this.respostaPergunta != this.respostas[0].valorOpcao) {
        return this.informarErroAceite('Ops...', 'Você escolheu a resposta incorreta, tente novamente');
      }

      this.voltarTela();
      // abrindo a tela de cadastro
      this.$emit('alternar-exibicao', {
        event: 'alternar-tela-cadastro',
      });
    },
    informarErroAceite(titulo, mensagem) {
      this.$emit('alternar-modal', {
        event: 'informar-erros-lgpd',
        data: {
          titulo,
          mensagem,
        },
      });
    },
  },

  props: {
    isProd: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
<style scoped>
.container-lgpd {
  height: 50vh !important;
}
.informacao-termos button {
  font-size: 16px !important;
}
.espacamento-maior {
  padding-bottom: 8rem !important;
}
</style>
