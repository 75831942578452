<template>
  <base-pill-nav-bar :exibir.sync="aceitesPendentes" :exibirBackground="true">
    <template v-slot:header-pill-nav-bar>
      <p class="f-11 mt-4 mb-4" style="color: var(--text-color-highlight)">
        <strong>Você precisa revisar os seguintes termos</strong>
      </p>
    </template>

    <template v-slot:body-pill-nav-bar>
      <section class="w-100 d-flex flex-column align-items-center">
        <!-- Aceites -->
        <div
          class="w-80 d-flex align-items-center justify-content-center mb-3"
          v-for="(aceite, index) in aceitesFormulario"
          :key="index"
        >
          <base-pill-check-box
            id="aceite-link"
            :name="aceite.nomeTermo"
            class="w-100"
            :pillSize="'pill-large'"
            :selectedValue="aceite.aceito"
            @click="aceite.aceito = !aceite.aceito"
            :campoSwitch="true"
            v-tooltip.top="aceite.texto"
          >
            <template v-slot:pill-check-box-title>
              <label
                class="pl-2 pr-2 mb-0 f-065 text-black"
                :for="aceite.nomeTermo"
              >
                <span v-html="aceite.texto"></span>
                <span class="red" v-if="aceite.obrigatorio"> *</span>
              </label>
            </template>
          </base-pill-check-box>
        </div>

        <!-- Actions -->
        <base-pill-button
          :type="'light'"
          :class="'mt-3 mb-3'"
          :right-middle-pill="'fas fa-check-circle'"
          :pillColors="['pill-light']"
          :pillIconColors="['icon-dark']"
          :rightMiddlePillRootColor="
            config.COR_DESTAQUE_CIRCUNFERENCIA_DOS_BOTOES
          "
          :rightMiddlePillIconRootColor="
            config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_DOS_BOTOES
          "
          :pillSize="['pill-average']"
          :disabled="loading"
          @click="validarAceites"
        >
          <template v-slot:pill-button-title>
            <span class="w-90 px-2 pl-3 text-center text-black">Salvar</span>
          </template>
        </base-pill-button>
      </section>
    </template>

    <template v-slot:footer-pill-nav-bar>
      <img
        :src="config.INTRO_IMG_URL"
        class="w-25 mt-2 mb-2"
        alt="Logo Plataforma QR Sorteios"
      />
    </template>
  </base-pill-nav-bar>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'aceites',

  beforeMount() {
    this.httpClient = new this.$httpClient()
  },
  mounted() {
    if (
      this.aceites.data &&
      this.aceites.data.length &&
      this.obrigatoriosAceitosDoCadastro
    ) {
      console.log('alterando status')
      this.alterarAceites({
        data: { usuario: this.profile.telefone, aceites: this.aceites.data }
      })
    }
  },

  data: () => ({
    aceitesFormulario: []
  }),
  computed: {
    ...mapGetters({
      config: 'config/getConfig',
      profile: 'consumidor/getProfile',
      loading: 'consumidor/getLoadingStatus',
      aceites: 'aceites/getAceites'
    }),
    aceitesPendentes() {
      //verifica se ja vieram aceites do cadastro
      if (
        this.aceites.data &&
        this.aceites.data.length &&
        this.obrigatoriosAceitosDoCadastro.ok
      ) {
        return false
      }
      return (
        this.profile &&
        this.profile.aceites.filter((a) => a.aceito == null).length > 0 &&
        this.profile.cadastroInicial === false &&
        this.aceitesFormulario.length
      )
    },
    obrigatoriosAceitosDoCadastro() {
      for (let i = 0; i < this.aceites.length; i++) {
        if (this.aceites[i].obrigatorio && !this.aceites[i].aceito) {
          return { ok: false, nomeTermo: this.aceites[i].nomeTermo }
        }
      }
      return { ok: true }
    },
    obrigatoriosAceitos() {
      for (let i = 0; i < this.aceitesFormulario.length; i++) {
        if (
          this.aceitesFormulario[i].obrigatorio &&
          !this.aceitesFormulario[i].aceito
        ) {
          return { ok: false, nomeTermo: this.aceitesFormulario[i].nomeTermo }
        }
      }
      return { ok: true }
    }
  },
  methods: {
    ...mapActions({
      alterarAceites: 'consumidor/alterarAceites'
    }),

    validarAceites() {
      if (!this.obrigatoriosAceitos.ok) {
        return this.$emit('emmitInfoModal', {
          event: 'modal-informacao-generica',
          data: {
            icone: 'fas fa-exclamation-circle',
            titulo: 'Ops...',
            mensagem:
              'O aceite de ' +
              this.obrigatoriosAceitos.nomeTermo +
              ' é obrigatório',
            textoBoato: 'OK'
          }
        })
      } else {
        this.alterarAceites({
          data: {
            usuario: this.profile.telefone,
            aceites: this.aceitesFormulario
          }
        })
      }
    },

    clearForm() {
      this.name = ''
      this.birth = ''
      this.cpf = ''
      this.documentoNumero = ''
      this.rg = ''
      this.gender = null
      this.resposta = ''
      this.vendedor = ''
      this.email = ''
      this.street = ''
      this.number = ''
      ;(this.state = null), (this.neighborhood = '')
      this.city = ''
      this.complement = ''
      this.cep = ''
    },

    checkForm(e) {
      if (this.$v.$invalid) {
        this.emitirModalInformacao({
          icone: 'fas fa-exclamation-circle',
          titulo: 'Ops...',
          mensagem: 'Perfil atualizado com sucesso!',
          textoBoato: 'OK'
        })
        this.$v.$touch()
      } else {
        this.submitForm()
      }
    },

    redirecionarTelaInicial() {
      this.emitirEventosReload()
      this.$router.push({
        name:
          this.config.ESCANEAR_REDIRECIONAMENTO ||
          this.telasConfiguraveis[0].destino
      })
    }
  },

  watch: {
    profile() {
      this.aceitesFormulario = this.profile
        ? JSON.parse(JSON.stringify(this.profile.aceites))
        : []
    }
  }
}
</script>