<template>
	<div @click="$emit('click')" class="app-wrapper-item">
		<span class="title">
			{{ title }}
		</span>
		<span class="icon">
			<i class="fas fa-chevron-right"></i>
		</span>
	</div>
</template>

<script>
export default {
	name: 'app-wrapper-item',
	props: {
		title: {
			type: String,
			default: ''
		}
	}
}
</script>