class Authentication {
  constructor(token = null, usuario, senha, tipo = 'consumidor', comunicacao, tokenFacebook = null, errorHandler = (function (data) { })) {
    this.token = token;
    this.usuario = usuario;
    this.senha = senha;
    this.tipo = tipo;
    this.comunicacao = comunicacao;
    this.tokenFacebook = tokenFacebook;
    this.errorHandler = errorHandler;
    this.error;
    this.emailVerificado = null;
  }
  async start() {
    // Autenticar usuário para recuperar token.
    let resposta;
    if (!this.token) {
      // Autenticando com endpoint de logar normal
      resposta = await this.comunicacao.send('/login/v1/autenticacao', 'post', {
        usuario: this.usuario,
        senha: this.senha,
        tipo: this.tipo
      });

      // Setando token
      if (resposta.status == 200) {
        this.token = resposta.data.retorno.token || resposta.data.retorno;
      } else {
        this.error = resposta.status;
        this.errorHandler(resposta);
      }
    }
    // Recuperar informações do usuário.
    if (this.token && this.usuario) {
      this.comunicacao.token = this.token;

      // para os casos em que já tem um token salvo, apenas verificar se o usuário de fato existe
      // tratamento para erro em casos onde o token já estava salvo, no app de uma campanha anterior, por exemplo
      if (!resposta) {
        resposta = await this.comunicacao.send(`/login/v1/logins/${this.usuario}`, 'get');
      }

      if (resposta.status == 200) {
        this.emailVerificado = resposta.data.retorno.emailVerificado;
      } else {
        this.token = null;
        this.error = resposta.status;
        this.errorHandler(resposta);
      }
    } else {
      this.emailVerificado = false;
    }
    // Autenticação já existente recuperar dados do local storage.
    if (this.token) {
      this.comunicacao.token = this.token;
      localStorage.login = JSON.stringify({
        token: this.token,
        usuario: this.usuario,
        tipo: this.tipo,
        emailVerificado: this.emailVerificado
      });
    }
    // Retornando Autenticação.
    return this;
  }
  // Recupera objeto login do local storage do app.
  static signedIn() {
    let login = localStorage.getItem('login');
    if (login) {
      login = JSON.parse(login);
      if (login.token) {
        return login;
      }
    }
    return false;
  }
  // Recupera flag de verificação de e-mail do local storage do app.
  static verifiedEmail() {
    let login = localStorage.getItem('login');
    if (login) {
      login = JSON.parse(login);
      if (login.token && login.emailVerificado) {
        return login.emailVerificado;
      }
    }
    return false;
  }
  // Altera flag de verificação de E-mail.
  static changeVerifiedEmailStatus(val) {
    let login = localStorage.getItem('login');
    if (login) {
      login = JSON.parse(login);
      if (login) {
        login.emailVerificado = val;
        localStorage.login = JSON.stringify({
          token: login.token,
          usuario: login.usuario,
          tipo: login.tipo,
          emailVerificado: login.emailVerificado
        });
      }
    }
  }
}

export default Authentication;
