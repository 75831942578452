var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pill-product-group"},[_c('div',{staticClass:"container container-produto mt-2"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12 col-md-12 col-lg-12 col-xl-12"},[_c('div',{staticClass:"card",style:([_vm.styleCard])},[_c('div',{staticClass:"pill-product pt-1",class:[_vm.classesArray],staticStyle:{"background-color":"rgb(250, 250, 250)"},attrs:{"disabled":_vm.disabled}},[_vm._t("pill-product-title",null,{"toolTipMessage":""})],2),_c('div',{staticClass:"bg-image hover-overlay ripple ripple-surface ripple-surface-light",attrs:{"data-mdb-ripple-color":"light"},on:{"click":_vm.handleClick}},[_c('img',{staticClass:"img-fluid",staticStyle:{"cursor":"pointer","height":"16vh","min-width":"100%","margin":"auto","object-fit":"contain"},attrs:{"src":_vm.urlImageProduct || `${require('@/assets/images/img-insdisponivel.png')}`}})]),_c('hr',{staticClass:"my-0 mx-3"}),_c('div',{staticClass:"card-body user-select-none"},[_c('div',{staticClass:"d-flex justify-content-center px-2 py-1 mb-2 font-weight-bold"},[_c('div',{staticClass:"text-secondary text-center fonte-descricao-produto",staticStyle:{"min-height":"30px","max-height":"30px","font-size":"0.7rem"}},[_vm._v(" "+_vm._s(_vm.nomeProduto)+" ")])]),_c('div',{staticClass:"d-flex justify-content-center"},[_c('button',{staticClass:"btn px-5 py-1 mb-2 h5",class:{
                  'estilo-produto-invalido': _vm.valorProduct > _vm.saldoUsuario || _vm.estoqueZerado,
                  'estilo-produto-valido': _vm.valorProduct <= _vm.saldoUsuario,
                  'estilo-produto-pendente': _vm.valorProduct == _vm.saldoTotalUsuarioNaoDisponivel,
                  'btn-primary': !_vm.mostrarTextoMensagemBotao,
                },attrs:{"type":"button","disabled":_vm.produtoNoCarrinho || _vm.estoqueZerado},on:{"click":function($event){return _vm.handleClickAdicaDeProdutoNoCarrinho(_vm.valorProduct, _vm.saldoUsuario, _vm.saldoTotalUsuarioNaoDisponivel)},"mouseover":function($event){_vm.mostrarTextoMensagemBotao = true},"mouseleave":function($event){_vm.mostrarTextoMensagemBotao = false}}},[_vm._v(" Eu quero ")])]),_c('div',{staticClass:"texto-mensagem-botao",class:{
                'mostrar-elemento': _vm.mostrarTextoMensagemBotao,
                'estilo-produto-invalido-label': _vm.valorProduct > _vm.saldoUsuario || _vm.estoqueZerado,
                'estilo-produto-valido-label': _vm.valorProduct <= _vm.saldoUsuario,
              },staticStyle:{"font-size":"0.6rem"}},[(_vm.estoqueZerado)?_c('span',[_vm._v("Produto sem estoque")]):(_vm.valorProduct == _vm.saldoTotalUsuarioNaoDisponivel && this.config.EXIBIR_SALDO_COMO_PONTUACAO)?_c('span',{staticStyle:{"text-align":"center"}},[_vm._v("Ainda existem pontos a serem disponibilizados")]):(_vm.valorProduct == _vm.saldoTotalUsuarioNaoDisponivel && !this.config.EXIBIR_SALDO_COMO_PONTUACAO)?_c('span',{staticStyle:{"text-align":"center"}},[_vm._v("Ainda existe cashback a ser disponibilizado")]):(_vm.valorProduct > _vm.saldoUsuario && this.config.EXIBIR_SALDO_COMO_PONTUACAO)?_c('span',[_vm._v("Pontos Insuficientes")]):(_vm.valorProduct > _vm.saldoUsuario && !this.config.EXIBIR_SALDO_COMO_PONTUACAO)?_c('span',[_vm._v("Cashback Insuficiente")]):(_vm.produtoNoCarrinho)?_c('span',[_vm._v("Produto já está no carrinho")]):_vm._e()])])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }