<template>
  <div class="qr-card card" :class="arredondado ? 'qr-card-arredondado' : 'card-quadrado'">
    <div class="card-header" v-if="$slots.header">
      <slot name="header"></slot>
    </div>
    <div class="card-body" :style="styleCardBody" :class="cardBodyClasses">
      <slot></slot>
    </div>
    <div class="card-footer" :class="{ 'footer-transparent': transparente }" v-if="$slots.footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<style scoped lang="scss">
.card-footer.footer-transparent {
  border-radius: 0 !important;
  overflow: visible;
  background-color: transparent !important;
  position: fixed;
  bottom: 0;
  width: calc(100% - 30px);
}
</style>

<script>
export default {
  name: 'app-card',
  data() {
    return {
      styleCardBody: {
        backgroundColor: this.fundoBody ? this.fundoBody : '',
      },
    };
  },
  props: {
    transparente: {
      type: Boolean,
      required: false,
      default: false,
      description: 'Propriedade que define se o fundo do footer será transparente.',
    },
    arredondado: {
      type: Boolean,
      required: false,
      default: true,
      description: 'Propriedade que define se o componente terá um visual mais arredondado.',
    },
    fundoBody: {
      type: String,
      required: false,
      default: '',
      description: 'Propriedade que define se o componente terá um visual mais arredondado.',
    },
    cardBodyClasses: {
      type: String,
      required: false,
      default: '',
      description: 'Propriedade que define classes personalizadas para o card-body.',
    },
  },
};
</script>
