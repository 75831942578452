<template>
  <div class="grid-colunas-menu">
    <div
      v-for="(item, index) in listaItens"
      :key="index || tamanhoTela"
      :class="index == 2 ? 'grid-item' : ''"
      :style="index > 0 ? (tamanhoTela > 600 ? 'margin-left: 20px;' : index < 2 ? 'margin-left: 5px;' : '') : ''"
    >
      <div @click="selecionarItemMenu(item.nome)">
        <button
          class="pill-menu-button"
          :class="[classesArray, itemSelect === item.nome ? 'pill-button-light-select shadowSelect' : '']"
          :disabled="item.quantidade === 0"
          style="pointer-events: none;"
        >
          <div
            class="left-middle-pill"
            :class="[pillColors, tamanhoTela > 600 ? 'pill-average' : 'pill-small', pillBorderColors]"
            :style="leftMiddlePillRootColor ? `background-color: ${leftMiddlePillRootColor} !important;` : ''"
          >
            <i
              :class="[item.icone, pillIconColors]"
              :style="leftMiddlePillIconRootColor ? `color: ${leftMiddlePillIconRootColor} !important;` : ''"
            ></i>
          </div>
          <div class="fontSizes" :class="itemSelect === item.nome ? 'boldeSelect' : ''">
            {{ `${item.nome}(${item.quantidade})` }}
          </div>
          <!-- <div
          class="right-middle-pill"
          :class="[pillColors, pillSize]"
          :style="leftMiddlePillRootColor ? `background-color: ${leftMiddlePillRootColor} !important;` : ''"
        >
          <i
            :class="[pillIconColors]"
            :style="
              leftMiddlePillIconRootColor
                ? `color: ${leftMiddlePillIconRootColor} !important; font-size: 10px;`
                : 'font-size: 10px;'
            "
          >
            {{ item.quantidade }}
          </i>
        </div> -->
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BasePillMenuButton',

  props: {
    type: {
      type: String,
      required: false,
      default: 'primary',
    },

    pillListItens: {
      type: Array,
      require: true,
      default: [],
      description: 'lista de itens do menu de botões',
    },

    leftMiddlePill: {
      type: String,
      required: false,
      description: 'Pill at left button',
    },

    rightMiddlePill: {
      type: String,
      required: false,
      description: 'Pill at rigth button',
    },

    pillSelect: {
      type: String,
      require: true,
      description: 'botão selecionado',
    },

    pillColors: {
      type: Array,
      required: false,
      description: 'Pill classes',
    },

    pillBorderColors: {
      type: [String, Array],
      required: false,
      default: '',
      description: 'Propriedade que define a cor das bordas do botão (se declarada)',
    },

    pillIconColors: {
      type: Array,
      required: false,
      description:
        'Propriedade que define as cores dos ícones de cada pill, respectivamente, de acordo com as cores do elemento pai',
    },

    pillSize: {
      type: Array,
      required: false,
      description: 'Pill classes',
    },

    leftMiddlePillRootColor: {
      type: String,
      required: false,
      default: '',
      description: 'Propriedade que define alguma cor capaz de sobrescrever a cor declarada na pílula',
    },

    leftMiddlePillIconRootColor: {
      type: String,
      required: false,
      default: '',
      description: 'Propriedade que define alguma cor capaz de sobrescrever a cor declarada no ícone pílula',
    },

    rightMiddlePillRootColor: {
      type: String,
      required: false,
      default: '',
      description: 'Propriedade que define alguma cor capaz de sobrescrever a cor declarada na pílula',
    },

    rightMiddlePillIconRootColor: {
      type: String,
      required: false,
      default: '',
      description: 'Propriedade que define alguma cor capaz de sobrescrever a cor declarada no ícone pílula',
    },
  },

  data() {
    return {
      itemSelect: '',
      tamanhoTela: 500,
    };
  },

  mounted() {
    this.itemSelect = this.pillSelect;
  },

  computed: {
    buttonTypeClass() {
      return `pill-button-${this.type}`;
    },

    classesArray() {
      return [this.buttonTypeClass, { disabled: this.disabled }];
    },

    listaItens() {
      return this.pillListItens;
    },
  },

  methods: {
    selecionarItemMenu(tab) {
      if (tab !== this.itemSelect) {
        this.itemSelect = tab;
        this.$emit('selecionarItemMenu', tab);
      }
    },
  },

  watch: {
    'window.innerWidth'(val) {
      this.tamanhoTela = val;
    },
    pillSelect: val => {
      if (val !== this.itemSelect) {
        this.itemSelect = val;
      }
    },
  },
};
</script>
<style lang="scss" src="./style.scss"></style>
