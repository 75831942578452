<template>
  <div
    id="topNotifications"
    :class="{ 'notificacao-userbar-novo': this.config.USERBAR_NOVO }"
  >
    <span
      @click="openNotificationModal"
      class="notification-icon cursor-pointer"
    >
      <i class="fas fa-bell"></i>
    </span>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'app-notifications',
  computed: {
    ...mapGetters({
      config: 'config/getConfig'
    })
  },
  methods: {
    openNotificationModal() {
      this.$emit('emmitModalEvent', { event: 'openNotificationSettingsModal' })
    },
  }
}
</script>
