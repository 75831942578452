<template>
  <div id="options" v-if="!this.config.USERBAR_NOVO">
    <span @click="switchOpen" class="cursor-pointer" :style="`color: var(--color-text);`">
      <i class="fas fa-cog"></i>
    </span>
    <base-settings :class="{ open: openState }">
      <div class="d-flex justify-content-between mb-3">
        <p class="m-0 text-secondary small-option">
          <small>Configurações</small>
        </p>
        <p @click="closeMenu" class="m-0 text-gray-darker small-option">
          <small><i class="fas fa-times"></i></small>
        </p>
      </div>
      <ul>
        <!-- Regulamento -->
        <li v-if="config.CONCORDAR_REGULAMENTO" @click="navigateTo('regulamento')">
          <p class="m-0 small-option" style="color: var(--text-color-highlight)">
            <small>Regulamento</small>
          </p>
          <p class="m-0 small-option text-secondary">
            <small><i class="fas fa-file"></i></small>
          </p>
        </li>
        <!-- Informações do app -->
        <li v-if="config.OPCAO_INFORMACOES_APP_VISIVEL_CONFIG == true" @click="animatedNavigateTo('sobre')">
          <p class="m-0 small-option" style="color: var(--text-color-highlight)">
            <small>Informações do App</small>
          </p>
          <p class="m-0 small-option text-secondary">
            <small><i class="fas fa-info-circle"></i></small>
          </p>
        </li>
        <!-- Notificações -->
        <!-- <li v-if="config.MOSTRAR_NOTIFICACOES">
          <p class="m-0 small-option" style="color: var(--text-color-highlight)">
            <small>Receber Notificações</small>
          </p>
          <span class="d-flex flex-column justify-content-center align-items-center m-0 qr-switch">
            <input v-model="notification" type="checkbox" id="switch" />
            <label for="switch">Toggle</label>
          </span>
        </li> -->
        <!-- Informações do app -->
        <li v-if="config.MODULO_CODIGO_TEMPORARIO_ATIVO" @click="animatedNavigateTo('obterCodigoTemporario')">
          <p class="m-0 small-option" style="color: var(--text-color-highlight)">
            <small>Código temporário</small>
          </p>
          <p class="m-0 small-option text-secondary">
            <small><i class="fas fa-lock"></i></small>
          </p>
        </li>
        <!-- Logout -->
        <li @click="openLogoutModal" v-if="!config.OCULTAR_SAIR">
          <p class="m-0 small-option" style="color: var(--text-color-highlight)">
            <small>Sair</small>
          </p>
          <p class="m-0 small-option text-secondary">
            <small><i class="fas fa-sign-out-alt"></i></small>
          </p>
        </li>
      </ul>
    </base-settings>
  </div>
  <div id="options" class="userbar-novo-op" v-else>
    <span @click="switchOpen" class="cursor-pointer" :style="`color: var(--color-text);`">
      <i class="fas fa-cog"></i>
    </span>
    <base-settings :class="{ open: openState }">
      <div
        class="d-flex justify-content-between mb-3 userbar-novo"
        style="min-height: 65px; align-items: center; padding: 0 15px"
      >
        <h5 @click="closeMenu" class="m-0 text-color-text">
          <i class="fas fa-chevron-left"></i>
          <small style="margin-left: 10px">Voltar</small>
        </h5>
        <h5 class="m-0 text-color-text">Ajustes</h5>
      </div>
      <ul>
        <!-- Documentos fiscais pendentes -->
        <li @click="navigateTo('docFiscaisPendentes')">
          <h6 class="m-0">Documentos fiscais pendentes</h6>
        </li>
        <!-- dados usuário -->
        <li @click="openProfileModal">
          <h6 class="m-0">Meus dados</h6>
        </li>
        <!-- alterar senha -->
        <li @click="openChangePasswordModal">
          <h6 class="m-0">Alterar Senha</h6>
        </li>
        <!-- Regulamento -->
        <li @click="navigateTo('regulamento')">
          <h6 class="m-0">Regulamento</h6>
        </li>
        <!-- Informações do app -->
        <li @click="animatedNavigateTo('sobre')">
          <h6 class="m-0">Informações do App</h6>
        </li>
        <!-- Excluir conta - ainda não existe -->
        <!-- <li @click="navigateTo('')">
          <h6 class="m-0">
            Excluir minha conta
          </h6>
        </li> -->
        <!-- Logout -->
        <li @click="openLogoutModal">
          <h6 class="m-0">Sair</h6>
        </li>
      </ul>
    </base-settings>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'app-options',
  beforeMount() {
    this.notification = this.notificationStatus;
  },
  computed: {
    ...mapGetters({
      config: 'config/getConfig',
      openState: 'ui/getSettingsOpenedState',
      notificationStatus: 'ui/getNotificationStatus',
    }),
  },
  data: () => ({
    notification: false,
  }),
  methods: {
    ...mapActions({
      handleMenuSlide: 'ui/handleMenuSlide',
      switchState: 'ui/switchSettingsMenuState',
      switchNotifications: 'ui/switchNotificationStatus',
    }),
    animatedNavigateTo(route) {
      this.closeMenu();
      this.$router.push({ name: route });
      this.handleMenuSlide(route);
    },
    switchOpen() {
      this.switchState(!this.openState);
    },
    openProfileModal() {
      if (!this.config.USERBAR_NOVO) this.$emit('emmitModalEvent', { event: 'openProfileModal' });
      else this.$emit('openProfileModal', true);
    },
    openChangePasswordModal() {
      if (!this.config.USERBAR_NOVO) this.$emit('emmitModalEvent', { event: 'openChangePasswordModal' });
      else this.$emit('openChangePasswordModal', true);
    },
    closeMenu() {
      this.switchState(false);
    },
    openLogoutModal() {
      this.closeMenu();
      if (!this.config.USERBAR_NOVO) this.$emit('emmitModalEvent', { event: 'openLogoutModal' });
      else this.$emit('openLogoutModal', true);
    },
    openNotificationModal() {
      this.closeMenu();
      this.$emit('emmitModalEvent', { event: 'openNotificationSettingsModal' });
    },
    navigateTo(route) {
      this.closeMenu();
      this.$router.push({ name: route });
    },
  },
  watch: {
    notification(val) {
      this.switchNotifications(val);
    },
  },
};
</script>
