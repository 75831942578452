<template>
  <div class="w-100 d-flex flex-column align-items-center">
    <div id="selecionar-container" class="w-100 d-flex flex-column align-items-center" v-if="mostrarSelecao">
      <!-- Título -->
      <div class="w-75 d-flex align-items-center justify-content-center mb-2">
        <p class="f-11 mt-4 text-center" style="color: var(--text-color-highlight)">
          <strong>Esqueci minha senha</strong>
        </p>
      </div>
      <!-- Subtítulo -->
      <div class="w-75 d-flex align-items-center justify-content-center mb-3">
        <p class="f-07 mb-3 text-center" style="color: var(--text-color-highlight)">
          Por favor, selecione como gostaria de redefinir sua senha. Você pode selecionar duas opções, sendo elas:
          celular (SMS) e e-mail.
        </p>
      </div>
      <!-- Cards -->
      <div class="w-75 d-flex align-items-center justify-content-center">
        <!-- E-mail -->
        <base-pill-big-select-card
          :background="
            config.FORCAR_CORES_MENU
              ? config.TABELA_COR.find(cor => cor.nome == 'COR_BACKGROUND_FORCADO_BOTOES').cor
              : 'transparent'
          "
          :textColor="
            config.FORCAR_CORES_MENU
              ? config.TABELA_COR.find(cor => cor.nome == 'COR_TEXTO_FORCADO_BOTOES').cor
              : 'initial'
          "
          :mainIcon="'fas fa-envelope'"
          :titleText="'E-mail'"
          @click="alternarTelaEsqueciSenha('email')"
        ></base-pill-big-select-card>
        <!-- SMS -->
        <base-pill-big-select-card
          class="ml-3"
          :background="
            config.FORCAR_CORES_MENU
              ? config.TABELA_COR.find(cor => cor.nome == 'COR_BACKGROUND_FORCADO_BOTOES').cor
              : 'transparent'
          "
          :textColor="
            config.FORCAR_CORES_MENU
              ? config.TABELA_COR.find(cor => cor.nome == 'COR_TEXTO_FORCADO_BOTOES').cor
              : 'initial'
          "
          :mainIcon="'fas fa-mobile-alt'"
          :titleText="'SMS'"
          @click="alternarTelaEsqueciSenha('sms')"
        ></base-pill-big-select-card>
      </div>
      <!-- Ações -->
      <div
        class="
          w-75
          d-flex
          align-items-center
          justify-content-center
          mb-1
          pb-1
          pt-5
        "
      >
        <base-pill-button
          :type="'light'"
          :left-middle-pill="'fas fa-chevron-circle-left'"
          :pillColors="['pill-dark']"
          :pillIconColors="['icon-light']"
          :leftMiddlePillRootColor="config.COR_DESTAQUE_CIRCUNFERENCIA_DOS_BOTOES"
          :leftMiddlePillIconRootColor="config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_DOS_BOTOES"
          :pillSize="['pill-average']"
          @click="alternarTelaEsqueciSenha('voltar')"
        >
          <template v-slot:pill-button-title>
            <span class="w-90 pl-2 pr-3 text-center f-07 text-black">Voltar</span>
          </template>
        </base-pill-button>
      </div>
    </div>
    <div>
      <component
        :is="modalAtual"
        :isProd="isProd"
        v-on="$listeners"
        :usuario="usuario"
        @voltar="
          mostrarSelecao = true;
          modalAtual = '';
        "
      ></component>
    </div>
    <!-- <div id="email-container">
      <the-esqueci-senha :isProd="isProd" v-on="$listeners" :usuario="usuario" :is="modalAtual"/>
    </div>
    <div id="sms-container">
      <the-esqueci-senha-via-sms :usuario="usuario" :isProd="isProd" v-on="$listeners" :is="modalAtual"/>
    </div> -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import TheEsqueciSenha from '../TheEsqueciSenha';
import TheEsqueciSenhaViaSms from '../TheEsqueciSenhaViaSMS';

export default {
  name: 'TheSelecionarMeioEsqueciSenha',
  components: {
    TheEsqueciSenha,
    TheEsqueciSenhaViaSms,
  },
  computed: {
    ...mapGetters({
      config: 'config/getConfig',
    }),
  },
  data: () => ({
    mostrarSelecao: true,
    modalAtual: '',
  }),
  props: {
    isProd: {
      type: Boolean,
      required: true,
    },
    usuario: {
      type: String,
      required: false,
    },
  },
  methods: {
    ...mapActions({
      gerarTokenTemporario: 'autenticacao/esqueciMinhaSenha',
    }),

    alternarTelaEsqueciSenha(local) {
      // this.$emit('alternar-exibicao', {
      //   event: 'alternar-tela-selecionar-meio-esqueci-senha',
      // });

      this.mostrarSelecao = false;
      switch (local) {
        case 'voltar':
          this.voltarTela();
          break;
        case 'email':
          this.modalAtual = 'TheEsqueciSenha';
          // this.$emit('alternar-exibicao', {
          //   event: 'alternar-tela-esqueci-senha',
          // });
          break;
        case 'sms':
          this.modalAtual = 'TheEsqueciSenhaViaSms';
          // this.$emit('alternar-exibicao', {
          //   event: 'alternar-tela-esqueci-senha-via-sms',
          // });
          break;
        default:
          break;
      }
    },

    voltarTela() {
      this.$emit('alternar-exibicao', {
        event: 'alternar-tela-selecionar-meio-esqueci-senha',
      });
    },

    navigateTo(name) {
      this.$router.push({ name });
    },
  },
};
</script>
