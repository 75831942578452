<template>
  <div class="base-pill-modal-v2">
    <transition name="fade" appear>
      <div class="pill-modal-overlay" :style="estiloOverlay" v-if="openModal" @click="fecharModal()"></div>
    </transition>
    <transition :name="nomeTransicao" appear>
      <div class="pill-modal p-0 rounded-0 container w-100" :style="estiloGeral" v-if="openModal">
        <div class="pill-modal-header p-2" :style="estiloHeader">
          <button
            v-if="mostrarBotaoDeFechar"
            :style="estiloBotaoFechar"
            class="pill-modal-close"
            @click="fecharModal()"
          >
            <i class="fa fa-times" aria-hidden="true"></i>
          </button>

          <slot class="btn purple mr-2" style="font-size: 0.6rem" name="pill-modal-header-botao-secundario"> </slot>
        </div>
        <div class="pill-modal-body" :style="estiloBody">
          <slot name="pill-modal-body"></slot>
        </div>
        <div class="pill-modal-footer">
          <slot name="pill-modal-footer"></slot>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'BasePillModalV2',
  props: {
    openModal: {
      type: Boolean,
      required: true,
      default: false,
      description: 'Boolean que define o estado da modal',
    },

    title: {
      type: String,
      required: false,
      default: null,
      description: 'Quando passado algum valor, exibe o conteúdo ao lado da pílula esquerda',
    },

    mostrarBotaoDeFechar: {
      type: Boolean,
      required: true,
      default: true,
      description: 'Boolean que define se o bloco com o botão de fechar será apresentado ou não',
    },

    nomeTransicao: {
      type: String,
      required: false,
      default: 'slide',
      description: 'Propriedade que define se a animação de abrir e fechar ocorrerá verticalmente',
    },

    estiloOverlay: {
      type: String,
      required: false,
      default: '',
      description: 'Propriedade que define se o bloco pill-modal-overlay terá alguma estilização customizada',
    },

    estiloGeral: {
      type: String,
      required: false,
      default: '',
      description: 'Propriedade que define se o bloco pill-modal-body terá alguma estilização customizada',
    },

    estiloBody: {
      type: String,
      required: false,
      default: '',
      description: 'Propriedade que define se o bloco pill-modal terá alguma estilização customizada',
    },

    estiloHeader: {
      type: String,
      required: false,
      default: '',
      description: 'Propriedade que define se o bloco pill-modal-header terá alguma estilização customizada',
    },

    estiloBotaoFechar: {
      type: String,
      required: false,
      default: '',
      description: 'Propriedade que define se o picone de pill-modal-close terá alguma estilização customizada',
    },
  },
  data() {
    return {
      modal: {
        open: this.openModal,
      },
    };
  },
  computed: {
    ...mapGetters({
      config: 'config/getConfig',
      currentCampaign: 'campanha/getCampaign',
    }),
  },
  methods: {
    fecharModal() {
      this.$emit('fechar-modal', {
        dados: {
          modal: {
            fechar: true,
          },
        },
      });
    },
  },
};
</script>
<style lang="scss" src="./style.scss" scoped></style>
