const privateProps = new WeakMap()

class Redirect {
  constructor(routes) {
    privateProps.set(this, { routes })
  }

  get routes() {
    return privateProps.get(this).routes
  }

  static addRedirectPath(id, path) {
    localStorage.setItem(id, path)
  }

  static removeRedirectPath(id) {
    if (localStorage.getItem(id)) {
      localStorage.removeItem(id)
    }
  }

  checkRedirectStatus() {
    return this.routes.map(key => {
      if (localStorage.getItem(key))
        return { path: localStorage.getItem(key), redirect: true }
      return { path: null, redirect: false }
    })
  }
}

module.exports = Redirect
