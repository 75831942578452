export const BRASIL_STATES = [
  {
    value: "AC",
    desc: "Acre",
    idIBGE: 12
  },
  {
    value: "AL",
    desc: "Alagoas",
    idIBGE: 27
  },
  {
    value: "AM",
    desc: "Amazonas",
    idIBGE: 13
  },
  {
    value: "AP",
    desc: "Amapá",
    idIBGE: 16
  },
  {
    value: "BA",
    desc: "Bahia",
    idIBGE: 29
  },
  {
    value: "CE",
    desc: "Ceará",
    idIBGE: 23
  },
  {
    value: "DF",
    desc: "Distrito Federal",
    idIBGE: 53
  },
  {
    value: "ES",
    desc: "Espírito Santo",
    idIBGE: 32
  },
  {
    value: "GO",
    desc: "Goiás",
    idIBGE: 52
  },
  {
    value: "MA",
    desc: "Maranhão",
    idIBGE: 21
  },
  {
    value: "MG",
    desc: "Minas Gerais",
    idIBGE: 31
  },
  {
    value: "MS",
    desc: "Mato Grosso do Sul",
    idIBGE: 50
  },
  {
    value: "MT",
    desc: "Mato Grosso",
    idIBGE: 51
  },
  {
    value: "PA",
    desc: "Pará",
    idIBGE: 15
  },
  {
    value: "PB",
    desc: "Paraíba",
    idIBGE: 25
  },
  {
    value: "PE",
    desc: "Pernambuco",
    idIBGE: 26
  },
  {
    value: "PI",
    desc: "Piauí",
    idIBGE: 22
  },
  {
    value: "PR",
    desc: "Paraná",
    idIBGE: 41
  },
  {
    value: "RJ",
    desc: "Rio de Janeiro",
    idIBGE: 33
  },
  {
    value: "RN",
    desc: "Rio Grande do Norte",
    idIBGE: 24
  },
  {
    value: "RO",
    desc: "Rondônia",
    idIBGE: 11
  },
  {
    value: "RR",
    desc: "Roraima",
    idIBGE: 14
  },
  {
    value: "RS",
    desc: "Rio Grande do Sul",
    idIBGE: 43
  },
  {
    value: "SC",
    desc: "Santa Catarina",
    idIBGE: 42
  },
  {
    value: "SE",
    desc: "Sergipe",
    idIBGE: 28
  },
  {
    value: "SP",
    desc: "São Paulo",
    idIBGE: 35
  },
  {
    value: "TO",
    desc: "Tocantins",
    idIBGE: 17
  }
];