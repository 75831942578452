import Vue from 'vue';

// Initial state
const initialState = () => ({
  profile: null,
  loading: false,
  carregando: false,
  cpf: '',
  primeiroAcesso: null,
  emailLogin: null,
  dadosAutenticacaoTemporaria: null,
  usuariosIndicados: [],
});

// State object
const state = initialState();

// Getters
const getters = {
  getLoadingStatus(state) {
    return state.loading;
  },
  getLoaderRegistro(state) {
    return state.carregando;
  },
  getProfile(state) {
    return state.profile;
  },
  getCPF(state) {
    return state.cpf;
  },
  getPrimeiroAcesso(state) {
    return state.primeiroAcesso;
  },
  getEmailLogin(state) {
    return state.emailLogin;
  },
  obterDadosAutenticacaoTemporaria(state) {
    return state.dadosAutenticacaoTemporaria;
  },
  getUsuariosIndicados(state) {
    return state.usuariosIndicados;
  },
};

// Actions
const actions = {
  clearState({ commit }) {
    commit('RESET');
  },

  selecionaCPF({ commit }, { cpf }) {
    if (cpf) {
      commit('setCPF', cpf);
    } else {
      commit('setCPF', '');
    }
  },

  fetchProfile({ commit }, { login }) {
    return new Promise(async resolve => {
      const api = new Vue.prototype.$httpClient();
      const response = await api.send(`/consumidor/v1/consumidores/${login.usuario}`, 'GET', null, null, false);
      if ([200].includes(response.status)) {
        commit('setProfile', response.data.retorno);
        resolve({
          status: 200,
          msg: 'Perfil recuperado com sucesso',
        });
      } else {
        commit('setProfile', null);
        resolve({
          status: response.status || 500,
          msg: response.data.mensagem || 'Você pode estar off-line. Tente novamente mais tarde.',
        });
      }
    });
  },

  limparPrimeiroAcesso({ commit }) {
    commit('setPrimeiroAcesso', null);
  },

  consultarCPF({ commit }, { usuario, isProd }) {
    return new Promise(async resolve => {
      const config = Vue.prototype.$storeConfig.config();
      const api = new Vue.prototype.$httpClient(
        isProd ? config.VUE_APP_PROD_WS_URL : config.VUE_APP_DEV_WS_URL,
        btoa(new Date().getTime())
      );
      const response = await api.send(`/consumidor/v1/verificar/${usuario}`, 'GET', null, null, false);
      if (response.status == 200) {
        commit('setPrimeiroAcesso', { ...response.data.retorno, cpf: usuario });
        resolve({
          status: 200,
          msg: 'Consumidor encontrado.',
        });
      } else if (response.status == 404) {
        resolve({
          status: 404,
          msg: 'Consumidor não encontrado.',
        });
      } else {
        resolve({
          status: response.status || 500,
          msg: response.data.mensagem || 'Você pode estar off-line. Tente novamente mais tarde.',
        });
      }
    });
  },

  changePassword({ commit }, { data }) {
    return new Promise(async resolve => {
      const api = new Vue.prototype.$httpClient();

      commit('setLoadingStatus', true);

      const response = await api.send(`/login/v1/logins/trocarSenha`, 'PATCH', {
        senhaAtual: data.currentPassword,
        novaSenha: data.newPassword,
        confirmacao: data.confirmPassword,
      });

      commit('setLoadingStatus', false);

      if ([200].includes(response.status)) {
        resolve({
          status: 200,
          msg: 'Senha atualizada com sucesso!',
        });
      } else {
        resolve({
          status: response.status || 500,
          msg: response.data.mensagem || 'Você pode estar off-line. Tente novamente mais tarde.',
        });
      }
    });
  },

  alterarNotificacao({ commit }, { data }) {
    return new Promise(async resolve => {
      const api = new Vue.prototype.$httpClient();

      commit('setLoadingStatus', true);

      const response = await api.send(`/consumidor/v1/alterarNotificacao`, 'POST', {
        telefone: data.telefone,
        aceitaNotificacao: data.aceitaNotificacao,
      });

      commit('setLoadingStatus', false);

      if ([200].includes(response.status)) {
        resolve({
          status: 200,
          msg: 'Autorização de notificação foi atualizada com sucesso!',
        });
      } else {
        resolve({
          status: response.status || 500,
          msg: response.data.mensagem || 'Você pode estar off-line. Tente novamente mais tarde.',
        });
      }
    });
  },

  enviarDeviceID({ commit }, { data }) {
    return new Promise(async resolve => {
      const api = new Vue.prototype.$httpClient();

      commit('setLoadingStatus', true);

      const response = await api.send(`/notificacao/v1/dispositivos`, 'POST', {
        usuario: data.usuario,
        tipoUsuario: 'consumidor',
        identificacao: {
          servico: data.servicoNotificador || 'sns',
          id: data.id,
        },
        // tipo: 'android',
        tipo: data.tipoDispositivo || 'pwa',
        descricao: data.descricao,
      });

      commit('setLoadingStatus', false);

      if ([200, 201].includes(response.status)) {
        resolve({
          status: response.status,
          msg: response.data.mensagem,
        });
      } else {
        resolve({
          status: response.status,
          msg: response.data.mensagem,
        });
      }
    });
  },

  criarConta({ commit }, { user, wsUrl, type, commitLoader }) {
    return new Promise(async resolve => {
      const api = new Vue.prototype.$httpClient(wsUrl, undefined, 60000, true);
      if (commitLoader) {
        commit('setLoaderRegistro', true);
      }
      const response = await api.send(`/login/v1/logins`, 'POST', {
        email: user.email || undefined,
        nome: user.name,
        usuario: user.phone,
        senha: user.password,
        telefoneContato: user.telefoneContato,
        consumidor: {
          aceites: user.aceites || [],
          telefoneContato: user.telefoneContato,
          indicacao: user.indicacao,
        },
        tipo: type,
        dataNascimento: user.dataNascimento || '',
        telefoneContato: user.telefoneContato || '',
        email: user.email || '',
        genero: user.genero || '',
        endereco: Object.values(user.endereco).some(Boolean) ? user.endereco : {},
        documentoVariavel: user.documentoVariavel || '',
        aceitaNotificacao: user.aceitaNotificacao || false,
        resposta: user.resposta || '',
        extra: user.extra || '',
        primeiraLoja: user.primeiraLoja || {},
      });
      commit('setEmailLogin', user.email || null);
      if (commitLoader) {
        commit('setLoaderRegistro', false);
      }
      if ([200, 201].includes(resolve.status)) {
        resolve({
          status: 201,
          msg: 'Perfil cadastrado com sucesso',
        });
      } else {
        resolve({
          status: response.status || 500,
          msg: response.data.mensagem || 'Você pode estar off-line. Tente novamente mais tarde.',
          data: response.data,
        });
      }
    });
  },

  criarContaFacebook({ commit }, { usuario, wsUrl, tipo }) {
    return new Promise(async resolve => {
      const api = new Vue.prototype.$httpClient(wsUrl, undefined, 60000);
      const url = `/login/v1/logins/facebook`;
      commit('setLoadingStatus', true);
      const resposta = await api.send(url, 'POST', {
        usuario: usuario.usuario,
        nome: usuario.nome,
        tipo: tipo,
        senha: usuario.senha,
        tokenFacebook: usuario.facebook.token,
        email: usuario.facebook.email,
        imgUrl: null,
      });
      commit('setLoadingStatus', false);
      if ([200, 201].includes(resposta.status)) {
        resolve({
          status: 201,
          msg: 'Perfil cadastrado com sucesso',
        });
      } else {
        resolve({
          status: resposta.status || 500,
          msg: resposta.data.mensagem || 'Você pode estar off-line. Tente novamente mais tarde.',
        });
      }
    });
  },

  updateProfile({ commit, dispatch }, { profile }) {
    return new Promise(async resolve => {
      const api = new Vue.prototype.$httpClient();

      commit('setLoadingStatus', true);

      const response = await api.send(`/consumidor/v1/consumidores`, 'POST', {
        ...profile,
      });

      commit('setLoadingStatus', false);

      if ([200, 201].includes(response.status)) {
        const updateResponse = await dispatch('fetchProfile', {
          login: {
            usuario: profile.telefone,
          },
        });
        if ([200, 201].includes(updateResponse.status)) {
          resolve({
            status: 200,
            msg: 'Seu perfil foi atualizado com sucesso!',
          });
        } else {
          resolve(updateResponse);
        }
      } else if ([400].includes(response.status)) {
        if (response.data && response.data.retorno && typeof response.data.retorno == 'object') {
          let message = 'Ocorreram os seguintes erros de validação: ';
          response.data.retorno.forEach(r => {
            message += ' - ' + r;
          });
          resolve({
            status: 400,
            msg: message,
          });
        } else {
          resolve({
            status: response.status || 500,
            msg: response.data.mensagem || 'Você pode estar off-line. Tente novamente mais tarde.',
          });
        }
      } else {
        resolve({
          status: response.status || 500,
          msg: response.data.mensagem || 'Você pode estar off-line. Tente novamente mais tarde.',
        });
      }
    });
  },

  uploadImagemPerfil(_, fotoBase64) {
    return new Promise(async resolve => {
      const api = new Vue.prototype.$httpClient();
      const url = '/upload/v1/fotoPerfil';
      const resposta = await api.send(url, 'POST', {
        fotoBase64,
      });
      if ([200, 201].includes(resposta.status)) {
        resolve({
          status: 200,
          msg: 'Upload da imagem feito com sucesso!',
          arquivo: resposta.data.retorno.nomeArquivo,
        });
      } else {
        resolve({
          status: resposta.status,
          msg: resposta.data.mensagem || 'Você pode estar off-line. Tente novamente mais tarde.',
        });
      }
    });
  },

  alterarAceites({ commit }, { data }) {
    return new Promise(async resolve => {
      const api = new Vue.prototype.$httpClient();

      commit('setLoadingStatus', true);
      let aceites = data.aceites.map(a => {
        return { nomeTermo: a.nomeTermo, aceito: a.aceito === true };
      });
      const response = await api.send(`/consumidor/v1/alterarAceites`, 'POST', {
        usuario: data.usuario,
        aceites: aceites,
      });

      commit('setLoadingStatus', false);

      if ([200].includes(response.status)) {
        commit('setProfile', response.retorno);
        resolve({
          status: 200,
          msg: 'Aceites atualizados com sucesso!',
        });
      } else {
        resolve({
          status: response.status || 500,
          msg: response.data.mensagem || 'Você pode estar off-line. Tente novamente mais tarde.',
        });
      }
    });
  },

  gerarCodigoTemporario({ commit }) {
    return new Promise(async resolve => {
      const api = new Vue.prototype.$httpClient();

      const response = await api.send(`/login/v1/autenticacaoTemporaria/gerarCodigo`, 'POST', null, null, false);

      if ([200].includes(response.status)) {
        commit('setDadosAutenticacaoTemporaria', response.data.retorno);
        resolve({
          status: 200,
          msg: 'Dados obtidos com sucesso',
        });
      } else {
        commit('setCodigoAutenticacaoTemporaria', null);
        resolve({
          status: response.status || 500,
          msg: response.data.mensagem || 'Você pode estar off-line. Tente novamente mais tarde.',
        });
      }
    });
  },

  excluirConta({ commit }, { usuario }) {
    return new Promise(async resolve => {
      const api = new Vue.prototype.$httpClient();

      const response = await api.send(`/consumidor/v1/consumidores/${usuario}`, 'DELETE', null, null, false);

      if ([200].includes(response.status)) {
        resolve({
          status: 200,
          msg: 'Conta excluída com sucesso!',
        });
      } else {
        resolve({
          status: response.status || 500,
          msg: response.data.mensagem || 'Você pode estar off-line. Tente novamente mais tarde.',
        });
      }
    });
  },

  fetchConsumidoresIndicados({ commit }, { paginacao }) {
    return new Promise(async resolve => {
      const api = new Vue.prototype.$httpClient();
      const response = await api.send(
        `/consumidor/v1/consumidores/indicacoes/${paginacao.totalPorPagina}/${paginacao.paginaAtual}`,
        'GET',
        null,
        null,
        false
      );
      if ([200].includes(response.status)) {
        commit('setusuariosIndicados', response.data.retorno);
        resolve({
          status: 200,
          msg: 'Indicações encontradas com sucesso!',
        });
      } else {
        commit('setusuariosIndicados', null);
        resolve({
          status: response.status || 500,
          msg:
            response.data.mensagem || 'Falha ao listar os usuários indicados, verifique sua conexão e tente novamente.',
        });
      }
    });
  },

  verificarSeLoginExiste({ commit }, { user, wsUrl }) {
    return new Promise(async resolve => {
      const api = new Vue.prototype.$httpClient(wsUrl, undefined, 60000, true);
      const response = await api.send(`${wsUrl}/login/v1/logins/verificar/${user}`, 'GET', null, null, false);
      if ([200].includes(response.status)) {
        resolve({
          status: 200,
          msg: response.data.mensagem,
        });
      } else {
        resolve({
          status: response.status || 500,
          msg: response.data.mensagem || 'Falha ao verificar se login existe, verifique sua conexão e tente novamente.',
        });
      }
    });
  },

  verificaTelefoneContatoExiste({ commit }, { telefoneContato, telefoneContatoAtual }) {
    return new Promise(async resolve => {
      const api = new Vue.prototype.$httpClient();
      const resposta = await api.send(
        `/consumidor/v1/consumidores/verificarTelefoneContato`,
        'POST',
        {
          telefoneContato: telefoneContato,
          telefoneContatoAtual: telefoneContatoAtual,
        },
        null,
        false
      );
      if ([200, 201].includes(resposta.status)) {
        resolve({
          status: 200,
          msg: resposta.data.mensagem,
          retorno: resposta.data.retorno,
        });
      } else {
        resolve({
          status: resposta.status || 500,
          msg: resposta.data.mensagem || 'Você pode estar off-line. Tente novamente mais tarde.',
          retorno: resposta.data.retorno || null,
        });
      }
    });
  },
};

// Mutations
const mutations = {
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach(key => {
      state[key] = newState[key];
    });
  },
  setProfile(state, data) {
    state.profile = data;
  },
  setLoadingStatus(state, data) {
    state.loading = data;
  },
  setLoaderRegistro(state, data) {
    state.carregando = data;
  },
  setCPF(state, data) {
    state.cpf = data;
  },
  setPrimeiroAcesso(state, data) {
    state.primeiroAcesso = data;
  },
  setEmailLogin(state, data) {
    state.emailLogin = data;
  },
  setDadosAutenticacaoTemporaria(state, data) {
    state.dadosAutenticacaoTemporaria = data;
  },
  setusuariosIndicados(state, data) {
    state.usuariosIndicados = data;
  },
};

// Module exports
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
