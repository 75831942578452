<template>
  <div class="w-100 d-flex flex-column align-items-center">
    <!-- Título -->
    <div class="w-75 d-flex align-items-center justify-content-center mb-2">
      <p
        class="f-11 mt-4 text-center"
        style="color: var(--text-color-highlight)"
      >
        <strong>Bem vindo!</strong>
      </p>
    </div>

    <!-- Subtítulo -->
    <div class="w-75 d-flex align-items-center justify-content-center mb-2">
      <p
        class="f-07 mb-3 text-center"
        style="color: var(--text-color-highlight)"
      >
        {{
          config.PRIMEIRO_ACESSO_MENSAGEM_INICIAL ||
          'Digite no campo abaixo o seu CPF para continuar:'
        }}
      </p>
    </div>

    <!-- CPF -->
    <div class="w-75 d-flex align-items-center justify-content-center mb-2">
      <base-pill-input
        id="email-usuario"
        type="tel"
        :autocomplete="false"
        :placeholder="
          config.TIPO_USUARIO == 'cpfCnpj'
            ? 'CPF/CNPJ'
            : config.TIPO_USUARIO == 'cnpj'
            ? 'CNPJ'
            : 'CPF'
        "
        :left-middle-pill="true"
        class="w-100 mb-3"
        :icons="['fas fa-user']"
        :pillColors="['pill-dark']"
        :pillIconColors="['icon-light']"
        :pillSize="['pill-average']"
        iconValidation="fas fa-info-circle"
        :mask="
          config.TIPO_USUARIO == 'cpfCnpj'
            ? ['###.###.###-##', '##.###.###/####-##']
            : config.TIPO_USUARIO == 'cnpj'
            ? ['##.###.###/####-##']
            : ['###.###.###-##']
        "
        :masked="false"
        v-model="cpf"
        :valid="[11, 14].includes(cpf.length)"
        @keyup.enter="
          !loading && [11, 14].includes(cpf.length)
            ? consultarConsumidor()
            : () => {}
        "
      />
    </div>

    <!-- Ações -->
    <div
      class="
        w-75
        d-flex
        align-items-center
        justify-content-between
        mb-3
        pt-5
        pb-5
      "
    >
      <base-pill-button
        id="esqueci-senha-voltar"
        :type="'light'"
        :left-middle-pill="'fas fa-chevron-circle-left'"
        :pillColors="['pill-dark']"
        :pillIconColors="['icon-light']"
        :pillSize="['pill-average']"
        @click="voltarTela()"
      >
        <template v-slot:pill-button-title>
          <span class="w-90 pl-2 pr-3 text-center f-07 light-gray">Voltar</span>
        </template>
      </base-pill-button>

      <base-pill-button
        id="esqueci-senha-enviar"
        :type="'dark'"
        :right-middle-pill="'fas fa-chevron-circle-right'"
        :pillColors="['pill-light']"
        :pillIconColors="['icon-dark']"
        :pillSize="['pill-average']"
        :disabled="loading || ![11, 14].includes(cpf.length)"
        @click="consultarConsumidor"
      >
        <template v-slot:pill-button-title>
          <span class="w-90 pl-3 pr-2 text-center f-07 light-gray"
            >Consultar</span
          >
        </template>
      </base-pill-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ThePrimeiroAcesso',

  computed: {
    ...mapGetters({
      config: 'config/getConfig'
    })
  },

  data: () => ({
    cpf: '',
    loading: false
  }),

  beforeMount() {
    const params = this.$route.query

    if (Object.keys(params).length && params.usuario) {
      this.cpf = params.usuario
      this.consultarConsumidor()
    }
  },

  methods: {
    ...mapActions({
      consultarCPF: 'consumidor/consultarCPF'
    }),

    navigateTo(name) {
      this.$router.push({ name })
    },

    voltarTela() {
      this.$emit('alternar-exibicao', {
        event: 'alternar-tela-primeiro-acesso'
      })
    },

    obterProximatela() {
      return this.config.LGPD
        ? this.config.LGPD_DETALHADA
          ? 'alternar-tela-lgpd-detalhada'
          : 'alternar-tela-lgpd'
        : 'alternar-tela-cadastro'
    },

    navegarProximaTela() {
      this.$emit('alternar-exibicao', {
        event: 'alternar-tela-primeiro-acesso'
      })

      this.$emit('alternar-exibicao', {
        event: this.obterProximatela()
      })
    },

    async consultarConsumidor() {
      this.loading = true
      if (this.cpf) {
        await this.consultarCPF({
          usuario: this.cpf,
          isProd: this.isProd
        }).then((res) => {
          this.$emit('emmitEvent', {
            event: 'gtm',
            data: {
              event: 'cpfConsultado',
              category: 'PrimeiroAcesso',
              action: 'form',
              label: 'Consultou CPF',
              value: this.cpf
            }
          })
          if (res.status == 404) {
            this.$swal({
              type: 'info',
              title: 'Primeiro Acesso',
              text:
                this.config.PRIMEIRO_ACESSO_MENSAGEM ||
                'Você ainda não é cliente do QRSorteios. Para participar, realize o seu cadastro a seguir!',
              onClose: () => {
                if (this.config.PRIMEIRO_ACESSO_URL != 'vazio') {
                  window.open(`${this.config.PRIMEIRO_ACESSO_URL}`, '_self')
                }
              }
            })
          } else if (res.status == 200) {
            this.$swal({
              type: 'success',
              title: 'Primeiro Acesso',
              text:
                this.config.PRIMEIRO_ACESSO_MENSAGEM_CONSUMIDOR_JA_CADASTRADO ||
                'Na próxima pagina, cadastre uma senha para o seu acesso',
              onClose: () => {
                this.navegarProximaTela()
              }
            })
          } else {
            this.$swal({
              type: 'error',
              title: 'Primeiro Acesso',
              text: 'Erro ao fazer a consulta... Tente novamente mais tarde'
            })
          }
        })
      }
      this.loading = false
    }
  },

  props: {
    isProd: {
      type: Boolean,
      required: true
    }
  }
}
</script>
<style scoped>
.btn-consultar-quantidade {
  border-radius: 0px;
}
.preregistro-view {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>
