<template>
  <div class="w-100 d-flex flex-column align-items-center">
    <!-- Título -->
    <div class="w-75 d-flex flex-row align-items-center justify-content-center">
      <p class="f-11 mt-4 mb-4 text-center" style="color: var(--text-color-highlight)">
        <strong>Login</strong>
      </p>
    </div>

    <!-- Telefone/CPF|CNPJ -->
    <div
      class="w-75 d-flex align-items-center justify-content-center"
      :class="config.INPUTS_DARK_LOGIN_CADASTRO ? 'dark-inputs' : 'light-inputs'"
    >
      <!-- Telefone -->
      <base-pill-input
        :autocomplete="false"
        v-if="!config.LOGIN_CPF"
        ref="user"
        id="telefone-usuario"
        type="tel"
        placeholder="Telefone"
        :left-middle-pill="true"
        class="w-100 mb-3"
        :icons="['fas fa-user']"
        :pillColors="['pill-dark']"
        :leftMiddlePillRootColor="config.COR_DESTAQUE_CIRCUNFERENCIA_DOS_INPUTS"
        :leftMiddlePillIconRootColor="config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_DOS_INPUTS"
        :pillIconColors="['icon-light']"
        :pillSize="['pill-average']"
        iconValidation="fas fa-info-circle"
        :mask="['(##)#####-####']"
        :masked="false"
        :valid="!$v.form.user.$invalid"
        v-model="form.user"
        @keyup.enter="!loading ? checkForm() : () => {}"
      />
      <!-- CPF|CNPJ -->
      <base-pill-input
        v-else
        :autocomplete="false"
        ref="user"
        id="cpf-cnpj-usuario"
        type="tel"
        :placeholder="config.TIPO_USUARIO == 'cpfCnpj' ? 'CPF/CNPJ' : config.TIPO_USUARIO == 'cnpj' ? 'CNPJ' : 'CPF'"
        :left-middle-pill="true"
        class="w-100 mb-3"
        :icons="['fas fa-user']"
        :pillColors="['pill-dark']"
        :leftMiddlePillRootColor="config.COR_DESTAQUE_CIRCUNFERENCIA_DOS_INPUTS"
        :leftMiddlePillIconRootColor="config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_DOS_INPUTS"
        :pillIconColors="['icon-light']"
        :pillSize="['pill-average']"
        iconValidation="fas fa-info-circle"
        :mask="
          config.TIPO_USUARIO == 'cpfCnpj'
            ? ['###.###.###-##', '##.###.###/####-##']
            : config.TIPO_USUARIO == 'cnpj'
            ? ['##.###.###/####-##']
            : ['###.###.###-##']
        "
        :masked="false"
        :valid="!$v.form.user.$invalid"
        v-model="form.user"
        @keyup.enter="!loading ? checkForm() : () => {}"
      />
    </div>
    <!-- Password field -->
    <div
      class="w-75 d-flex align-items-center justify-content-center"
      :class="config.INPUTS_DARK_LOGIN_CADASTRO ? 'dark-inputs' : 'light-inputs'"
    >
      <base-pill-input
        :autocomplete="false"
        ref="password"
        id="senha-usuario"
        type="password"
        placeholder="Senha"
        :left-middle-pill="true"
        class="w-100 mb-3"
        :icons="['fas fa-lock']"
        :pillColors="['pill-dark']"
        :leftMiddlePillRootColor="config.COR_DESTAQUE_CIRCUNFERENCIA_DOS_INPUTS"
        :leftMiddlePillIconRootColor="config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_DOS_INPUTS"
        :pillIconColors="['icon-light']"
        :pillSize="['pill-average']"
        iconValidation="fas fa-info-circle"
        v-model="form.password"
        :valid="!$v.form.password.$invalid"
        @keyup.enter="!loading ? checkForm() : () => {}"
      />
    </div>
    <!-- Entrar -->
    <div class="w-75 d-flex flex-row align-items-center justify-content-center mt-2">
      <base-pill-button
        :type="'light'"
        :right-middle-pill="'fas fa-sign-in-alt'"
        :pillColors="['pill-light']"
        :pillIconColors="['icon-dark']"
        :rightMiddlePillRootColor="config.COR_DESTAQUE_CIRCUNFERENCIA_DOS_BOTOES"
        :rightMiddlePillIconRootColor="config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_DOS_BOTOES"
        :pillSize="['pill-average']"
        :disabled="loadingSignOut"
        @click="!loadingSignOut ? checkForm() : () => {}"
      >
        <template v-slot:pill-button-title>
          <span class="w-90 pl-3 pr-2 text-center f-07 text-black">ENTRAR</span>
        </template>
      </base-pill-button>
    </div>
    <!-- Entrar com Google|Facebook -->
    <div class="w-75 d-flex flex-column align-items-center justify-content-center" v-if="realizarLoginRedesSociais">
      <p class="mt-5"><strong>Entrar com</strong></p>
      <div
        class="w-100 d-flex flex-row align-items-center pb-5"
        :class="config.LOGIN_FACEBOOK && config.LOGIN_GOOGLE ? 'justify-content-between' : 'justify-content-center'"
      >
        <FacebookButton @emmitEvent="submitFacebook" :isProd="isProd" class="w-45" v-if="config.LOGIN_FACEBOOK" />
        <base-pill-button
          :type="'light'"
          class="w-45"
          :right-middle-pill="'fab fa-google'"
          :pillColors="['pill-light-gray']"
          :pillIconColors="['icon-light']"
          :pillSize="['pill-average']"
          @click="navigateTo('esqueciSenha')"
          v-if="config.LOGIN_GOOGLE"
        >
          <template v-slot:pill-button-title>
            <span class="w-90 pl-3 pr-2 text-center f-07 light-gray">Google</span>
          </template>
        </base-pill-button>
      </div>
    </div>
    <!-- Esqueci a senha -->
    <div
      class="w-100 d-flex align-items-center justify-content-center"
      :class="!realizarLoginRedesSociais ? 'pt-5' : ''"
    >
      <base-pill-button
        :type="'light'"
        :class="'pb-4'"
        :right-middle-pill="'fas fa-sign-in-alt'"
        :pillColors="['pill-light']"
        :pillIconColors="['icon-dark']"
        :rightMiddlePillRootColor="config.COR_DESTAQUE_CIRCUNFERENCIA_DOS_BOTOES"
        :rightMiddlePillIconRootColor="config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_DOS_BOTOES"
        :pillSize="['pill-average']"
        @click="alternarTelaEsqueciSenha()"
      >
        <template v-slot:pill-button-title>
          <span class="w-90 pl-3 pr-2 text-center text-black f-07">Esqueci minha senha</span>
        </template>
      </base-pill-button>
    </div>

    <!-- Modal para quando as configurações MODIFICAR_CAMPOS_PERFIL e CAMPOS_OBRIGATORIOS_PERFIL não incluir email -->
    <base-modal
      :show.sync="modais.emailSuporte.ativo"
      modal-classes="d-flex align-items-center qr-modal-middle"
      headerClasses="p-2 text-center pl-4"
      body-classes="p-0"
      preventClose
    >
      <template slot="header">Atenção</template>
      <template>
        <base-card header-classes="bg-white pb-5 text-center" body-classes="px-0 py-0" class="border-0">
          <div class="text-center d-flex justify-content-center align-items-center flex-column p-4 mb-3">
            Caro cliente, por favor entrar em contato com nosso suporte através do email abaixo:
            <strong>
              {{ this.config.EMAIL_SUPORTE }}
            </strong>
          </div>
        </base-card>
      </template>
    </base-modal>

    <!-- Texto acima do antigo botão de cadastro -->
    <!-- <div
      class="text-center mt-2"
      v-if="config.TEXTO_ACIMA_BOTAO_CADASTRO !== 'vazio'"
    >
      <h5
        class="
          text-uppercase text-login
          font-weight-bold
          text-shadow
          texto-acima-cadastro
        "
      >
        {{ config.TEXTO_ACIMA_BOTAO_CADASTRO }}
      </h5>
    </div> -->
    <!-- Cadastrar -->
    <!-- <div
        class="row"
        :class="config.TEXTO_ACIMA_BOTAO_CADASTRO == 'vazio' ? 'mt-3' : 'mt-2'"
      >
        <div class="col-2"></div>
        <div class="col-8">
          <base-button
            type="primary"
            class="w-100 shadow-sm"
            v-if="!this.block"
            @click="
              config.TELA_PRIMEIRO_ACESSO
                ? navigateTo('PrimeiroAcesso')
              : config.LGPD
                ? config.LGPD_DETALHADA
                  ? navigateTo('lgpdDetalhada')
                : navigateTo('lgpd')
              : navigateTo('cadastro')
            "
            >Cadastre-Se</base-button
          >
          <base-button
            type="primary"
            class="w-100 shadow-sm"
            v-else
            @click="
              campaignMsg(
                'Não há campanhas promocionais disponíveis no momento!',
                false
              )
            "
            >Cadastre-Se</base-button
          >
        </div>
        <div class="col-1"></div>
      </div> -->

    <div class="w-100 d-flex align-items-center justify-content-center mb-2" v-if="isCordovaIphoneApp">
      <span class="w-90 text-center text-light rounded">
        <small class="f-065"> A Apple não possui ligação e/ou responsabilidade sobre a campanha </small>
      </span>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { required, minLength } from 'vuelidate/lib/validators';
import FacebookButton from '@/components/layout/social/FacebookButton';
import { Data } from '@/lib/Helper';

export default {
  name: 'TheLogin',
  created() {
    let bgqr, segundaImg, primeiraImg;
    let intervalo = setInterval(() => {
      if (this.config.SEGUNDA_IMG_LOGO_LOGIN) {
        segundaImg = document.querySelector('#segundaImg');
        primeiraImg = document.querySelector('#primeiraImg');
        segundaImg.style.display = 'block';
        primeiraImg.classList.add('primeiraImg');
      }
      if (
        this.config.TABELA_COR.find((cor) => cor.nome == 'PRIMEIRA_COR_GRADIENT_LOGIN').cor != 'null' &&
        this.config.TABELA_COR.find((cor) => cor.nome == 'SEGUNDA_COR_GRADIENT_LOGIN').cor != 'null'
      ) {
        bgqr = document.querySelector('.qrsorteios');
        if (bgqr) bgqr.classList.add('bgLogin');
      }
      if (
        ((this.config.SEGUNDA_IMG_LOGO_LOGIN && segundaImg) || !this.config.SEGUNDA_IMG_LOGO_LOGIN) &&
        ((this.config.TABELA_COR.find((cor) => cor.nome == 'PRIMEIRA_COR_GRADIENT_LOGIN').cor != 'null' && bgqr) ||
          this.config.TABELA_COR.find((cor) => cor.nome == 'PRIMEIRA_COR_GRADIENT_LOGIN').cor == 'null')
      ) {
        clearInterval(intervalo);
      }
    }, 100);
  },

  destroyed() {
    if (
      this.config.TABELA_COR.find((cor) => cor.nome == 'PRIMEIRA_COR_GRADIENT_LOGIN').cor != 'null' &&
      this.config.TABELA_COR.find((cor) => cor.nome == 'SEGUNDA_COR_GRADIENT_LOGIN').cor != 'null'
    ) {
      let bgqr = document.querySelector('.bgLogin');
      bgqr.classList.remove('bgLogin');
    }
    let segundaImg = document.querySelector('#segundaImg');
    if (segundaImg) segundaImg.style.display = 'none';
    if (this.config.SEGUNDA_IMG_LOGO_LOGIN) {
      primeiraImg = document.querySelector('#primeiraImg');
      segundaImg.style.display = 'block';
      primeiraImg.classList.remove('primeiraImg');
    }
  },

  async beforeMount() {
    const params = this.$route.query;
    const loginSalvo = localStorage.getItem('login');

    if (loginSalvo) {
      this.submitForm(); // forçando uma re-autenticação... se der erro, deve deslogar
      const tela = this.telasConfiguraveis[0].destino || 'contaCorrente';
      if (this.$route.name != tela) this.$router.push({ name: tela });
    } else if (this.config.LINK_REDIRECIONAR_DESLOGADO && Object.keys(params).length === 0) {
      window.location.href = this.config.LINK_REDIRECIONAR_DESLOGADO;
    }

    // Reaparecendo a logo da campanha
    let logoCampanha = document.querySelector('.campaing-logo');
    if (logoCampanha) {
      logoCampanha.style.display = 'flex';
    }

    if (Object.keys(params).length) {
      // QS: /cadastro/?cadastroNome=Teste Alpha centauri&cadastroEmail=teste.alpha@centauri.com&cadastroCpf=37762762906&cadastroPhone=19900001111
      if (params.cadastroNome && params.cadastroEmail) {
        this.stateSignupURL.cpf = params.cadastroCpf || '';
        this.stateSignupURL.nome = params.cadastroNome || '';
        this.stateSignupURL.email = params.cadastroEmail || '';
        this.stateSignupURL.phone = params.cadastroPhone || '';
        this.setSignupURL(this.stateSignupURL);
      }
      if (params.usuario) {
        this.form.user = params.usuario;
        this.form.password = params.senha;
        this.form.token = params.token;
        this.$emit('emmitEvent', {
          event: 'authQueryParams',
          data: {
            user: this.form.user,
            password: this.form.password ? this.form.password : null,
            type: this.associateMode ? 'associado' : 'consumidor',
            token: this.form.token ? this.form.token : null,
            route: params.tela,
          },
        });
      }
    }
  },

  components: { FacebookButton },

  computed: {
    ...mapGetters({
      config: 'config/getConfig',
      loading: 'autenticacao/isLoading',
      telasConfiguraveis: 'config/obterTelasConfiguraveis',
      stateSignupURL: 'signupURL/getSignupData',
    }),

    associateMode() {
      return this.$route.meta.associate;
    },

    imgFundoLogin() {
      return this.config.FUNDO_IMG_LOGIN ? 'imgFundoLogin' : '';
    },

    btnTransparente() {
      return this.config.BTN_LOGIN_TRANSPARENTE ? 'btnTransparente' : '';
    },

    typeBtn() {
      return this.config.BTN_LOGIN_TRANSPARENTE ? '' : 'secondary';
    },

    isCordovaIphoneApp() {
      return typeof cordova != 'undefined' && cordova.platformId == 'ios';
    },

    realizarLoginRedesSociais() {
      return this.config.LOGIN_FACEBOOK || this.config.LOGIN_GOOGLE;
    },
  },

  data: () => ({
    form: {
      user: '',
      password: '',
      token: '',
    },
    modais: {
      emailSuporte: {
        ativo: false,
      },
    },
    loadingSignOut: false,
    finishIntro: false,
    startIntro: false,
    showSplash: false,
    block: true,
  }),

  methods: {
    ...mapActions({
      setSignupURL: 'signupURL/saveSignupData',
    }),
    carregaCampanhas: async function () {
      const wsUrl = this.isProd ? this.config.VUE_APP_PROD_WS_URL : this.config.VUE_APP_DEV_WS_URL;
      const currentDate = Data.dataHoraAtual();
      const url = `${wsUrl}/campanha/v1/campanhas/`;
      let campanhaRecuperada = null;
      await fetch(url)
        .then((response) => response.json())
        .then((data) => {
          data.retorno
            .filter((camp) => {
              let initial = `${camp.dataInicial} 00:00:00`;
              let final = `${camp.dataFinalCadastros} 23:59:00`;
              return camp.ativa && currentDate >= initial && currentDate <= final;
            })
            .forEach((camp) => {
              campanhaRecuperada = camp;
            });
          campanhaRecuperada ? (this.block = false) : (this.block = true);
        })
        .catch((error) => {
          console.error('Error:', error);
          this.block = true;
          this.informarCampanhaNaoEncontrada();
        });
    },
    informarCampanhaNaoEncontrada() {
      this.$emit('alternar-modal', {
        event: 'informar-campanha-nao-encontrada',
      });
    },
    checkForm(e) {
      this.loadingSignOut = true;
      if (this.$v.$invalid) {
        this.$emit('emmitInfoModal', {
          event: 'modal-informacao-generica',
          data: {
            icone: 'fas fa-exclamation-circle',
            titulo: 'Ops...',
            mensagem: 'Por favor preencha o seu CPF e senha.',
            textoBotao: 'OK',
          },
        });
      } else {
        this.submitForm();
      }
      setTimeout(() => {
        this.loadingSignOut = false;
      }, 1000);
    },
    navigateTo(name) {
      this.$router.push({ name });
    },
    alternarTelaEsqueciSenha() {
      if (this.config.MODIFICAR_CAMPOS_PERFIL && !this.config.CAMPOS_OBRIGATORIOS_PERFIL.includes('email')) {
        this.modais.emailSuporte.ativo = true;
      } else if (this.config.PERMITIR_REDEFINIR_SENHA_VIA_SMS) {
        this.$emit('alternar-exibicao', {
          event: 'alternar-tela-login',
        });
        this.$emit('alternar-exibicao', {
          event: 'alternar-tela-selecionar-meio-esqueci-senha',
        });
      } else {
        this.$emit('alternar-exibicao', {
          event: 'alternar-tela-login',
        });
        this.$emit('alternar-exibicao', {
          event: 'alternar-tela-esqueci-senha',
        });
      }
    },
    submitForm() {
      // If login is successfull, start intro animation
      this.$emit('emmitEvent', {
        event: 'auth',
        data: {
          user: this.form.user,
          password: this.form.password,
          type: this.associateMode ? 'associado' : 'consumidor',
          token: null,
        },
      });
    },
    submitFacebook(evt) {
      switch (evt.event) {
        // Profile and Auth events
        case 'auth':
          this.$emit('emmitEvent', {
            event: 'auth',
            data: {
              user: evt.data.user,
              password: evt.data.password,
              type: evt.data.type,
              token: null,
              tokenFacebook: evt.data.tokenFacebook,
            },
          });
          break;
        default:
          break;
      }
    },
    informarErrosAutenticacao(titulo, mensagem) {
      this.$emit('alternar-modal', {
        event: 'informar-erros-autenticacao',
        data: {
          titulo,
          mensagem,
        },
      });
    },

    abrirModalSuporteEmail() {
      if (this.config.MODIFICAR_CAMPOS_PERFIL && !this.config.CAMPOS_OBRIGATORIOS_PERFIL.includes('email')) {
        this.modais.emailSuporte.ativo = true;
      }
    },
  },

  mounted() {
    setTimeout(() => {
      if (!window.navigator.userAgent.match(/iPad/i) && window.navigator.userAgent.match(/iPhone/i)) {
        this.$refs.user.$el.querySelector('input').focus();
      }
    }, 2300);

    this.carregaCampanhas();
  },

  validations: {
    form: {
      user: {
        required,
        minLength: minLength(10),
      },
      password: {
        required,
        minLength: minLength(1),
      },
    },
  },

  props: {
    isProd: {
      type: Boolean,
      required: true,
    },
  },

  watch: {
    'form.user': function (value) {
      if ((value.length == 11 && this.config.LOGIN_CPF) || (value.length == 12 && !this.config.LOGIN_CPF)) {
        if (!window.navigator.userAgent.match(/iPad/i) && !window.navigator.userAgent.match(/iPhone/i)) {
          // this.$refs.password.$el.querySelector('input').focus()
        }
      }
    },
  },
};
</script>
<style lang="scss">
/* .bgLogin {
  background: linear-gradient(to bottom, var(--color-primary-gradient-login) 60%, var(--color-secondary-gradient-login)) !important;
} */

.apple-disclaimer {
  position: fixed;
  bottom: 0.7rem;
}

.qr-modal-middle {
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex !important;
}
</style>
