import Vue from 'vue';

// Object initial state
const initialState = () => ({
  produtos: [],
  produtosFiltrados: [],
  totalRegistros: 0,
  paginas: 0,
  carregando: false,
});

// State object
const state = initialState();

// Getter object
const getters = {
  getTotalizador(state) {
    return state.totalRegistros;
  },
  getPaginas(state) {
    return state.paginas;
  },
  getLoader(state) {
    return state.carregando;
  },
  getProdutos(state) {
    return state.produtos;
  },
  getProdutosFiltrados(state) {
    return state.produtosFiltrados;
  },
};

// Actions
const actions = {
  clearState({ commit }) {
    commit('RESET');
  },

  fetchProdutos({ commit }, { campaign, paginacao }) {
    return new Promise(async resolve => {
      const api = new Vue.prototype.$httpClient()
      const url = `/produto/v1/produtos/${campaign.ano}/${campaign.identificacao}/${
        paginacao.porPagina
      }/${paginacao.pagina}`;
      const res = await api.send(url, 'GET', null, undefined, false)

      if ([200, 201].includes(res.status)) {
        commit('setProdutos', res.data.retorno.retorno);
        commit('setTotalizador', res.data.retorno.total);
        commit('setPaginas', res.data.retorno.paginas);
        resolve({
          status: 200,
          msg: 'Lista de produtos obtida com sucesso!',
          total: res.data.retorno.total,
        });
      } else if ([404].includes(res.status)) {
        commit('setProdutos', []);
        resolve({ status: 404, msg: 'Não foi encontrado produtos!' });
      }
    });
  },
  fetchProdutosFiltroConfig({ commit }, { campaign, filtro }) {
    return new Promise(async resolve => {
      const api = new Vue.prototype.$httpClient()
      const url = `/produto/v1/produtos/listarFiltroConfiguracao`;
      const res = await api.send(url, 'POST', {
        campanha: campaign,
        filtro: filtro
      })

      if ([200, 201].includes(res.status)) {
        commit('setProdutosFiltrados', res.data.retorno);
        resolve({
          status: 200,
          msg: 'Lista de produtos obtida com sucesso!',
        });
      } else if ([404].includes(res.status)) {
        commit('setProdutosFiltrados', []);
        resolve({ status: 404, msg: 'Não foi encontrado produtos!' });
      }
    });
  },
};

// Mutations
const mutations = {
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach(key => {
      state[key] = newState[key];
    });
  },
  setProdutos(state, data) {
    state.produtos = data;
  },
  setProdutosFiltrados(state, data) {
    state.produtosFiltrados = data;
  },
  setTotalizador(state, data) {
    state.totalRegistros = data;
  },
  setPaginas(state, data) {
    state.paginas = data;
  },
  switchCarregando(state, data) {
    state.carregando = data;
  },
};

// Exporting store module
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
