import Vue from 'vue';
import { Text } from '../../lib/Helper';

// Initial state
const initialState = () => ({
  carregando: false,
  premios: [],
  raspadinhas: [],
  premiacoesInstantaneas: { total: 0, lista: [] },
  raspadinhasAltConfig: {},
  raspadinhaAtual: null,
  raspadinhasPorPeriodo: null,
  totalRaspadinhaDisponivel: {},
});

// State object
const state = initialState();

// Getters
const getters = {
  getLoader(state) {
    return state.carregando;
  },
  retornarPremios(state) {
    return state.premios;
  },
  retornarRaspadinhaAtual(state) {
    return state.raspadinhaAtual;
  },
  getRaspadinhasAltConfig(state) {
    return state.raspadinhasAltConfig;
  },
  getRaspadinhasPorPeriodo(state) {
    return state.raspadinhasPorPeriodo;
  },
  getScratches(state) {
    return state.raspadinhas;
  },
  obterPremiacoesInstantaneas(estado) {
    return estado.premiacoesInstantaneas;
  },
};

// Actions
const actions = {
  clearState({ commit }) {
    commit('RESET');
  },

  carregarPremiacoesInstantaneas({ commit }, { campanha, paginacao, usuario, filtros }) {
    return new Promise(async resolve => {
      commit('setLoader', true);
      const api = new Vue.prototype.$httpClient();

      const parametrosFiltros = {
        ...filtros,
        usuario,
      };

      const url = `/raspadinhaAlternativa/v1/raspadinhas/${campanha.ano}/${campanha.identificacao}/${paginacao.totalPorPagina}/${paginacao.paginaAtual}`;
      const retorno = await api.send(url, 'GET', parametrosFiltros, undefined, false);

      if (retorno.status !== 200) {
        commit('setLoader', false);
        commit('definirPremiacoesInstantaneas', { total: 0, lista: [] });

        resolve({
          status: 404,
          msg: 'Não há raspadinhas para esse consumidor.',
        });

        return;
      }

      commit('definirPremiacoesInstantaneas', {
        total: retorno.data.retorno.total,
        lista: retorno.data.retorno.retorno,
      });

      resolve({
        status: 200,
        msg: 'Raspadinhas recuperadas para esse usuário.',
        retorno: retorno.data,
      });
      commit('setLoader', false);
    });
  },

  fetchScratches({ commit }, { campaign, user }) {
    return new Promise(async resolve => {
      const api = new Vue.prototype.$httpClient();
      const url = `/raspadinhaAlternativa/v1/raspadinhas/${campaign.ano}/${campaign.identificacao}/10/1/?usuario=${user}`;
      const response = await api.send(url, 'GET', null, undefined, false);

      if (response.status == 200) {
        commit('setTotalRaspadinha', response.data.retorno.total);
        commit('atualizarRaspadinhas', response.data.retorno.retorno);
        resolve({
          status: 200,
          msg: 'Raspadinhas recuperadas para esse usuário.',
        });
      } else {
        commit('atualizarRaspadinhas', []);
        resolve({
          status: 404,
          msg: 'Não há raspadinhas para esse consumidor.',
        });
      }
    });
  },

  cerregarRaspadinha({}, { campanha, filtros, paginacao, urlBase = null, token = null }) {
    return new Promise(async resolve => {
      const fitroFormatado = Text.serializarParams(filtros);
      const api = urlBase && token ? new Vue.prototype.$httpClient(urlBase, token) : new Vue.prototype.$httpClient();

      const url = `/raspadinhaAlternativa/v1/raspadinhas/${campanha.ano}/${campanha.identificacao}/${paginacao.totalPorPagina}/${paginacao.paginaAtual}?${fitroFormatado}`;
      const response = await api.send(url, 'GET', null, undefined, false);

      if (response.status == 200) {
        const raspadinhaAlternativa = (response.data.retorno.retorno || []).find(e => e.id == filtros.id);
        resolve({
          status: 200,
          msg: 'Raspadinha recuperada para esse usuário.',
          data: raspadinhaAlternativa,
        });
      } else {
        resolve({
          status: 404,
          msg: 'Não há raspadinhas para esse consumidor.',
          data: null,
        });
      }
    });
  },

  obterPremios({ commit }, { campanha }) {
    return new Promise(async resolve => {
      const api = new Vue.prototype.$httpClient();
      const url = `/raspadinhaAlternativa/v1/tabelaPremio/${campanha.ano}/${campanha.identificacao}`;
      const resposta = await api.send(url, 'GET', null, undefined, false);
      if ([200, 201].includes(resposta.status)) {
        commit('atualizarPremios', resposta.data.retorno);
        resolve({ status: 200, msg: 'Prêmios de raspadinha recuperados.' });
      } else {
        commit('atualizarPremios', []);
        resolve({
          status: resposta.status,
          msg: resposta.data.mensagem || 'Ocorreu um erro desconhecido durante sua requisição.',
        });
      }
    });
  },

  fetchRaspadinhasAltConfig({ commit }, { campaign }) {
    return new Promise(async resolve => {
      const api = new Vue.prototype.$httpClient();
      const url = `raspadinhaAlternativa/v1/configuracoes/${campaign.ano}/${campaign.identificacao}`;
      const response = await api.send(url, 'GET', null, undefined, false);

      if (response.data.status == 200) {
        commit('setRaspadinhasAltConfig', response.data.retorno);
        resolve({
          status: 200,
          data: response.data,
        });
      } else {
        commit('setRaspadinhasAltConfig', {});
        resolve({
          status: response.data.status,
          mensagem: response.data.mensagem,
        });
      }
    });
  },

  raspar({ commit }, id) {
    return new Promise(async resolve => {
      const api = new Vue.prototype.$httpClient();
      const url = `/raspadinhaAlternativa/v1/raspadinhas/raspar/${id}`;
      const resposta = await api.send(url, 'GET', null, undefined, false);
      if ([200, 201].includes(resposta.status)) {
        commit('atualizarRaspadinhaAtual', resposta.data.retorno);
        resolve({
          status: 200,
          msg: 'Raspadinha raspada com sucesso.',
          resposta: resposta.data,
        });
      } else {
        resolve({
          status: resposta.status,
          msg: resposta.data.mensagem || 'Ocorreu um erro desconhecido durante sua requisição.',
        });
      }
    });
  },

  entregarPremioOnline({ commit }, id) {
    return new Promise(async resolve => {
      commit('setLoader', true);
      const api = new Vue.prototype.$httpClient();
      const url = `/raspadinhaAlternativa/v1/picPay/${id}`;
      const resposta = await api.send(url, 'GET', null, undefined, false);
      if ([200, 201].includes(resposta.status)) {
        commit('atualizarRaspadinhaAtual', resposta.data.retorno);
        commit('setLoader', false);
        resolve({
          status: 200,
          msg: 'Prêmio entregue com sucesso!',
          resposta: resposta.data,
        });
      } else {
        commit('setLoader', false);
        resolve({
          status: resposta.status,
          msg: resposta.data.mensagem || 'Ocorreu um erro desconhecido durante sua requisição.',
        });
      }
    });
  },

  selecionarRaspadinha({ commit }, raspadinha) {
    commit('atualizarRaspadinhaAtual', { id: raspadinha.id });
  },

  obterQuantidadeRaspadinhasPeriodo({ commit }, { campanha, cpf }) {
    return new Promise(async resolve => {
      const api = new Vue.prototype.$httpClient();
      const url = `/raspadinhaAlternativa/v1/consumidor/raspadinhasPorPeriodo/${campanha.ano}/${campanha.identificacao}/${cpf}`;

      const response = await api.send(url, 'GET', null, undefined, false);

      if (response.status === 200) {
        commit('setRaspadinhasPorPeriodo', response.data.retorno);
        resolve({
          status: 200,
          msg: 'Quantidade raspadinhas por período obtido.',
        });
      } else {
        commit('setRaspadinhasPorPeriodo', null);
        resolve({
          status: response.status || 500,
          msg: `Erro ao obter quantidade de raspadinhas por período: ${response.data.mensagem || 'erro interno'}`,
        });
      }
    });
  },
};

// Mutations
const mutations = {
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach(key => {
      state[key] = newState[key];
    });
  },

  setRaspadinhasPorPeriodo(state, data) {
    state.raspadinhasPorPeriodo = data;
  },

  atualizarPremios(state, data) {
    state.premios = data;
  },

  setRaspadinhasAltConfig(state, data) {
    state.raspadinhasAltConfig = data;
  },

  atualizarRaspadinhas(state, data) {
    state.raspadinhas = data;
  },

  atualizarRaspadinhaAtual(state, data) {
    state.raspadinhaAtual = data;
  },

  setLoader(state, data) {
    state.carregando = data;
  },

  definirPremiacoesInstantaneas(estado, dados) {
    estado.premiacoesInstantaneas = dados;
  },
};

// Module exports
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
