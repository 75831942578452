import { ConfigurationOptions } from 'aws-sdk'
import Vue from 'vue'
import { campaign as campaignStorage, campaign } from '../../lib/Storage'

// Initial state
const initialState = () => ({
  campaign: null,
  closedCampaign: null
})

// State object
const state = initialState()

// Getters
const getters = {
  getCampaign(state) {
    return state.campaign
  },
  getClosedCampaign(state) {
    return state.closedCampaign
  }
}

// Actions
const actions = {
  clearState({ commit }) {
    commit('RESET')
  },

  // current date deve ser uma string no formato 'YYYY-MM-DD hh:mm:ss'(implementações disso a partir do moment.js)
  fetchCampaign({ commit }, { currentDate, wsUrl = false }) {
    return new Promise(async resolve => {
      const api = new Vue.prototype.$httpClient()
      const response = await api.send(
        `${wsUrl ? wsUrl : ''}/campanha/v1/campanhas/`,
        'GET',
        null,
        undefined,
        true
      )

      if ([200].includes(response.status)) {
        const foundCampaigns = response.data.retorno
        let recoveredCampaign, recoveredClosedCampaign

        // Filtrando campanhas
        foundCampaigns
          .filter(camp => {
            let initial = `${camp.dataInicial} 00:00:00`
            let final = `${camp.dataFinal} 23:59:00`
            return camp.ativa && currentDate >= initial && currentDate <= final
          })
          .forEach(camp => {
            recoveredCampaign = JSON.parse(JSON.stringify(camp))
          })

        // Filtrando campanhas encerradas, porém ativas
        foundCampaigns
          .filter(camp => {
            let final = `${camp.dataFinal} 23:59:00`
            return camp.ativa && currentDate > final
          })
          .forEach(camp => {
            recoveredClosedCampaign = JSON.parse(JSON.stringify(camp))
          })

        // Verificando se alguma campanha ativa foi encontrada
        if (recoveredCampaign) {
          commit('setCampaign', recoveredCampaign)
          campaignStorage.saveCampaign(JSON.stringify(recoveredCampaign))
          resolve({
            status: 200,
            msg: 'Campanha promocional encontrada'
          })
        } else if (recoveredClosedCampaign) {
          // Verificando se alguma campanha encerrada foi encontrada

          commit('setClosedCampaign', recoveredClosedCampaign)
          //salvando campanha encerrada, para que ConfigurationOptions, etc, possam funcionar
          commit('setCampaign', recoveredClosedCampaign)
          campaignStorage.saveCampaign(JSON.stringify(recoveredClosedCampaign))

          localStorage.setItem(
            'ClosedCampaign',
            JSON.stringify(recoveredClosedCampaign)
          )
          resolve({
            status: 200,
            msg: 'Campanhas promocionais encerradas (porém ativas) encontradas.'
          })
        } else {
          resolve({
            status: 404,
            msg: 'Não há campanhas promocionais disponíveis no momento!'
          })
        }
      } else {
        resolve({
          status: response.status || 500,
          msg:
            response.data.mensagem ||
            'Você pode estar off-line. Tente novamente mais tarde.'
        })
      }
    })
  },

  consultaCpfRestrito({ commit }, { cpf, campanha, wsUrl = false }) {
    return new Promise(async resolve => {
      const api = new Vue.prototype.$httpClient()
      
      const res = await api.send(
        `${wsUrl ? wsUrl : ''}/campanha/v1/cpfsrestritos/${campanha.ano.toString()}/${campanha.identificacao}/consumidor/${cpf}`,
        'POST',
        {
          auth_user: btoa(cpf)
        },
        undefined,
        true
      )
      if ([200].includes(res.status)) {
        if (
          res.data.retorno.restricoes &&
          res.data.retorno.restricoes.length > 0 &&
          res.data.retorno.restricoes.some(e => e.proprietario == false && e.cnpjCpfAssociado == null && e.ativo == true)
        ) {
          resolve({
            status: 403,
            retorno: res.data.retorno,
            msg: 'Usuario Não Autorizado'
          })
        } else {
          resolve({
            status: 200,
            retorno: res.data.retorno,
            msg: 'Usuario Autorizado'
          })
        }
      } else {
        resolve({
          status: res.status,
          retorno: res.data.retorno,
          msg: res.mensagem
        })
      }
    })
  },

  setStorageCampaign({ commit }, { campaign }) {
    commit('setCampaign', campaign)
  },

  setStorageClosedCampaign({ commit }, { campaign }) {
    commit('setClosedCampaign', campaign)
  }
}

// Mutations
const mutations = {
  RESET(state) {
    const newState = initialState()
    Object.keys(newState).forEach(key => {
      state[key] = newState[key]
    })
  },
  setCampaign(state, data) {
    state.campaign = data
  },
  setClosedCampaign(state, data) {
    state.closedCampaign = data
  }
}

// Module exports
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
