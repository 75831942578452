// Initial state
const initialState = () => ({
	showIntro: false,
	openWrapper: false,
	finishSignInModal: false,
	backgroundLock: false,
	settingsOpened: false,
	notificationsEnabled: true,
	transition: 'slide-left'
})

// State object
const state = initialState()

// Getters
const getters = {
	getCampaignAnimationState(state) {
		return state.showIntro
	},
	getOpenWrapper(state) {
		return state.openWrapper
	},
	getFinishSignInModalState(state) {
		return state.finishSignInModal
	},
	getSettingsOpenedState(state) {
		return state.settingsOpened
	},
	getBackgroundLockState(state) {
		return state.backgroundLock
	},
	getNotificationStatus(state) {
		return state.notificationsEnabled
	},
	getTransition() {
		return state.transition
	}
}

// Actions
const actions = {
	clearState({ commit }) { commit('RESET') },
	campaignAnimationState({ commit }, value) {
		commit('setIntroAnimationState', value)
	},
	handleMenuSlide(_, id) {
		const el = document.getElementById('menu-slide')
		const itemPosicao = document.getElementById(id)
		const sW = window.screen.width
		const offset = (itemPosicao.offsetLeft - sW/2 + itemPosicao.offsetWidth/2)
		el.scrollLeft = offset
	},
	openWrapperState({ commit }, value) {
		commit('setOpenWrapper', value)
	},
	openFinishSignInModal({ commit }, value) {
		commit('setFinishSignInModalState', value)
	},
	switchNotificationStatus({ commit }, value) {
		commit('setNotificationStatus', value || false)
	},
	switchSettingsMenuState({ commit }, value) {
		commit('setSettingsOpenedState', value)
		if (value) commit('setBackgroundLock', true)
		else commit('setBackgroundLock', false)
	},
	commitTransition({ commit }, value) {
		commit('setTransition', value)
	}
}

// Mutations
const mutations = {
	RESET(state) {
		const newState = initialState();
		Object.keys(newState).forEach(key => {
			state[key] = newState[key]
		})
	},
	setIntroAnimationState(state, data) {
		state.showIntro = data
	},
	setOpenWrapper(state, data) {
		state.openWrapper = data
	},
	setFinishSignInModalState(state, data) {
		state.finishSignInModal = data
	},
	setBackgroundLock(state, data) {
		state.backgroundLock = data
	},
	setSettingsOpenedState(state, data) {
		state.settingsOpened = data
	},
	setNotificationStatus(state, data) {
		state.notificationsEnabled = data
	},
	setTransition(state, data) {
		state.transition = data
	}
}

// Module exports
export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}