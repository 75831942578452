import Vue from 'vue'

// Initial state
const initialState = () => ({
	scratches: []
})

// State object
const state = initialState()

// Getters
const getters = {
	getScratches(state) {
		return state.scratches
	}
}

// Actions
const actions = {
	clearState({ commit }) { commit('RESET') },
	fetchScratches({ commit }) {
		return new Promise(async (resolve) => {
			const api = new Vue.prototype.$httpClient()
      const url = '/raspadinha/v1/raspadinhas/consumidor'
      const response = await api.send(url, 'GET', null, undefined, true)
      if (response.status == 200) {
				commit('setScratches', response.data.retorno)
				resolve({
					status: 200,
					msg: 'Raspadinhas recuperadas para esse usuário.'
				})
      } else {
				commit('setScratches', [])
				resolve({
					status: 404,
					msg: 'Não há raspadinhas para esse consumidor.'
				})
			}
		})
	}
}

// Mutations
const mutations = {
	RESET(state) {
		const newState = initialState();
		Object.keys(newState).forEach(key => {
			state[key] = newState[key]
		})
	},
	setScratches(state, data) {
		state.scratches = data
	}
}

// Module exports
export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}