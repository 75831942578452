<template>
  <carousel
    @page-change="handleChangePage"
    @transition-end="handleTransition"
    :paginationEnabled="false"
    :navigateTo="manualNav"
    :per-page="1"
    :minSwipeDistance="125"
    class="comEspacamentoLateral"
  >
    <!-- Slide vazio -->
    <slide v-if="currentPage && currentPage.anterior" data-index="0">
      <card-vazio />
    </slide>
    <!-- Slide principal -->
    <slide data-index="1" :class="{ 'remove-header-padding': removeHeaderPadding }">
      <slot></slot>
    </slide>
    <!-- Slide vazio -->
    <slide v-if="currentPage && currentPage.proximo" data-index="2">
      <card-vazio />
    </slide>
  </carousel>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'card-carousel',
  beforeMount() {
    if (!this.currentPage.anterior) this.carouselPage = 0;
  },
  computed: {
    ...mapGetters({
      config: 'config/getConfig',
      telasConfiguraveis: 'config/obterTelasConfiguraveis',
    }),
    currentPage() {
      const obj = this.telasConfiguraveis;
      const route = obj ? obj.find(o => o.destino == this.$route.name) : null;
      if (route) {
        return route;
      } else {
        return null;
      }
    },
    manualNav() {
      if (this.currentPage && !this.currentPage.anterior) {
        this.carouselPage = 0;
        return [0, false];
      } else {
        return [1, false];
      }
    },
  },
  data: () => ({
    carouselPage: 1,
  }),
  methods: {
    handleChangePage(page) {
      this.carouselPage = page;
    },
    handleTransition() {
      this.$emit('emmitEvent', {
        event: 'cardTransition',
        data: {
          page: this.carouselPage,
          origin: this.$route.name,
        },
      });
    },
  },
  props: ['removeHeaderPadding'],
};
</script>
