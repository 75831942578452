<template>
	<section class="base-pill-toggle-button"
		:class="[{ disabled }, classesArray]"
	>
		<label class="pill-toggle-switch" :class="toggleSize ? toggleSize : 'toggle-button-average'">
      <input type="checkbox" class="pill-toggle-checkbox" :disabled="disabled" @click="handleClick" :checked="checked"/>
      <span class="pill-toggle-thumb"></span>
    </label>
	</section>
</template>

<script>
export default {
	name: "BasePillToggleButton",
	props: {
		disabled: {
			type: Boolean,
			default: false,
			required: false,
		},
		active: {
			type: Boolean,
			default: false,
			required: false,
		},
		toggleSize: {
			type: String,
			default: 'Average',
			required: false
		},
		checked: {
			typr: Boolean,
			default: false,
			required: false
		}
	},
  	methods: {
		handleClick(event) {
			if (!this.disabled) {
				this.$emit('click', event)
			}
		}
	},
	computed: {
		registerIndicatorTypeClass() {
      return `base-toggle-button`
    },

    classesArray() {
      return [this.registerIndicatorTypeClass, { disabled: this.disabled }]
    },
  },
};
</script>

<style lang="scss" src="./style.scss"></style>
