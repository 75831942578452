// Dependencias
import Vue from 'vue';
import VueHead from 'vue-head';
import VueRouter from 'vue-router';
// import Autenticacao from '../views/Autenticacao.vue'
import Inicio from '@/views/Inicio';

// Libs
import { authentication } from '../lib/Authentication';
import { redirect } from '../lib/Storage';

// vue router
Vue.use(VueRouter);

// VueHead
Vue.use(VueHead);

// Deinição das rotas
const routes = [
  {
    path: '/',
    name: 'intro',
    component: Inicio,
    meta: {
      requiresAuth: false,
      showMenu: false,
      showUserbar: false,
      showOptions: false,
      associate: false,
      showTopLogo: false,
      showBottomLogo: false,
      redirecionarLoja: true,
    },
    children: [
      {
        path: '/login',
        name: 'login',
        component: () => import('../views/auth/TheLogin/index.vue'),
      },
      {
        path: '/cadastro',
        name: 'cadastro',
        component: () => import('../views/auth/TheCadastro/index.vue'),
      },
    ],
  },
  {
    path: '/inicio',
    name: 'Inicio',
    component: Inicio,
    meta: {
      requiresAuth: false,
      showMenu: false,
      associate: false,
      showUserbar: false,
      showOptions: false,
      associate: false,
      showTopLogo: false,
      showBottomLogo: false,
      redirecionarLoja: true,
    },
  },
  {
    // ❌ deletar (utilizada pelo logout)
    path: '/autenticacao',
    name: 'autenticacao',
    component: Inicio,
    meta: {
      requiresAuth: false,
      showMenu: false,
      associate: false,
      showUserbar: false,
      showOptions: false,
      associate: false,
      showTopLogo: false,
      showBottomLogo: false,
      redirecionarLoja: true,
    },
  },
  {
    // ❌ deletar
    path: '/cadastro',
    name: 'cadastro',
    component: Inicio,
    meta: {
      requiresAuth: false,
      showMenu: false,
      associate: false,
      showUserbar: false,
      showOptions: false,
      showTopLogo: false,
      showBottomLogo: false,
      redirecionarLoja: true,
    },
  },
  {
    // ❌ deletar
    path: '/esqueciSenha',
    name: 'esqueciSenha',
    component: Inicio,
    meta: {
      requiresAuth: false,
      showMenu: false,
      associate: false,
      showUserbar: false,
      showOptions: false,
      showTopLogo: false,
      showBottomLogo: false,
      redirecionarLoja: true,
    },
  },
  {
    path: '/redefinirSenha',
    name: 'redefinirSenha',
    component: () => import('../views/auth/TheRedefinirSenha/index.vue'),
    meta: {
      requiresAuth: false,
      showMenu: false,
      associate: false,
      showUserbar: false,
      showOptions: false,
      showTopLogo: true,
      showBottomLogo: false,
      redirecionarLoja: false,
    },
  },
  {
    path: '/verificarEmail',
    name: 'verificarEmail',
    component: () => import('../views/ConfirmarEmail.vue'),
    meta: {
      requiresAuth: true,
      showMenu: false,
      associate: false,
      showUserbar: true,
      showOptions: true,
      showTopLogo: false,
      showBottomLogo: false,
      redirecionarLoja: false,
    },
  },
  {
    path: '/retornoCrawler',
    name: 'retornoCrawler',
    component: () => import('../views/RetornoCrawlerWeb.vue'),
    meta: {
      requiresAuth: true,
      showMenu: false,
      associate: false,
      showUserbar: true,
      showOptions: true,
      showTopLogo: false,
      showBottomLogo: false,
      redirecionarLoja: true,
    },
  },
  {
    path: '/retornoCrawlerIOS',
    name: 'retornoCrawlerApple',
    component: () => import('../views/RetornoCrawlerApple.vue'),
    meta: {
      requiresAuth: true,
      showMenu: false,
      associate: false,
      showUserbar: false,
      showOptions: false,
      showTopLogo: false,
      showBottomLogo: false,
      redirecionarLoja: true,
    },
  },
  {
    path: '/regulamento',
    name: 'regulamento',
    component: () => import('../views/Regulamento.vue'),
    meta: {
      requiresAuth: false,
      associate: false,
      showMenu: false,
      showUserbar: false,
      showOptions: false,
      showTopLogo: true,
      showBottomLogo: false,
      redirecionarLoja: true,
    },
  },
  {
    path: '/aceites',
    name: 'aceites',
    component: Inicio,
    meta: {
      requiresAuth: false,
      associate: false,
      showMenu: false,
      showUserbar: false,
      showOptions: false,
      showTopLogo: true,
      showBottomLogo: false,
      redirecionarLoja: true,
    },
  },
  {
    path: '/campanha',
    name: 'campanha',
    component: () => import('../views/Campanha.vue'),
    meta: {
      requiresAuth: true,
      associate: false,
      showMenu: true,
      showUserbar: true,
      showOptions: true,
      showTopLogo: false,
      showBottomLogo: false,
      redirecionarLoja: true,
    },
  },
  {
    path: '/ajuda',
    name: 'ajuda',
    component: () => import('../views/Ajuda.vue'),
    meta: {
      requiresAuth: true,
      associate: false,
      showMenu: true,
      showUserbar: true,
      showOptions: true,
      showTopLogo: false,
      showBottomLogo: false,
      redirecionarLoja: true,
    },
  },
  {
    path: '/faq',
    name: 'faq',
    component: () => import('../views/Faq.vue'),
    meta: {
      requiresAuth: true,
      associate: false,
      showMenu: false,
      showUserbar: false,
      showOptions: false,
      showTopLogo: false,
      showBottomLogo: false,
      redirecionarLoja: true,
    },
  },
  {
    path: '/docFiscaisPendentes',
    name: 'docFiscaisPendentes',
    component: () => import('../views/DocFiscaisPendentes.vue'),
    meta: {
      requiresAuth: true,
      associate: false,
      showMenu: true,
      showUserbar: true,
      showOptions: true,
      showTopLogo: false,
      showBottomLogo: false,
      redirecionarLoja: true,
    },
  },
  {
    path: '/documentosFiscais',
    name: 'documentosFiscais',
    component: () => import('../views/DocumentosFiscais.vue'),
    meta: {
      requiresAuth: true,
      associate: false,
      showMenu: true,
      showUserbar: true,
      showOptions: true,
      showTopLogo: false,
      showBottomLogo: false,
      redirecionarLoja: true,
    },
  },
  {
    path: '/acompanharPedidos',
    name: 'acompanharPedidos',
    component: () => import('../views/AcompanharPedidos.vue'),
    meta: {
      requiresAuth: true,
      associate: false,
      showMenu: true,
      showUserbar: true,
      showOptions: true,
      showTopLogo: false,
      showBottomLogo: false,
      redirecionarLoja: true,
    },
  },
  {
    path: '/sobre',
    name: 'sobre',
    component: () => import('../views/Sobre.vue'),
    meta: {
      requiresAuth: true,
      associate: false,
      showMenu: true,
      showUserbar: true,
      showOptions: true,
      showTopLogo: false,
      showBottomLogo: false,
      redirecionarLoja: true,
    },
  },
  {
    path: '/telaInicial',
    name: 'telaInicial',
    component: () => import('../views/TelaInicial.vue'),
    meta: {
      requiresAuth: true,
      associate: false,
      showMenu: true,
      showUserbar: true,
      showOptions: true,
      showTopLogo: false,
      showBottomLogo: false,
      redirecionarLoja: true,
    },
  },
  {
    path: '/girosDaSorte',
    name: 'girosDaSorte',
    component: () => import('../views/GirosDaSorte.vue'),
    meta: {
      requiresAuth: true,
      associate: false,
      showMenu: true,
      showUserbar: true,
      showOptions: true,
      showTopLogo: false,
      showBottomLogo: false,
      redirecionarLoja: true,
    },
  },
  {
    path: '/amigos',
    name: 'amigos',
    component: () => import('../views/Amigos.vue'),
    meta: {
      requiresAuth: true,
      associate: false,
      showMenu: true,
      showUserbar: true,
      showOptions: true,
      showTopLogo: false,
      showBottomLogo: false,
      redirecionarLoja: true,
    },
  },
  {
    path: '/contaCorrente',
    name: 'contaCorrente',
    component: () => import('../views/ContaCorrente.vue'),
    meta: {
      requiresAuth: true,
      associate: false,
      showMenu: true,
      showUserbar: true,
      showOptions: true,
      showTopLogo: false,
      showBottomLogo: false,
      redirecionarLoja: true,
    },
  },
  {
    path: '/metas',
    name: 'metas',
    component: () => import('../views/Metas.vue'),
    meta: {
      requiresAuth: true,
      associate: false,
      showMenu: true,
      showUserbar: true,
      showOptions: true,
      showTopLogo: false,
      showBottomLogo: false,
      redirecionarLoja: true,
    },
  },
  {
    path: '/cupons',
    name: 'cupons',
    component: () => import('../views/Cupons.vue'),
    meta: {
      requiresAuth: true,
      associate: false,
      showMenu: true,
      showUserbar: true,
      showOptions: true,
      showTopLogo: false,
      showBottomLogo: false,
      redirecionarLoja: true,
    },
  },
  {
    path: '/cuponsNovo',
    name: 'cuponsNovo',
    component: () => import('../views/CuponsNew.vue'),
    meta: {
      requiresAuth: true,
      associate: false,
      showMenu: true,
      showUserbar: true,
      showOptions: true,
      showTopLogo: false,
      showBottomLogo: false,
      redirecionarLoja: true,
    },
  },
  {
    path: '/compraAprovada',
    name: 'compraAprovada',
    component: () => import('../views/CuponsNew.vue'),
    meta: {
      requiresAuth: true,
      associate: false,
      showMenu: true,
      showUserbar: true,
      showOptions: true,
      showTopLogo: false,
      showBottomLogo: false,
      redirecionarLoja: true,
    },
  },
  {
    path: '/raspadinhasAlternativas',
    name: 'raspadinhasAlternativas',
    component: () => import('../views/RaspadinhasAlternativas.vue'),
    meta: {
      requiresAuth: true,
      associate: false,
      showMenu: true,
      showUserbar: true,
      showOptions: true,
      showTopLogo: false,
      showBottomLogo: false,
      redirecionarLoja: true,
    },
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/TelaTemporaria.vue'),
    meta: {
      requiresAuth: true,
      associate: false,
      showMenu: true,
      showUserbar: true,
      showOptions: true,
      showTopLogo: false,
      showBottomLogo: false,
      redirecionarLoja: true,
    },
  },
  {
    path: '/lojasCredenciadas',
    name: 'lojasCredenciadas',
    component: () => import('../views/LojasCredenciadas.vue'),
    meta: {
      requiresAuth: true,
      associate: false,
      showMenu: true,
      showUserbar: true,
      showOptions: true,
      showTopLogo: false,
      showBottomLogo: false,
      redirecionarLoja: true,
    },
  },
  {
    path: '/pinturasAlternativas',
    name: 'pinturasAlternativas',
    component: () => import('../views/RaspadinhasAlternativas.vue'),
    meta: {
      requiresAuth: true,
      associate: false,
      showMenu: true,
      showUserbar: true,
      showOptions: true,
      showTopLogo: false,
      showBottomLogo: false,
      redirecionarLoja: true,
    },
  },
  {
    path: '/saldoCashback',
    name: 'saldoCashback',
    component: () => import('../views/ExtratoCashback.vue'),
    meta: {
      requiresAuth: true,
      associate: false,
      showMenu: true,
      showUserbar: true,
      showOptions: true,
      showTopLogo: false,
      showBottomLogo: false,
      redirecionarLoja: true,
    },
  },
  {
    path: '/extratoCashback',
    name: 'extratoCashback',
    component: () => import('../views/ExtratoCashback.vue'),
    meta: {
      requiresAuth: true,
      associate: false,
      showMenu: true,
      showUserbar: true,
      showOptions: true,
      showTopLogo: false,
      showBottomLogo: false,
      redirecionarLoja: true,
    },
  },
  {
    path: '/metasCashback',
    name: 'metasCashback',
    component: () => import('../views/MetasCashback.vue'),
    meta: {
      requiresAuth: true,
      associate: false,
      showMenu: true,
      showUserbar: true,
      showOptions: true,
      showTopLogo: false,
      showBottomLogo: false,
      redirecionarLoja: true,
    },
  },
  {
    path: '/historicoCompras',
    name: 'historicoCompras',
    component: () => import('../views/HistoricoCompras.vue'),
    meta: {
      requiresAuth: true,
      associate: false,
      showMenu: false,
      showUserbar: true,
      showOptions: true,
      showTopLogo: false,
      showBottomLogo: false,
      redirecionarLoja: true,
    },
  },
  {
    path: '/detalhesCompra',
    name: 'detalhesCompra',
    component: () => import('../views/DetalhesCompra.vue'),
    meta: {
      requiresAuth: true,
      associate: false,
      showMenu: false,
      showUserbar: true,
      showOptions: true,
      showTopLogo: false,
      showBottomLogo: false,
      redirecionarLoja: true,
    },
  },
  {
    path: '/detalhamentoExtrato',
    name: 'detalhamentoExtrato',
    component: () => import('../views/DetalhamentoExtrato.vue'),
    meta: {
      requiresAuth: true,
      associate: false,
      showMenu: false,
      showUserbar: true,
      showOptions: true,
      showTopLogo: false,
      showBottomLogo: false,
      redirecionarLoja: true,
    },
  },
  {
    path: '/detalhamentoExtratoDebito',
    name: 'detalhamentoExtratoDebito',
    component: () => import('../views/DetalhamentoExtratoDebito.vue'),
    meta: {
      requiresAuth: true,
      associate: false,
      showMenu: false,
      showUserbar: true,
      showOptions: true,
      showTopLogo: false,
      showBottomLogo: false,
      redirecionarLoja: true,
    },
  },
  {
    path: '/raspar',
    name: 'raspar',
    component: () => import('../views/Raspar.vue'),
    meta: {
      requiresAuth: true,
      associate: false,
      showMenu: false,
      showUserbar: false,
      showOptions: false,
      showTopLogo: false,
      showBottomLogo: false,
      redirecionarLoja: true,
    },
  },
  {
    path: '/rasparAlternativo',
    name: 'rasparAlternativo',
    component: () => import('../views/Raspar.vue'),
    meta: {
      requiresAuth: false,
      associate: false,
      showMenu: false,
      showUserbar: false,
      showOptions: false,
      showTopLogo: false,
      showBottomLogo: false,
      redirecionarLoja: false,
      embedded: true,
    },
  },
  {
    path: '/rasparPremiacaoInstantaneaAlternativo',
    name: 'rasparPremiacaoInstantaneaAlternativo',
    component: () => import('../views/PremiacaoInstantanea.vue'),
    meta: {
      requiresAuth: false,
      associate: false,
      showMenu: false,
      showUserbar: false,
      showOptions: false,
      showTopLogo: false,
      showBottomLogo: false,
      redirecionarLoja: false,
      embedded: true,
    },
  },
  {
    path: '/pintar',
    name: 'pintar',
    component: () => import('../views/Raspar.vue'),
    meta: {
      requiresAuth: true,
      associate: false,
      showMenu: false,
      showUserbar: false,
      showOptions: false,
      showTopLogo: false,
      showBottomLogo: false,
      redirecionarLoja: true,
    },
  },
  {
    path: '/associados',
    name: 'associados',
    component: () => import('../views/Associados.vue'),
    meta: {
      requiresAuth: true,
      associate: false,
      showMenu: true,
      showUserbar: true,
      showOptions: true,
      showTopLogo: false,
      showBottomLogo: false,
      redirecionarLoja: true,
    },
  },
  {
    path: '/fidelidade',
    name: 'fidelidade',
    component: () => import('../views/Fidelidade.vue'),
    meta: {
      requiresAuth: true,
      associate: false,
      showMenu: true,
      showUserbar: true,
      showOptions: true,
      showTopLogo: false,
      showBottomLogo: false,
      redirecionarLoja: true,
    },
  },
  {
    path: '/ofertas',
    name: 'ofertas',
    component: () => import('../views/Ofertas.vue'),
    meta: {
      requiresAuth: true,
      associate: false,
      showMenu: true,
      showUserbar: true,
      showOptions: true,
      showTopLogo: false,
      showBottomLogo: false,
      redirecionarLoja: true,
    },
  },
  {
    path: '/cadastroManual',
    name: 'cadastroManual',
    component: () => import('../views/CadastrarManual.vue'),
    meta: {
      requiresAuth: true,
      associate: false,
      showMenu: false,
      showUserbar: false,
      showOptions: false,
      showTopLogo: false,
      showBottomLogo: false,
      redirecionarLoja: true,
    },
  },
  {
    path: '/lancamentoManual',
    name: 'lancamentoManual',
    component: () => import('../views/LancamentoManual.vue'),
    meta: {
      requiresAuth: true,
      associate: false,
      showMenu: false,
      showUserbar: true,
      showOptions: true,
      showTopLogo: false,
      showBottomLogo: false,
      redirecionarLoja: true,
    },
  },
  {
    path: '/cadastroCompra',
    name: 'cadastroCompra',
    component: () => import('../views/CadastroCompra.vue'),
    meta: {
      requiresAuth: true,
      associate: false,
      showMenu: false,
      showUserbar: true,
      showOptions: true,
      showTopLogo: false,
      showBottomLogo: false,
      redirecionarLoja: true,
    },
  },
  {
    path: '/digitacaoManual',
    name: 'digitacaoManual',
    component: () => import('../views/DigitacaoManual.vue'),
    meta: {
      requiresAuth: true,
      associate: false,
      showMenu: false,
      showUserbar: true,
      showOptions: false,
      showTopLogo: false,
      showBottomLogo: false,
      redirecionarLoja: true,
    },
  },
  {
    path: '/cadastrarCupom',
    name: 'cadastrarCupom',
    component: () => import('../views/CadastrarCupom.vue'),
    meta: {
      requiresAuth: true,
      associate: false,
      showMenu: true,
      showUserbar: true,
      showOptions: true,
      showTopLogo: false,
      showBottomLogo: false,
      redirecionarLoja: true,
    },
  },
  {
    path: '/quiz',
    name: 'quiz',
    component: () => import('../views/Quiz.vue'),
    meta: {
      requiresAuth: true,
      associate: false,
      showMenu: false,
      showUserbar: false,
      showOptions: false,
      showTopLogo: false,
      showBottomLogo: false,
      redirecionarLoja: true,
    },
  },
  {
    path: '/escanearComper',
    name: 'escanearCupomComper',
    component: () => import('../views/EscanearComper.vue'),
    meta: {
      requiresAuth: true,
      associate: false,
      showMenu: false,
      showUserbar: true,
      showOptions: false,
      showTopLogo: false,
      showBottomLogo: false,
      redirecionarLoja: true,
    },
  },
  {
    path: '/escanear',
    name: 'escanear',
    component: () => import('../views/Escanear.vue'),
    meta: {
      requiresAuth: true,
      associate: false,
      showMenu: false,
      showUserbar: true,
      showOptions: false,
      showTopLogo: false,
      showBottomLogo: false,
      redirecionarLoja: true,
    },
  },
  {
    path: '/*-*-*',
    name: 'escanearDinamico',
    component: () => import('../views/Escanear.vue'),
    meta: {
      requiresAuth: true,
      associate: false,
      showMenu: false,
      showUserbar: false,
      showOptions: false,
      showTopLogo: false,
      showBottomLogo: false,
      redirecionarLoja: true,
    },
  },
  {
    path: '/termos',
    name: 'termos',
    component: () => import('../views/Termos.vue'),
    meta: {
      requiresAuth: false,
      associate: false,
      showMenu: false,
      showUserbar: false,
      showOptions: false,
      showTopLogo: true,
      showBottomLogo: true,
      redirecionarLoja: true,
    },
  },
  {
    path: '/politicaPrivacidade',
    name: 'politicaPrivacidade',
    component: () => import('../views/PoliticaPrivacidade.vue'),
    meta: {
      requiresAuth: false,
      associate: false,
      showMenu: false,
      showUserbar: false,
      showOptions: false,
      showTopLogo: true,
      showBottomLogo: true,
      redirecionarLoja: true,
    },
  },
  {
    path: '/politicaPrivacidadePersonalizada',
    name: 'politicaPrivacidadePersonalizada',
    component: () => import('../views/politicaPrivacidadePersonalizada.vue'),
    meta: {
      requiresAuth: false,
      associate: false,
      showMenu: false,
      showUserbar: false,
      showOptions: false,
      showTopLogo: true,
      showBottomLogo: false,
      redirecionarLoja: true,
    },
  },
  {
    path: '/avisoPrivacidade',
    name: 'avisoPrivacidade',
    component: () => import('../views/AvisoPrivacidade.vue'),
    meta: {
      requiresAuth: false,
      associate: false,
      showMenu: false,
      showUserbar: false,
      showOptions: false,
      showTopLogo: true,
      showBottomLogo: false,
      redirecionarLoja: true,
    },
  },
  {
    path: '/lgpd',
    name: 'lgpd',
    component: () => import('../views/Lgpd.vue'),
    meta: {
      requiresAuth: false,
      associate: false,
      showMenu: false,
      showUserbar: false,
      showOptions: false,
      showTopLogo: true,
      showBottomLogo: true,
      redirecionarLoja: true,
    },
  },
  {
    path: '/lgpdDetalhada',
    name: 'lgpdDetalhada',
    component: () => import('../views/LgpdDetalhada.vue'),
    meta: {
      requiresAuth: false,
      associate: false,
      showMenu: false,
      showUserbar: false,
      showOptions: false,
      showTopLogo: true,
      showBottomLogo: true,
      redirecionarLoja: true,
    },
  },
  {
    path: '/preRegistro',
    name: 'PreRegistro',
    component: () => import('../views/PreRegistro.vue'),
    meta: {
      requiresAuth: false,
      associate: false,
      showMenu: false,
      showUserbar: false,
      showOptions: false,
      showTopLogo: true,
      showBottomLogo: false,
      redirecionarLoja: false,
    },
  },
  {
    path: '/totalPontos',
    name: 'TotalPontos',
    component: () => import('../views/TotalPontos.vue'),
    meta: {
      requiresAuth: false,
      associate: false,
      showMenu: false,
      showUserbar: false,
      showOptions: false,
      showTopLogo: true,
      showBottomLogo: false,
      redirecionarLoja: false,
    },
  },
  {
    path: '/primeiroAcesso',
    name: 'PrimeiroAcesso',
    component: () => import('../views/PrimeiroAcesso.vue'),
    meta: {
      requiresAuth: false,
      associate: false,
      showMenu: false,
      showUserbar: false,
      showOptions: false,
      showTopLogo: true,
      showBottomLogo: false,
      redirecionarLoja: false,
    },
  },
  {
    path: '/chatSuporte',
    name: 'chatSuporte',
    component: () => import('../views/ChatSuporte.vue'),
    meta: {
      requiresAuth: false,
      associate: false,
      showMenu: false,
      showUserbar: false,
      showOptions: false,
      showTopLogo: true,
      showBottomLogo: false,
      redirecionarLoja: false,
    },
  },
  {
    path: '/produtos',
    name: 'produtos',
    component: () => import('../views/Produtos.vue'),
    meta: {
      requiresAuth: true,
      associate: false,
      showMenu: true,
      showUserbar: true,
      showOptions: true,
      showTopLogo: false,
      showBottomLogo: false,
      redirecionarLoja: true,
    },
  },
  {
    path: '/indicacao',
    name: 'indicacao',
    component: () => import('../views/Indicacao.vue'),
    meta: {
      requiresAuth: true,
      associate: false,
      showMenu: true,
      showUserbar: true,
      showOptions: true,
      showTopLogo: false,
      showBottomLogo: false,
      redirecionarLoja: true,
    },
  },
  {
    path: '/vitrineProdutos',
    name: 'vitrineProdutos',
    component: () => import('../views/VitrineDeProdutos2.vue'),
    meta: {
      requiresAuth: true,
      associate: false,
      showMenu: true,
      showUserbar: true,
      showOptions: true,
      showTopLogo: false,
      showBottomLogo: false,
      redirecionarLoja: true,
    },
  },
  {
    path: '/controleProdutos',
    name: 'controleProdutos',
    component: () => import('../views/ControleProdutos.vue'),
    meta: {
      requiresAuth: true,
      associate: false,
      showMenu: true,
      showUserbar: true,
      showOptions: true,
      showTopLogo: false,
      showBottomLogo: false,
      redirecionarLoja: true,
    },
  },
  {
    path: '/premiacaoInstantanea',
    name: 'premiacaoInstantanea',
    component: () => import('../views/PremiacaoInstantanea.vue'),
    meta: {
      requiresAuth: true,
      associate: false,
      showMenu: false,
      showUserbar: false,
      showOptions: false,
      showTopLogo: false,
      showBottomLogo: false,
      redirecionarLoja: true,
    },
  },
  {
    path: '/obterCodigoTemporario',
    name: 'obterCodigoTemporario',
    component: () => import('../views/auth/TheObterCodigo/index.vue'),
    meta: {
      requiresAuth: true,
      associate: false,
      showMenu: true,
      showUserbar: true,
      showOptions: true,
      showTopLogo: false,
      showBottomLogo: false,
      redirecionarLoja: true,
    },
  },
  {
    path: '/homeProduto',
    name: 'homeProduto',
    component: () => import('../views/HomeProduto.vue'),
    meta: {
      requiresAuth: true,
      associate: false,
      showMenu: true,
      showUserbar: true,
      showOptions: true,
      showTopLogo: false,
      showBottomLogo: false,
      redirecionarLoja: true,
    },
  },
  {
    path: '/filtrosProduto',
    name: 'filtrosProduto',
    component: () => import('../views/FiltrosProduto.vue'),
    meta: {
      requiresAuth: true,
      associate: false,
      showMenu: true,
      showUserbar: true,
      showOptions: true,
      showTopLogo: false,
      showBottomLogo: false,
      redirecionarLoja: true,
    },
  },
  {
    path: '/detalhesProduto',
    name: 'detalhesProduto',
    component: () => import('../views/DetalhesProduto.vue'),
    meta: {
      requiresAuth: true,
      associate: false,
      showMenu: true,
      showUserbar: true,
      showOptions: true,
      showTopLogo: false,
      showBottomLogo: false,
      redirecionarLoja: true,
    },
  },
  {
    path: '/totem',
    name: 'totem',
    component: () => import('../views/Totem.vue'),
    meta: {
      requiresAuth: false,
      associate: false,
      showMenu: true,
      showUserbar: true,
      showOptions: true,
      showTopLogo: false,
      showBottomLogo: false,
      redirecionarLoja: true,
    },
  },
  {
    path: '/*',
    name: 'pageNotFound',
    component: () => import('../views/PaginaNaoEncontrada.vue'),
    meta: {
      requiresAuth: false,
      showMenu: false,
      showUserbar: false,
      showTopLogo: true,
    },
  },
];

// Criação do router
const router = new VueRouter({
  mode: typeof cordova != 'undefined' ? 'hash' : 'history',
  base: process.env.BASE_URL,
  routes,
});

// Verificação pré rota
router.beforeEach((to, _, next) => {
  if (to.matched.some((record) => record.meta.redirecionarLoja)) {
    const config = Vue.prototype.$storeConfig.config();

    if (config.REDIRECIONAR_LOJA_APP) {
      const pwa = config.APP_URLS.filter((e) => e.tipo == 'pwa');
      const phonegap = config.APP_URLS.filter((e) => e.tipo == 'phonegap');
      const mobile = [/Android/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i];
      const android = [/Android/i];
      const ios = [/iPhone/i, /iPad/i, /iPod/i];

      // 1) Verificando se o cliente tem pwa e compilado
      if (pwa.length !== 0 && phonegap.length !== 0) {
        const mobileBrowser = mobile.some((u) => navigator.userAgent.match(u));
        const notApp = location.protocol.includes('http');
        // 2) Verificando se o está sendo executado pelo celular e está no navegador

        if (mobileBrowser && notApp) {
          if (config.URL_LOJAS) {
            const isAndroid = android.some((u) => navigator.userAgent.match(u));
            const isIos = ios.some((u) => navigator.userAgent.match(u));
            // 3) Verificando se é Android ou iOS

            if (isAndroid) {
              let url_android = config.URL_LOJAS.filter((e) => e.loja == 'android').map((e) => e.url);

              window.location = url_android[0];
            } else if (isIos) {
              let url_ios = config.URL_LOJAS.filter((e) => e.loja == 'ios').map((e) => e.url);

              window.location = url_ios[0];
            }
          }
        }
      }
    }
  }

  const login = authentication.signedIn();
  // const config = storeConfig.config()
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // Requer autenticação
    if (!login.token) {
      // Informar aqui as rotas que serão redirecionadas automaticamente após o login do usuário no app
      if (to.name == 'escanearDinamico') redirect.addRedirectPath('cupomEscaneado', to.fullPath);
      if (to.name == 'verificarEmail') redirect.addRedirectPath('emailVerificado', to.fullPath);
      // Redirecionar para tela de login
      next({
        name: 'autenticacao',
      });
    } else {
      next();
    }
  } else {
    // Não requer autenticação
    next();
  }
});

// Exportando router
export default router;
