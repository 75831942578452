<template>
  <div class="logout-modal">
    <base-modal :show.sync="show" modal-classes="modal-dialog-top qr-modal" body-classes="p-0">
      <template slot="header">Sair</template>
      <template>
        <b-container>
          <div class="row">
            <div class="col-2"></div>
            <div class="col-8">
              <img src="@/assets/svgs/sadboy.svg" alt="Sadboy" />
            </div>
            <div class="col-2"></div>
          </div>
          <div class="row mb-4">
            <div class="col-12 mb-4 text-center">
              <p class="m-0 text-primary">
                <small>
                  <strong>Tem certeza?</strong>
                </small>
              </p>
            </div>
            <div class="col-5">
              <base-button type="primary" class="w-100 shadow" @click="exit">
                <small>
                  <strong>Voltar</strong>
                </small>
              </base-button>
            </div>
            <div class="col-2"></div>
            <div class="col-5">
              <base-button type="secondary" class="w-100 shadow" @click="logout">
                <small>
                  <strong>Sair</strong>
                </small>
              </base-button>
            </div>
          </div>
        </b-container>
      </template>
    </base-modal>
  </div>
</template>

<script>
export default {
  name: 'desconectar',
  data: () => ({
    show: false,
  }),
  props: {
    showModal: {
      type: Boolean,
      description: 'Modal display boolean',
      default: false,
    },
  },
  methods: {
    exit() {
      this.show = false;
    },
    logout() {
      this.show = false;
      setTimeout(() => {
        this.$emit('emmitEvent', { event: 'logout' });
      }, 300);
    },
  },
  watch: {
    show(val) {
      if (!val) {
        this.$emit('emmitEvent', {
          event: 'gtm',
          data: {
            event: 'logoutUsuario',
            category: 'Desconectar',
            action: 'form',
            label: 'Usuário fez logout',
          },
        });
        this.$emit('emmitModalEvent', {
          event: 'closeLogoutModal',
        });
      }
    },
    showModal(val) {
      if (val) this.show = true;
    },
  },
};
</script>
