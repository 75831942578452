// Base components
import BaseButton from '../components/theme/BaseButton';
import BaseCard from '../components/theme/BaseCard';
import BaseDropdown from '../components/theme/BaseDropdown';
import BaseHeader from '../components/theme/BaseHeader';
import BaseInput from '../components/theme/BaseInput';
import BaseList from '../components/theme/BaseList';
import BaseModal from '../components/theme/BaseModal';
import BasePagination from '../components/theme/BasePagination';
import BasePaginationV2 from '../components/theme/BasePaginationV2';
import BaseSelect from '../components/theme/BaseSelect';
import BaseSettings from '../components/theme/BaseSettings';
import BaseTextArea from '../components/theme/BaseTextArea';

// Pill components
import BasePillButton from '../components/theme/BasePillButton';
import BasePillMenuButton from '../components/theme/BasePillMenuButton';
import BasePillProduct from '../components/theme/BasePillProduct';
import BasePillModal from '../components/theme/BasePillModal';
import BasePillInput from '../components/theme/BasePillInput';
import BasePillNavBar from '../components/theme/BasePillNavBar';
import BasePillSelect from '../components/theme/BasePillSelect';
import BasePillToggleButton from '../components/theme/BasePillToggleButton';
import BasePillCheckBox from '../components/theme/BasePillCheckBox';
import BasePillBombInput from '../components/theme/BasePillBombInput';
import BasePillMiniCard from '../components/theme/BasePillMiniCard';
import BasePillBigSelectCard from '../components/theme/BasePillBigSelectCard';
import BasePillTemporaryCode from '../components/theme/BasePillTemporaryCode';
import BasePillModalV2 from '../components/theme/BasePillModalV2';
import BasePillSlider from '../components/theme/BasePillSlider';

// Layout components
import AppNotifications from '../components/layout/template/AppNotifications';
import Campaign from '../components/layout/template/Campaign';
import MainCard from '../components/layout/template/MainCard';
import Menu from '../components/layout/template/Menu';
import MenuItem from '../components/layout/template/MenuItem';
import NotificationItem from '../components/layout/template/NotificationItem';
import Options from '../components/layout/template/Options';
import Userbar from '../components/layout/template/Userbar';
import Wrapper from '../components/layout/template/Wrapper';
import WrapperItem from '../components/layout/template/WrapperItem';
import CardCarousel from '../components/layout/template/CardCarousel';
import CardVazio from '../components/layout/template/CardVazio';
import TheAppVersion from '@/components/layout/TheAppVersion';
import TheParticles from '@/components/layout/TheParticles';

// Pill layout components
import PillHeaderTitle from '@/components/layout/template/PillHeaderTitle';
import PillHeaderTitleV2 from '@/components/layout/template/PillHeaderTitleV2';

// Produto components
import BotaoCategoria from '../components/layout/produtos/BotaoCategoria';
import CardProduto from '../components/layout/produtos/CardProduto';

import CardPedido from '../components/layout/produtos/CardPedido';

export default {
  install(Vue) {
    // Base components
    Vue.component(BaseButton.name, BaseButton);
    Vue.component(BaseCard.name, BaseCard);
    Vue.component(BaseDropdown.name, BaseDropdown);
    Vue.component(BaseHeader.name, BaseHeader);
    Vue.component(BaseInput.name, BaseInput);
    Vue.component(BaseList.name, BaseList);
    Vue.component(BaseModal.name, BaseModal);
    Vue.component(BasePagination.name, BasePagination);
    Vue.component(BasePaginationV2.name, BasePaginationV2);
    Vue.component(BaseSelect.name, BaseSelect);
    Vue.component(BaseSettings.name, BaseSettings);
    Vue.component(BaseTextArea.name, BaseTextArea);
    // Pill components
    Vue.component(BasePillButton.name, BasePillButton);
    Vue.component(BasePillModal.name, BasePillModal);
    Vue.component(BasePillInput.name, BasePillInput);
    Vue.component(BasePillNavBar.name, BasePillNavBar);
    Vue.component(BasePillSelect.name, BasePillSelect);
    Vue.component(BasePillToggleButton.name, BasePillToggleButton);
    Vue.component(BasePillCheckBox.name, BasePillCheckBox);
    Vue.component(BasePillBombInput.name, BasePillBombInput);
    Vue.component(BasePillMiniCard.name, BasePillMiniCard);
    Vue.component(BasePillBigSelectCard.name, BasePillBigSelectCard);
    Vue.component(BasePillTemporaryCode.name, BasePillTemporaryCode);
    Vue.component(BasePillModalV2.name, BasePillModalV2);
    Vue.component(BasePillSlider.name, BasePillSlider);

    // Layout components
    Vue.component(AppNotifications.name, AppNotifications);
    Vue.component(Campaign.name, Campaign);
    Vue.component(MainCard.name, MainCard);
    Vue.component(Menu.name, Menu);
    Vue.component(MenuItem.name, MenuItem);
    Vue.component(NotificationItem.name, NotificationItem);
    Vue.component(Options.name, Options);
    Vue.component(Userbar.name, Userbar);
    Vue.component(Wrapper.name, Wrapper);
    Vue.component(WrapperItem.name, WrapperItem);
    Vue.component(CardCarousel.name, CardCarousel);
    Vue.component(CardVazio.name, CardVazio);
    Vue.component(TheAppVersion.name, TheAppVersion);
    Vue.component(TheParticles.name, TheParticles);

    // Pill layout components
    Vue.component(PillHeaderTitle.name, PillHeaderTitle);
    Vue.component(PillHeaderTitleV2.name, PillHeaderTitleV2);

    //componentes novos
    Vue.component(BasePillProduct.name, BasePillProduct);
    Vue.component(BasePillMenuButton.name, BasePillMenuButton);

    // Produto components
    Vue.component(BotaoCategoria.name, BotaoCategoria);
    Vue.component(CardProduto.name, CardProduto);
    Vue.component(CardPedido.name, CardPedido);
  },
};
