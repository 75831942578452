var render = function render(){var _vm=this,_c=_vm._self._c;return (!this.config.MENU_NOVO)?_c('a',{staticClass:"shadow",class:{
    'router-link-active': this.$route.name == _vm.destination,
    'font-weight-bold': _vm.navBold == true,
  },style:(`background-color: ${_vm.background};`),attrs:{"href":"javascript:void(0)","id":_vm.destination},on:{"click":_vm.animatedNavigateTo}},[_c('span',{staticClass:"item-icon",style:(`color: ${
        _vm.config.FORCAR_CORES_MENU && this.$route.name == _vm.destination
          ? this.config.TABELA_COR.find(cor => cor.nome == 'COR_BACKGROUND_FORCADO_BOTOES').cor
          : _vm.textColor
      };`)},[_c('i',{class:_vm.icon})]),_c('span',{staticClass:"item-label",style:(`color: ${
        _vm.config.FORCAR_CORES_MENU && this.$route.name == _vm.destination
          ? this.config.TABELA_COR.find(cor => cor.nome == 'COR_BACKGROUND_FORCADO_BOTOES').cor
          : _vm.textColor
      };`)},[_vm._v(_vm._s(_vm.label))])]):_c('div',{class:{ 'menu-flex': !this.config.CLIENTE_USA_INTEGRACAO && _vm.index == 1 }},[_c('a',{staticClass:"item-menu-novo d-flex-center",class:{
      'font-weight-bold': _vm.navBold == true,
    },attrs:{"href":"javascript:void(0)","id":_vm.destination},on:{"click":_vm.animatedNavigateTo}},[_c('span',{staticClass:"item-icon",style:(`color: ${_vm.textColor};`)},[(_vm.destination == 'girosDaSorte')?_c('img',{staticClass:"roleta-icon",attrs:{"src":"https://qrsconfigurador.s3.amazonaws.com/APAS/imagens/c40322aa-9df7-48dd-b035-e6a4616ef5c6.png","alt":"roleta"}}):_c('i',{class:_vm.icon})]),_c('span',{staticClass:"item-label",style:(`color: ${_vm.textColor};`)},[_vm._v(_vm._s(_vm.label))])]),(!this.config.CLIENTE_USA_INTEGRACAO && _vm.index == 1 && !this.block)?_c('a',{staticClass:"cadastrar-cupom",on:{"click":function($event){return _vm.cadastrarCupom()}}},[_vm._m(0),_c('span',{staticClass:"item-label"},[_vm._v("CADASTRAR CUPOM")])]):_vm._e(),(!this.config.CLIENTE_USA_INTEGRACAO && _vm.index == 1 && this.block)?_c('a',{staticClass:"cadastrar-cupom",on:{"click":function($event){return _vm.campaignMsg('Não há campanhas promocionais disponíveis no momento!', false)}}},[_vm._m(1),_c('span',{staticClass:"item-label"},[_vm._v("CADASTRAR CUPOM")])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"item-icon"},[_c('i',{staticClass:"far fa-file-alt"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"item-icon"},[_c('i',{staticClass:"far fa-file-alt"})])
}]

export { render, staticRenderFns }