<template>
  <li class="notification-item" :class="{ 'inactive' : seen }" @click="$emit('click')">
    <div class="texts">
      <p class="c-date m-0"><small>{{ date }}</small></p>
      <p class="c-title m-0"><strong>{{ title }}</strong></p>
      <p class="c-text m-0"><small>{{ text }}</small></p>
      <p class="c-type m-0"><small>{{ type }}</small></p>
    </div>
    <div v-if="imgUrl" class="image">
      <img :src="imgUrl" alt="image" />
    </div>
  </li>
</template>

<script>
export default {
  name: 'notification-item',
  props: {
    date: {
      type: String,
      description: 'Display date',
      default: '00/00/00'
    },
    title: {
      type: String,
      description: 'Display title',
      default: ''
    },
    text: {
      type: String,
      description: 'Display text',
      default: ''
    },
    type: {
      type: String,
      description: 'Display type',
      default: ''
    },
    imgUrl: {
      type: String,
      description: 'Display image (optional)',
      default: ''
    },
    seen: {
      type: Boolean,
      default: 'Notification status',
      default: false
    }
  }
}
</script>