// Dependencies.
import Vue from 'vue'
import App from './App.vue'
import AppError from './views/ErroConfigurador'
import plugins from './plugins'
import router from './router'
import store from './store'
import { storeConfig } from './lib/Storage'
import VueGtm from '@gtm-support/vue2-gtm';
import './registerServiceWorker'
import { Data } from './lib/Helper'

// Production tips.
Vue.config.productionTip = false

// QRSorteios window utils
window.qrsorteios = {
  // URLS's for debugger
  debuggerUrls: [],
  // Init app throug onDeviceReady
  start: function () {
    document.addEventListener(
      'deviceready',
      window.qrsorteios.onDeviceReady,
      false
    )
    if (typeof cordova == 'undefined') {
      this.onDeviceReady()
    }
  },
  // Loads confg from QRSConfig
  onDeviceReady: async function () {
    if (typeof cordova != 'undefined') {
      document.addEventListener('backbutton', window.qrsorteios.btnBack, false)
      cordova.plugins.certificates.trustUnsecureCerts(true)
      setTimeout(() => {
        window.qrsorteios.addDebugger(0)
      }, 1000)
      //registrando push
      console.log('registrando push')
      console.log(PushNotification)
      let push = PushNotification.init({
        android: {
          // Add your Google Mobile App SenderId here
          senderID: '146726719039'
        },
        browser: {
          pushServiceURL: 'http://push.api.phonegap.com/v1/push'
        },
        ios: {
          alert: 'true',
          badge: 'true',
          sound: 'true'
        },
        windows: {}
      })
      window.qrsorteios.device = device.model
      push.on('registration', function (data) {
        console.log(data)
        window.qrsorteios.deviceId = data.registrationId
      })
      push.on('notification', function (data) {
        console.log(data)
      })
      push.on('error', function (err) {
        console.log(err)
      })
    }
    const verifyStoreConf = (alreadyLoaded = false) => {
      const currentUrl = (typeof BuildInfo != 'undefined' && BuildInfo.packageName
        ? BuildInfo.packageName
        : window.location.origin
      );
      let formattedConfigs = {}
      let remoteConfigs = {}
      fetch('https://config.commercemaster.com.br/configuracoes/listarPorChaveValor', {
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json, text/plain, */*",
          "Authorization": "476d14b6a3b89b5357e03573983a5975"
        },
        method: 'POST',
        body: JSON.stringify({
          aplicacao: 'QRSORTEIOS',
          nome: 'QRBETA',
          chave: 'APP_URLS',
          valor: currentUrl
        })
      }).then(e => e.json())
        .then(function (response) {
          if ([200, 201].includes(response.statusCode)) {
            const config = response.dados;
            if (config && config[0]) {
              for (let i = 0; i < config[0].entradas.length; i++) {
                const e = config[0].entradas[i];
                if (e.chave == "APP_URLS") {
                  formattedConfigs = {
                    id: config[0].id,
                    urls: JSON.parse(e.valor),
                    values: config[0].entradas
                  }
                  break;
                }
              }
            }
            const values = formattedConfigs.values;
            const parsedValues = {};
            for (let i = 0; i < values.length; i++) {
              const e = values[i];
              try {
                parsedValues[e.chave] = JSON.parse(e.valor);
              } catch (_) {
                parsedValues[e.chave] = e.valor;
              }
            }
            const configF = JSON.stringify(parsedValues);
            remoteConfigs = {
              config: configF,
              key: 'storeConfig@' + currentUrl
            }
            localStorage.setItem(remoteConfigs.key, remoteConfigs.config)
            const dataAtual = Data.dataAtual()
            const dataLimite = Data.incrementaDiasData(dataAtual, 14)
            localStorage.setItem('dataLimiteCacheConfigSalva', dataLimite)
            if (!alreadyLoaded) window.qrsorteios.initVue()
          } else {
            window.qrsorteios.vue = new Vue({
              el: '#app',
              render: h => h(AppError, {
                props: {
                  msgErro: response.dados
                }
              })
            })
          }
        })
        .catch(function (e) {
          window.qrsorteios.vue = new Vue({
            el: '#app',
            render: h => h(AppError, {
              props: {
                msgErro: e
              }
            })
          })
        })
    }
    if (storeConfig.config()) {
      window.qrsorteios.initVue()
      verifyStoreConf(true)
    } else {
      verifyStoreConf()
    }
  },
  // Open cordova browser for cordova
  openBrowser: function (url, target = '_blank') {
    if (typeof cordova != 'undefined') {
      let inAppRef = cordova.InAppBrowser.open(
        url,
        target,
        'location=no,clearsessioncache=yes,zoom=no,closebuttoncaption=Fechar'
      )
      return inAppRef
    } else {
      window.open(url, self)
    }
  },
  // Back button for cordova
  disableBackBtn: false,
  btnBack: function (e) {
    e.preventDefault()
    if (!window.qrsorteios.disableBackBtn) window.history.go(-1)
  },
  // QRCode scanner for cordova
  openQRCodeScanner: function (callback, callbackError) {
    if (typeof cordova != 'undefined') {
      window.qrsorteios.disableBackBtn = true
      let options = {
        preferFrontCamera: false,
        showFlipCameraButton: false,
        showTorchButton: true,
        torchOn: false,
        saveHistory: false,
        prompt: 'Coloque um codigo de barras dentro da area',
        resultDisplayDuration: 0,
        formats: 'QR_CODE',
        orientation: 'portrait',
        disableAnimations: true,
        disableSuccessBeep: true
      }
      cordova.plugins.barcodeScanner.scan(
        result => {
          window.qrsorteios.disableBackBtn = false
          callback(result)
        },
        error => {
          window.qrsorteios.disableBackBtn = false
          callbackError(error)
        },
        options
      )
    }
  },
  // Verify url
  isUrlOn: function (url, callback) {
    var xmlHttp = new XMLHttpRequest()
    xmlHttp.timeout = 2000
    xmlHttp.onreadystatechange = function () {
      if (xmlHttp.readyState === XMLHttpRequest.DONE) {
        if ([200, 0].includes(xmlHttp.status)) {
          callback(true)
        } else {
          callback(false)
        }
      }
    }
    xmlHttp.open('GET', url, true)
    xmlHttp.send(null)
  },
  // Adds a debugger to window
  addDebugger: function (i) {
    if (this.debuggerUrls.length > 0) {
      if (typeof this.debuggerUrls[i] != 'undefined') {
        var url = this.debuggerUrls[i]
        this.isUrlOn(url, success => {
          if (success) {
            ; (function (e) {
              e.setAttribute('src', url)
              document.getElementsByTagName('body')[0].appendChild(e)
            })(document.createElement('script'))
          } else {
            this.addDebugger(i + 1)
          }
        })
      }
    }
  },
  // Starts vue app
  initVue: () => {
    Vue.use(plugins)
    let configuracao = storeConfig.config()
    if (configuracao.GOOGLE_GTM_ID && configuracao.GOOGLE_GTM_ID != 'vazio') {
      Vue.use(VueGtm, {
        id: configuracao.GOOGLE_GTM_ID, // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
        defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
        compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
        enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
        debug: true, // Whether or not display console logs debugs (optional)
        loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
        vueRouter: router, // Pass the router instance to automatically sync with router (optional)
        trackOnNextTick: false // Whether or not call trackView in Vue.nextTick
      });
    }
    window.qrsorteios.vue = new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount('#app')

    window.qrsorteios.vue.$root.$on('atualizarVersao', function (data) {
      let lojas = data.split('*-*-*')
      if (typeof cordova != 'undefined') {
        if (cordova.platformId == 'android') {
          let android = lojas[0]
            .replace(/(?=details)/, 'market://')
            .match(/market:\/\/[\w\W]+/)[0]
          window.open(android, '_system')
        } else if (cordova.platformId == 'ios') {
          cordova.InAppBrowser.open(encodeURI(lojas[1]), '_system')
        }
      }
    })
  }
}

// Starting QRsorteios
window.qrsorteios.start()
