var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"base-pill-modal"},[_c('transition',{attrs:{"name":"fade","appear":""}},[(_vm.openModal)?_c('div',{staticClass:"pill-modal-overlay",class:{ fundoCorSolida: _vm.fundoCorSolida },on:{"click":function($event){_vm.closeOverlay ? _vm.fecharModal() : () => {}}}}):_vm._e()]),_c('transition',{attrs:{"name":_vm.nomeTransicao,"appear":""}},[(_vm.openModal)?_c('div',{staticClass:"pill-modal"},[(_vm.closeOverlay)?_c('button',{staticClass:"pill-modal-close",on:{"click":function($event){_vm.closeOverlay ? _vm.fecharModal() : () => {}}}}):_vm._e(),_c('div',{staticClass:"pill-modal-header"},[(_vm.pills[0])?_c('div',{staticClass:"start-items"},[_c('div',{staticClass:"left-pill",class:_vm.pillColors[0],style:(_vm.config.COR_DESTAQUE_CIRCUNFERENCIA_CARDS_MODAIS
                ? `background-color: ${_vm.config.COR_DESTAQUE_CIRCUNFERENCIA_CARDS_MODAIS} !important;`
                : '')},[_c('i',{class:_vm.headerIcons[0],style:(_vm.config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_CARDS_MODAIS
                  ? `color: ${_vm.config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_CARDS_MODAIS} !important;`
                  : '')})]),(_vm.title)?_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.title))]):_vm._e()]):_vm._e(),(_vm.pills[1])?_c('div',{staticClass:"end-items"},[_c('div',{staticClass:"right-pill",class:_vm.pillColors[1],style:(_vm.config.COR_DESTAQUE_CIRCUNFERENCIA_CARDS_MODAIS
                ? `background-color: ${_vm.config.COR_DESTAQUE_CIRCUNFERENCIA_CARDS_MODAIS} !important;`
                : '')},[_c('i',{class:_vm.headerIcons[1],style:(_vm.config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_CARDS_MODAIS
                  ? `color: ${_vm.config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_CARDS_MODAIS} !important;`
                  : '')})])]):_vm._e()]),_c('div',{staticClass:"pill-modal-body"},[_vm._t("pill-modal-body")],2),_c('div',{staticClass:"pill-modal-footer"},[_vm._t("pill-modal-footer")],2)]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }