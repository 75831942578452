import Vue from 'vue';
import { Text } from '../../lib/Helper';

// Initial state
const initialState = () => ({
  saldo: 0,
  saldoDisponibilizar: 0,
  extratos: [],
  extratoSimplificado: [],
  debitoAtual: null,
  paginas: 0,
  extratoAtual: null,
  dataDisponibilizar: null,
  saldoExpirar: 0,
  dataExpirar: null,
  extratoSimplificadoAtual: null,

  loading: false,

  //produtos fidelidade
  produtosFidelidade: [],
  totalProdutosFidelidade: 0,

  totalPaginasEstoque: 0,
  estoqueProdutos: [],
  totalEstoqueProdutos: [],

  pedidos: [],
  totalPedidos: 0,
  totalPaginasPedidos: 0,

  observacaoItem: {},
  //fim produtos fidelidade
});

// State object
const state = initialState();

// Getters
const getters = {
  getSaldo(state) {
    return state.saldo;
  },
  getSaldoDisponibilizar(state) {
    return state.saldoDisponibilizar;
  },
  getExtratos(state) {
    return state.extratos;
  },
  getExtratoSimplificado(state) {
    return state.extratoSimplificado;
  },
  getDebitoAtual(state) {
    return state.debitoAtual;
  },
  getPaginas(state) {
    return state.paginas;
  },
  getExtratoAtual(state) {
    return state.extratoAtual;
  },
  getExtratoSimplificadoAtual(state) {
    return state.extratoSimplificadoAtual;
  },
  getDataDisponibilizar(state) {
    return state.dataDisponibilizar;
  },
  getSaldoExpirar(state) {
    return state.saldoExpirar;
  },

  getLoading(state) {
    return state.loading;
  },

  getDataExpirar(state) {
    return state.dataExpirar;
  },

  getProdutosFidelidade(state) {
    return state.produtosFidelidade;
  },
  getTotalProdutosFidelidade(state) {
    return state.totalProdutosFidelidade;
  },
  getTotalPaginasEstoque(state) {
    return state.totalPaginasEstoque;
  },

  getEstoqueProdutos(state) {
    return state.estoqueProdutos;
  },
  getTotalEstoqueProdutos(state) {
    return state.totalEstoqueProdutos;
  },

  getPedidos(state) {
    return state.pedidos;
  },
  getTotalPedidos(state) {
    return state.totalPedidos;
  },
  getTotalPaginasPedidos(state) {
    return state.totalPaginasPedidos;
  },

  getObservacaoItem(state) {
    return state.observacaoItem;
  },
};

// Actions
const actions = {
  clearState({ commit }) {
    commit('RESET');
  },
  consultarSaldo({ commit }, { usuario }) {
    return new Promise(async (resolve) => {
      const api = new Vue.prototype.$httpClient();
      const url = `/cashback/v1/saldo/${usuario}`;
      const response = await api.send(url, 'GET', null, undefined, false);
      if (response.status == 200) {
        commit('setSaldo', response.data.retorno.valorDisponivel);
        commit('setSaldoDisponibilizar', response.data.retorno.valorDisponibilizar);
        commit('setDataDisponibilizar', response.data.retorno.disponibilizaEm);
        commit('setSaldoExpirar', response.data.retorno.valorExpirar);
        commit('setDataExpirar', response.data.retorno.expiraEm);
        resolve({
          status: 200,
          msg: 'Saldo cashback recuperado para esse usuário.',
        });
      } else {
        commit('setSaldo', 0);
        commit('setSaldoDisponibilizar', 0);
        commit('setSaldoExpirar', 0);
        resolve({
          status: 404,
          msg: 'Não há saldo cashback para esse consumidor.',
        });
      }
    });
  },
  consultarExtrato({ commit }, { usuario, paginacao }) {
    return new Promise(async (resolve) => {
      const api = new Vue.prototype.$httpClient();
      const url = `cashback/v1/extrato/${usuario}/${paginacao.totalPorPagina}/${paginacao.paginaAtual}`;
      const response = await api.send(url, 'GET', null, undefined, false);
      if (response.status == 200) {
        commit('setExtrato', response.data.retorno);
        resolve({
          status: 200,
          msg: 'Lista de extratos recuperado para esse usuário.',
        });
      } else {
        commit('setExtrato', {});
        resolve({
          status: 404,
          msg: 'Não há extratos para esse consumidor.',
        });
      }
    });
  },

  fetchExtratoSimplificado({ commit }, { filtro, paginacao }) {
    return new Promise(async (resolve) => {
      const filtroFormatado = Text.serializarParams(filtro);

      const api = new Vue.prototype.$httpClient();
      const url = `cashback/v1/extratos/simplificados/${paginacao.totalPorPagina}/${paginacao.paginaAtual}?${filtroFormatado}`;
      const res = await api.send(url, 'GET');

      if ([200, 201].includes(res.status)) {
        commit('setExtratoSimplificado', res.data.retorno.retorno);

        resolve({
          status: 200,
          msg: 'Lista de extratos simplificados recuperado para esse usuário',
          total: res.data.retorno.total,
        });
      } else {
        commit('setExtratoSimplificado', []);
        resolve({
          status: 404,
          msg: 'Não há extratos para esse consumidor.',
        });
      }
    });
  },

  fetchProdutosFidelidade({ commit }, { paginacao }) {
    return new Promise(async (resolve) => {
      const api = new Vue.prototype.$httpClient();
      const url = `cashback/v1/produto/listar/${paginacao.totalPorPagina}/${paginacao.paginaAtual}`;
      const res = await api.send(url, 'GET');

      if ([200, 201].includes(res.status)) {
        commit('setProdutosFidelidade', res.data.retorno.retorno);
        commit('setTotalProdutosFidelidade', res.data.retorno.total);
        resolve({
          status: 200,
          msg: 'Lista de produtos obtida com sucesso!',
          total: res.data.retorno.total,
        });
      } else if ([404].includes(res.status)) {
        commit('setProdutosFidelidade', []);
        commit('setTotalProdutosFidelidade', 0);
        resolve({ status: 404, msg: 'Não foi encontrado nenhum produto!' });
      } else {
        commit('setProdutosFidelidade', []);
        commit('setTotalProdutosFidelidade', 0);
        resolve({ status: res.status || 500, msg: 'Erro desconhecido ao listar produtos' });
      }
    });
  },

  fetchEstoqueProdutos({ commit }, { paginacao, filtro, estoque }) {
    return new Promise(async (resolve) => {
      const api = new Vue.prototype.$httpClient();
      const url = `cashback/v1/estoque/listar/${paginacao.totalPorPagina}/${paginacao.paginaAtual}`;
      const res = await api.send(
        url,
        'GET',
        {
          ...filtro,
          ...estoque,
        },
        null,
        false
      );

      if ([200, 201].includes(res.status)) {
        commit('setEstoqueProdutos', res.data.retorno.retorno);
        commit('setTotalEstoqueProdutos', res.data.retorno.total);
        commit('setTotalPaginasEstoque', res.data.retorno.paginas);
        resolve({
          status: 200,
          msg: 'Estoque de produtos obtido com sucesso!',
          total: res.data.retorno.total,
        });
      } else if ([404].includes(res.status)) {
        commit('setEstoqueProdutos', []);
        commit('setTotalEstoqueProdutos', 0);
        resolve({ status: 404, msg: 'Não foi encontrado nenhum estoque!' });
      } else {
        commit('setEstoqueProdutos', []);
        commit('setTotalEstoqueProdutos', 0);
        resolve({ status: res.status || 500, msg: 'Erro desconhecido ao listar estoque de produtos' });
      }
    });
  },

  enviarPedidoProduto({ commit }, { usuario, lojaCNPJ, produtos }) {
    return new Promise(async (resolve) => {
      commit('setLoading', true);
      const api = new Vue.prototype.$httpClient();
      const url = `cashback/v1/pedido/${usuario}`;
      const res = await api.send(url, 'POST', {
        lojaCNPJ,
        produtos,
      });

      commit('setLoading', false);

      if ([200, 201].includes(res.status)) {
        resolve({
          status: res.data.status,
          retorno: res.data.retorno,
          msg: res.data.mensagem,
        });
      } else {
        resolve({
          status: res.status || 500,
          retorno: res.data.retorno,
          msg: res.data.mensagem || 'Erro desconhecido ao realizar pedido!',
        });
      }
    });
  },

  fetchPedidos({ commit }, { paginacao, filtro }) {
    return new Promise(async (resolve) => {
      const api = new Vue.prototype.$httpClient();
      const url = `cashback/v1/pedido/listar/${paginacao.totalPorPagina}/${paginacao.paginaAtual}`;
      const res = await api.send(
        url,
        'GET',
        {
          ...filtro,
        },
        undefined,
        false
      );

      if ([200, 201].includes(res.status)) {
        commit('setPedidos', res.data.retorno.retorno);
        commit('setTotalPedidos', res.data.retorno.total);
        commit('setTotalPaginasPedidos', res.data.retorno.paginas);
        resolve({
          status: 200,
          msg: 'Pedidos obtidos com sucesso!',
          total: res.data.retorno.total,
        });
      } else if ([404].includes(res.status)) {
        commit('setPedidos', []);
        commit('setTotalPedidos', 0);
        resolve({ status: 404, msg: 'Não foi encontrado nenhum pedido!' });
      } else {
        commit('setPedidos', []);
        commit('setTotalPedidos', 0);
        resolve({ status: res.status || 500, msg: res.data.mensagem || 'Erro desconhecido ao listar pedidos' });
      }
    });
  },

  enviarAlterarObservacaoItemPedido({ commit }, { pedidoID, posicaoItemArray, observacao }) {
    return new Promise(async (resolve) => {
      commit('setLoading', true);
      const api = new Vue.prototype.$httpClient();
      const url = `cashback/v1/pedido/alterarObservacaoItem/${pedidoID}/${posicaoItemArray}`;
      const res = await api.send(url, 'POST', {
        observacao,
      });

      commit('setLoading', false);

      if ([200, 201].includes(res.status)) {
        resolve({
          status: res.data.status,
          retorno: res.data.retorno,
          msg: res.data.mensagem,
        });
      } else {
        resolve({
          status: res.status || 500,
          retorno: res.data.retorno,
          msg: res.data.mensagem || 'Erro desconhecido ao alterar a observação do item!',
        });
      }
    });
  },
};

// Mutations
const mutations = {
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },
  setSaldo(state, data) {
    state.saldo = data;
  },
  setSaldoDisponibilizar(state, data) {
    state.saldoDisponibilizar = data;
  },
  setExtrato(state, data) {
    let listaId = state.extratos.map((e) => e.id);
    if (data.retorno) {
      data.retorno.forEach((e) => {
        if (!listaId.includes(e.id)) {
          listaId.push(e.id);
          state.extratos.push(e);
        }
      });
    }
    state.extratos = state.extratos.flat();
    state.paginas = data.paginas;
  },
  setExtratoSimplificado(state, data) {
    state.extratoSimplificado = data;
  },

  setExtratoSimplificadoAtual(state, data) {
    state.extratoSimplificadoAtual = data;
  },
  setDebitoAtual(state, data) {
    state.debitoAtual = data;
  },

  setExtratoAtual(state, data) {
    state.extratoAtual = state.extratos.find((e) => e.id == data);
  },
  setDataDisponibilizar(state, data) {
    state.dataDisponibilizar = data;
  },
  setSaldoExpirar(state, data) {
    state.saldoExpirar = data;
  },
  setDataExpirar(state, data) {
    state.dataExpirar = data;
  },
  setLoading(state, data) {
    state.loading = data;
  },

  setProdutosFidelidade(state, data) {
    state.produtosFidelidade = data;
  },
  setTotalProdutosFidelidade(state, data) {
    state.totalProdutosFidelidade = data;
  },

  setTotalPaginasEstoque(state, data) {
    state.totalPaginasEstoque = data;
  },

  setEstoqueProdutos(state, data) {
    state.estoqueProdutos = data;
  },
  setTotalEstoqueProdutos(state, data) {
    state.totalEstoqueProdutos = data;
  },

  setPedidos(state, data) {
    state.pedidos = data;
  },
  setTotalPedidos(state, data) {
    state.totalPedidos = data;
  },
  setTotalPaginasPedidos(state, data) {
    state.totalPaginasPedidos = data;
  },

  setObservacaoItem(state, data) {
    state.observacaoItem = data;
  },
};

// Module exports
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
