import Vue from 'vue'

// Initial state
const initialState = () => ({
	cedulas: [],
	loading: false
})

// State object
const state = initialState()

// Getters
const getters = {
	getCedulas(state) {
		return state.cedulas
	},
	loadState(state) {
		return state.loading
	}
}

// Actions
const actions = {
	clearState({
		commit
	}) {
		commit('RESET')
	},
	fetchCedulas({
		commit
	}, {
		campanha,
		cpf
	}) {
		return new Promise(async (resolve) => {
			const validarParametros = () => {
				const validarCpf = () => (
					(typeof cpf !== "undefined") &&
					(cpf !== "") &&
					(cpf !== " ") &&
					(cpf) // com algum valor
				)

				const validarCampanha = () => (
					(typeof campanha !== "undefined") &&
					(campanha.ano) &&
					(campanha.identificacao)
				)

				return (validarCpf() && validarCampanha())
			}
			const emitirAlertaParametrosIncorretos = () => {
				commit('setCedulas', [])
				resolve({
					status: 404,
					msg: 'Parâmetros incorretos para listagem de cédulas.'
				})
			}
			const emitirAlertaSucesso = (cedulas) => {
				commit('setCedulas', cedulas)
				resolve({
					status: 200,
					msg: 'Cédulas recuperadas.',
					cedulas: cedulas,
				})
			}
			const emitirAlertaFalha = () => {
				commit('setCedulas', [])
				resolve({
					status: 404,
					msg: 'Nenhuma cédula encontrada.'
				})
			}
			const processarRequisicao = async () => {
				const api = new Vue.prototype.$httpClient()
				const url = `/quiz/v1/cedulas/obterCedulas`
				const response = await api.send(url, 'POST', {
					campanha,
					usuario: cpf,
					filtro: {
						usuario: cpf,
						semResposta: true,
						dentroDataLimite: true
					}
				});

				(response.status == 200)
					? emitirAlertaSucesso(response.data.retorno)
					: emitirAlertaFalha()
			}

			// Validando dados a serem enviados na requisição...
			(validarParametros())
				? processarRequisicao()
				: emitirAlertaParametrosIncorretos()
		})
	},
	salvarCedulas({
		commit
	}, {
		campanha,
		cpf,
		respostas
	}) {
		return new Promise(async (resolve) => {
			const emitirAlertaSucesso = () => {
				resolve({
					status: 200,
					msg: 'Respostas salvas',
				})
			}
			const emitirAlertaFalha = ({ status, mensagem }) => {
				if ([400, 404].includes(status)) {
					[404].includes(status)
						? resolve({ status: 404, msg: 'Prazo de resposta expirado' })
						: resolve({ status: 400, msg: mensagem })
				} else {
					resolve({ status: status, msg: 'Parece que você está offline, verifique sua conexão com a internet!' })
				}
				
			}
			const processarRequisicao = async () => {
				const api = new Vue.prototype.$httpClient()
				const url = `/quiz/v1/cedulas/`
				const response = await api.send(url, 'PUT', {
					campanha,
					usuario: cpf,
					respostas
				}, undefined, false)

				if (response.status == 200) emitirAlertaSucesso()
				else
          emitirAlertaFalha({
            status: response.status,
            mensagem: response.data.mensagem
          })
			}

			// Enviando respostas...
			processarRequisicao()
		})
	},
}

// Mutations
const mutations = {
	RESET(state) {
		const newState = initialState();
		Object.keys(newState).forEach(key => {
			state[key] = newState[key]
		})
	},
	setCedulas(state, data) {
		state.cedulas = data
	},
	startLoading(state) {
		state.loading = true
	},
}

// Module exports
export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
