var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pill-header-title",class:[_vm.backgroundColor],style:(_vm.config.COR_DESTAQUE_CIRCUNFERENCIA_CARDS_MODAIS
      ? `background-color: ${_vm.config.COR_DESTAQUE_CIRCUNFERENCIA_CARDS_MODAIS} !important;`
      : '')},[(_vm.pillIcon)?_c('div',{staticClass:"left-middle-pill pill-size cursor-pointer",class:_vm.backgroundPill ? _vm.backgroundPill : 'pill-body-dark',style:(_vm.config.COR_DESTAQUE_CIRCUNFERENCIA_CARDS_MODAIS
        ? `background-color: ${_vm.config.COR_DESTAQUE_CIRCUNFERENCIA_CARDS_MODAIS} !important;`
        : '')},[_c('i',{class:_vm.pillIcon,style:(_vm.config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_CARDS_MODAIS && !_vm.pillIconSameColorTitle
          ? `color: ${_vm.config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_CARDS_MODAIS} !important;`
          : '')})]):_vm._e(),_vm._t("body-pill-header-title",function(){return [_c('div',{staticClass:"w-100"})]}),_vm._t("body-pill-header-pontuacao"),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.left",value:(_vm.toolTipMessage),expression:"toolTipMessage",modifiers:{"left":true}}],staticClass:"right-header-title-info cursor-pointer"},[_c('i',{staticClass:"fas fa-info-circle"})])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }