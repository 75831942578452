<template>
  <base-pill-modal
      :openModal="show"
      :pills="[true, false]"
      :pillColors="['pill-dark']"
      :headerIcons="['fas fa-thumbs-up']"
      :closeOverlay="false"
    >
      <template v-slot:pill-modal-body>
        <div class="w-100 d-flex flex-column align-items-center mb-3">
          <h4 class="text-align-center text-black">
            <strong>Bem-vindo!</strong>
          </h4>
          <p class="w-75 text-align-center text-center text-black">
            <small><strong>
              {{
                config.TEXTO_PRIMEIROS_PASSOS ||
                'Percebemos que você ainda não completou seu cadastro. Antes de começar a ganhar seus prêmios e números da sorte é necessário completar o cadastro.'
              }}
            </strong></small>
          </p>
        </div>
      </template>
      <template v-slot:pill-modal-footer>
        <!-- justify-content-center | justify-content-between -->
        <div class="w-100 d-flex flex-row justify-content-center">
          <base-pill-button
            :type="'light'"
            :class="'mt-3'"
            :right-middle-pill="'fas fa-check-circle'"
            :pillColors="['pill-dark']"
            :rightMiddlePillRootColor="config.COR_DESTAQUE_CIRCUNFERENCIA_DOS_BOTOES"
            :rightMiddlePillIconRootColor="config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_DOS_BOTOES"
            :pillSize="['pill-average']"
            @click="completarSeuCadastro"
          >
            <template v-slot:pill-button-title>
              <span class="w-90 px-2 text-center text-black text-uppercase"><strong>OK</strong></span>
            </template>
          </base-pill-button>
        </div>
      </template>
    </base-pill-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'completar-cadastro',
  data: () => ({
    show: false,
  }),
  props: {
    showModal: {
      type: Boolean,
      description: 'Modal display boolean',
      default: false
    }
  },
  methods: {
    completarSeuCadastro() {
      this.show = false
      this.$emit('emmitModalEvent', {
        event: 'openProfileModalToFinishSignUp'
      })
    }
  },
  computed: {
    ...mapGetters({
      config: 'config/getConfig'
    }),
  },
  watch: {
    show(val) {
      if (!val) {
        this.$emit('emmitModalEvent', {
          event: 'closeFinishSignUpModal'
        })
      }
    },
    showModal(val) {
      if (val) this.show = true
    }
  }
}
</script>
