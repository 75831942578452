<template>
  <base-pill-button
    :type="'light'"
    :right-middle-pill="'fab fa-facebook'"
    :pillColors="['pill-light-gray']"
    :pillIconColors="['icon-light']"
    :pillSize="['pill-average']"
    @click="facebookLogin"
  >
    <template v-slot:pill-button-title>
      <span class="w-90 pl-3 pr-2 text-center f-07 light-gray">Facebook</span>
    </template>
  </base-pill-button>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'facebook-button',
  computed: {
    ...mapGetters({
      config: 'config/getConfig'
    })
  },
  methods: {
    ...mapActions({
      autenticacaoFacebook: 'autenticacao/autenticacaoFacebook'
    }),
    init: async function () {
      const appId = this.isProd
        ? this.config.VUE_APP_PROD_APPFBID
        : this.config.VUE_APP_DEV_APPFBID
      openFB.init({ appId: appId })
    },
    facebookAutentica(token) {
      this.autenticacaoFacebook({
        tokenFacebook: token,
        isProd: this.isProd
      }).then((res) => {
        if (res.status == 200) {
          this.$emit('emmitEvent', {
            event: 'auth',
            data: {
              user: res.dados.usuario,
              password: null,
              type: 'consumidor',
              token: res.dados.token,
              tokenFacebook: res.dados.token
            }
          })
        } else {
          localStorage.setItem(
            'facebookInfo',
            JSON.stringify({
              token: token,
              email: res.dados.email,
              nome: res.dados.name
            })
          )
          this.$router.push({ name: 'cadastro' })
        }
      })
    },
    facebookLogin: function () {
      openFB.login(
        (response) => {
          if (response.status === 'connected') {
            this.facebookAutentica(response.authResponse.accessToken)
          }
        },
        { scope: 'email' }
      )
    }
  },
  async mounted() {
    this.init()
  },
  props: ['isProd']
}
</script>
