<template>
  <div id="card" :style="`height: ${alturaCard}; background-color: ${bgColorCard}`">
    <img id="imagem" :src="imagem || require('../../../assets/images/img-insdisponivel.png')" :alt="imagemDescricao" />
    <span id="titulo" :style="`font-size: ${tamanhoTitulo}; color: ${corTitulo}; font-weight: ${pesoTitulo};`">{{
      titulo
    }}</span>
  </div>
</template>

<style scoped lang="scss">
#bloco {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: fit-content;
}
#card {
  padding: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border-radius: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  img {
    max-height: 140px;
    margin: auto;
  }
  &:hover {
    cursor: pointer;
  }
}
#titulo {
  text-align: center;
  line-height: 1;
  bottom: 10px;
}
</style>

<script>
export default {
  name: 'card-produto',
  props: {
    titulo: {
      type: String,
      required: true,
      default: '',
      description: 'Propriedade que recebe o nome que será colocado no card',
    },
    imagem: {
      type: String,
      required: true,
      default: '',
      description: 'Propriedade que recebe a URL da imagem que será colocada no card',
    },
    imagemDescricao: {
      type: String,
      required: false,
      default: '',
      description: 'Propriedade que recebe descrição da imagem que será colocado no card',
    },
    larguraCard: {
      type: String,
      required: false,
      default: '100px',
      description: 'Propriedade que define a largura do card',
    },
    alturaCard: {
      type: String,
      required: false,
      default: '200px',
      description: 'Propriedade que define a altura do card da imagem',
    },
    bgColorCard: {
      type: String,
      required: false,
      default: 'white',
      description: 'Propriedade que define a cor de fundo do card',
    },
    larguraImagem: {
      type: String,
      required: false,
      default: '60%',
      description: 'Propriedade que define a largura da imagem',
    },
    tamanhoTitulo: {
      type: String,
      required: false,
      default: '12px',
      description: 'Propriedade que define a font-size do nome',
    },
    corTitulo: {
      type: String,
      required: false,
      default: 'gray',
      description: 'Propriedade que define a cor da fonte do nome',
    },
    pesoTitulo: {
      type: String,
      required: false,
      default: '500',
      description: 'Propriedade que define a font-weight do nome',
    },
  },
};
</script>
