<template>
  <app-card>
    <template slot="header">
      <div class="row">
        <div class="col-12">
          <div id="animation-container">
            <div id="one"></div>
          </div>
        </div>
      </div>
    </template>
    <div>
      <div class="animationLoading">
        <div id="animation-container">
          <div id="one"></div>
          <div id="two"></div>
          <div id="three"></div>
        </div>
        <div id="four"></div>
        <div id="five"></div>
        <div id="six"></div>
      </div>
    </div>
    <template slot="footer">
      <campaign-info />
    </template>
  </app-card>
</template>

<script>
export default {
  name: "card-vazio"
};
</script>