const api = require('./storeConfigApi');
import { Data } from '../../Helper';

const storeConfig = {
  // Returns customers list from QRSConfig API
  async getCustomersList() {
    const currentUrl = (typeof BuildInfo != 'undefined' && BuildInfo.packageName
      ? BuildInfo.packageName
      : window.location.origin
    );
    try {
      let formattedConfigs = {}
      const response = await api.post(`/configuracoes/listarPorChaveValor`, {
        aplicacao: 'QRSORTEIOS',
        nome: 'QRBETA',
        chave: 'APP_URLS',
        valor: currentUrl
      });
      if ([200, 201].includes(response.status)) {
        const config = response.data.dados;
        if (config && config[0]) {
          for (let i = 0; i < config[0].entradas.length; i++) {
            const e = config[0].entradas[i];
            if (e.chave == "APP_URLS") {
              formattedConfigs = {
                id: config[0].id,
                urls: JSON.parse(e.valor),
                values: config[0].entradas
              }
              break;
            }
          }
        }
        const values = formattedConfigs.values;
        const parsedValues = {};
        for (let i = 0; i < values.length; i++) {
          const e = values[i];
          try {
            parsedValues[e.chave] = JSON.parse(e.valor);
          } catch (_) {
            parsedValues[e.chave] = e.valor;
          }
        }
        const configF = JSON.stringify(parsedValues);
        return {
          config: configF,
          key: 'storeConfig@' + currentUrl
        }
      }
    } catch (e) {
      return {
        erro: true,
        retornoErro: JSON.stringify(e, null, 2)
      };
    }
  },
  // Get config by URL
  getconfig(obtainedList) {
    const currentUrl = (typeof BuildInfo != 'undefined' && BuildInfo.packageName
      ? BuildInfo.packageName
      : window.location.origin
    );
    for (let i in obtainedList) {
      let urlFound = obtainedList[i].urls.find(u => u.url == currentUrl);
      if (urlFound) {
        const values = obtainedList[i].values;
        const parsedValues = {};
        values.forEach(e => {
          try {
            parsedValues[e.chave] = JSON.parse(e.valor);
          } catch (_) {
            parsedValues[e.chave] = e.valor;
          }
        })
        const config = JSON.stringify(parsedValues);
        return {
          config: config,
          key: 'storeConfig@' + currentUrl
        }
      }
    }
    return false
  },
  // Verify if config exists into local storage
  config() {
    const currentUrl = (typeof BuildInfo != 'undefined' && BuildInfo.packageName
      ? BuildInfo.packageName
      : window.location.origin
    );
    if (localStorage.getItem('storeConfig@' + currentUrl)) {
      const dataLimiteCache = localStorage.getItem('dataLimiteCacheConfigSalva');
      const dataAtual = Data.dataAtual();
      if (dataLimiteCache <= dataAtual) {
        const dataAtual = Data.dataAtual()
        const dataLimite = Data.incrementaDiasData(dataAtual, 14)
        localStorage.setItem('dataLimiteCacheConfigSalva', dataLimite)
        document.location.reload(true);
      } else {
        return JSON.parse(localStorage.getItem('storeConfig@' + currentUrl));
      }
    } else {
      return false;
    }
  }
}


export default storeConfig;
