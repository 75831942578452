<template>
  <div class="pill-bomb-input-group">
    <div class="base-pill-bomb-input" :class="[disabled ? 'disabled' : '']">
      <div class="header-pill-bomb-input">
        <div class="items-start">
          <slot name="left-middle-pill">
            <div
              class="left-middle-pill"
              :class="[pillColors, pillSize]"
              :style="leftMiddlePillRootColor ? `background-color: ${leftMiddlePillRootColor} !important;` : ''"
              v-if="leftMiddlePill"
            >
              <span
                class="title"
                :class="[pillTitleColor]"
                :style="leftMiddlePillIconRootColor ? `color: ${leftMiddlePillIconRootColor} !important;` : ''"
                v-if="title"
                >{{ title }}</span
              >
            </div>
          </slot>

          <slot name="pill-bomb-input-subtitle">
            <span class="pill-bomb-input-subtitle" v-if="subtitle">{{ subtitle }}</span>
          </slot>
        </div>

        <slot name="pill-bomb-input-right-validation">
          <div
            class="pill-bomb-input-right-validation"
            v-if="leftMiddlePill && iconValidation"
            v-tooltip.top="errorMessageValidation"
          >
            <i :class="[iconValidation, getValidation]" v-if="!noInput"></i>
            <i :class="[iconValidation, getValidation]" v-else></i>
          </div>
        </slot>
      </div>

      <div class="body-pill-bomb-input" v-if="!noInput">
        <input
          v-if="mask"
          :id="id"
          :value="value"
          v-on="listeners"
          v-mask="mask"
          :masked="masked"
          :type="type"
          :class="[{ valid: valid }, inputClasses] + ' pill-bomb-input'"
          :placeholder="placeholder"
          :autocomplete="autocomplete ? 'on' : 'off'"
          :disabled="disabled"
          :ref="refInput"
        />

        <input
          v-else-if="min"
          :id="id"
          :type="type"
          v-on="listeners"
          :class="[{ valid: valid }, inputClasses] + ' pill-bomb-input'"
          :value="value"
          :min="min"
          :max="max"
          :placeholder="placeholder"
          :autocomplete="autocomplete ? 'on' : 'off'"
          :disabled="disabled"
          :ref="refInput"
        />

        <input
          v-else-if="formatMoney"
          v-money="moneyLib"
          :id="id"
          :type="type"
          v-on="listeners"
          :class="[{ valid: valid }, inputClasses]"
          class="pill-bomb-input placeholder-primary"
          :value="value"
          :placeholder="placeholder"
          :autocomplete="autocomplete ? 'on' : 'off'"
          :disabled="disabled"
          :ref="refInput"
        />

        <template v-else-if="dataList">
          <input
            :list="dataList ? listId : ''"
            :id="id"
            :type="type"
            v-on="listeners"
            :class="[{ valid: valid }, inputClasses] + ' pill-bomb-input ' + 'placeholder-primary'"
            :placeholder="placeholder"
            :autocomplete="autocomplete ? 'on' : 'off'"
            :disabled="disabled"
            :ref="refInput"
          />

          <datalist :id="listId">
            <option v-for="option in listOptions" :key="option.key" :value="option.value" />
          </datalist>
        </template>

        <input
          v-else
          :id="id"
          :type="type"
          v-on="listeners"
          :class="[{ valid: valid }, inputClasses]"
          class="pill-bomb-input placeholder-primary"
          :value="value"
          :placeholder="placeholder"
          :autocomplete="autocomplete ? 'on' : 'off'"
          :disabled="disabled"
          :ref="refInput"
          :readonly="readOnly"
        />
      </div>

      <div class="body-pill-bomb-input" v-else>
        <div class="body-pill-bomb-value">
          <span :class="[defaultValue ? 'value' : 'no-value']">{{ defaultValue || '_' }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VMoney, Money } from 'v-money';
import { mask, masked } from 'vue-the-mask';

export default {
  directives: { mask, masked, money: VMoney },
  components: { Money },
  name: 'BasePillBombInput',

  inheritAttrs: false,

  mounted() {
    if (this.refInput) {
      setTimeout(() => {
        this.$nextTick(() => {
          this.$refs[this.refInput].focus();
        });
      }, 1200);
    }
  },

  data: () => ({
    focused: false,
    hasValue: false,
  }),

  props: {
    id: {
      type: String,
      required: true,
      description: 'ID of the input so we can create a label for that input',
    },

    noInput: {
      type: Boolean,
      required: false,
      default: false,
      description: 'Propriedade que define se o input será utilizado, ou não',
    },

    autocomplete: {
      type: Boolean,
      required: false,
      default: false,
      description: 'autocomplete attribute of the input tag(HTML 5 tag)',
    },

    label: {
      type: String,
      required: false,
      description: 'Label that goes above the input',
    },

    leftMiddlePill: {
      type: Boolean,
      required: false,
      description: 'Pill at left input',
    },

    title: {
      type: String,
      required: false,
      default: '',
      description: 'Text at pills',
    },

    subtitle: {
      type: String,
      required: false,
      default: '',
      description: 'Text at pills',
    },

    icons: {
      type: [String, Array],
      required: false,
      description: 'Icons classes at pills',
    },

    inputClasses: {
      type: Array,
      required: false,
      description: 'Input classes',
    },

    pillColors: {
      type: Array,
      required: false,
      description: 'Pill classes',
    },

    leftMiddlePillRootColor: {
      type: String,
      required: false,
      default: '',
      description: 'Propriedade que define alguma cor capaz de sobrescrever a cor declarada na pílula',
    },

    leftMiddlePillIconRootColor: {
      type: String,
      required: false,
      default: '',
      description: 'Propriedade que define alguma cor capaz de sobrescrever a cor do ícone declarada na pílula',
    },

    pillTitleColor: {
      type: String,
      required: false,
      description:
        'Propriedade que define as cores dos ícones de cada pill, respectivamente, de acordo com as cores do elemento pai',
    },

    pillSize: {
      type: Array,
      required: false,
      description: 'Pill classes',
    },

    placeholder: {
      type: String,
      required: false,
      default: '',
      description: 'Input placeholder',
    },

    type: {
      type: String,
      required: false,
      default: 'text',
      description: 'Input type',
    },

    value: {
      type: [String, Number],
      description: 'Input value',
      default: '',
    },

    required: {
      type: Boolean,
      required: false,
      default: false,
      description: 'Is this input required? If so we will add a * after the label',
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
      description: 'Propriedade que define se um input deve ou não estar desabilitado.',
    },

    mask: {
      type: [String, Array, Boolean],
      description: 'Mask that the input will have',
    },

    masked: {
      type: Boolean,
      default: false,
      description: 'Boolean that will say if the value of the input will be saved masked or not',
    },

    valid: {
      type: Boolean,
      default: false,
      description: 'Boolean that we will use to check if the input is now valid',
    },

    min: {
      type: String,
      default: '',
      description: 'The min date',
    },

    max: {
      type: String,
      default: '',
      description: 'The max date',
    },

    search: {
      type: Boolean,
      default: false,
      required: false,
      description: 'Boolean to pill with serach styles',
    },

    iconValidation: {
      type: String,
      required: false,
      description: 'ícone que indica o status da validaaçãao do input',
    },

    formatMoney: {
      type: Boolean,
      required: false,
      default: false,
      description: 'Propriedade que define se será utilizado input compatível com v-money.',
    },
    moneyLib: {
      type: Object,
      required: false,
      description: 'Objeto com parâmetros para o v-money',
    },

    defaultValue: {
      type: String,
      required: false,
      default: '',
      description: 'Propriedade que define um valor padrão a ser exibido caso inputs estejam desabilitados.',
    },

    errorMessageValidation: {
      type: String,
      required: false,
      default: '',
      description: 'Propriedade que define uma mensagem, tooltip, para validações.',
    },

    dataList: {
      type: [Boolean],
      required: false,
      description: 'Propriedade que define se existe lista a ser utilizada',
    },

    listId: {
      type: [String],
      required: false,
      description: 'Propriedade que define o id da lista a ser utilizada',
    },

    listOptions: {
      type: [Array],
      required: false,
      description: 'Pilha de dados da lista',
    },

    refInput: {
      type: String,
      required: false,
      description: 'Propriedade de ref do input',
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false,
      description: 'Propriedade que define o input apenas como leitura',
    },
  },

  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: this.updateValue,
        focus: this.onFocus,
      };
    },
    getValidation() {
      if (!this.noInput) {
        return this.hasValue ? (this.valid ? 'valid' : 'invalid') : 'initial';
      } else {
        return this.defaultValue ? 'valid' : 'initial';
      }
    },
  },

  methods: {
    updateValue(event) {
      let value =
        event.target.value && !this.masked && this.mask ? event.target.value.replace(/\D/g, '') : event.target.value;

      this.hasValue = value ? true : false;

      this.$emit('input', value);
    },

    onFocus(value) {
      this.focused = true;
      this.$emit('focus', value);
    },
  },
};
</script>

<style lang="scss" src="./style.scss"></style>
