<template>
  <base-pill-modal :openModal="show" :pills="[]" :pillColors="[]" :headerIcons="[]" :closeOverlay="true"
    @fechar-modal="show = false">
    <template v-slot:pill-modal-body>
      <div class="col-12">
        <div class="w-100 d-flex flex-column align-items-center my-4 ajuste-scroll">
          <span class="f-11" style="color: var(--text-color-highlight)">
            <strong>{{ buscarConfig('TITULO_CHAVE_ACESSO') || 'Número' }}:</strong>
          </span>
          <p class="f-11" style="color: var(--text-color-highlight)">
            <strong class="quebra-texto">
              {{ documentoFiscal.cabecalho.chaveAcesso || documentoFiscal.cabecalho.coo }}</strong>
          </p>
          <p style="color: var(--text-color-highlight)" v-if="documentoFiscal.apuracao">
            <strong>Status: {{ obterTituloApuracao(documentoFiscal.apuracao.status) }}</strong>
          </p>
          <div class="row " v-if="documentoFiscal.uriImagem && !verificaSeDocumentoImgOuPdf">
            <div class="d-flex flex-column align-items-center">
              <ImageZoomVue :imageSrc="documentoFiscal.uriImagem" alt="Imagem do documento fiscal."
                class="formatacao-img-documento" />
              <strong class="documento-texto">Clique 2 vezes na imagem para dar zoom</strong>
            </div>

          </div>
          <div class="row ml-2 mr-2" v-if="documentoFiscal.uriImagem && verificaSeDocumentoImgOuPdf">
            <base-button class="fundo-botao-ver-documento" @click="abrirPdfOutraGuia">
              Clique aqui para visualizar o documento
            </base-button>
          </div>
          <div class="row mt-3">
            <p class="titulo-historico text-center"
              v-if="documentoFiscal.apuracao && documentoFiscal.apuracao.historico.length">
              Histórico de Status
            </p>
          </div>
          <div v-if="documentoFiscal.apuracao && documentoFiscal.apuracao.historico.length">
            <div v-for="item in documentoFiscal.apuracao.historico" :key="item.dataHora">
              <p class="texto-status-data text-center ">
                {{ obterTituloApuracao(item.status) }} - {{ dataHoraFormatada(item.dataHora) }}
              </p>
              <div class="d-flex justify-content-center">
                <p v-if="item.observacao" class="text-center w-90 quebra-texto">
                  <span class="font-weight-bold">Observação: </span>{{ item.observacao }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:pill-modal-footer>
      <div class="w-100 d-flex flex-row justify-content-center"></div>
    </template>
  </base-pill-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import { Data } from '../../lib/Helper';
import ImageZoomVue from '../../components/layout/template/ImageZoom.vue';

export default {
  name: 'detalhes-documento-fiscal',


  components: {
    ImageZoomVue
  },

  computed: {
    ...mapGetters({
      config: 'config/getConfig',
      documentoFiscal: 'documentoFiscal/getDocumentoFiscal',
    }),

    verificaSeDocumentoImgOuPdf() {
      if (this.documentoFiscal && this.documentoFiscal.uriImagem) {
        const tipoDocumento = this.documentoFiscal.uriImagem.endsWith('pdf') ? true : false;
        return tipoDocumento;
      }
      return false;
    },
  },

  data: () => ({
    show: false,
  }),

  methods: {
    buscarConfig(nome) {
      if (this.config.TABELA_DOCUMENTOS_FISCAIS_INFORMACOES) {
        const config = this.config.TABELA_DOCUMENTOS_FISCAIS_INFORMACOES.find(e => e.nome == nome);
        if (config) {
          return config.valor;
        }
      }
      return null;
    },

    fecharNavBar() {
      this.show = false;
    },
    obterTituloApuracao(status) {
      let titulo = 'PENDENTE';
      if (status === 'VALIDO') titulo = 'VÁLIDO';
      if (status === 'INVALIDO') titulo = 'INVÁLIDO';
      return titulo;
    },
    dataHoraFormatada(dataHora) {
      return Data.dataHoraFormatada(dataHora);
    },

    abrirPdfOutraGuia() {
      window.open(this.documentoFiscal.uriImagem, '_blank', 'noreferrer');
    },
  },

  props: {
    showModal: {
      type: Boolean,
      description: 'Modal display boolean',
      default: false,
    },
    detalhesDocumentoFiscal: {
      type: Boolean,
      description: 'Propriedade que define se este componente sera utilizado.',
      default: false,
    },
  },

  watch: {
    show(val) {
      if (!val) {
        this.$emit('emmitEvent', {
          event: 'closeDetalhesDocumentoFiscal',
        });
      }
    },
    showModal(val) {
      if (val) {
        this.show = true;
      }
    },
  },
};
</script>

<style scoped>
.ajuste-scroll {
  overflow-y: scroll;
  max-height: 85vh;
}

.quebra-texto {
  word-break: break-word;
}

.titulo-historico {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.2;
}

.texto-status-data {
  font-size: 1.5rem;
}

.formatacao-img-documento {
  width: 25rem;
  border: 1px solid #000000;
}

.fundo-botao-ver-documento {
  background-color: #e1e1e1;
}

@media (max-width: 480px) {

  .documento-texto {
    display: block;
    font-size: .8em;
    width: 15rem;
    text-align: center;
  }

  .formatacao-img-documento {
    width: 15rem;
  }

  .titulo-historico {
    font-size: 1.4rem;
  }

  .texto-status-data {
    font-size: 1.3rem;
  }
}

@media (max-height: 600px) {
  .formatacao-img-documento {
    max-height: 20rem;
  }
}
</style>
