import Vue from 'vue'

const initialState = () => ({
  grupos: []
})

const state = initialState()

const getters = {
  getGrupos: state => state.grupos
}

const actions = {
  fetchGrupos({ commit }, { campaign }) {
    return new Promise(async resolve => {
      const api = new Vue.prototype.$httpClient()
      const url = `campanha/v1/gruposassociados/${campaign.ano}/${campaign.identificacao}`
      const response = await api.send(url, 'GET', null, undefined, false)

      if (response.data.status == 200) {
        commit('setGrupos', response.data.retorno)
        resolve({
          status: 200,
          mensagem: 'Lista de grupos recuperada com sucesso'
        })
      } else {
        commit('setGrupos', [])
        resolve({
          status: response.data.status,
          mensagem: response.data.mensagem
        })
      }
    })
  }
}

const mutations = {
  setGrupos(state, data) {
    state.grupos = data
  }
}

export default { namespaced: true, state, getters, actions, mutations }
