<template>
  <div
    class="base-pill-check-box-group"
    :style="
      `--checkbox-ativa: ${
        config.COR_DESTAQUE_CIRCUNFERENCIA_DOS_INPUTS ? config.COR_DESTAQUE_CIRCUNFERENCIA_DOS_INPUTS : '#000'
      };`
    "
  >
    <div class="pill-check-box" :class="[pillSize]" v-show="campoSwitch">
      <i
        :id="id"
        :name="name"
        @click="handleClick"
        :checked="checked"
        style="font-size: 2rem; padding-left: 1rem"
        :class="checked ? 'fas fa-toggle-on cor-ligada' : 'fas fa-toggle-off cor-desligada'"
      ></i>
      <slot name="pill-check-box-title"></slot>
    </div>
    <div class="pill-check-box" :class="[pillSize]" v-show="!campoSwitch" @click="handleClick">
      <input
        :id="id"
        :name="name"
        class="input-pill-check-box"
        :type="type ? type : 'checkbox'"
        :disabled="disabled"
        :checked="checked"
      />
      <span class="thumb-pill-check-box">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" aria-hidden="true" focusable="false">
          <path fill="none" stroke="currentColor" stroke-width="3" d="M1.73 12.91l6.37 6.37L22.79 4.59" />
        </svg>
      </span>
      <slot name="pill-check-box-title"></slot>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'BasePillCheckBox',
  // caso seja um radio button, o valor que o botão deve armazenar deve ser declarado no "id"
  props: {
    id: {
      type: String,
      required: true,
      description: 'ID of the input so we can create a label for that input',
    },

    type: {
      type: String,
      required: false,
      default: 'checkbox',
    },

    name: {
      type: String,
      required: true,
      description: 'Propriedade que define o grupo de um mesmo elemento, permitindo a seleção de apenas um do mesmo.',
    },

    value: {
      type: [String, Boolean],
      required: false,
      default: false,
    },

    selectedValue: {
      type: [Boolean, String],
      required: false,
      default: false,
      description:
        'Propriedade que armazena o valor de uma seleção, e que refere-se aos possíveis valores dos componentes irmãos.',
    },

    campoSwitch: {
      type: Boolean,
      required: false,
      default: false,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    title: {
      type: String,
      required: false,
    },

    pillSize: {
      type: String,
      required: false,
      default: 'pill-average',
    },
  },

  data: () => ({
    checked: false,
  }),

  beforeMount() {
    this.verificarSelecao();
  },

  computed: {
    ...mapGetters({
      config: 'config/getConfig',
    }),

    buttonTypeClass() {
      return `pill-check-box-${this.type}`;
    },

    classesArray() {
      return [this.buttonTypeClass, { disabled: this.disabled }];
    },
  },

  methods: {
    handleClick(event) {
      if (!this.disabled) {
        this.checked = !this.checked;
        this.$emit('click', event);
      }
    },

    verificarSelecao() {
      if (this.type == 'radio') {
        this.id == this.selectedValue ? (this.checked = true) : (this.checked = false);
      } else {
        this.selectedValue ? (this.checked = true) : (this.checked = false);
      }
    },
  },
  watch: {
    selectedValue: function() {
      this.verificarSelecao();
    },
  },
};
</script>

<style lang="scss" src="./style.scss"></style>
