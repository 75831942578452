<template>
  <div class="pill-button-group">
    <button
      @click="handleClick"
      class="pill-button"
      :class="[classesArray]"
      :disabled="disabled"
    >
      <slot name="left-middle-pill">
        <div
          class="left-middle-pill"
          :class="[pillColors, pillSize, pillBorderColors]"
          :style=" leftMiddlePillRootColor ? `background-color: ${leftMiddlePillRootColor} !important;` : ''"
          v-if="leftMiddlePill"
        >
          <i
            :class="[leftMiddlePill, pillIconColors]"
            :style=" leftMiddlePillIconRootColor ? `color: ${leftMiddlePillIconRootColor} !important;` : ''"
          ></i>
        </div>
      </slot>
      <slot name="pill-button-title"></slot>
      <slot name="right-middle-pill">
        <div
          class="right-middle-pill"
          :class="[pillColors, pillSize]"
          :style=" rightMiddlePillRootColor ? `background-color: ${rightMiddlePillRootColor} !important;` : ''"
          v-if="rightMiddlePill"
        >
          <i
            :class="[rightMiddlePill, pillIconColors]"
            :style=" rightMiddlePillIconRootColor ? `color: ${rightMiddlePillIconRootColor} !important;` : ''"
          ></i>
        </div>
      </slot>
    </button>
  </div>
</template>

<script>
export default {
  name: 'BasePillButton',

  props: {
    type: {
      type: String,
      required: false,
      default: 'primary'
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false
    },

    disabledMessage: {
      type: String,
      required: false
    },

    leftMiddlePill: {
      type: String,
      required: false,
      description: 'Pill at left button'
    },

    rightMiddlePill: {
      type: String,
      required: false,
      description: 'Pill at rigth button'
    },

    pillColors: {
      type: Array,
      required: false,
      description: 'Pill classes'
    },

    pillBorderColors: {
      type: [String, Array],
      required: false,
      default: '',
      description: 'Propriedade que define a cor das bordas do botão (se declarada)'
    },

    pillIconColors: {
        type: Array,
        required: false,
        description: "Propriedade que define as cores dos ícones de cada pill, respectivamente, de acordo com as cores do elemento pai"
    },

    pillSize: {
      type: Array,
      required: false,
      description: 'Pill classes'
    },

    leftMiddlePillRootColor: {
      type: String,
      required: false,
      default: '',
      description: "Propriedade que define alguma cor capaz de sobrescrever a cor declarada na pílula",
    },

    leftMiddlePillIconRootColor: {
      type: String,
      required: false,
      default: '',
      description: "Propriedade que define alguma cor capaz de sobrescrever a cor declarada no ícone pílula",
    },

    rightMiddlePillRootColor: {
      type: String,
      required: false,
      default: '',
      description: "Propriedade que define alguma cor capaz de sobrescrever a cor declarada na pílula",
    },

    rightMiddlePillIconRootColor: {
      type: String,
      required: false,
      default: '',
      description: "Propriedade que define alguma cor capaz de sobrescrever a cor declarada no ícone pílula",
    },
  },

  computed: {
    buttonTypeClass() {
      return `pill-button-${this.type}`
    },

    classesArray() {
      return [this.buttonTypeClass, { disabled: this.disabled }]
    }
  },

  methods: {
    handleClick(event) {
      if (!this.disabled) {
        this.$emit('click', event)
      } else {
        this.$swal.fire({
          icon: 'warning',
          title: 'Ops...',
          text: this.disabledMessage
        })
      }
    }
  }
}
</script>
<style lang="scss" src="./style.scss"></style>
