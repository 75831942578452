/**
 * Retorno padrão para chamadas à métodos e api.
 * @class {Escanear}
 */
class Escanear {
  // static async enviar(comunicacao, cupom) {
  //   // Enviando requisição para o endpoint de escanear cupons
  //   let resposta = await comunicacao.send(
  //     `/cupom/v2/cupons/escanear/${cupom.anoCampanha}/${cupom.identificacaoCampanha}/${cupom.numeroAleatorio}`,
  //     'get',
  //     null,
  //     undefined,
  //     false
  //   );
  //   // Retornando resposta
  //   if (resposta.data) {
  //     return resposta.data;
  //   } else {
  //     return { status: 500, mensagem: 'Erro de rede', retorno: null };
  //   }
  // }
  /**
   * Transforma o URL em um objeto de cupom
   * @param {String} url - url
   */
  static decodarURL(url) {
    let parametros = url.split('/').reverse()[0].split('-');
    if (parametros.length < 3) return false;
    const cupom = {
      anoCampanha: parametros[0],
      identificacaoCampanha: parametros[1],
      numeroAleatorio: parametros[2].includes("\n") ? parametros[2].split("\n")[0].toString() : parametros[2]
    };
    return cupom;
  }
}

module.exports = Escanear;