<template>
  <div class="inicio-container">
    <!-- Partículas -->
    <the-particles class="inicio-particles" v-if="config.EXIBIR_PARTICULAS_ANIMADAS_TELAS_AUTH" />

    <!-- Modal conexão internet -->
    <base-pill-modal
      :openModal="modais.conexaoInternet"
      :pills="[true, false]"
      :pillColors="['pill-dark']"
      :headerIcons="['fas fa-times']"
      :closeOverlay="true"
      @fechar-modal="modais.conexaoInternet = false"
    >
      <template v-slot:pill-modal-body>
        <div class="w-100 d-flex flex-column align-items-center mb-3">
          <h4 class="text-align-center text-black">
            <strong>Ops...</strong>
          </h4>
          <p class="w-75 text-align-center text-center text-black">
            <small
              ><strong>
                Não foi possível recuperar campanhas promocionais disponíveis no momento, verifique sua conexão com a
                internet.
              </strong></small
            >
          </p>
        </div>
      </template>
      <template v-slot:pill-modal-footer>
        <div class="w-100 d-flex flex-row justify-content-center">
          <base-pill-button
            :type="'light'"
            :class="'mt-3'"
            :right-middle-pill="'fas fa-check-circle'"
            :pillColors="['pill-dark']"
            :rightMiddlePillRootColor="config.COR_DESTAQUE_CIRCUNFERENCIA_DOS_BOTOES"
            :rightMiddlePillIconRootColor="config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_DOS_BOTOES"
            :pillSize="['pill-average']"
            @click="modais.conexaoInternet = false"
          >
            <template v-slot:pill-button-title>
              <span class="w-90 px-2 text-center text-black text-uppercase"><strong>OK</strong></span>
            </template>
          </base-pill-button>
        </div>
      </template>
    </base-pill-modal>

    <!-- Modal campanha bloqueada -->
    <base-pill-modal
      :openModal="modais.campanhaBloqueada"
      :pills="[true, false]"
      :pillColors="['pill-dark']"
      :headerIcons="['far fa-sad-tear']"
      :closeOverlay="true"
      @fechar-modal="modais.campanhaBloqueada = false"
    >
      <template v-slot:pill-modal-body>
        <div class="w-100 d-flex flex-column align-items-center mb-3">
          <h4 class="text-align-center text-black">
            <strong>Ops...</strong>
          </h4>
          <p class="w-75 text-align-center text-center text-black">
            <small><strong> Não há campanhas promocionais disponíveis no momento! </strong></small>
          </p>
        </div>
      </template>
      <template v-slot:pill-modal-footer>
        <div class="w-100 d-flex flex-row justify-content-center">
          <base-pill-button
            :type="'light'"
            :class="'mt-3'"
            :right-middle-pill="'fas fa-check-circle'"
            :pillColors="['pill-dark']"
            :rightMiddlePillRootColor="config.COR_DESTAQUE_CIRCUNFERENCIA_DOS_BOTOES"
            :rightMiddlePillIconRootColor="config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_DOS_BOTOES"
            :pillSize="['pill-average']"
            @click="modais.campanhaBloqueada = false"
          >
            <template v-slot:pill-button-title>
              <span class="w-90 px-2 text-center text-black text-uppercase"><strong>OK</strong></span>
            </template>
          </base-pill-button>
        </div>
      </template>
    </base-pill-modal>

    <!-- modal erro ao obter campanhas -->
    <base-pill-modal
      :openModal="modais.erroObterCampanha"
      :pills="[true, false]"
      :pillColors="['pill-dark']"
      :headerIcons="['far fa-sad-tear']"
      :closeOverlay="true"
      @fechar-modal="modais.erroObterCampanha = false"
    >
      <template v-slot:pill-modal-body>
        <div class="w-100 d-flex flex-column align-items-center mb-3">
          <h4 class="text-align-center text-black">
            <strong>Ops...</strong>
          </h4>
          <p class="w-75 text-align-center text-center text-black">
            <small
              ><strong>
                Não foi possível recuperar campanhas promocionais disponíveis no momento, verifique sua conexão com a
                internet.
              </strong></small
            >
          </p>
        </div>
      </template>
      <template v-slot:pill-modal-footer>
        <div class="w-100 d-flex flex-row justify-content-center">
          <base-pill-button
            :type="'light'"
            :class="'mt-3'"
            :right-middle-pill="'fas fa-check-circle'"
            :pillColors="['pill-dark']"
            :rightMiddlePillRootColor="config.COR_DESTAQUE_CIRCUNFERENCIA_DOS_BOTOES"
            :rightMiddlePillIconRootColor="config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_DOS_BOTOES"
            :pillSize="['pill-average']"
            @click="modais.erroObterCampanha = false"
          >
            <template v-slot:pill-button-title>
              <span class="w-90 px-2 text-center text-black text-uppercase"><strong>OK</strong></span>
            </template>
          </base-pill-button>
        </div>
      </template>
    </base-pill-modal>

    <!-- Modal respostas incompletas|termos não aceitos (telas LGPD) -->
    <base-pill-modal
      :openModal="modais.errosLGPD.ativo"
      :pills="[true, false]"
      :pillColors="['pill-dark']"
      :headerIcons="['fas fa-exclamation-circle']"
      :closeOverlay="true"
      @fechar-modal="modais.errosLGPD.ativo = false"
    >
      <template v-slot:pill-modal-body>
        <div class="w-100 d-flex flex-column align-items-center mb-3">
          <h4 class="text-align-center text-black">
            <strong>{{ modais.errosLGPD.titulo }}</strong>
          </h4>
          <p class="w-75 text-align-center text-center text-black">
            <small
              ><strong>
                {{ modais.errosLGPD.mensagem }}
              </strong></small
            >
          </p>
        </div>
      </template>
      <template v-slot:pill-modal-footer>
        <div class="w-100 d-flex flex-row justify-content-center">
          <base-pill-button
            :type="'light'"
            :class="'mt-3'"
            :right-middle-pill="'fas fa-check-circle'"
            :pillColors="['pill-dark']"
            :rightMiddlePillRootColor="config.COR_DESTAQUE_CIRCUNFERENCIA_DOS_BOTOES"
            :rightMiddlePillIconRootColor="config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_DOS_BOTOES"
            :pillSize="['pill-average']"
            @click="modais.errosLGPD.ativo = false"
          >
            <template v-slot:pill-button-title>
              <span class="w-90 px-2 text-center text-black text-uppercase"><strong>OK</strong></span>
            </template>
          </base-pill-button>
        </div>
      </template>
    </base-pill-modal>

    <!-- Modal respostas aceitos (telas LGPD) -->
    <base-pill-modal
      :openModal="modais.errosAceites.ativo"
      :pills="[true, false]"
      :pillColors="['pill-dark']"
      :headerIcons="['fas fa-exclamation-circle']"
      :closeOverlay="true"
      @fechar-modal="modais.errosAceites.ativo = false"
    >
      <template v-slot:pill-modal-body>
        <div class="w-100 d-flex flex-column align-items-center mb-3">
          <h4 class="text-align-center text-black">
            <strong>{{ modais.errosAceites.titulo }}</strong>
          </h4>
          <p class="w-75 text-align-center text-center text-black">
            <small
              ><strong>
                {{ modais.errosAceites.mensagem }}
              </strong></small
            >
          </p>
        </div>
      </template>
      <template v-slot:pill-modal-footer>
        <div class="w-100 d-flex flex-row justify-content-center">
          <base-pill-button
            :type="'light'"
            :class="'mt-3'"
            :right-middle-pill="'fas fa-check-circle'"
            :pillColors="['pill-dark']"
            :rightMiddlePillRootColor="config.COR_DESTAQUE_CIRCUNFERENCIA_DOS_BOTOES"
            :rightMiddlePillIconRootColor="config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_DOS_BOTOES"
            :pillSize="['pill-average']"
            @click="modais.errosAceites.ativo = false"
          >
            <template v-slot:pill-button-title>
              <span class="w-90 px-2 text-center text-black text-uppercase"><strong>OK</strong></span>
            </template>
          </base-pill-button>
        </div>
      </template>
    </base-pill-modal>

    <!-- Modal erros de autenticacao (cadastro|login) -->
    <base-pill-modal
      :openModal="modais.errosAutenticacao.ativo"
      :pills="[true, false]"
      :pillColors="['pill-dark']"
      :headerIcons="['fas fa-exclamation-circle']"
      :closeOverlay="true"
      @fechar-modal="modais.errosAutenticacao.ativo = false"
    >
      <template v-slot:pill-modal-body>
        <div class="w-100 d-flex flex-column align-items-center mb-3">
          <h4 class="text-align-center text-black">
            <strong>{{ modais.errosAutenticacao.titulo }}</strong>
          </h4>
          <p class="w-75 text-align-center text-center text-black">
            <small><strong v-html="modais.errosAutenticacao.mensagem"> </strong></small>
          </p>
        </div>
      </template>
      <template v-slot:pill-modal-footer>
        <div class="w-100 d-flex flex-row justify-content-center">
          <base-pill-button
            :type="'light'"
            :class="'mt-3'"
            :right-middle-pill="'fas fa-check-circle'"
            :pillColors="['pill-dark']"
            :rightMiddlePillRootColor="config.COR_DESTAQUE_CIRCUNFERENCIA_DOS_BOTOES"
            :rightMiddlePillIconRootColor="config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_DOS_BOTOES"
            :pillSize="['pill-average']"
            @click="modais.errosAutenticacao.ativo = false"
          >
            <template v-slot:pill-button-title>
              <span class="w-90 px-2 text-center text-black text-uppercase"><strong>OK</strong></span>
            </template>
          </base-pill-button>
        </div>
      </template>
    </base-pill-modal>

    <!-- Imagens/logo -->
    <div class="container-logos-inicio" :style="loginExiste ? 'height: 40vh;' : ''">
      <transition name="fade" appear>
        <div
          class="w-100 d-flex flex-row align-items-center justify-content-center inicio-top-logo"
          v-if="imagens.imagemTopo"
        >
          <a
            class="mt-4 mb-2 link"
            v-if="config.LINK_REDIRECIONA_INTRO_IMG"
            target="_blank"
            :href="config.LINK_REDIRECIONA_INTRO_IMG"
          >
            <img :src="config.INTRO_IMG_URL" alt="Logo Polgo" />
          </a>
          <img v-else :src="config.INTRO_IMG_URL" class="mt-4 mb-2" alt="Logo Polgo" />
        </div>
      </transition>
      <transition name="fade" appear>
        <div
          class="w-100 d-flex flex-row align-items-center justify-content-center inicio-top-logo-alternative"
          v-if="imagens.imagensAlternadas"
        >
          <img
            :src="config.LOGO_LOGIN_IMG_URL || config.CAMPANHA_IMG_URL"
            :class="navBar.aceites ? 'mt-1 mb-1' : 'mt-4 mb-2'"
            alt="Logo campanha"
            :style="navBar.aceites ? 'max-height: 22vh' : 'max-height: 16vh;'"
          />
        </div>
      </transition>
      <transition name="fade" appear>
        <div
          class="w-100 d-flex flex-row align-items-center justify-content-center inicio-center-logo"
          :style="loginExiste ? 'top: 35%; bottom: 65%;' : ''"
          v-if="imagens.imagemCentral"
        >
          <img
            :src="config.LOGO_LOGIN_IMG_URL || config.CAMPANHA_IMG_URL"
            class="mt-1 mb-1"
            alt="Logo campanha"
            :style="`max-width: ${larguraLogoCentral}%`"
          />
          <!-- <div
          v-if="this.config.LOGO_LOGIN_IMG_URL"
          class="campaing-logo"
        >
          <img
            :src="config.LOGO_LOGIN_IMG_URL"
            alt="Campaign logo"
            id="primeiraImg"
          />
          <img
            v-if="this.config.SEGUNDA_IMG_LOGO_LOGIN"
            :src="config.SEGUNDA_IMG_LOGO_LOGIN"
            alt="Campaign logo"
            id="segundaImg"
            style="display: none"
          />
        </div>
        <div
          v-if="!this.config.LOGO_LOGIN_IMG_URL"
          class="campaing-logo"
        >
          <img
            :src="config.CAMPANHA_IMG_URL"
            alt="Campaign logo"
            id="primeiraImg"
          />
          <img
            v-if="this.config.SEGUNDA_IMG_LOGO_LOGIN"
            :src="config.SEGUNDA_IMG_LOGO_LOGIN"
            alt="Campaign logo"
            id="segundaImg"
            style="display: none"
          />
        </div> -->
        </div>
      </transition>
    </div>

    <!-- Controles -->
    <base-pill-nav-bar :exibir="navBar.controle || navBar.login" :fecharNavBar="false" :exibirBackground="false">
      <template v-slot:header-pill-nav-bar>
        <!-- Botões iniciais -->
        <!-- COR_DESTAQUE_CIRCUNFERENCIA_DOS_BOTOES -->
        <!-- COR_DESTAQUE_ICONE_CIRCUNFERENCIA_DOS_BOTOES -->
        <div class="card-login mt-5 mb-2" :class="!loginExiste ? 'mb-4' : ''">
          <div class="d-flex align-items-end container-login" :class="loginExiste ? 'mb-2' : 'mt-2'">
            <base-input
              class="mb-0"
              @keyup.enter="$v.usuario.$invalid ? false : verificarLogin()"
              :type="'tel'"
              :placeholder="`${
                config.TIPO_USUARIO == 'cpfCnpj' ? 'CPF/CNPJ' : config.TIPO_USUARIO == 'cnpj' ? 'CNPJ' : 'CPF'
              }`"
              :label="`Para continuar, digite seu ${
                config.TIPO_USUARIO == 'cpfCnpj' ? 'CPF ou CNPJ' : config.TIPO_USUARIO == 'cnpj' ? 'CNPJ' : 'CPF'
              }`"
              :labelClasses="`login-input-label text-secondary mb-4`"
              :inputClasses="`login-input text-secondary ${loginExiste ? 'w-100' : ''}`"
              :mask="
                config.TIPO_USUARIO == 'cpfCnpj'
                  ? ['###.###.###-##', '##.###.###/####-##']
                  : config.TIPO_USUARIO == 'cnpj'
                  ? ['##.###.###/####-##']
                  : ['###.###.###-##']
              "
              :masked="false"
              v-model="$v.usuario.$model"
              :valid="$v.usuario.$invalid && (usuario.length == 11 || usuario.length == 14) ? false : undefined"
              :error="$v.usuario.$error && (usuario.length == 11 || usuario.length == 14)"
              style="margin-bottom: 0"
            />
            <button
              v-if="!loginExiste"
              type="button"
              :disabled="$v.usuario.$invalid ? true : false"
              @click="$v.usuario.$invalid ? false : verificarLogin()"
              class="botao-login"
              :style="$v.usuario.$invalid ? 'opacity: .65' : ''"
            >
              <i class="fas fa-chevron-right" />
            </button>
          </div>
          <span
            class="text-danger invalido"
            v-if="$v.usuario.$invalid && (usuario.length == 11 || usuario.length == 14)"
          >
            {{
              `${
                config.TIPO_USUARIO == 'cpfCnpj' ? 'CPF/CNPJ' : config.TIPO_USUARIO == 'cnpj' ? 'CNPJ' : 'CPF'
              } inválido`
            }}
          </span>
          <div
            v-if="carregando || carregandoAutenticacao"
            class="w-100 d-flex justify-content-center align-items-center mt-4 mb-3"
          >
            <div class="loading"></div>
          </div>
          <div
            v-if="loginExiste && !carregando && !carregandoAutenticacao"
            class="d-flex flex-row justify-between align-items-end container-login w-100"
            :class="loginExiste ? 'mb-2' : ''"
          >
            <base-input
              id="input-senha"
              autocomplete="off"
              :type="mostrarSenha ? 'text' : 'password'"
              placeholder="Digite sua senha"
              label="Senha"
              labelClasses="login-input-label text-secondary"
              inputClasses="login-input text-secondary w-100"
              :style="'margin-bottom: 0; width: 100%'"
              v-model="$v.senha.$model"
              @keyup.enter="!$v.senha.$invalid ? autenticar() : exibirErroValidacaoSenha()"
            />
          </div>
          <div
            v-if="loginExiste && (usuario.length == 11 || usuario.length == 14)"
            class="w-100 d-flex align-items-end justify-content-end container-login mb-4"
            style="height: fit-content"
          >
            <span @click="alternarTelaEsqueciSenha()" class="esqueci-senha cursor-pointer">Esqueci minha senha</span>
          </div>
          <div v-if="loginExiste" class="w-100 d-flex flex-row align-items-center justify-content-center mt-2 mb-2">
            <base-pill-button
              :type="'light'"
              :right-middle-pill="'fas fa-sign-in-alt'"
              :pillColors="['pill-light']"
              :pillIconColors="['icon-dark']"
              :rightMiddlePillRootColor="config.COR_DESTAQUE_CIRCUNFERENCIA_DOS_BOTOES"
              :rightMiddlePillIconRootColor="config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_DOS_BOTOES"
              :pillSize="['pill-average']"
              @click="!$v.senha.$invalid ? autenticar() : exibirErroValidacaoSenha()"
              :disabled="!senha"
              :style="!senha ? 'opacity: .65' : ''"
            >
              <template v-slot:pill-button-title>
                <span class="w-90 pl-3 pr-2 text-center f-07 text-black">Entrar</span>
              </template>
            </base-pill-button>
          </div>
          <!-- <button
            class="botao-entrar"
            v-if="loginExiste"
            :disabled="$v.senha.$invalid"
            :style="$v.senha.$invalid ? 'opacity: .65' : ''"
            @click="autenticar()"
          >
            Entrar
          </button> -->
        </div>
      </template>
    </base-pill-nav-bar>

    <!-- Login -->
    <!-- <base-pill-nav-bar
      :exibir="navBar.login"
      :fecharNavBar="navBar.login"
      :exibirBackground="config.EXIBIR_FUNDO_NAVBAR_AUTH"
      @fechar-nav-bar="alternarLogin()"
    >
      <template v-slot:body-pill-nav-bar>
        <the-login
          v-on="$listeners"
          :isProd="isProd"
          @alternar-exibicao="alternarExibicao"
          @alternar-modal="alternarModal"
        />
      </template>

      <template v-slot:footer-pill-nav-bar>
        <img :src="config.INTRO_IMG_URL" class="w-25 mt-2 mb-2" alt="Logo Polgo" />
      </template>
    </base-pill-nav-bar> -->

    <!-- Cadastro -->
    <base-pill-nav-bar
      :exibir="navBar.cadastro"
      :fecharNavBar="navBar.cadastro"
      :exibirBackground="config.EXIBIR_FUNDO_NAVBAR_AUTH"
      @fechar-nav-bar="alternarCadastro()"
      :pillNavBarClasses="'ocultar-rolagem'"
    >
      <template v-slot:body-pill-nav-bar>
        <the-cadastro
          :isProd="isProd"
          :telaPreCadastro="estado.telaAnteriorCadastro"
          :autocompletarCadastro="parametrosURL.autocompletarCadastro"
          :nomeUsuario="parametrosURL.nome"
          :emailUsuario="parametrosURL.email"
          :phoneUsuario="parametrosURL.phone"
          v-on="$listeners"
          @alternar-exibicao="alternarExibicao"
          @alternar-modal="alternarModal"
          class="pb-5"
        />
      </template>

      <!-- <template v-slot:footer-pill-nav-bar>
        <img :src="config.INTRO_IMG_URL" class="w-25 mt-1 mb-1" alt="Logo Polgo" />
      </template> -->
    </base-pill-nav-bar>

    <!-- LGPD -->
    <base-pill-nav-bar
      :exibir="navBar.lgpd"
      :fecharNavBar="navBar.lgpd"
      :exibirBackground="config.EXIBIR_FUNDO_NAVBAR_AUTH"
      @fechar-nav-bar="alternarLGPD()"
    >
      <template v-slot:body-pill-nav-bar>
        <the-lgpd
          v-on="$listeners"
          :isProd="isProd"
          @alternar-exibicao="alternarExibicao"
          @alternar-modal="alternarModal"
        />
      </template>

      <template v-slot:footer-pill-nav-bar>
        <img :src="config.INTRO_IMG_URL" class="w-25 mt-2 mb-2" alt="Logo Polgo" />
      </template>
    </base-pill-nav-bar>

    <!-- LGPD Detalhada -->
    <base-pill-nav-bar
      :exibir="navBar.lgpdDetalhada"
      :fecharNavBar="navBar.lgpdDetalhada"
      :exibirBackground="config.EXIBIR_FUNDO_NAVBAR_AUTH"
      @fechar-nav-bar="alternarLGPDDetalhada()"
    >
      <template v-slot:body-pill-nav-bar>
        <the-lgpd-detalhada
          :isProd="isProd"
          v-on="$listeners"
          @alternar-exibicao="alternarExibicao"
          @alternar-modal="alternarModal"
        />
      </template>

      <template v-slot:footer-pill-nav-bar>
        <img :src="config.INTRO_IMG_URL" class="w-25 mt-2 mb-2" alt="Logo Polgo" />
      </template>
    </base-pill-nav-bar>

    <!-- LGPD Detalhada -->
    <base-pill-nav-bar
      :exibir="navBar.aceites"
      :fecharNavBar="navBar.aceites"
      :exibirBackground="config.EXIBIR_FUNDO_NAVBAR_AUTH"
      @fechar-nav-bar="alternarAceites()"
    >
      <template v-slot:body-pill-nav-bar>
        <the-aceites
          :isProd="isProd"
          v-on="$listeners"
          @alternar-exibicao="alternarExibicao"
          @alternar-modal="alternarModal"
        />
      </template>

      <template v-slot:footer-pill-nav-bar>
        <img :src="config.INTRO_IMG_URL" class="w-25 mt-2 mb-2" alt="Logo Polgo" />
      </template>
    </base-pill-nav-bar>

    <!-- Termos -->

    <!-- Primeiro Acesso -->
    <base-pill-nav-bar
      :exibir="navBar.primeiroAcesso"
      :fecharNavBar="navBar.primeiroAcesso"
      :exibirBackground="config.EXIBIR_FUNDO_NAVBAR_AUTH"
      @fechar-nav-bar="alternarPrimeiroAcesso()"
    >
      <template v-slot:body-pill-nav-bar>
        <the-primeiro-acesso :isProd="isProd" v-on="$listeners" @alternar-exibicao="alternarExibicao" />
      </template>

      <template v-slot:footer-pill-nav-bar>
        <img :src="config.INTRO_IMG_URL" class="w-25 mt-2 mb-2" alt="Logo Polgo" />
      </template>
    </base-pill-nav-bar>

    <!-- Pré registro -->

    <!-- Redefinir senha via e-mail -->
    <base-pill-nav-bar
      :exibir="navBar.esqueciSenha"
      :fecharNavBar="navBar.esqueciSenha"
      :exibirBackground="config.EXIBIR_FUNDO_NAVBAR_AUTH"
      @fechar-nav-bar="alternarEsqueciSenha()"
    >
      <template v-slot:body-pill-nav-bar>
        <the-esqueci-senha
          :isProd="isProd"
          v-on="$listeners"
          @alternar-exibicao="alternarExibicao"
          :usuario="usuario"
        />
      </template>

      <template v-slot:footer-pill-nav-bar>
        <img :src="config.INTRO_IMG_URL" class="w-25 mt-2 mb-2" alt="Logo Polgo" />
      </template>
    </base-pill-nav-bar>

    <!-- Modal para quando as configurações MODIFICAR_CAMPOS_PERFIL e CAMPOS_OBRIGATORIOS_PERFIL não incluir email -->
    <base-modal
      :show.sync="modais.emailSuporte.ativo"
      modal-classes="d-flex align-items-center qr-modal-middle"
      headerClasses="p-2 text-center pl-4"
      body-classes="p-0"
      preventClose
    >
      <template slot="header">Atenção</template>
      <template>
        <base-card header-classes="bg-white pb-5 text-center" body-classes="px-0 py-0" class="border-0">
          <div class="text-center d-flex justify-content-center align-items-center flex-column p-4 mb-3">
            Caro cliente, por favor entrar em contato com nosso suporte através do email abaixo:
            <strong>
              {{ this.config.EMAIL_SUPORTE }}
            </strong>
          </div>
        </base-card>
      </template>
    </base-modal>

    <!-- Selecionar meio para recuperar senha (esqueci senha) -->
    <base-pill-nav-bar
      :exibir="navBar.selecionarMeioEsqueciSenha"
      :fecharNavBar="navBar.selecionarMeioEsqueciSenha"
      :exibirBackground="config.EXIBIR_FUNDO_NAVBAR_AUTH"
      @fechar-nav-bar="alternarSelecionarMeioEsqueciSenha()"
    >
      <template v-slot:body-pill-nav-bar>
        <the-selecionar-meio-esqueci-senha
          :isProd="isProd"
          v-on="$listeners"
          @alternar-exibicao="alternarExibicao"
          :usuario="usuario"
        />
      </template>

      <template v-slot:footer-pill-nav-bar>
        <img :src="config.INTRO_IMG_URL" class="w-25 mt-2 mb-2" alt="Logo Plataforma QR Sorteios" />
      </template>
    </base-pill-nav-bar>
    <!-- Botão WhatsApp -->
    <!-- <div
      class="botao-zap"
      v-if="config.AWS_BUCKET_PASTA == 'sicoobcrediauc2' && $route.name != 'cadastro'"
      @click="
        abrirLink(
          'https://api.whatsapp.com/send?phone=554934255400&text=Preciso%20de%20apoio%20para%20Assembleia%20Digital%2C%20meu%20nome%20%C3%A9'
        )
      "
    >
      <button>Dúvidas? Contate-nos via WhatsApp</button>
      <div>
        <img src="../assets/images/whatsapp-logo.png" alt="WhatsApp" />
      </div>
    </div> -->

    <!-- Selecionar redefinir senha via SMS -->
    <base-pill-nav-bar
      :exibir="navBar.esqueciSenhaViaSMS"
      :fecharNavBar="navBar.esqueciSenhaViaSMS"
      :exibirBackground="config.EXIBIR_FUNDO_NAVBAR_AUTH"
      @fechar-nav-bar="alternarEsqueciSenhaViaSMS()"
    >
      <template v-slot:body-pill-nav-bar>
        <the-esqueci-senha-via-Sms
          :usuario="usuario"
          :isProd="isProd"
          v-on="$listeners"
          @alternar-exibicao="alternarExibicao"
        />
      </template>

      <template v-slot:footer-pill-nav-bar>
        <img :src="config.INTRO_IMG_URL" class="w-25 mt-2 mb-2" alt="Logo Plataforma QR Sorteios" />
      </template>
    </base-pill-nav-bar>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import TheLogin from './auth/TheLogin';
import TheCadastro from './auth/TheCadastro';
import TheAceites from './termos/TheAceites';
import TheLgpd from './termos/TheLgpd';
import TheLgpdDetalhada from './termos/TheLgpdDetalhada';
import TheEsqueciSenha from './auth/TheEsqueciSenha';
import ThePrimeiroAcesso from './auth/ThePrimeiroAcesso';
import { required, minLength } from 'vuelidate/lib/validators';
import { Validation, Data } from '@/lib/Helper';
import TheSelecionarMeioEsqueciSenha from './auth/TheSelecionarMeioEsqueciSenha';
import TheEsqueciSenhaViaSms from './auth/TheEsqueciSenhaViaSMS';

export default {
  name: 'Inicio',
  props: {
    isProd: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    TheLogin,
    TheCadastro,
    TheAceites,
    TheLgpd,
    TheLgpdDetalhada,
    TheEsqueciSenha,
    ThePrimeiroAcesso,
    TheSelecionarMeioEsqueciSenha,
    TheEsqueciSenhaViaSms,
  },
  async beforeMount() {
    const params = this.$route.query;
    if (localStorage.getItem('login')) {
      const tela = this.telasConfiguraveis[0].destino || 'contaCorrente';
      if (this.$route.name != tela) this.$router.push({ name: tela });
    } else if (this.config.LINK_REDIRECIONAR_DESLOGADO && Object.keys(params).length === 0) {
      window.location.href = this.config.LINK_REDIRECIONAR_DESLOGADO;
    }

    await this.carregarCampanhas();
    this.verificarParametrosCadastroURL();

    // Realizando login pelo token e/ou senha na URL
    if (Object.keys(params).length) {
      // QS: /cadastro/?cadastroNome=Teste Alpha centauri&cadastroEmail=teste.alpha@centauri.com&cadastroCpf=37762762906&cadastroPhone=19900001111
      if (params.usuario) {
        this.$emit('emmitEvent', {
          event: 'authQueryParams',
          data: {
            user: params.usuario,
            password: params.senha ? params.senha : null,
            type: this.associateMode ? 'associado' : 'consumidor',
            token: params.token ? params.token : null,
            route: params.tela,
          },
        });
      }
    }
    if (this.$route.name == 'login') {
      // this.alternarImagens();
      // this.alternarControle();
      this.navBar.controle = true;
    }
    if (this.$route.name == 'cadastro') {
      this.alternarImagens();
      this.alternarControle();
      await this.definirTelaPreCadastro();
      if (this.estado.cadastroBloqueado) {
        this.modais.campanhaBloqueada = true;
      } else {
        switch (this.estado.telaAnteriorCadastro) {
          case 'primeiro-acesso':
            this.navBar.primeiroAcesso = true;
            break;
          case 'aceites':
            this.navBar.aceites = true;
            break;
          case 'lgpd':
            this.navBar.lgpd = true;
            break;
          case 'lgpd-detalhada':
            this.navBar.lgpdDetalhada = true;
            break;
          case 'cadastro':
            this.navBar.cadastro = true;
            break;
        }
      }
    }
  },

  mounted() {
    const rota = this.$route.name;
    if (rota == 'aceites') this.obterTelaPreCadastro();
  },

  data: () => ({
    carregando: false,
    mostrarSenha: false,
    senha: '',
    loginExiste: false,
    usuario: '',
    imagens: {
      imagemTopo: true,
      imagemCentral: true,
      imagensAlternadas: false,
    },
    navBar: {
      controle: true, // padrão
      login: false,
      cadastro: false,
      lgpd: false,
      lgpdDetalhada: false,
      aceites: false,
      esqueciSenha: false,
      primeiroAcesso: false,
      selecionarMeioEsqueciSenha: false,
      esqueciSenhaViaSMS: false,
    },
    modais: {
      conexaoInternet: false,
      campanhaBloqueada: false,
      erroObterCampanha: false,
      errosLGPD: {
        ativo: false,
        titulo: '',
        mensagem: '',
      },
      errosAceites: {
        ativo: false,
        titulo: '',
        mensagem: '',
      },
      errosAutenticacao: {
        ativo: false,
        titulo: '',
        mensagem: '',
      },
      emailSuporte: {
        ativo: false,
      },
    },
    estado: {
      cadastroBloqueado: true,
      telaAnteriorCadastro: null,
    },
    parametrosURL: {
      nome: '',
      email: '',
      phone: '',
      autocompletarCadastro: false,
    },
  }),
  computed: {
    ...mapGetters({
      config: 'config/getConfig',
      telasConfiguraveis: 'config/obterTelasConfiguraveis',
      carregandoAutenticacao: 'autenticacao/isLoading',
    }),
    associateMode() {
      return this.$route.meta.associate;
    },
    larguraLogoCentral() {
      return !this.config.LARGURA_MAXIMA_LOGOTIPO_TELA_INICIAL ||
        Number.isNaN(Number(this.config.LARGURA_MAXIMA_LOGOTIPO_TELA_INICIAL))
        ? '40'
        : this.config.LARGURA_MAXIMA_LOGOTIPO_TELA_INICIAL;
    },
  },
  methods: {
    ...mapActions({
      verificarSeLoginExiste: 'consumidor/verificarSeLoginExiste',
    }),
    alternarTelaEsqueciSenha() {
      if (this.config.MODIFICAR_CAMPOS_PERFIL && !this.config.CAMPOS_OBRIGATORIOS_PERFIL.includes('email')) {
        this.modais.emailSuporte.ativo = true;
      } else if (this.config.PERMITIR_REDEFINIR_SENHA_VIA_SMS) {
        this.alternarSelecionarMeioEsqueciSenha();
      } else {
        this.alternarEsqueciSenha();
      }
    },
    async verificarLogin() {
      this.carregando = true;
      const wsUrl = this.isProd ? this.config.VUE_APP_PROD_WS_URL : this.config.VUE_APP_DEV_WS_URL;
      let resposta = await this.verificarSeLoginExiste({ user: this.usuario, wsUrl: wsUrl });
      if (resposta.status == 200) {
        this.loginExiste = true;

        this.$nextTick(() => {
          this.carregando = false;
          setTimeout(this.inputFocus, 1);
        });
      } else if (resposta.status == 404 && resposta.msg == 'Nenhum Login cadastrado com este usuário.') {
        this.loginExiste = false;
        this.parametrosURL.phone = this.usuario;
        this.obterTelaPreCadastro();
        this.carregando = false;
      } else {
        this.emitirModalInformacao({
          icone: 'fas fa-exclamation',
          titulo: 'Ops...',
          mensagem: 'Ocorreu um erro ao verificar suas credenciais de login. Tente novamente mais tarde.',
          textoBoato: 'OK',
          acao: () => {},
        });
        this.carregando = false;
      }
    },
    autenticar() {
      if (localStorage.getItem('dadosFormCadastro')) {
        localStorage.removeItem('dadosFormCadastro');
      }
      this.$emit('emmitEvent', {
        event: 'auth',
        data: {
          user: this.usuario,
          password: this.senha,
          type: this.associateMode ? 'associado' : 'consumidor',
          token: null,
        },
      });
    },
    exibirErroValidacaoSenha() {
      this.emitirModalInformacao({
        icone: 'fas fa-exclamation',
        titulo: 'Senha inválida!',
        mensagem: 'Digite uma senha com no mínimo 6 caracteres.',
        textoBoato: 'OK',
        acao: () => {},
      });
    },
    abrirLink(link) {
      window.open(link);
    },
    alternarControle() {
      this.navBar.controle = !this.navBar.controle;
    },
    alternarImagens() {
      this.imagens.imagemTopo = !this.imagens.imagemTopo;
      this.imagens.imagemCentral = !this.imagens.imagemCentral;
      this.imagens.imagensAlternadas = !this.imagens.imagensAlternadas;
    },
    // features :D
    alternarLogin() {
      this.$router.push('login');
      this.alternarImagens();
      this.alternarControle();
      this.navBar.login = !this.navBar.login;
    },
    alternarCadastro() {
      this.$router.push('cadastro');
      this.alternarImagens();
      this.alternarControle();
      this.navBar.cadastro = !this.navBar.cadastro;
      if (this.navBar.cadastro == false) {
        this.$router.back();
      }
    },
    alternarLGPD() {
      this.alternarImagens();
      this.alternarControle();
      this.navBar.lgpd = !this.navBar.lgpd;
    },
    alternarLGPDDetalhada() {
      this.alternarImagens();
      this.alternarControle();
      this.navBar.lgpdDetalhada = !this.navBar.lgpdDetalhada;
    },
    alternarAceites() {
      this.$router.push({ name: 'aceites' });
      this.alternarImagens();
      this.alternarControle();
      this.navBar.aceites = !this.navBar.aceites;
    },
    alternarEsqueciSenha() {
      this.alternarImagens();
      this.alternarControle();
      this.navBar.esqueciSenha = !this.navBar.esqueciSenha;
    },
    alternarPrimeiroAcesso() {
      this.alternarImagens();
      this.alternarControle();
      this.navBar.primeiroAcesso = !this.navBar.primeiroAcesso;
    },
    alternarSelecionarMeioEsqueciSenha() {
      this.alternarImagens();
      this.alternarControle();
      this.navBar.selecionarMeioEsqueciSenha = !this.navBar.selecionarMeioEsqueciSenha;
    },
    alternarEsqueciSenhaViaSMS() {
      this.alternarImagens();
      this.alternarControle();
      this.navBar.esqueciSenhaViaSMS = !this.navBar.esqueciSenhaViaSMS;
    },

    // Definindo telas intermediárias
    async definirTelaPreCadastro() {
      if (this.config.TELA_PRIMEIRO_ACESSO) {
        this.estado.telaAnteriorCadastro = 'primeiro-acesso';
      } else {
        if (this.config.MECANICA_ACEITES) {
          const wsUrl = this.isProd ? this.config.VUE_APP_PROD_WS_URL : this.config.VUE_APP_DEV_WS_URL;
          const url = `${wsUrl}/consumidor/v1/aceites/`;
          await fetch(url)
            .then((response) => response.json())
            .then((data) => {
              if (data.retorno.length) {
                this.estado.telaAnteriorCadastro = 'aceites';
              } else {
                this.estado.telaAnteriorCadastro = 'cadastro';
              }
            })
            .catch((error) => {
              console.error('Error:', error);
              this.block = true;
            });
        } else if (this.config.LGPD) {
          this.estado.telaAnteriorCadastro = 'cadastro';
        } else if (this.config.LGPD_DETALHADA) {
          this.estado.telaAnteriorCadastro = 'cadastro';
        } else {
          this.estado.telaAnteriorCadastro = 'cadastro';
        }
      }
    },
    async obterTelaPreCadastro() {
      await this.definirTelaPreCadastro();

      if (this.estado.cadastroBloqueado) {
        this.modais.campanhaBloqueada = true;
      } else {
        switch (this.estado.telaAnteriorCadastro) {
          case 'primeiro-acesso':
            this.alternarPrimeiroAcesso();
            break;
          case 'aceites':
            this.alternarAceites();
            break;
          case 'lgpd':
            this.alternarLGPD();
            break;
          case 'lgpd-detalhada':
            this.alternarLGPDDetalhada();
            break;
          case 'cadastro':
            this.alternarCadastro();
            break;
        }
      }
    },

    // Alternando a exibição com os eventos emitidos pelas telas. (substitui o navigateTo())
    alternarExibicao(evt) {
      switch (evt.event) {
        case 'alternar-tela-login':
          this.alternarLogin();
          break;
        case 'alternar-tela-cadastro':
          this.alternarCadastro();
          break;
        case 'alternar-tela-lgpd':
          this.alternarLGPD();
          break;
        case 'alternar-tela-lgpd-detalhada':
          this.alternarLGPDDetalhada();
          break;
        case 'alternar-tela-aceites':
          this.alternarAceites();
          break;
        case 'alternar-tela-esqueci-senha':
          this.alternarEsqueciSenha();
          break;
        case 'alternar-tela-primeiro-acesso':
          this.alternarPrimeiroAcesso();
          break;
        case 'alternar-tela-selecionar-meio-esqueci-senha':
          this.alternarSelecionarMeioEsqueciSenha();
          break;
        case 'alternar-tela-esqueci-senha-via-sms':
          this.alternarEsqueciSenhaViaSMS();
          break;
        default:
          break;
      }
    },

    // Alternando a exibição de modais com os eventos emitidos pelas telas. (substitui o sweet alert)
    alternarModal(evt) {
      switch (evt.event) {
        case 'informar-campanha-nao-encontrada':
          this.modais.erroObterCampanha = true;
          break;
        case 'informar-campanha-nao-disponivel-nao-ativa':
          this.modais.campanhaBloqueada = true;
          break;
        case 'informar-erros-lgpd':
          this.modais.errosLGPD.ativo = true;
          this.modais.errosLGPD.titulo = evt.data.titulo;
          this.modais.errosLGPD.mensagem = evt.data.mensagem;
          break;
        case 'informar-erros-aceites':
          this.modais.errosAceites.ativo = true;
          this.modais.errosAceites.titulo = evt.data.titulo;
          this.modais.errosAceites.mensagem = evt.data.mensagem;
          break;
        case 'informar-erros-autenticacao':
          this.modais.errosAutenticacao.ativo = true;
          this.modais.errosAutenticacao.titulo = evt.data.titulo;
          this.modais.errosAutenticacao.mensagem = evt.data.mensagem;
          break;
        default:
          break;
      }
    },

    // Campanha
    carregarCampanhas: async function () {
      let campanhaRecuperada = null;
      const wsUrl = this.isProd ? this.config.VUE_APP_PROD_WS_URL : this.config.VUE_APP_DEV_WS_URL;
      const [currentDate, url] = [Data.dataHoraAtual(), `${wsUrl}/campanha/v1/campanhas/`];

      await fetch(url)
        .then((response) => response.json())
        .then((data) => {
          data.retorno
            .filter((camp) => {
              let initial = `${camp.dataInicial} 00:00:00`;
              let final = `${camp.dataFinalCadastros} 23:59:00`;
              return camp.ativa && currentDate >= initial && currentDate <= final;
            })
            .forEach((camp) => {
              campanhaRecuperada = camp;
            });
          campanhaRecuperada ? (this.estado.cadastroBloqueado = false) : (this.estado.cadastroBloqueado = true);
        })
        .catch((error) => {
          console.error('Error:', error);
          this.estado.cadastroBloqueado = true;
          this.modais.conexaoInternet = true;
        });
    },

    inputFocus() {
      const inputSenha = document.querySelector('#input-senha');
      inputSenha.focus();
    },

    // Parâmetros da URL
    verificarParametrosCadastroURL: function () {
      const params = this.$route.query;
      if (Object.keys(params).length) {
        // ?nome=Teste%20Alpha%20centauri&email=teste.alpha%40centauri.com&usuario=37762762906
        if (params.nome && params.email && params.usuario) {
          this.parametrosURL.nome = params.nome;
          this.parametrosURL.email = params.email;
          this.parametrosURL.phone = params.usuario;
          this.parametrosURL.autocompletarCadastro = true;

          this.obterTelaPreCadastro();

          // abrir modal de completar cadastro
        }
      }
    },

    // Navegador
    navigateTo(name) {
      this.$router.push({ name });
    },

    emitirModalInformacao({ icone, titulo, mensagem, textoBotao, acao }) {
      this.$emit('emmitInfoModal', {
        event: 'modal-informacao-generica',
        data: {
          icone,
          titulo,
          mensagem,
          textoBotao,
          acao,
        },
      });
    },
  },
  watch: {
    usuario() {
      this.loginExiste = false;
      this.senha = '';
    },
  },
  validations() {
    return {
      usuario: {
        required,
        minLength: minLength(10),
        isValid: this.config.LOGIN_CPF
          ? this.config.TIPO_USUARIO == 'cpfCnpj'
            ? Validation.cpfCnpj
            : this.config.TIPO_USUARIO == 'cnpj'
            ? Validation.cnpj
            : Validation.cpf
          : true,
      },
      senha: {
        required,
        minLength: minLength(6),
      },
    };
  },
};
</script>
<style lang="scss">
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading {
  width: 50px;
  height: 50px;
  border: 4px solid rgba(0, 0, 0, 0.15);
  border-top-color: var(--color-secondary);
  border-radius: 100%;
  animation: spin 1s infinite;
}

.form-group {
  margin-bottom: 0;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  background-image: none !important;
  transition: background-color 5000s ease-in-out 0s;
  caret-color: var(--color-secondary);
}
.pill-nav-bar {
  padding-top: 25vh !important;
}
.container-pill-nav-bar {
  min-width: 300px !important;
  background-color: rgba(255, 255, 255, 0.8) !important;
  @media (max-width: 768px) {
    max-width: 500px !important;
  }
}
.container-login {
  width: 100%;
  color: var(--color-secondary);
  margin: auto;
}
.invalido {
  font-size: 10px;
  line-height: 1;
  font-weight: 500;
}
.botao-login {
  border: 0;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-secondary);
  margin-right: 10px;
  i {
    width: 24px;
    color: white;
  }
}
.login-input {
  border: 0;
  border-bottom: 1px solid var(--color-secondary) !important;
  border-radius: 0;
  background-color: transparent;
  width: calc(100% - 20px);
  &::placeholder {
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: var(--color-secondary);
  }
  &:focus {
    border: 0;
    border-bottom: 1px solid var(--color-secondary);
    background-color: transparent;
    box-shadow: 0 0 0 !important;
  }
}
.login-input-label {
  font-family: 'Roboto', sans-serif;
  color: var(--color-secondary);
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
}
.esqueci-senha {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: var(--color-secondary);
}
.botao-entrar {
  width: 100%;
  background: var(--color-secondary);
  border-radius: 8px;
  color: white;
  border: 0;
  margin-bottom: 20px;
  padding: 5px 0;
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
}

.ocultar-rolagem {
  overflow: hidden !important;
}
</style>
<style scoped lang="scss">
// .botao-zap {
//   position: fixed;
//   z-index: 9999999999;
//   top: 30px;
//   right: 20px;
//   height: 50px;
//   display: flex;
//   flex-grow: 1;
//   align-items: center;
//   button {
//     font-size: 16px;
//     font-weight: 500;
//     height: 35px;
//     margin-right: 10px;
//     padding: 0 15px;
//     border-radius: 35px;
//     background-image: linear-gradient(to bottom, #61fd7d, #2bb826);
//     color: white;
//     border: 0;
//     @media (max-width: 407px) {
//       height: 50px;
//     }
//   }
//   div {
//     height: 100%;
//     width: 50px;
//     border-radius: 50px;
//     background-image: linear-gradient(to bottom, #61fd7d, #2bb826);
//     display: flex;
//     justify-content: center;
//     align-items: center;
//   }
//   img {
//     height: 50%;
//   }
// }
.inicio-container {
  width: 100vw;
  height: 100vh;
  .container-logos-inicio {
    max-width: 100vw;
    height: 60vh;
    display: flex;
    flex-direction: column;
    margin: auto;
  }
  .inicio-particles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .inicio-top-logo {
    // position: absolute;
    // top: 0;
    z-index: 9999;
    height: 30%;
    img {
      max-height: 100px;
      max-width: 200px;
    }

    .link {
      max-width: 200px;
      img {
        max-width: 100%;
      }
    }
  }

  .inicio-top-logo-alternative {
    // position: absolute;
    // top: 0;

    img {
      max-width: 200px;
    }
  }

  .inicio-center-logo {
    // position: absolute;
    // top: 50%;
    // bottom: 50%;
    height: 70%;
    img {
      max-height: 200px;
      max-width: 200px;
      z-index: 0;
    }
  }

  // Animação fade
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.8s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
}
</style>
