import Vue from 'vue'

// Initial state
const initialState = () => ({
	checkingAccout: 0,
	history: [],
	totalPages: 2,
	total: 0,
	loading: false,
	metas: null,
})

// State object
const state = initialState()

// Getters
const getters = {
	getCheckingAccountValue(state) {
		return state.checkingAccout
	},
	getHistory(state) {
		return state.history
	},
	getTotalPages(state) {
		return state.totalPages
	},
	getTotal(state) {
		return state.total
	},
	loadingState(state) {
		return state.loading
	},
	getMetas(state) {
		return state.metas
	},
}

// Actions
const actions = {
	clearState({ commit }) { commit('RESET') },
	fetchChechingAccountValue({ commit }, { campaign, profile }) {
		return new Promise(async (resolve) => {
			if (!profile.cpf) {
				commit('setCheckingAccountValue', 0)
				resolve({
					status: 404,
					msg: 'Complete o cadastro para acessar recursos de conta corrente'
				})
			} else {
				const api = new Vue.prototype.$httpClient()
				const url = `/contacorrente/v1/saldos/${campaign.ano}/${campaign.identificacao}/${profile.cpf}`;
				const response = await api.send(url, 'GET', null, undefined, false)
				if (response.status == 200) {
					commit('setCheckingAccountValue', parseFloat(response.data.retorno.saldo))
					resolve({
						status: 200,
						msg: 'Saldo do consumidor obtido com sucesso'
					})
				} else {
					commit('setCheckingAccountValue', 0)
					resolve({
						status: 404,
						msg: 'Consumidor não possui saldo em conta corrente'
					})
				}
			}
		})
	},
	fetchHistory({ commit }, { campaign, pagination, profile }) {
		return new Promise(async (resolve) => {
			if (!profile.cpf) {
				commit('setCheckingAccountValue', 0)
				resolve({
					status: 400,
					msg: 'Complete o cadastro para ter acesso aos recursos de conta corrente'
				})
			} else {
				const api = new Vue.prototype.$httpClient()
				const url = `/contacorrente/v1/lancamentos/${campaign.ano}/${campaign.identificacao}/${profile.cpf}/${pagination ? pagination.perPage : '0'}/${ pagination ? pagination.page : '0' }`
				commit('setLoading', true)
				const response = await api.send(url, 'GET', null, undefined, false)
				if ([200, 201].includes(response.status)) {
					commit('setTotal', response.data.retorno.total)
					commit('setTotalPages', response.data.retorno.paginas)
					commit('setHistory', response.data.retorno.retorno.reverse())
					commit('setLoading', false)
					resolve({
						status: 200,
						msg: 'Saldo do consumidor obtido com sucesso'
					})
				} else {
					commit('setTotal', 0)
					commit('setTotalPages', 2)
					commit('setHistory', [])
					commit('setLoading', false)
					resolve({
						status: response.status,
						msg: response.data.mensagem || 'Ocorreu um erro desconhecido durante sua requisição!'
					})
				}
			}
		})
	},
	fetchMetas({ commit }, { campanha, profile }) {
		return new Promise(async (resolve) => {
			const api = new Vue.prototype.$httpClient()
			const url = `/contacorrente/v1/metas/${campanha.ano}/${campanha.identificacao}/${profile.cpf}`
			commit('setLoading', true)
			const response = await api.send(url, 'GET', null, undefined, false)
			if ([200, 201].includes(response.status)) {
				commit('setMetas', response.data.retorno)
				commit('setLoading', false)
				resolve({
					status: 200,
					msg: 'Metas do consumidor obtidas com sucesso'
				})
			} else {
				commit('setMetas', null)
				commit('setLoading', false)
				resolve({
					status: response.status,
					msg: response.data.mensagem || 'Ocorreu um erro desconhecido durante sua requisição!'
				})
			}
		})
	},
}

// Mutations
const mutations = {
	RESET(state) {
		const newState = initialState();
		Object.keys(newState).forEach(key => {
			state[key] = newState[key]
		})
	},
	setCheckingAccountValue(state, data) {
		state.checkingAccout = data
	},
	setHistory(state, data) {
		state.history = data
	},
	setLoading(state, data) {
		state.loading = data
	},
	setTotalPages(state, data) {
		state.totalPages = data
	},
	setTotal(state, data) {
		state.total = data
	},
	setMetas(state, data) {
		state.metas = data
	},
}

// Module exports
export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}