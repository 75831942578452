<template>
  <div class="base-pill-modal">
    <transition name="fade" appear>
      <div
        class="pill-modal-overlay"
        v-if="openModal"
        @click="closeOverlay ? fecharModal() : () => {}"
        :class="{ fundoCorSolida: fundoCorSolida }"
      ></div>
      <!-- o clique do overlay é habilitado por padrão, mas deve ser desabilitado caso uma prop seja passada -->
    </transition>
    <transition :name="nomeTransicao" appear>
      <div class="pill-modal" v-if="openModal">
        <button class="pill-modal-close" @click="closeOverlay ? fecharModal() : () => {}" v-if="closeOverlay" />
        <div class="pill-modal-header">
          <div class="start-items" v-if="pills[0]">
            <div
              class="left-pill"
              :class="pillColors[0]"
              :style="
                config.COR_DESTAQUE_CIRCUNFERENCIA_CARDS_MODAIS
                  ? `background-color: ${config.COR_DESTAQUE_CIRCUNFERENCIA_CARDS_MODAIS} !important;`
                  : ''
              "
            >
              <i
                :class="headerIcons[0]"
                :style="
                  config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_CARDS_MODAIS
                    ? `color: ${config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_CARDS_MODAIS} !important;`
                    : ''
                "
              ></i>
            </div>
            <span class="title" v-if="title">{{ title }}</span>
          </div>
          <div class="end-items" v-if="pills[1]">
            <div
              class="right-pill"
              :class="pillColors[1]"
              :style="
                config.COR_DESTAQUE_CIRCUNFERENCIA_CARDS_MODAIS
                  ? `background-color: ${config.COR_DESTAQUE_CIRCUNFERENCIA_CARDS_MODAIS} !important;`
                  : ''
              "
            >
              <i
                :class="headerIcons[1]"
                :style="
                  config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_CARDS_MODAIS
                    ? `color: ${config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_CARDS_MODAIS} !important;`
                    : ''
                "
              ></i>
            </div>
          </div>
        </div>
        <div class="pill-modal-body">
          <slot name="pill-modal-body"></slot>
        </div>
        <div class="pill-modal-footer">
          <slot name="pill-modal-footer"></slot>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'BasePillModal',
  props: {
    openModal: {
      type: Boolean,
      required: true,
      default: false,
      description: 'Boolean que define o estado da modal',
    },

    title: {
      type: String,
      required: false,
      default: null,
      description: 'Quando passado algum valor, exibe o conteúdo ao lado da pílula esquerda',
    },

    pills: {
      type: Array,
      required: true,
      description: 'Array, com ponteiros booleanos, que definem quais a píluas que devem, ou não, ser renderizadas',
    },

    headerIcons: {
      type: Array,
      required: true,
      description: 'Quando ambas as pílulas estiverem selecionadas, seve ser passado mais de um valor para a array',
    },

    pillColors: {
      type: Array,
      required: true,
      description:
        'Quando ambas as píluas estiverem selecionadas, deve ser passado mais de um valor, de modo que a posição das cores corresponda á ordem de renderização',
    },

    pillSize: {
      type: Array,
      required: false,
      description: 'Propriedade que define o tamanho das pílulas',
    },

    closeOverlay: {
      type: Boolean,
      required: false,
      default: true,
      description:
        'Propriedade que define se o modla pode ser fechado quando um clique é realizado fora de seus limites',
    },

    fundoCorSolida: {
      type: Boolean,
      required: false,
      default: false,
      description: 'Se for true, a cor de fundo da modal será sem transparência',
    },

    nomeTransicao: {
      type: String,
      required: false,
      default: 'slide',
      description: 'Propriedade que define se a animação de abrir e fechar ocorrerá verticalmente',
    },

    // habilitar overlay, por padrão deve ser true (só desativa quando infromado)
  },
  data() {
    return {
      modal: {
        open: this.openModal,
      },
    };
  },
  computed: {
    ...mapGetters({
      config: 'config/getConfig',
      currentCampaign: 'campanha/getCampaign',
    }),
  },
  methods: {
    fecharModal() {
      this.$emit('fechar-modal', {
        dados: {
          modal: {
            fechar: true,
          },
        },
      });
    },
  },
};
</script>
<style lang="scss" src="./style.scss"></style>
