<template>
  <div class="w-100 d-flex flex-column align-items-center">
    <!-- Título -->
    <div class="w-75 d-flex align-items-center justify-content-center mb-3">
      <p
        class="f-11 mt-4 mb-3 text-center"
        style="color: var(--text-color-highlight)"
      >
        <strong>Termos</strong>
      </p>
    </div>

    <!-- Título termos de aceite -->
    <div class="w-75 d-flex align-items-center justify-content-center mb-3">
      <p
        class="f-08 mb-3 text-center"
        style="color: var(--text-color-highlight)"
      >
        <strong>TERMOS DE ACEITE</strong>
      </p>
    </div>

    <!-- Termos de aceite -->
    <div
      class="
        w-75
        d-flex
        flex-column
        align-items-center
        justify-content-center
        mb-3
      "
    >
      <div
        class="w-100 d-flex align-items-center justify-content-center mb-3"
        v-for="termo in termos"
        :key="termo.id"
      >
        <base-pill-check-box
          :id="termo.titulo"
          :name="'input-termo' + termo.id"
          class="w-100"
          :pillSize="'pill-average'"
          @click="alternarRespostaTermosAceite(termo.titulo)"
          :selectedValue="verificarTermoAceito(termo.titulo)"
          :campoSwitch="true"
          v-tooltip.top="termo.termo"
        >
          <template v-slot:pill-check-box-title>
            <label class="pl-5 pr-3 mb-0 f-065 text-black" :for="termo.titulo">
              {{ termo.termo }}
            </label>
          </template>
        </base-pill-check-box>
      </div>
      <div class="w-100 d-flex align-items-center justify-content-center mb-3">
        <base-pill-check-box
          id="termos-gerais"
          name="input-termos-gerais"
          class="w-100"
          :pillSize="'pill-average'"
          @click="alternarTodosOsTermos()"
          :selectedValue="selectAll"
          :campoSwitch="true"
          v-tooltip.top="'Li e concordo com todas as opções acima'"
        >
          <template v-slot:pill-check-box-title>
            <label
              class="pl-5 pr-3 mb-0 f-065 text-black"
              for="input-termos-gerais"
            >
              Li e concordo com todas as opções acima
            </label>
          </template>
        </base-pill-check-box>
      </div>
    </div>

    <!-- Título temos politica de privacidade -->
    <div class="w-75 d-flex align-items-center justify-content-center mb-3">
      <p
        class="f-08 mt-4 mb-3 text-center"
        style="color: var(--text-color-highlight)"
      >
        <strong>TERMOS DE POLÍTICA DE PRIVACIDADE</strong>
      </p>
    </div>

    <!-- Política de privacidade -->
    <div class="w-75 d-flex align-items-center justify-content-center mb-3">
      <div class="w-100 d-flex align-items-center justify-content-center mb-3">
        <base-pill-check-box
          id="politica-privacidade-link"
          name="politica-privacidade-link"
          class="w-100"
          :pillSize="'pill-average'"
          @click="alternarTermoPolitica()"
          :selectedValue="politica"
          :campoSwitch="true"
          v-tooltip.top="'Li e estou ciente dos Termos de política e Privacidade'"
        >
          <template v-slot:pill-check-box-title>
            <label
              class="pl-5 pr-3 mb-0 f-065 text-black"
              for="input-termos-gerais"
            >
              Li e estou ciente dos
              <span
                @click="navigateTo('politicaPrivacidade')"
                style="cursor: pointer"
              >
                <u>Termos de Política de Privacidade</u>
              </span>
            </label>
          </template>
        </base-pill-check-box>
      </div>
    </div>

    <!-- Título termos regulamento -->
    <div class="w-75 d-flex align-items-center justify-content-center mb-3">
      <p
        class="f-08 mt-4 mb-3 text-center text-uppercase"
        style="color: var(--text-color-highlight)"
      >
        <strong>REGULAMENTO DA {{ config.NOME_PROMOCAO }}</strong>
      </p>
    </div>

    <!-- Regulamento -->
    <div class="w-75 d-flex align-items-center justify-content-center mb-3">
      <base-pill-check-box
        id="regulamento-link"
        name="regulamento-link"
        class="w-100"
        :pillSize="'pill-average'"
        :campoSwitch="true"
        @click="alternarTermoRegulamento()"
        :selectedValue="regulamento"
      >
        <template v-slot:pill-check-box-title>
          <label
            class="pl-5 pr-3 mb-0 f-065 text-black"
            for="input-termos-gerais"
          >
            Li e aceito o
            <span @click="navigateTo('regulamento')" style="cursor: pointer">
              <u>Regulamento da {{ config.NOME_PROMOCAO }}</u>
            </span>
          </label>
        </template>
      </base-pill-check-box>
    </div>

    <!-- Título termos mensagem -->
    <div
      class="w-75 d-flex align-items-center justify-content-center mb-3"
      v-if="config.CONCORDAR_RECEBER_MENSAGENS"
    >
      <p
        class="f-08 mt-4 mb-3 text-center text-uppercase"
        style="color: var(--text-color-highlight)"
      >
        <strong>INFORMAÇÕES E COMUNICAÇÕES</strong>
      </p>
    </div>

    <!-- Termos mensagem -->
    <div
      class="w-75 d-flex align-items-center justify-content-center mb-3"
      v-if="config.CONCORDAR_RECEBER_MENSAGENS"
    >
      <base-pill-check-box
        id="mensagens-link"
        name="mensagens-link"
        class="w-100"
        :pillSize="'pill-average'"
        @click="alternarTermoNotificacoes()"
        :campoSwitch="true"
        :selectedValue="notificacoes"
      >
        <template v-slot:pill-check-box-title>
          <label
            class="pl-5 pr-3 mb-0 f-065 text-black"
            for="input-termos-gerais"
          >
            <span>{{ config.TEXTO_RECEBER_MENSAGENS }}</span>
          </label>
        </template>
      </base-pill-check-box>
    </div>

    <!-- Perguntas -->
    <div
      class="
        w-75
        d-flex
        flex-column
        align-items-center
        justify-content-center
        mb-3
      "
      v-if="pergunta"
    >
      <p
        class="w-100 f-09 mt-4 mb-4 text-center"
        style="color: var(--text-color-highlight)"
      >
        <strong>{{ pergunta }}</strong>
      </p>
      <div class="w-100 d-flex align-items-center justify-content-between mb-3">
        <div
          class="
            w-45
            d-flex
            flex-column
            align-items-center
            justify-content-center
            mb-3
          "
          v-for="(resposta, index) in respostas"
          :key="index"
        >
          <base-pill-check-box
            :id="resposta.valorOpcao"
            name="grupo-inputs-respostas-lgpd"
            type="radio"
            class="w-100"
            :pillSize="'pill-average'"
            @click="alternarRespostaPergunta(resposta.valorOpcao)"
            :selectedValue="respostaPergunta"
            :campoSwitch="true"
          >
            <template v-slot:pill-check-box-title>
              <label
                class="pl-5 pr-3 mb-0 f-065 text-black"
                :for="resposta.valorOpcao"
              >
                {{ resposta.textoOpcao }}
              </label>
            </template>
          </base-pill-check-box>
        </div>
      </div>
    </div>

    <!-- Ações -->
    <div
      class="
        w-75
        d-flex
        align-items-center
        justify-content-between
        mb-3
        pt-5
        pb-5
      "
      :class="pergunta ? 'pt-3' : 'pt-5'"
    >
      <base-pill-button
        id="lgpd-voltar"
        :type="'light'"
        :left-middle-pill="'fas fa-chevron-circle-left'"
        :pillColors="['pill-dark']"
        :pillIconColors="['icon-light']"
        :pillSize="['pill-average']"
        @click="voltarTela()"
      >
        <template v-slot:pill-button-title>
          <span class="w-90 pl-2 pr-3 text-center f-07 text-black">Voltar</span>
        </template>
      </base-pill-button>

      <base-pill-button
        id="lgpd-voltar"
        v-if="!this.block"
        :type="'dark'"
        :right-middle-pill="'fas fa-chevron-circle-right'"
        :pillColors="['pill-light']"
        :pillIconColors="['icon-dark']"
        :pillSize="['pill-average']"
        @click="validadeTermos"
      >
        <template v-slot:pill-button-title>
          <span class="w-90 pl-3 pr-2 text-center f-07 light"
            >Continuar</span
          >
        </template>
      </base-pill-button>

      <base-pill-button
        id="lgpd-voltar"
        v-else
        :type="'dark'"
        :right-middle-pill="'fas fa-chevron-circle-right'"
        :pillColors="['pill-light']"
        :pillIconColors="['icon-dark']"
        :pillSize="['pill-average']"
        @click="informarCampanhaNaoDisponivel()"
      >
        <template v-slot:pill-button-title>
          <span class="w-90 pl-3 pr-2 text-center f-07 light-gray"
            >Continuar</span
          >
        </template>
      </base-pill-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { Data } from '@/lib/Helper'

export default {
  name: 'TheLgpdDetalhada',

  created() {
    // Escondendo a logo quando a tela é menor
    let logoQR,
      logoCampanha,
      menuInferior,
      indexIntervalo = 0
    let intervalo = setInterval(() => {
      logoQR = document.querySelector('.qr-logo')
      logoCampanha = document.querySelector('.campaing-logo')
      menuInferior = document.querySelector('.fixed-bottom')
      if (menuInferior && logoQR && logoCampanha) {
        clearInterval(intervalo)
        var x = window.matchMedia('(max-width: 640px)')
        eventoEsconderLogo(x)
        x.addListener(eventoEsconderLogo)
      }
      indexIntervalo++
      if (indexIntervalo > 20) {
        clearInterval(intervalo)
      }
    }, 100)

    function eventoEsconderLogo(x) {
      if (window.matchMedia('(max-width: 640px)').matches) {
        logoCampanha.style.display = 'none'
        logoQR.style.display = 'none'
        menuInferior.style.paddingBottom = '1rem'
      } else {
        logoCampanha.style.display = 'block'
        logoQR.style.display = 'block'
        menuInferior.style.paddingBottom = '5rem'
      }
    }
  },

  beforeMount() {
    const configPergunta = this.config.PERGUNTA_PRE_CADASTRO

    if (configPergunta.length >= 1) {
      this.pergunta = configPergunta[0].textoOpcao

      for (let i = 1; i <= configPergunta.length - 1; i++) {
        this.respostas.push(configPergunta[i])
      }
    }
  },

  mounted() {
    this.carregaCampanhas()
  },

  computed: {
    ...mapGetters({
      config: 'config/getConfig',
      lgpdTermosLista: 'lgpd/getTermosLista',
      lgpdPolitica: 'lgpd/getPolitica',
      lgpdRegulamento: 'lgpd/getRegulamento',
      lgpdNotificacoes: 'lgpd/getNotificacoes'
    }),

    selectAll: {
      get: function () {
        if (
          this.termosLista.length == this.termos.length &&
          this.politica &&
          this.regulamento
        ) {
          this.deAcordo = true
        } else {
          this.deAcordo = false
        }
        return this.termos
          ? this.termosLista.length == this.termos.length
          : false
      },
      set: function (value) {
        var termosLista = []
        if (value) {
          this.termos.forEach(function (e) {
            termosLista.push(e.titulo)
          })
        }
        this.termosLista = termosLista
      }
    },

    politica: {
      get() {
        return this.lgpdPolitica
      },
      set(value) {
        this.salvarPolitica({ politica: value })
      }
    },

    termosLista: {
      get() {
        return this.lgpdTermosLista
      },
      set(value) {
        this.salvarTermosLista({ titulo: value })
      }
    },

    regulamento: {
      get() {
        return this.lgpdRegulamento
      },
      set(value) {
        this.salvarRegulamento({ regulamento: value })
      }
    },

    notificacoes: {
      get() {
        return this.lgpdNotificacoes
      },
      set(value) {
        this.salvarNotificacoes({ notificacoes: value })
      }
    }
  },

  data: () => ({
    termos: [
      {
        id: '1',
        titulo: 'Termo 1',
        termo:
          'Eu concordo que o perfil cadastrado na plataforma estará acessível no banco de dados do SOFTWARE QRSORTEIOS e, caso seja necessário, somente os operadores do sistema poderão ter acesso.'
      },
      {
        id: '2',
        titulo: 'Termo 2',
        termo:
          'Eu concordo que meu perfil na plataforma disponibilizará à desenvolvedora o URL de onde o usuário provêm e seus IPs de acesso.'
      },
      {
        id: '3',
        titulo: 'Termo 3',
        termo: 'Estou ciente de quais dados pessoais que recolhem.'
      },
      {
        id: '4',
        titulo: 'Termo 4',
        termo:
          'Estou ciente da finalidade e uso dos respectivos dados pessoais.'
      },
      {
        id: '5',
        titulo: 'Termo 5',
        termo:
          'Estou ciente por quanto tempo os dados pessoais ficam armazenados.'
      },
      {
        id: '6',
        titulo: 'Termo 6',
        termo: 'Estou ciente sobre a segurança dos dados pessoais armazenados.'
      },
      {
        id: '7',
        titulo: 'Termo 7',
        termo:
          'Estou ciente sobre os dados pessoais armazenados e alocados em servidores fora do País Brasil.'
      },
      {
        id: '8',
        titulo: 'Termo 8',
        termo:
          'Estou ciente sobre o armazenamento de cookies ou dados de navegação no aplicativo.'
      },
      {
        id: '9',
        titulo: 'Termo 9',
        termo:
          'Estou ciente sobre as implicações de consentir à Presente Política de Privacidade.'
      },
      {
        id: '10',
        titulo: 'Termo 10',
        termo:
          'Estou ciente sobre possíveis alterações na política de privacidade.'
      },
      {
        id: '11',
        titulo: 'Termo 11',
        termo:
          'Estou ciente sobre o foro/jurisdição para resolução de conflitos referentes à presente política de privacidade.'
      }
    ],

    deAcordo: false,
    selected: [],

    pergunta: '',
    respostas: [],
    respostaPergunta: '',

    block: true
  }),
  methods: {
    ...mapActions({
      salvarTermosLista: 'lgpd/salvarTermosLista',
      salvarPolitica: 'lgpd/salvarPolitica',
      salvarRegulamento: 'lgpd/salvarRegulamento',
      salvarNotificacoes: 'lgpd/salvarNotificacoes'
    }),

    carregaCampanhas: async function () {
      const wsUrl = this.isProd
        ? this.config.VUE_APP_PROD_WS_URL
        : this.config.VUE_APP_DEV_WS_URL
      const currentDate = Data.dataHoraAtual()
      const url = `${wsUrl}/campanha/v1/campanhas/`
      let campanhaRecuperada = null
      await fetch(url)
        .then((response) => response.json())
        .then((data) => {
          data.retorno
            .filter((camp) => {
              let initial = `${camp.dataInicial} 00:00:00`
              let final = `${camp.dataFinalCadastros} 23:59:00`
              return (
                camp.ativa && currentDate >= initial && currentDate <= final
              )
            })
            .forEach((camp) => {
              campanhaRecuperada = camp
            })
          campanhaRecuperada ? (this.block = false) : (this.block = true)
        })
        .catch((error) => {
          console.error('Error:', error)
          this.block = true
          this.informarCampanhaNaoEncontrada()
        })
    },
    informarCampanhaNaoEncontrada() {
      this.$emit('alternar-modal', {
        event: 'informar-campanha-nao-encontrada'
      })
    },
    informarCampanhaNaoDisponivel() {
      this.$emit('alternar-modal', {
        event: 'informar-campanha-nao-disponivel-nao-ativa'
      })
    },

    navigateTo(name) {
      this.$router.push({ name })
    },

    navigateBack() {
      this.$router.go(-1)
    },

    voltarTela() {
      this.$emit('alternar-exibicao', {
        event: 'alternar-tela-lgpd-detalhada'
      })
    },

    handleTransition(evt) {
      this.$emit('emmitEvent', {
        event: 'cardTransition',
        data: {
          page: evt.data.page,
          origin: evt.data.origin
        }
      })
    },

    alternarTodosOsTermos() {
      this.selectAll = !this.selectAll
    },

    alternarRespostaTermosAceite(termo) {
      !this.verificarTermoAceito(termo)
        ? this.termosLista.push(termo)
        : this.termosLista.splice(this.termosLista.indexOf(termo), 1)
    },

    verificarTermoAceito(termo) {
      return this.termosLista.length > 0
        ? this.termosLista.find((e) => e == termo)
        : false
    },

    alternarTermoPolitica() {
      this.politica = !this.politica
    },

    alternarTermoRegulamento() {
      this.regulamento = !this.regulamento
    },

    alternarTermoNotificacoes() {
      this.notificacoes = !this.notificacoes
    },

    alternarRespostaPergunta(resposta) {
      this.respostaPergunta = resposta
    },

    validadeTermos() {
      if (this.deAcordo) {
        if (
          this.pergunta &&
          this.respostaPergunta != this.respostas[0].valorOpcao
        ) {
          return this.informarErrosLGPD(
            'Ops...',
            'Você escolheu a resposta incorreta, tente novamente.'
          )
        }

        // this.navigateTo('cadastro')
        // fechando essa tela
        this.$emit('alternar-exibicao', {
          event: 'alternar-tela-lgpd-detalhada'
        })
        // abrindo a tela de cadastro
        this.$emit('alternar-exibicao', {
          event: 'alternar-tela-cadastro'
        })
      } else {
        let texto = ''
        if (!this.lgpdPolitica) {
          texto += `Política de privacidade`
        }
        if (this.termosLista.length !== this.termos.length) {
          texto += `${texto ? ', ': ''} Termos de aceite`
        }
        if (!this.lgpdRegulamento) {
          texto += `${texto ? ', ': ''} Regulamento`
        }

        this.informarErrosLGPD(
          'Ops...',
          'Você não concordou com: ' + texto
        )
      }
    },

    informarErrosLGPD(titulo, mensagem) {
      this.$emit('alternar-modal', {
        event: 'informar-erros-lgpd',
        data: {
          titulo,
          mensagem
        }
      })
    }
  },
  props: {
    isProd: {
      type: Boolean,
      required: true
    }
  }
}
</script>
