<template>
  <div class="pill-select-group">
    <div
      class="base-pill-select"
      :class="[selectPillClass ? selectPillClass : 'select-pill-primary', selectSize ? selectSize : 'average']"
      :style="
        config.COR_DESTAQUE_CIRCUNFERENCIA_DOS_INPUTS
          ? `background-color: ${config.COR_DESTAQUE_CIRCUNFERENCIA_DOS_INPUTS} !important;`
          : ''
      "
    >
      <slot name="title">
        <span v-if="!defaultOption" class="f-07 title" :class="[titleColor]">
          {{ title }}
          <span v-if="required">*</span>
        </span>
      </slot>
      <select
        name="base-pill-select"
        :id="id"
        class="select-body"
        :class="[selectBodyClass]"
        v-on="listeners"
        v-bind="$attrs"
      >
        <option v-if="defaultOption" :value="defaultOption" :class="optionClass" selected>
          {{ obterValorAtual }}
        </option>

        <option v-for="(op, i) in options" :key="i" :class="optionClass" style="font-weight: bold;" :value="op.value"
          >{{ op.desc }}</option
        >
      </select>
      <div class="select-icon">
        <i
          class="icon fas fa-chevron-down"
          :style="
            config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_DOS_INPUTS
              ? `color: ${config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_DOS_INPUTS} !important;`
              : ''
          "
        ></i>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { mask, masked } from 'vue-the-mask';

export default {
  directives: { mask, masked },
  name: 'BasePillSelect',

  inheritAttrs: false,

  data: () => ({
    focused: false,
  }),

  props: {
    id: {
      type: String,
      required: true,
      description: 'ID of the input so we can create a label for that input',
    },

    title: {
      type: String,
      required: false,
      default: '',
      description: 'Text at pills',
    },

    titleColor: {
      type: String,
      required: false,
      default: '',
      description: 'Propriedade que define a cor do título do select',
    },

    options: {
      type: Array,
      required: false,
      description: 'Pilha de dados que será renderizada nas opções',
    },

    defaultOption: {
      type: String,
      description: 'Valor previamente definido',
    },

    selectBodyClass: {
      type: String,
      required: false,
      description: 'Classe que define a cor predominate do select.',
    },

    selectPillClass: {
      type: String,
      required: false,
      description: 'Classe que define a cor da pill do select.',
    },

    selectSize: {
      type: String,
      required: false,
      description: 'Propriedade que define o tamanho (height) do select.',
    },

    required: {
      type: Boolean,
      required: false,
      description: 'Propriedade que define um caractere especial no título do campo quando sinalizado como verdadeiro.',
    },
    optionClass: {
      type: String,
      required: false,
      description: 'Classe que define a cor predominate do option.',
    },
  },

  computed: {
    ...mapGetters({
      config: 'config/getConfig',
      currentCampaign: 'campanha/getCampaign',
    }),

    listeners() {
      return {
        ...this.$listeners,
        input: this.updateValue,
        focus: this.onFocus,
      };
    },
    obterValorAtual() {
      return this.options.filter(e => e.value == this.defaultOption)[0].desc;
    },
  },

  methods: {
    updateValue(event) {
      let value =
        event.target.value && !this.masked && this.mask ? event.target.value.replace(/\D/g, '') : event.target.value;
      this.$emit('input', value);
    },

    onFocus(value) {
      this.focused = true;
      this.$emit('focus', value);
    },
  },
};
</script>

<style lang="scss" src="./style.scss"></style>
