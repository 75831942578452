/**
 * Class for String formatting.
 */
class Text {

    constructor() { }

    static limitText(text, limit, ret = "...") {
        if (text.length > limit) {
            return text.substring(0, (limit - 2)).trim() + ret;
        } else {
            return text;
        }
    }

    static breakText(text, limit, lineBreak = "<br />") {
        if (text.length > limit) {
            let textS = text.split(" ");
            let lineBreaks = 1;
            text = '';
            for (let i in textS) {
                text += textS[i] + " ";
                if (text.split(lineBreak).join().length > lineBreaks * limit) {
                    text += lineBreak;
                    lineBreaks++;
                }
            }
        }
        return text;
    }

    static formatCNPJCPF(cnpjCpf) {
        if (cnpjCpf) {
            cnpjCpf = cnpjCpf.toString().replace(/[^\d.-]/g, "");
            if (cnpjCpf.length == 14) {
                return cnpjCpf.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")
            }
            else if (cnpjCpf.length == 11) {
                return cnpjCpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
            }
        } else {
            return '';
        }
    }

    static formatPhone(phone) {
        //row.telefone.length == 11 ? row.telefone.replace(/^(\d{2})(\d{5})(\d{4})/, "($1) $2-$3") : row.telefone.replace(/^(\d{2})(\d{4})(\d{4})/, "($1) $2-$3")}}
        if (phone) {
            phone = phone.toString().replace(/[^\d.-]/g, "");
            if (phone.length == 11) {
                return phone.replace(/^(\d{2})(\d{5})(\d{4})/, "($1) $2-$3")
            } else {
                return phone.replace(/^(\d{2})(\d{4})(\d{4})/, "($1) $2-$3")
            }
        } else {
            return '';
        }
    }

    static formatAddress(address) {
        if (address) {
            return `${address.endereco}, ${address.numero}, ${address.bairro}, ${address.cidade}, ${address.cep} - ${address.estado}`;
        } else {
            return '';
        }
    }
    
    static serializarParams(params) {
		try {
			return Object.entries(Object.assign({}, params)).
				map(([key, value]) => `${key}=${value}`).
				join('&');
		} catch (err) {
			console.log('Erro ao serializar parâmetros', err)
			return false
		}
	}
}

export default Text;