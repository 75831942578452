<template>
  <div
    class="base-pill-big-select-card text-center cursor-pointer"
    :style="`background-color: ${background};`"
    @click="handleClick"
  >
    <div class="content" :style="`color: ${textColor};`">
      <i class="iconSize" :class="mainIcon"></i> <br />
      <span class="titleText">{{ titleText }}</span>
    </div>

    <!-- Body -->
    <slot name="body-base-pill-big-select-card">
      <div class="w-100"></div>
    </slot>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'BasePillBigSelectCard',
  props: {
    background: {
      type: String,
      required: true,
      default: '',
      description: 'Cor de fundo do card.',
    },

    mainIcon: {
      type: String,
      required: true,
      default: '',
      description: 'Ícone principal que será exibido centralizado no card.',
    },

    titleText: {
      type: String,
      required: true,
      default: '',
      description: 'Texto que tem como função informar o titulo do card.',
    },

    textColor: {
      type: String,
      required: true,
      default: '',
      description: 'Cor do titulo do card.',
    },
  },
  computed: {
    ...mapGetters({
      config: 'config/getConfig',
    }),
  },

  methods: {
    //Emitindo evento de click no card
    handleClick(event) {
      this.$emit('click', event);
    },
  },
};
</script>
<style lang="scss" src="./style.scss"></style>
