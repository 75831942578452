var render = function render(){var _vm=this,_c=_vm._self._c;return (!this.config.MENU_NOVO)?_c('div',{class:{ 'menu-alternative-height': _vm.alterarTamanhoMenu },attrs:{"id":"app-menu"}},[(_vm.showMyMenuActions)?_c('div',{staticClass:"menu-actions",class:{
  'justify-content-around':
    _vm.config.DIGITAR_CUPOM_MANUALMENTE || _vm.isDesktop || !_vm.config.EXIBIR_BOTAO_ESCANEAR_QR_CODE,
},attrs:{"id":"myMenuActions"}},[((!_vm.config.DIGITAR_CUPOM_MANUALMENTE || _vm.config.HABILITAR_CADASTRO_CUPONS_DOCUMENTOS_FISCAIS_SIMULTANEOS) &&
  !_vm.isDesktop &&
  _vm.config.EXIBIR_BOTAO_ESCANEAR_QR_CODE &&
  !_vm.config.HABILITAR_LANCAMENTO_MANUAL
  )?_c('base-pill-button',{staticClass:"pt-3 mr-2",attrs:{"type":'light',"left-middle-pill":'fas fa-qrcode',"pillColors":['pill-dark'],"pillIconColors":['icon-light'],"leftMiddlePillRootColor":_vm.config.COR_DESTAQUE_CIRCUNFERENCIA_DOS_BOTOES,"leftMiddlePillIconRootColor":_vm.config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_DOS_BOTOES,"pillSize":['pill-average']},on:{"click":function($event){_vm.block
    ? _vm.campaignMsg('Não há campanhas promocionais disponíveis no momento!', false)
    : _vm.checkCupom({ nomeDaRota: 'escanear', navegacaoAnimada: false })}},scopedSlots:_vm._u([{key:"pill-button-title",fn:function(){return [_c('span',{staticClass:"pl-2 pr-2 text-center f-07 text-black"},[_c('strong',{style:(_vm.config.COR_DESTAQUE_TEXTO_NEGRITO_BOTOES
  ? `color: ${_vm.config.COR_DESTAQUE_TEXTO_NEGRITO_BOTOES} !important;`
  : '')},[_vm._v(" Escanear QRCode ")])])]},proxy:true}],null,false,2470799629)}):_vm._e(),((!_vm.config.DIGITAR_CUPOM_MANUALMENTE || _vm.config.HABILITAR_CADASTRO_CUPONS_DOCUMENTOS_FISCAIS_SIMULTANEOS) &&
  !_vm.config.HABILITAR_LANCAMENTO_MANUAL
  )?_c('base-pill-button',{staticClass:"pt-3",attrs:{"type":'light',"left-middle-pill":'fas fa-file-alt',"pillColors":['pill-dark'],"pillIconColors":['icon-light'],"leftMiddlePillRootColor":_vm.config.COR_DESTAQUE_CIRCUNFERENCIA_DOS_BOTOES,"leftMiddlePillIconRootColor":_vm.config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_DOS_BOTOES,"pillSize":_vm.larguraJanela > 1024 ? ['pill-large'] : ['pill-average']},on:{"click":function($event){!_vm.block
    ? _vm.checkCupom({ nomeDaRota: 'digitacaoManual', navegacaoAnimada: false })
    : _vm.campaignMsg('Não há campanhas promocionais disponíveis no momento!', false)}},scopedSlots:_vm._u([{key:"pill-button-title",fn:function(){return [_c('span',{staticClass:"w-90 pl-2 pr-2 text-center f-07 text-black"},[_c('strong',{style:(_vm.config.COR_DESTAQUE_TEXTO_NEGRITO_BOTOES
  ? `color: ${_vm.config.COR_DESTAQUE_TEXTO_NEGRITO_BOTOES} !important;`
  : '')},[_vm._v(" "+_vm._s(_vm.config.TEXTO_BOTAO_CADASTRO_CUPOM_FISCAL)+" ")])])]},proxy:true}],null,false,3844553531)}):_vm._e(),((_vm.config.DIGITAR_CUPOM_MANUALMENTE ||
  _vm.config.HABILITAR_CADASTRO_CUPONS_DOCUMENTOS_FISCAIS_SIMULTANEOS ||
  _vm.config.HABILITAR_CADASTRO_COMPRA_POR_QUANTIDADE) &&
  !_vm.config.HABILITAR_LANCAMENTO_MANUAL
  )?_c('base-pill-button',{staticClass:"pt-3",attrs:{"type":'light',"left-middle-pill":'fas fa-file-alt',"pillColors":['pill-dark'],"pillIconColors":['icon-light'],"leftMiddlePillRootColor":_vm.config.COR_DESTAQUE_CIRCUNFERENCIA_DOS_BOTOES,"leftMiddlePillIconRootColor":_vm.config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_DOS_BOTOES,"pillSize":['pill-large']},on:{"click":function($event){!_vm.block
    ? _vm.checkCupom({
      nomeDaRota: _vm.config.HABILITAR_CADASTRO_COMPRA_POR_QUANTIDADE ? 'cadastroCompra' : 'cadastroManual',
      navegacaoAnimada: true,
    })
    : _vm.campaignMsg('Não há campanhas promocionais disponíveis no momento!', false)}},scopedSlots:_vm._u([{key:"pill-button-title",fn:function(){return [_c('span',{staticClass:"w-90 pl-2 pr-2 text-center f-07 text-black"},[_c('strong',{style:(_vm.config.COR_DESTAQUE_TEXTO_NEGRITO_BOTOES
  ? `color: ${_vm.config.COR_DESTAQUE_TEXTO_NEGRITO_BOTOES} !important;`
  : '')},[_vm._v(" "+_vm._s(_vm.config.TEXTO_BOTAO_CADASTRO_DOCUMENTO_FISCAL)+" ")])])]},proxy:true}],null,false,334701087)}):_vm._e(),(_vm.config.HABILITAR_LANCAMENTO_MANUAL)?_c('base-pill-button',{staticClass:"pt-3",attrs:{"type":'light',"left-middle-pill":'fas fa-file-alt',"pillColors":['pill-dark'],"pillIconColors":['icon-light'],"leftMiddlePillRootColor":_vm.config.COR_DESTAQUE_CIRCUNFERENCIA_DOS_BOTOES,"leftMiddlePillIconRootColor":_vm.config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_DOS_BOTOES,"pillSize":['pill-small']},on:{"click":function($event){!_vm.block
    ? _vm.checkCupom({ nomeDaRota: 'lancamentoManual', navegacaoAnimada: true })
    : _vm.campaignMsg('Não há campanhas promocionais disponíveis no momento!', false)}},scopedSlots:_vm._u([{key:"pill-button-title",fn:function(){return [_c('span',{staticClass:"w-90 pl-2 pr-2 text-center f-07 text-black"},[_c('strong',{style:(_vm.config.COR_DESTAQUE_TEXTO_NEGRITO_BOTOES
  ? `color: ${_vm.config.COR_DESTAQUE_TEXTO_NEGRITO_BOTOES} !important;`
  : '')},[_vm._v(" "+_vm._s(_vm.config.TEXTO_BOTAO_CADASTRO_CUPOM_FISCAL)+" ")])])]},proxy:true}],null,false,3844553531)}):_vm._e()],1):_vm._e(),_c('div',{staticClass:"menu-slide",attrs:{"id":"menu-slide"}},_vm._l((_vm.retornaTelasConfiguraveis),function(r){return _c('app-menu-item',{key:r.destino,attrs:{"background":_vm.config.FORCAR_CORES_MENU
  ? _vm.config.TABELA_COR.find(cor => cor.nome == 'COR_BACKGROUND_FORCADO_BOTOES').cor
  : 'transparent',"textColor":_vm.config.FORCAR_CORES_MENU
  ? _vm.config.TABELA_COR.find(cor => cor.nome == 'COR_TEXTO_FORCADO_BOTOES').cor
  : 'initial',"label":r.label,"icon":r.icone,"destination":r.destino,"navBold":_vm.config.NAVEGACAO_NEGRITO ? true : false}})}),1)]):_c('div',{staticClass:"menu-novo",class:{ 'menu-alternative-height': _vm.alterarTamanhoMenu },attrs:{"id":"app-menu"}},[_c('div',{staticClass:"menu-slide",attrs:{"id":"menu-slide"}},_vm._l((_vm.retornaTelasConfiguraveis),function(r,index){return _c('app-menu-item',{key:r.destino,attrs:{"textColor":"var(--color-secondary-text-button)","label":r.label,"icon":r.icone,"destination":r.destino,"index":index,"navBold":_vm.config.NAVEGACAO_NEGRITO ? true : false},on:{"openFinishSignUpModal":_vm.abrirModalCompletarCadastro}})}),1)])
}
var staticRenderFns = []

export { render, staticRenderFns }