import Vue from 'vue';

// Object initial state
const initialState = () => ({
  industrias: [],
  totalRegistros: 0,
  carregando: false,
});

// State object
const state = initialState();

// Getter object
const getters = {
  getTotalizador(state) {
    return state.totalRegistros;
  },
  getLoader(state) {
    return state.carregando;
  },
  getIndustrias(state) {
    return state.industrias;
  },
};

// Actions
const actions = {
  clearState({ commit }) {
    commit('RESET');
  },

  fetchIndustrias({ commit }, { paginacao }) {
    return new Promise(async resolve => {
      const api = new Vue.prototype.$httpClient()
      const url = `/produto/v1/industrias/${paginacao.porPagina}/${paginacao.pagina}`;
      const res = await api.send(url, 'GET', null, undefined, false)

      if ([200, 201].includes(res.status)) {
        commit('setIndustrias', res.data.retorno.retorno);
        commit('setTotalizador', res.data.retorno.total);
        resolve({
          status: 200,
          msg: 'Lista de industrias obtida com sucesso!',
          total: res.data.retorno.total,
        });
      } else if ([404].includes(res.status)) {
        commit('setIndustrias', []);
        resolve({ status: 404, msg: 'Não foi encontrada indutrias!' });
      }
    });
  },
};

// Mutations
const mutations = {
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach(key => {
      state[key] = newState[key];
    });
  },
  setIndustrias(state, data) {
    state.industrias = data;
  },
  setTotalizador(state, data) {
    state.totalRegistros = data;
  },
  switchCarregando(state, data) {
    state.carregando = data;
  },
};

// Exporting store module
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
