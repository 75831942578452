<template>
  <div class="form-temporary-code d-flex align-items-center justify-content-center flex-wrap">
    <input
      class="input-field"
      type="text"
      name="input-field-1"
      id="input-field-1"
      autocomplete="off"
      maxLength="1"
      size="1"
      ref="input1"
      @focus="checkEmptyInputs()"
      @keyup="checkInputTemporaryCode"
    />

    <input
      class="input-field"
      type="text"
      name="input-field-2"
      id="input-field-2"
      autocomplete="off"
      maxLength="1"
      size="1"
      ref="input2"
      @focus="checkEmptyInputs()"
      @keyup="checkInputTemporaryCode"
    />

    <input
      class="input-field"
      type="text"
      name="input-field-3"
      id="input-field-3"
      autocomplete="off"
      maxLength="1"
      size="1"
      ref="input3"
      @focus="checkEmptyInputs()"
      @keyup="checkInputTemporaryCode"
    />
    <input
      class="input-field"
      type="text"
      name="input-field-4"
      id="input-field-4"
      autocomplete="off"
      maxLength="1"
      size="1"
      ref="input4"
      @focus="checkEmptyInputs()"
      @keyup="checkInputTemporaryCode"
    />
    <input
      class="input-field"
      type="text"
      name="input-field-5"
      id="input-field-5"
      autocomplete="off"
      maxLength="1"
      size="1"
      ref="input5"
      @focus="checkEmptyInputs()"
      @keyup="checkInputTemporaryCode"
    />
    <input
      class="input-field"
      type="text"
      name="input-field-6"
      id="input-field-6"
      autocomplete="off"
      maxLength="1"
      size="1"
      ref="input6"
      @focus="checkEmptyInputs()"
      @keyup="checkInputTemporaryCode"
    />
  </div>
</template>

<script>
export default {
  name: 'BasePillTemporaryCode',
  props: {
    value: {
      type: String,
      required: true,
      default: '',
      description: 'Campos que serão inseridos cada digito do código temporário.',
    },
  },

  mounted() {
    this.$refs['input1'].focus();
  },

  watch: {
    temporaryCode: function(newValue) {
      if (newValue.length === 6) this.$emit('input', newValue);
    },
  },
  data() {
    return {
      temporaryCode: '',
    };
  },
  methods: {
    focusNextInput(input) {
      if (input < 6) this.$refs[`input${input + 1}`].focus();
    },

    focusPreviousInput(input) {
      if (input > 0) {
        this.$refs[`input${input}`].value = '';
        this.$refs[`input${input}`].focus();
      }
    },

    checkEmptyInputs() {
      if (!this.$refs['input1'].value) return this.$refs['input1'].focus();
      if (!this.$refs['input2'].value) return this.$refs['input2'].focus();
      if (!this.$refs['input3'].value) return this.$refs['input3'].focus();
      if (!this.$refs['input4'].value) return this.$refs['input4'].focus();
      if (!this.$refs['input5'].value) return this.$refs['input5'].focus();
      if (!this.$refs['input6'].value) return this.$refs['input6'].focus();
    },

    checkInputTemporaryCode(element) {
      let caractereDigitado = element.target.value;

      const clear = value => {
        return value.substring(0, value.length - 1);
      };

      if (this.temporaryCode.length >= 6 && element.key !== 'Backspace') return false;

      if (this.temporaryCode.length <= 6) {
        this.temporaryCode += caractereDigitado;

        if (!caractereDigitado.match(/[0-9]/g) && caractereDigitado !== '') {
          this.focusPreviousInput(this.temporaryCode.length);
          this.temporaryCode = this.temporaryCode.replace(/[^0-9]/g, '');
        }

        if (element.key !== 'Backspace') {
          this.focusNextInput(this.temporaryCode.length);
        }
      }

      if (this.temporaryCode.length > 0 && element.key === 'Backspace') {
        this.focusPreviousInput(this.temporaryCode.length);
        this.temporaryCode = clear(this.temporaryCode);
      }
    },
  },
};
</script>

<style lang="scss" src="./style.scss"></style>
