<template>
  <div class="pill-slider-group">
    <div class="base-pill-slider">
      <p>
        {{ title }}
      </p>

      <input
        type="range"
        :min="valorMinimo"
        :max="valorMaximo"
        value="50"
        class="slider"
        :step="step"
        :orient="orientacao"
        :id="id"
        v-on="listeners"
        v-bind="$attrs"
      />

      <slot name="pill-body-slider"> </slot>
    </div>
  </div>
</template>

<script>
// import { mapGetters } from 'vuex';
// import { mask, masked } from 'vue-the-mask';

export default {
  // directives: { mask, masked },
  name: 'BasePillSlider',

  inheritAttrs: false,

  data: () => ({
    focused: false,
  }),

  props: {
    id: {
      type: String,
      required: true,
      description: 'ID do input',
    },

    title: {
      type: String,
      required: false,
      default: '',
      description: 'Título do componente',
    },

    valorMinimo: {
      type: Number,
      required: true,
      default: 0,
      description: 'Valor mais baixo que o slidedr poderá trabalhar',
    },

    valorMaximo: {
      type: Number,
      required: true,
      default: 100,
      description: 'Valor mais alto que o slidedr poderá trabalhar',
    },

    step: {
      type: Number,
      required: false,
      default: 10,
      description: 'De quanto em quanto que o slider irá funcionar',
    },

    orientacao: {
      type: String,
      required: false,
      default: 'horizontal',
      description: 'Orientação do compoenente, horizontal ou vertical',
    },
  },

  computed: {
    // ...mapGetters({
    //   config: 'config/getConfig',
    //   currentCampaign: 'campanha/getCampaign',
    // }),

    listeners() {
      return {
        ...this.$listeners,
        input: this.updateValue,
        focus: this.onFocus,
      };
    },
  },

  methods: {
    // updateValue(event) {
    //   let value =
    //     event.target.value && !this.masked && this.mask ? event.target.value.replace(/\D/g, '') : event.target.value;
    //   this.$emit('input', value);
    // },
    // onFocus(value) {
    //   this.focused = true;
    //   this.$emit('focus', value);
    // },
  },
};
</script>

<style lang="scss" src="./style.scss"></style>
