// Initial state
const initialState = () => ({
	termosLista: [],
	checks: {
		termos: false,
		politica: false,
		regulamento: false,
		notificacoes: false,
		avisoPrivacidade: false,
		aceites: []
	}
})

// State object
const state = initialState()

// Getters
const getters = {
	getTermos(state) {
		return state.checks.termos
	},
	getTermosLista(state) {
		return state.termosLista
	},
	getPolitica(state) {
		return state.checks.politica
	},
	getRegulamento(state) {
		return state.checks.regulamento
	},
	getNotificacoes(state) {
		return state.checks.notificacoes
	},
	getAvisoPrivacidade(state) {
		return state.checks.avisoPrivacidade
	},
	getAceites(state) {
		return state.checks.aceites
	}
}

// Actions
const actions = {
	clearState({ commit }) { commit('RESET') },
	salvarTermos({ commit }, value ) {
		commit('setTermos', value)
	},
	salvarTermosLista({ commit }, value ) {
		commit('setTermosLista', value)
	},
	salvarPolitica({ commit }, value) {
		commit('setPolitica', value)
	},
	salvarRegulamento({ commit }, value) {
		commit('setRegulamento', value)
	},
	salvarNotificacoes({ commit }, value) {
		commit('setNotificacoes', value)
	},
	salvarAvisoPrivacidade({ commit }, value) {
		commit('setAvisoPrivacidade', value)
	},
	salvaAceites({ commit }, value) {
		commit('setAceites', value)
	}
}

// Mutations
const mutations = {
	RESET(state) {
		const newState = initialState();
		Object.keys(newState).forEach(key => {
			state[key] = newState[key]
		})
	},
	setTermos(state, data) {
		state.checks.termos = data.termos
	},
	setTermosLista(state, data) {
		state.termosLista = data.titulo
	},
	setPolitica(state, data) {
		state.checks.politica = data.politica
	},
	setRegulamento(state, data) {
		state.checks.regulamento = data.regulamento
	},
	setNotificacoes(state, data) {
		state.checks.notificacoes = data.notificacoes
	},
	setAvisoPrivacidade(state, data) {
		state.checks.avisoPrivacidade = data.avisoPrivacidade
	},
	setAceites(state, data) {
		state.checks.aceites = data.aceites
	}
}

// Module exports
export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}