<template>
  <b-container v-if="!this.config.USERBAR_NOVO" style="padding: 6px">
    <div id="userbar" :class="{ 'pt-4': ocultaPerfil }">
      <!-- Parte referente ao usuário -->
      <div
        class="userbar d-flex justify-center pl-3 pr-5"
        @click="openWrapperState(!openWrapper)"
        :class="{ 'd-none': ocultaPerfil }"
      >
        <div style="display: flex; flex-flow: column;">
          <div class="user-content w-100 mt-0">Olá,</div>
          <span class="user-content w-100 mt-0" :class="{ 'd-none': ocultaPerfil }" style="font-weight: 700">
            {{ $text.limitText(perfil ? firstName : '', 11).toLowerCase() }}
          </span>
        </div>
        <span class="user-toggle" :class="{ 'd-none': ocultaPerfil }">
          <i
            class="fas text-color-text"
            :class="{
              'fa-chevron-right': !openWrapper,
              'fa-chevron-left': openWrapper,
            }"
          ></i>
        </span>
      </div>
      <!-- Parte referente ao logotipo -->
      <div class="userbar-logo pr-5 pb-4">
        <a v-if="config.LINK_REDIRECIONA_INTRO_IMG" target="_blank" :href="config.LINK_REDIRECIONA_INTRO_IMG">
          <img :src="config.CAMPANHA_IMG_URL" alt="Campaign logo" :style="ocultaPerfil ? 'right: 0' : ''" />
        </a>
        <img v-else :src="config.CAMPANHA_IMG_URL" alt="Campaign logo" :style="ocultaPerfil ? 'right: 0' : ''" />
      </div>
    </div>
  </b-container>
  <b-container v-else class="userbar-novo" style="max-width: 100vw !important; padding: 0">
    <div id="userbar" class="container" style="padding: 0; justify-content: space-between">
      <div style="display: flex; align-items: center">
        <!-- Parte referente ao logotipo -->
        <div class="logo-userbar-novo">
          <img :src="config.CAMPANHA_IMG_URL" alt="Campaign logo" />
        </div>
        <!-- Parte referente ao usuário -->
        <div>
          <h5 class="m-0 nome-usuario">Olá, {{ perfil ? firstName.toLowerCase() : '' }}</h5>
        </div>
      </div>
      <div style="display: flex">
        <app-notifications v-if="this.$route.meta.showOptions && config.MOSTRAR_NOTIFICACOES" class="mr-3" />
        <app-options
          :profile="perfil"
          :campaign="campaign"
          @openProfileModal="abrirModalPerfil"
          @openLogoutModal="abrirModalSair"
          @openChangePasswordModal="abrirModalMudarSenha"
          v-if="this.$route.meta.showOptions"
        />
      </div>
    </div>
  </b-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'app-userbar',
  computed: {
    ...mapGetters({
      config: 'config/getConfig',
      openWrapper: 'ui/getOpenWrapper',
      perfil: 'consumidor/getProfile',
    }),

    firstName() {
      return this.perfil.nome.split(' ')[0];
    },
    ocultaPerfil() {
      return this.config.OCULTA_PERFIL ? true : false;
    },
  },
  data: () => ({
    imgVisible: true,
  }),
  methods: {
    ...mapActions({
      openWrapperState: 'ui/openWrapperState',
    }),
    openPictureEditModal() {
      if (this.perfil.cadastroInicial) {
        return this.$swal({
          type: 'info',
          title: 'Ops...',
          text: 'Por favor complete seu cadastro antes de atualizar sua foto de perfil',
          onClose: () => this.$emit('emmitModalEvent', { event: 'openProfileModal' }),
        });
      }
      this.$emit('emmitModalEvent', {
        event: 'openChangePictureModal',
      });
    },
    abrirModalPerfil() {
      this.$emit('emmitModalEvent', { event: 'openProfileModal' });
    },
    abrirModalSair() {
      this.$emit('emmitModalEvent', { event: 'openLogoutModal' });
    },
    abrirModalMudarSenha() {
      this.$emit('emmitModalEvent', { event: 'openChangePasswordModal' });
    },
  },
  watch: {
    perfil(val) {
      if (val && val.uriFotoPerfil) {
        setTimeout(() => {
          this.imgVisible = false;
        }, 20);
        setTimeout(() => {
          this.imgVisible = true;
        }, 80);
      }
    },
  },
  props: ['campaign'],
};
</script>
