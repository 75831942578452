<template>
  <div class="pill-header-title-v2" :style="[estiloCabecalho]">
    <!-- Pill -->
    <div class="left-middle-pill pill-size pl-4" :style="[estiloIcone, estiloPillCompacto]" v-if="classesIcone">
      <i :class="[classesIcone]"></i>
    </div>

    <!-- Body -->
    <slot name="body-pill-header-title">
      <div class="w-100"></div>
    </slot>

    <!-- Valor pontuação -->
    <slot name="body-pill-header-texto-esquerda" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'PillHeaderTitleV2',
  props: {
    estiloCabecalho: {
      type: String,
      required: false,
      default: '',
      description: 'Propriedade que define estilizacao do componente',
    },

    estiloIcone: {
      type: String,
      required: false,
      default: '',
      description: 'Propriedade que define estilização para bloco com o ícone',
    },

    classesIcone: {
      type: String,
      required: false,
      default: '',
      description: 'ícone a ser exibido na pill do lado esquerdo. Define se o ícone será apresentado ou não',
    },

    ativarEstiloCompacto: {
      type: Boolean,
      required: false,
      default: false,
      description:
        'Define se o componente irá possuir estilos que sejam apresentados de forma mais compactos ao usuário',
    },
  },
  data() {
    return {
      estiloPillCompacto: {
        height: this.ativarEstiloCompacto ? '16px' : '',
        padding: '1.3em 0.5em 1.3em 1.5em',
        fontSize: '1.2rem',
      },
    };
  },
  computed: {
    ...mapGetters({
      // config: 'config/getConfig',
      // currentCampaign: 'campanha/getCampaign',
    }),
  },
  methods: {
    ...mapActions({
      handleMenuSlide: 'ui/handleMenuSlide',
    }),
    navigateTo(route) {
      this.$router.push({ name: route });
    },
  },
};
</script>
<style lang="scss" src="./style.scss"></style>
