import Vue from 'vue';

// Object initial state
const initialState = () => ({
  categorias: [],
  totalCategorias: 0,
  carregando: false,
  erro: null,
  totalPaginas: 0,
  categoria: {},
});

// State object
const state = initialState();

// Getter object
const getters = {
  getCategoria(state) {
    return state.categoria;
  },

  getCategorias(state) {
    return state.categorias;
  },

  getTotalizador(state) {
    return state.totalCategorias;
  },

  getPaginas(state) {
    return state.totalPaginas;
  },

  getLoader(state) {
    return state.carregando;
  },

  getErro(state) {
    return state.erro;
  },
};

// Actions
const actions = {
  carregarCategorias({ commit }, { paginacao, filtro = {} }) {
    return new Promise(async resolve => {
      commit('setLoader', true);
      const comunicacao = new Vue.prototype.$httpClient();
      let endpoint = `/produto/v1/categorias/${paginacao.totalPorPagina}/${paginacao.paginaAtual}`;

      if (Object.entries(filtro).length > 0) {
        if (filtro.codigo) {
          endpoint += `?codigo=${filtro.codigo}`;
        }
        if (filtro.codigo && filtro.nome) {
          endpoint += `&nome=${filtro.nome}`;
        } else if (filtro.nome) {
          endpoint += `?nome=${filtro.nome}`;
        }
      }

      const res = await comunicacao.send(endpoint, 'GET');

      if ([200, 201].includes(res.status)) {
        commit('setCategorias', res.data.retorno.retorno);
        commit('setTotalizador', res.data.retorno.total);
        commit('setPaginas', res.data.retorno.paginas);
        commit('setErro', null);
      } else if ([404].includes(res.status)) {
        commit('setCategorias', []);
        commit('setTotalizador', 0);
        commit('setErro', null);
      } else {
        commit('setTotalizador', 0);
        commit(
          'setErro',
          res.data.mensagem || 'Ops.. ocorreu um erro na conexão com o servidor, tente novamente mais tarde!'
        );
      }
      commit('setLoader', false);
      resolve();
    });
  },
};

// Mutations
const mutations = {
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach(key => {
      state[key] = newState[key];
    });
  },

  setCategorias(state, data) {
    state.categorias = data;
  },

  setCategoria(state, data) {
    state.categoria = data;
  },

  setTotalizador(state, data) {
    state.totalCategorias = data;
  },

  setLoader(state, data) {
    state.carregando = data;
  },

  setPaginas(state, data) {
    state.totalPaginas = data;
  },

  setErro(state, data) {
    state.erro = data;
  },
};

// Exporting store module
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
