<template>
    <section class="the-app-version">
        <span class="version">
            V. {{ APPLICATION_VERSION }}
        </span>
    </section>
</template>

<script>
// [] - Remover logo parte superior (subistituir "!requiresAuth" por uma propriedade específica, tal como "showBottomLogo: true")
export default {
    name: 'TheAppVersion',
    data: () => ({
        APPLICATION_VERSION: APPLICATION_VERSION,
    })
    // replace(/(\d{1}).(\d{2}).(\d{2})/, '$1.$2')
}
</script>

<style lang="scss" src="./style.scss"></style>