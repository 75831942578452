<template>
  <div
    class="pill-header-title"
    :class="[backgroundColor]"
    :style="
      config.COR_DESTAQUE_CIRCUNFERENCIA_CARDS_MODAIS
        ? `background-color: ${config.COR_DESTAQUE_CIRCUNFERENCIA_CARDS_MODAIS} !important;`
        : ''
    "
  >
    <!-- Pill -->
    <div
      class="left-middle-pill pill-size cursor-pointer"
      :class="backgroundPill ? backgroundPill : 'pill-body-dark'"
      :style="
        config.COR_DESTAQUE_CIRCUNFERENCIA_CARDS_MODAIS
          ? `background-color: ${config.COR_DESTAQUE_CIRCUNFERENCIA_CARDS_MODAIS} !important;`
          : ''
      "
      v-if="pillIcon"
    >
      <i
        :class="pillIcon"
        :style="
          config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_CARDS_MODAIS && !pillIconSameColorTitle
            ? `color: ${config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_CARDS_MODAIS} !important;`
            : ''
        "
      ></i>
    </div>

    <!-- Body -->
    <slot name="body-pill-header-title">
      <div class="w-100"></div>
    </slot>

    <!-- Valor pontuação -->
    <slot name="body-pill-header-pontuacao" />

    <!-- Info -->
    <div class="right-header-title-info cursor-pointer" v-tooltip.left="toolTipMessage">
      <i class="fas fa-info-circle"></i>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'PillHeaderTitle',
  props: {
    backgroundColor: {
      type: String,
      required: false,
      default: '',
      description: 'Propriedade que define a cor de fundo do componente',
    },
    backgroundPill: {
      type: String,
      required: false,
      default: '',
      description: 'Propriedade que define a cor de fundo do ícone',
    },
    pillIcon: {
      type: String,
      required: false,
      default: '',
      description: 'ícone a ser exibido na pill do lado esquerdo',
    },
    pillIconSameColorTitle: {
      type: Boolean,
      required: false,
      default: false,
      description: 'define se a cor utilizada no ícone deve ser a mesma utilizada no título',
    },
    toolTipMessage: {
      type: String,
      required: false,
      default: '',
      description: 'Mensagem a ser exibida quando para no tooltip',
    },
  },
  data: () => ({}),

  computed: {
    ...mapGetters({
      config: 'config/getConfig',
      currentCampaign: 'campanha/getCampaign',
    }),
  },
  methods: {
    ...mapActions({
      handleMenuSlide: 'ui/handleMenuSlide',
    }),
    navigateTo(route) {
      this.$router.push({ name: route });
    },
  },
};
</script>
<style lang="scss" src="./style.scss"></style>
