import Vue from 'vue';

// Initial state
const initialState = () => ({
  coupons: [],
  cupons: [],
  compra: [],
  pontos: [],
  formasPagamento: [],
  totalCupons: 0,
  winners: [],
  loading: false,
  couponsConfig: {},
  consumidorCupom: {},
  quantidadeCupons: 0,
  documentosCoupons: { total: '', lista: [] },
  cuponsConfigCompra: {},
  formasPagamentoConfigCuponsCompra: [],
  cuponsEscaneadosPorPeriodo: null,
  totalCupomPorLote: [],
});

// State object
const state = initialState();

// Getters
const getters = {
  getCoupons(state) {
    return state.coupons;
  },

  getQuantidadeCupons(state) {
    return state.quantidadeCupons;
  },

  getTotalCupons(state) {
    return state.totalCupons;
  },

  getCupons(state) {
    return state.cupons;
  },

  getCompra(state) {
    return state.compra;
  },

  getPontos(state) {
    return state.pontos;
  },

  getConsumidorCupom(state) {
    return state.consumidorCupom;
  },

  getDocumentosCouponsLista(state) {
    return state.documentosCoupons.lista;
  },

  getDocumentosCouponsTotal(state) {
    return state.documentosCoupons.total;
  },

  getFormasPagamento(state) {
    return state.formasPagamento;
  },

  getWinners(state) {
    return state.winners;
  },

  loadState(state) {
    return state.loading;
  },

  getCouponsConfig(state) {
    return state.couponsConfig;
  },

  getCuponsConfigCompra(state) {
    return state.cuponsConfigCompra;
  },
  getFormasPagamentoConfigCuponsCompra(state) {
    return state.formasPagamentoConfigCuponsCompra;
  },
  getCuponsEscaneadosPorPeriodo(state) {
    return state.cuponsEscaneadosPorPeriodo;
  },

  getTotalCupomPorLote(state) {
    return state.totalCupomPorLote;
  },
};

// Actions
const actions = {
  clearState({ commit }) {
    commit('RESET');
  },

  escanear({ commit }, cupom) {
    return new Promise(async resolve => {
      commit('startLoading');
      const api = new Vue.prototype.$httpClient();
      const url = `/cupom/v2/cupons/escanear/${cupom.anoCampanha}/${cupom.identificacaoCampanha}/${cupom.numeroAleatorio}`;

      const response = await api.send(url, 'GET', { ...cupom.data }, undefined, false);

      commit('stopLoading');
      if (response.data) {
        resolve({
          status: response.status,
          msg: response.data.mensagem,
        });
      } else {
        resolve({
          status: 500,
          msg: 'Erro de rede',
        });
      }
    });
  },

  fetchUserCoupons({ commit }, { campaign, user }) {
    return new Promise(async resolve => {
      const api = new Vue.prototype.$httpClient();
      const url = `/cupom/v2/cupons/consumidor/${campaign.ano}/${campaign.identificacao}/${user}`;
      const response = await api.send(url, 'GET', null, undefined, false);
      if (response.status == 200) {
        commit('setCoupons', response.data.retorno);
        resolve({
          status: 200,
          msg: 'Cupons recuperados para esse usuário.',
        });
      } else {
        commit('setCoupons', []);
        resolve({
          status: 404,
          msg: 'Nenhum cupom encontrado para esse usuário.',
        });
      }
    });
  },

  ListarDocumentosCupons({ commit }, { campaign, pagination }) {
    return new Promise(async resolve => {
      const api = new Vue.prototype.$httpClient();
      const url = `/cupom/v2/cupons/documentoFiscal/${campaign.ano}/${campaign.identificacao}/${pagination.porPagina}/${pagination.pagina}`;
      const response = await api.send(url, 'GET', null, undefined, false);
      if (response.status == 200) {
        commit('setDocumentosCoupons', response.data.retorno);
        resolve({
          status: 200,
          msg: 'Cupons recuperados para esse usuário.',
        });
      } else {
        commit('setCoupons', []);
        resolve({
          status: 404,
          msg: 'Nenhum cupom encontrado para esse usuário.',
        });
      }
    });
  },

  fetchUserCupons({ commit }, { campaign, user, pagination }) {
    return new Promise(async resolve => {
      const api = new Vue.prototype.$httpClient();
      const url = `/cupom/v2/cupons/consumidor/${campaign.ano}/${campaign.identificacao}/${user}/${pagination.porPagina}/${pagination.pagina}`;
      const response = await api.send(url, 'GET', null, undefined, false);
      if (response.status == 200) {
        commit('setCupons', response.data.retorno);
        resolve({
          status: 200,
          msg: 'Cupons recuperados para esse usuário.',
        });
      } else {
        commit('setCupons', []);
        resolve({
          status: 404,
          msg: 'Nenhum cupom encontrado para esse usuário.',
        });
      }
    });
  },

  consultarQuantidadeCupons({ commit }, { campanha, cpf, paginacao, isProd }) {
    return new Promise(async resolve => {
      const config = Vue.prototype.$storeConfig.config();
      const api = new Vue.prototype.$httpClient(
        isProd ? config.VUE_APP_PROD_WS_URL : config.VUE_APP_DEV_WS_URL,
        btoa(new Date().getTime())
      );
      const url = `/cupom/v2/cupons/consumidor/${campanha.ano}/${campanha.identificacao}/cpf/${cpf}/${paginacao.porPagina}/${paginacao.pagina}`;
      const response = await api.send(url, 'GET', null, undefined, false);
      if (response.status == 200) {
        commit('setQuantidadeCupons', response.data.retorno.total);
        resolve({
          status: 200,
          msg: 'Total recuperado.',
        });
      } else {
        commit('setQuantidadeCupons', 0);
        resolve({
          status: 404,
          msg: 'Nenhum usuário cadastrado com este CPF. Por favor revise os dados digitados.',
        });
      }
    });
  },

  obterQuantidadeCuponsPeriodo({ commit }, { campanha, cpf }) {
    return new Promise(async resolve => {
      const api = new Vue.prototype.$httpClient();
      const url = `/cupom/v2/cupons/escaneadosPorPeriodo/${campanha.ano}/${campanha.identificacao}/${cpf}`;

      const response = await api.send(url, 'GET', null, undefined, false);

      if (response.status === 200) {
        commit('setCuponsEscaneadosPorPeriodo', response.data.retorno);
        resolve({
          status: 200,
          msg: 'Quantidade cupons escaneados obtido.',
        });
      } else {
        commit('setCuponsEscaneadosPorPeriodo', null);
        resolve({
          status: response.status || 500,
          msg: `Erro ao obter cupons escaneados por período: ${response.data.mensagem || 'erro interno'}`,
        });
      }
    });
  },

  obterConsumidorCupom({ commit }, { telefone }) {
    return new Promise(async resolve => {
      const api = new Vue.prototype.$httpClient();
      const url = `/cupom/v2/consumidor/${telefone}`;
      const response = await api.send(url, 'GET', null, undefined, false);
      if (response.status == 200) {
        commit('setConsumidorCupom', response.data.retorno);
        resolve({
          status: 200,
          msg: 'Consumidor recuperado.',
        });
      } else {
        commit('setConsumidorCupom', []);
        resolve({
          status: 404,
          msg: 'Nenhum consumidor encontrado.',
        });
      }
    });
  },

  fetchWinners({ commit }, { campaign, date }) {
    return new Promise(async resolve => {
      commit('startLoading');
      const api = new Vue.prototype.$httpClient();
      const url = `cupom/v2/cupons/premiados/${campaign.ano}/${campaign.identificacao}/${date}`;
      const response = await api.send(url, 'GET', null, undefined, false);
      commit('stopLoading');
      if ([200, 201].includes(response.status)) {
        commit('setWinners', response.data.retorno);
        resolve({
          status: 200,
          msg: 'Ganhadores de sorteio obtidos com sucesso!',
        });
      } else {
        resolve({
          status: response.status,
          msg: response.data.mensagem || 'Ocorreu um erro desconhecido durante sua requisição.',
        });
      }
    });
  },

  fetchCouponsConfig({ commit }, { campaign }) {
    return new Promise(async resolve => {
      const api = new Vue.prototype.$httpClient();
      const url = `cupom/v2/cupons/configuracao/${campaign.ano}/${campaign.identificacao}`;
      const response = await api.send(url, 'GET', null, undefined, false);

      if (response.data.status == 200) {
        commit('setCouponsConfig', response.data.retorno);
        resolve({
          status: 200,
          data: response.data,
        });
      } else {
        commit('setCouponsConfig', {});
        resolve({
          status: response.data.status,
          mensagem: response.data.mensagem,
        });
      }
    });
  },

  fetchCouponsPorPeriodo({ commit }, { campanha, consumidor, periodo }) {
    return new Promise(async resolve => {
      const api = new Vue.prototype.$httpClient();
      const url = `cupom/v2/cupons/${campanha.ano}/${campanha.identificacao}/listarPorCampanha`;
      const response = await api.send(
        url,
        'POST',
        {
          filtro: {
            consumidor,
            periodo,
          },
        },
        undefined,
        false
      );

      if (response.data.status == 200) {
        commit('setQuantidadeCupons', response.data.retorno.total || 0);
        resolve({
          status: 200,
          data: response.data,
        });
      } else {
        commit('setQuantidadeCupons', 0);
        resolve({
          status: response.data.status,
          mensagem: response.data.mensagem,
        });
      }
    });
  },

  obterCompra({ commit }, { documentoFiscalID }) {
    return new Promise(async resolve => {
      const api = new Vue.prototype.$httpClient();
      const url = `produto/v1/compras/${documentoFiscalID}`;
      const response = await api.send(url, 'GET', null, undefined, false);

      if (response.data.status == 200) {
        commit('setCompra', response.data.retorno);
        resolve({
          status: 200,
          data: response.data,
        });
      } else {
        commit('setCompra', {});
        resolve({
          status: response.data.status,
          mensagem: response.data.mensagem,
        });
      }
    });
  },

  obterPontos({ commit }, { lancamentoID, campaign }) {
    return new Promise(async resolve => {
      const api = new Vue.prototype.$httpClient();
      const url = `contaCorrente/v1/lancamentos/${campaign.ano}/${campaign.identificacao}/${lancamentoID}`;
      const response = await api.send(url, 'GET', null, undefined, false);

      if (response.data.status == 200) {
        commit('setPontos', response.data.retorno);
        resolve({
          status: 200,
          data: response.data,
        });
      } else {
        commit('setPontos', {});
        resolve({
          status: response.data.status,
          mensagem: response.data.mensagem,
        });
      }
    });
  },

  alterarDinamicaCupom({ commit }, { dataDinamica }) {
    return new Promise(async resolve => {
      const api = new Vue.prototype.$httpClient();

      const response = await api.send(`cupom/v2/consumidor/dinamicaCupom`, 'POST', {
        telefone: dataDinamica.telefone,
        dinamicaCupom: dataDinamica.dinamicaCupom,
      });

      if ([200].includes(response.status)) {
        resolve({
          status: 200,
          msg: 'Dinânica cupom foi atualizada com sucesso!',
        });
      } else {
        resolve({
          status: response.status || 500,
          msg: response.data.mensagem || 'Você pode estar off-line. Tente novamente mais tarde.',
        });
      }
    });
  },

  fetchCuponsConfigCompra({ commit }, { campaign }) {
    return new Promise(async resolve => {
      const api = new Vue.prototype.$httpClient();
      const url = `cupom/v2/cupons/configuracaoCompra/${campaign.ano}/${campaign.identificacao}`;
      const response = await api.send(url, 'GET', null, undefined, false);

      if (response.data.status == 200) {
        commit('setCuponsConfigCompra', response.data.retorno);
        commit('setFormasPagamentoConfigCuponsCompra', response.data.retorno.formasPagamento);
        resolve({
          status: 200,
          data: response.data,
        });
      } else {
        commit('setCuponsConfigCompra', {});
        resolve({
          status: response.data.status,
          mensagem: response.data.mensagem,
        });
      }
    });
  },

  fetchTotalCupomEscaneadoPorLote({ commit }, { campaign }) {
    return new Promise(async resolve => {
      const api = new Vue.prototype.$httpClient();
      const url = `cupom/v2/cupons/lotes/${campaign.ano}/${campaign.identificacao}/totalEscaneados`;
      const response = await api.send(url, 'GET', null, undefined, false);

      if (response.data.status == 200) {
        commit('setTotalCupomPorLote', response.data.retorno);
        resolve({
          status: 200,
          data: response.data,
        });
      } else {
        commit('setTotalCupomPorLote', []);
        resolve({
          status: response.data.status,
          mensagem: response.data.mensagem || 'Você esta sem conexão, verifique sua rede',
        });
      }
    });
  },
};

// Mutations
const mutations = {
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach(key => {
      state[key] = newState[key];
    });
  },

  setCoupons(state, data) {
    state.coupons = data;
  },

  setQuantidadeCupons(state, data) {
    state.quantidadeCupons = data;
  },

  setCompra(state, data) {
    state.compra = data;
  },

  setPontos(state, data) {
    state.pontos = data;
  },

  setConsumidorCupom(state, data) {
    state.consumidorCupom = data;
  },

  setCupons(state, data) {
    state.totalCupons = data.total;
    state.cupons = data.retorno.lista;
    state.formasPagamento = data.retorno.formasPagamento;
  },

  setDocumentosCoupons(state, data) {
    state.documentosCoupons.total = data.total;
    state.documentosCoupons.lista = data.retorno;
  },

  setWinners(state, data) {
    state.winners = data;
  },

  setCouponsConfig(state, data) {
    state.couponsConfig = data;
  },

  setCuponsConfigCompra(state, data) {
    state.cuponsConfigCompra = data;
  },

  setFormasPagamentoConfigCuponsCompra(state, data) {
    state.formasPagamentoConfigCuponsCompra = data;
  },

  setCuponsEscaneadosPorPeriodo(state, data) {
    state.cuponsEscaneadosPorPeriodo = data;
  },

  startLoading(state) {
    state.loading = true;
  },

  stopLoading(state) {
    state.loading = false;
  },

  setTotalCupomPorLote(state, data) {
    state.totalCupomPorLote = data;
  },
};

// Module exports
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
