<template>
  <div @click="$emit('click')" id="card" :style="`min-height: ${alturaCard}; background-color: ${bgColorCard}`">
    <div class="imagens">
      <img
        style="max-height: 50px;"
        v-if="listaImagens.length && listaImagens[0].length && listaImagens[0] != 'null'"
        id="imagem"
        v-for="(imagem, index) in listaImagens.slice(0, 3)"
        :key="index"
        :src="imagem"
      />
      <img v-else id="imagem" :src="require('../../../assets/images/img-insdisponivel.png')" alt="Imagem do produto" />
    </div>
    <div class="infos">
      <span id="titulo" :style="`font-size: ${tamanhoTitulo}; color: ${corTitulo}; font-weight: ${pesoTitulo};`"
        >{{ titulo }}
      </span>
      <span v-html="subTitulo" class="subtitulo"> </span>
      <span class="status" :style="`color: ${corStatus}`"> {{ status }}</span>
      <!-- <div class="d-flex">
        <div v-for="(statu, index) in status">
          <span class="status" :style="`color:${retornarCorStatus(statu)}`">{{ statu }}</span>
          <span v-if="index !== status.length - 1"
            ><i class="fa-solid fa-arrow-right ml-2 mr-2" style="color: black"></i
          ></span>
        </div>
      </div> -->
    </div>
    <div class="exibir">
      <i class="fas fa-eye"></i>
    </div>
    <!-- <div class="d-flex flex-column">
    </div> -->
  </div>
</template>

<style scoped lang="scss">
#card {
  width: 100%;
  align-self: center;
  position: relative;
  display: flex;
  align-items: center;
  // justify-content: space-between;
  border-radius: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  img {
    max-height: 80px;
    margin: auto;
  }
  &:hover {
    cursor: pointer;
  }
}
#titulo {
  text-align: left;
  line-height: 2;
  word-break: break-all;
}
.exibir {
  width: 50px;
  min-width: 38px;
  border-radius: 0 10px 10px 0;
  height: 100%;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  margin-left: auto;
}
.imagens {
  width: 100px;
  display: flex;
  flex-direction: column;
  align-self: center;
  color: black;
  margin-top: 5px;
  margin-bottom: 5px;
  gap: 5px;
}
.subtitulo {
  color: black;
  text-align: left;
  font-size: 12px;
  line-height: 2;
}
.status {
  font-size: 14px;
  font-weight: 800;
  text-align: left;
}
.infos {
  display: flex;
  flex-direction: column;
  height: fit-content;
  margin: 10px;
}
</style>

<script>
export default {
  name: 'card-pedido',
  props: {
    titulo: {
      type: String,
      required: true,
      default: '',
      description: 'Propriedade que recebe o nome que será colocado no card',
    },
    listaImagens: {
      type: Array,
      required: false,
      default: () => [''],
      description:
        'Propriedade que recebe um array de URL de imagens que serão colocadas em coluna na esquerda do card',
    },
    imagem: {
      type: String,
      required: false,
      default: '',
      description: 'Propriedade que recebe uma URL de imagem que será colocada na esquerda do card',
    },
    subTitulo: {
      type: String,
      required: false,
      default: '',
      description: 'Propriedade que recebe o subtítulo que será colocado no card',
    },
    status: {
      type: Array,
      required: false,
      default: '',
      description: 'Propriedade que recebe o status do pedido que será colocado no card',
    },
    corStatus: {
      type: String,
      required: false,
      default: 'black',
      description: 'Propriedade que recebe a cor da fonte do status que será colocado no card',
    },
    larguraCard: {
      type: String,
      required: false,
      default: '200px',
      description: 'Propriedade que define a largura do card',
    },
    alturaCard: {
      type: String,
      required: false,
      default: '100px',
      description: 'Propriedade que define a altura do card da imagem',
    },
    bgColorCard: {
      type: String,
      required: false,
      default: 'white',
      description: 'Propriedade que define a cor de fundo do card',
    },
    larguraImagem: {
      type: String,
      required: false,
      default: '60%',
      description: 'Propriedade que define a largura da imagem',
    },
    tamanhoTitulo: {
      type: String,
      required: false,
      default: '16px',
      description: 'Propriedade que define a font-size do título',
    },
    corTitulo: {
      type: String,
      required: false,
      default: 'black',
      description: 'Propriedade que define a cor da fonte do título',
    },
    pesoTitulo: {
      type: String,
      required: false,
      default: '600',
      description: 'Propriedade que define a font-weight do título',
    },
  },
  methods: {
    retornarCorStatus(statu) {
      console.log(statu);
      switch (statu) {
        case 'APROVADO':
        case 'ENVIADO':
        case 'PENDENTE':
        case 'SEPARACAO':
          return 'green';
        case 'REPROVADO':
          return 'red';
        default:
          return 'blue'; // ou qualquer outra cor padrão
      }
    },
  },
};
</script>
