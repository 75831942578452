import Vue from 'vue'

const initialState = () => ({
  carregando: false,
  documentosFiscais: [],
  configuracaoDocumentosFiscais: {},
  totalDocumentosFiscais: 0,
  documentoFiscal: {},
});

const state = initialState();

const getters = {
  obterEstadoCarregamento(state) {
    return state.carregando;
  },
  getDocumentosFiscais(state) {
    return state.documentosFiscais;
  },
  getConfiguracaoDocumentosFiscais(state) {
    return state.configuracaoDocumentosFiscais;
  },
  getTotalDocumentosFiscais(state) {
    return state.totalDocumentosFiscais;
  },
  getDocumentoFiscal(state) {
    return state.documentoFiscal;
  },
}

const actions = {
  clearState({ commit }) { commit('RESET') },
  cadastrarDocumentoNaoFiscal({ commit }, { campanha, estado, usuario, dadosNaoFiscal, uriImagem
  }) {
    return new Promise(async resolve => {
      const api = new Vue.prototype.$httpClient()
      const url = 'documentoFiscal/v1/documentos/inserirNaoFiscal'
      commit('alternarCarregamento', true)
      const resposta = await api.send(url, 'POST', {
        campanha: campanha,
        uf: estado,
        usuario: usuario,
        dadosNaoFiscal: dadosNaoFiscal,
        tipo: 'NaoFiscal',
        uriImagem: uriImagem
      })
      commit('alternarCarregamento', false)
      if ([200, 201].includes(resposta.status)) {
        resolve({
          status: resposta.status,
          msg: resposta.data.mensagem
        })
      } else {
        resolve({
          status: resposta.status || 500,
          msg: resposta.data.mensagem || 'Você pode estar off-line. Tente novamente mais tarde.'
        })
      }
    })
  },

  obterConfiguracaoDocumentoFiscal({ commit }, { campanha }) {
    return new Promise(async resolve => {
      const api = new Vue.prototype.$httpClient()
      const url = `/documentoFiscal/v1/configuracao/${campanha.ano}/${campanha.identificacao}`;
      commit('alternarCarregamento', true)
      const resposta = await api.send(url)
      commit('alternarCarregamento', false)

      if ([200, 201].includes(resposta.status)) {
        commit('definirConfiguracaoDocumentosFiscais', resposta.data.retorno)
        resolve({
          status: resposta.status,
          msg: resposta.data.mensagem
        })
      } else {
        commit('definirConfiguracaoDocumentosFiscais', {})
        resolve({
          status: resposta.status || 500,
          msg: resposta.data.mensagem || 'Você pode estar off-line. Tente novamente mais tarde.'
        })
      }
    })
  },

  listarDocumentosPorUsuario({ commit }, { campanha, usuario, tipoDocumento, limitePorUsuario = 100 }) {
    return new Promise(async resolve => {
      const api = new Vue.prototype.$httpClient();
      const url = `/documentoFiscal/v1/documentos/${limitePorUsuario}/1/`;
      commit('alternarCarregamento', true);
      const resposta = await api.send(url, 'GET', {
        anoCampanha: campanha.ano,
        identificacaoCampanha: campanha.identificacao,
        usuario,
        tipoDocumento,
      }, undefined, false);
      commit('alternarCarregamento', false);

      if ([200, 201].includes(resposta.status)) {
        if (resposta.data.retorno && resposta.data.retorno.retorno) {
          commit('definirDocumentosFiscais', resposta.data.retorno.retorno)
        } else {
          commit('definirDocumentosFiscais', resposta.data.retorno)
        }
        resolve({
          status: resposta.status,
          msg: resposta.data.mensagem
        })
      } else {
        commit('definirDocumentosFiscais', [])
        resolve({
          status: resposta.status || 500,
          msg: resposta.data.mensagem || 'Você pode estar off-line. Tente novamente mais tarde.'
        })
      }
    })
  },

  fetchDocumentosFiscais({ commit }, { paginacao = {}, usuario }) {
    return new Promise(async resolve => {
      const api = new Vue.prototype.$httpClient();
      const url = `/documentoFiscal/v1/documentos/${paginacao.porPagina || 0}/${paginacao.pagina || 0}`;
      const res = await api.send(url, 'GET', { usuario }, undefined, false);

      if ([200, 201].includes(res.status)) {
        commit('definirDocumentosFiscais', res.data.retorno.retorno);
        commit('setTotalDocumentosFiscais', res.data.retorno.total);
        resolve({
          status: 200,
          mensagem: 'Lista de documentos fiscais obtida com sucesso!',
          retorno: res.data.retorno.total,
        })
      } else if ([404].includes(res.status)) {
        commit('definirDocumentosFiscais', []);
        commit('setTotalDocumentosFiscais', 0);
        resolve({
          status: 404,
          mensagem: 'Não foi encontrado nenhum documento fiscal!',
          retorno: 0,
        });
      }
    });
  },
}

const mutations = {
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach(key => {
      state[key] = newState[key]
    });
  },
  alternarCarregamento(state, data) {
    state.carregando = data;
  },
  definirDocumentosFiscais(state, data) {
    state.documentosFiscais = data;
  },
  definirConfiguracaoDocumentosFiscais(state, data) {
    state.configuracaoDocumentosFiscais = data;
  },
  setTotalDocumentosFiscais(state, data) {
    state.totalDocumentosFiscais = data;
  },
  setDocumentoFiscal(state, data) {
    state.documentoFiscal = data;
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
