<template>
  <div class="w-100 d-flex flex-column align-items-center">
    <!-- Título -->
    <div class="w-75 d-flex align-items-center justify-content-center mb-2">
      <p class="f-11 mt-4 text-center" style="color: var(--text-color-highlight)">
        <strong>Redefinir senha via e-mail</strong>
      </p>
    </div>

    <!-- Carregando -->
    <div v-if="carregando" class="w-75 d-flex flex-column align-items-center justify-content-center mb-3 f-09">
      <div class="loading mb-3"></div>
      <span>Carregando...</span>
    </div>

    <!-- Subtítulo -->
    <div class="w-75 d-flex align-items-center justify-content-center mb-3">
      <p class="f-09 mb-3 text-center" style="color: var(--text-color-highlight)" v-if="emailUsuario && !carregando">
        {{
          `O e-mail para redefinir a senha foi encaminhado para (${emailUsuario}). Lembre-se de verificar a caixa de Spam.`
        }}
      </p>
    </div>

    <!-- Reenviar -->
    <base-pill-button
      v-if="!carregando"
      :disabled="tempoInicial > 0 || carregando"
      id="esqueci-senha-enviar"
      :type="'light'"
      :right-middle-pill="'fas fa-chevron-circle-right'"
      :pillColors="['pill-light']"
      :pillIconColors="['icon-dark']"
      :rightMiddlePillRootColor="config.COR_DESTAQUE_CIRCUNFERENCIA_DOS_BOTOES"
      :rightMiddlePillIconRootColor="config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_DOS_BOTOES"
      :pillSize="['pill-average']"
      @click="validarUsuario"
    >
      <template v-slot:pill-button-title>
        <span class="w-90 pl-3 pr-2 text-center text-black f-07">
          Reenviar {{ tempoInicial > 0 ? `(${tempoInicial})` : '' }}
        </span>
      </template>
    </base-pill-button>

    <!-- Voltar -->
    <div class="w-75 d-flex align-items-center justify-content-center pt-5">
      <base-pill-button
        id="esqueci-senha-voltar"
        :type="'light'"
        :left-middle-pill="'fas fa-chevron-circle-left'"
        :pillColors="['pill-dark']"
        :pillIconColors="['icon-light']"
        :leftMiddlePillRootColor="config.COR_DESTAQUE_CIRCUNFERENCIA_DOS_BOTOES"
        :leftMiddlePillIconRootColor="config.COR_DESTAQUE_ICONE_CIRCUNFERENCIA_DOS_BOTOES"
        :pillSize="['pill-average']"
        @click="voltarTela()"
      >
        <template v-slot:pill-button-title>
          <span class="w-90 pl-2 pr-3 text-center f-07 text-black">Voltar</span>
        </template>
      </base-pill-button>
    </div>
  </div>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'TheEsqueciSenha',
  beforeMount() {
    if (this.usuario) {
      this.form.usuario = this.usuario;
      this.validarUsuario();
    }
  },
  computed: {
    ...mapGetters({
      config: 'config/getConfig',
      carregando: 'autenticacao/isLoading',
    }),
  },
  data: () => ({
    emailUsuario: '',
    form: {
      email: '',
      usuario: '',
    },
    permitirReenvioEmail: false,
    manterReenviar: false,
    tempoInicial: 60,
  }),
  methods: {
    ...mapActions({
      gerarTokenTemporario: 'autenticacao/esqueciMinhaSenha',
    }),
    async validarUsuario() {
      if (this.permitirReenvioEmail) {
        this.$emit('emmitInfoModal', {
          event: 'modal-informacao-generica',
          data: {
            icone: 'fas fa-exclamation-circle',
            titulo: 'Ops...',
            mensagem: `Para reenviar o e-mail de recuperar senha, você deve esperar por mais ${this.tempoInicial} ${
              this.tempoInicial == 1 ? 'segundo' : 'segundos'
            }.`,
            textoBotao: 'OK',
          },
        });
        return;
      }
      if (this.$v.$invalid) {
        this.$emit('emmitInfoModal', {
          event: 'modal-informacao-generica',
          data: {
            icone: 'fas fa-exclamation-circle',
            titulo: 'Ops...',
            mensagem: `Há erros de validação! Por favor revise o preenchimento do formulário.`,
            textoBotao: 'OK',
            onClose: () => this.$v.$touch(),
          },
        });
      } else {
        await this.enviarFormulario();
      }
    },
    async enviarFormulario() {
      const r = await this.gerarTokenTemporario({
        usuario: this.form.usuario,
        isProd: this.isProd,
      });
      if (r.status == 200) {
        this.emailUsuario = r.email;

        this.$emit('emmitEvent', {
          event: 'gtm',
          data: {
            event: 'solicitacaoEsqueciSenha',
            category: 'EsqueciMinhaSenha',
            action: 'form',
            label: 'Solicitou token para redefinição de senha',
            value: this.form.usuario,
          },
        });
        this.tempoInicial = 60;
        this.permitirReenvioEmail = true;
        this.contadorTempoReenviarEmail();
      } else {
        this.$emit('emmitInfoModal', {
          event: 'modal-informacao-generica',
          data: {
            icone: 'fas fa-exclamation-circle',
            titulo: 'Ops...',
            mensagem: r.msg.includes('Receptores')
              ? 'Um erro inesperado ocorreu, entre em contato com o suporte'
              : r.msg,
            textoBotao: 'OK',
          },
        });
      }
    },
    voltarTela() {
      this.$emit('voltar');
      if (!this.config.PERMITIR_REDEFINIR_SENHA_VIA_SMS) {
        this.$emit('alternar-exibicao', {
          event: 'alternar-tela-esqueci-senha',
        });
      }
    },
    navigateTo(name) {
      this.$router.push({ name });
    },

    contadorTempoReenviarEmail() {
      let intervalo;

      if (this.tempoInicial > 0 && this.tempoInicial <= 60) {
        intervalo = setInterval(() => {
          this.tempoInicial--;

          if (this.tempoInicial <= 0) {
            clearInterval(intervalo);
            this.permitirReenvioEmail = false;
            this.manterReenviar = true;
            return;
          }
        }, 1000);
      }
    },
  },
  props: {
    isProd: {
      type: Boolean,
      required: true,
    },
    usuario: {
      type: String,
      required: false,
    },
  },
  validations: {
    form: {
      usuario: {
        required,
        minLength: minLength(10),
      },
    },
  },
};
</script>
