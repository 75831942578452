import Vue from 'vue';
import { authentication } from '../../lib/Authentication';

// Initial state
const initialState = () => ({
  loading: false,
  tempoRestante: null,
});

// State object
const state = initialState();

// Getters
const getters = {
  isLoading(state) {
    return state.loading;
  },

  getTempoRestante(state) {
    return state.tempoRestante;
  },
};

// Actions
const actions = {
  clearState({ commit }) {
    commit('RESET');
  },
  autenticacaoFacebook({ commit }, { isProd, tokenFacebook }) {
    return new Promise(async (resolve) => {
      const config = Vue.prototype.$storeConfig.config();
      const api = new Vue.prototype.$httpClient(isProd ? config.VUE_APP_PROD_WS_URL : config.VUE_APP_DEV_WS_URL);
      const url = `/login/v1/autenticar/facebook`;
      commit('setLoading', true);
      const resposta = await api.send(url, 'POST', { tokenFacebook });
      commit('setLoading', false);
      if ([200, 201].includes(resposta.status)) {
        resolve({
          status: 200,
          msg: 'Autenticação com o Facebook realizada com sucesso!',
          dados: resposta.data.retorno,
        });
      } else {
        resolve({
          status: resposta.status || 500,
          msg: resposta.data.mensagem || 'Você pode estar off-line. Tente novamente mais tarde.',
          dados: resposta.data && resposta.data.retorno ? resposta.data.retorno : null,
        });
      }
    });
  },
  confirmarEmail({ commit }, { isProd, tokenVerificacao }) {
    return new Promise(async (resolve) => {
      const config = Vue.prototype.$storeConfig.config();
      const api = new Vue.prototype.$httpClient(isProd ? config.VUE_APP_PROD_WS_URL : config.VUE_APP_DEV_WS_URL);
      const url = `login/v1/logins/email/confirmar`;
      commit('setLoading', true);
      const resposta = await api.send(url, 'POST', { tokenVerificacao });
      commit('setLoading', false);
      if ([200, 201].includes(resposta.status)) {
        resolve({
          status: 200,
          msg: 'Seu E-mail foi verificado com sucesso!',
        });
      } else {
        resolve({
          status: resposta.status || 500,
          msg: resposta.data.mensagem || 'Você pode estar off-line. Tente novamente mais tarde.',
        });
      }
    });
  },
  esqueciMinhaSenha({ commit }, { usuario, isProd }) {
    return new Promise(async (resolve) => {
      const config = Vue.prototype.$storeConfig.config();
      const api = new Vue.prototype.$httpClient();
      const urlBase = isProd ? config.VUE_APP_PROD_WS_URL : config.VUE_APP_DEV_WS_URL;
      const url = `${urlBase}/login/v1/logins/senha/solicitarRedefinicao`;
      commit('setLoading', true);
      const resposta = await api.send(url, 'POST', { usuario });
      commit('setLoading', false);
      if ([200, 201].includes(resposta.status)) {
        resolve({
          status: 200,
          msg: 'Por favor, verifique seu e-mail.',
          email: resposta.data.retorno.email,
        });
      } else {
        resolve({
          status: resposta.status || 500,
          msg: resposta.data.mensagem || 'Você pode estar off-line. Tente novamente mais tarde.',
        });
      }
    });
  },
  esqueciMinhaSenhaViaSms({ commit }, { usuario, campanha, isProd }) {
    return new Promise(async (resolve) => {
      const config = Vue.prototype.$storeConfig.config();
      const urlBase = isProd ? config.VUE_APP_PROD_WS_URL : config.VUE_APP_DEV_WS_URL;
      const api = new Vue.prototype.$httpClient();
      const url = `${urlBase}/login/v1/logins/senha/solicitarRedefinicaoViaSMS/${campanha.ano}/${campanha.identificacao}`;
      commit('setLoading', true);
      const resposta = await api.send(url, 'POST', { usuario });
      commit('setLoading', false);
      if ([200, 201].includes(resposta.status)) {
        commit('setTempoRestante', resposta.data.retorno.segundosRestantes);
        resolve({
          status: 200,
          msg: resposta.data.mensagem,
          retorno: resposta.data.retorno,
        });
      } else {
        resolve({
          status: resposta.status || 500,
          msg: resposta.data.mensagem || 'Você pode estar off-line. Tente novamente mais tarde.',
        });
      }
    });
  },
  redefinirSenha({ commit }, { tokenVerificacao, novaSenha, isProd }) {
    return new Promise(async (resolve) => {
      const config = Vue.prototype.$storeConfig.config();
      const api = new Vue.prototype.$httpClient(isProd ? config.VUE_APP_PROD_WS_URL : config.VUE_APP_DEV_WS_URL);
      const url = `/login/v1/logins/senha/confirmar`;
      commit('setLoading', true);
      const resposta = await api.send(url, 'POST', {
        tokenVerificacao: tokenVerificacao,
        novaSenha: novaSenha,
      });
      commit('setLoading', false);
      if ([200, 201].includes(resposta.status)) {
        resolve({
          status: 200,
          msg: 'Senha alterada, você será logado automaticamente!',
          usuario: resposta.data.retorno.login.usuario,
        });
      } else {
        resolve({
          status: resposta.status || 500,
          msg: resposta.data.mensagem || 'Você pode estar off-line. Tente novamente mais tarde.',
        });
      }
    });
  },
  signIn({ commit }, { user, password, type, token, isProd, tokenFacebook }) {
    return new Promise(async (resolve) => {
      commit('setLoading', true);
      const config = Vue.prototype.$storeConfig.config();
      const httpClient = new Vue.prototype.$httpClient(
        isProd ? config.VUE_APP_PROD_WS_URL : config.VUE_APP_DEV_WS_URL,
        token,
        15000,
        true
      );
      const errorHandler = (data) => {
        let mensagem = config.MENSAGEM_ERRO_AUTENTICACAO_SESSAO_SALVA;
        if (data && data.data && data.data.mensagem) {
          mensagem = data.data.mensagem;
        }

        commit('setLoading', false);
        resolve({
          status: 401,
          msg: mensagem || 'Falha ao conectar, verifique sua conexão e tente mais tarde novamente.',
        });
      };
      const auth = new authentication(
        token,
        user,
        password,
        type,
        httpClient,
        tokenFacebook ? tokenFacebook : null,
        errorHandler
      );
      await auth.start();

      commit('setLoading', false);
      resolve({
        status: 200,
        msg: 'Autenticado com sucesso',
        // loginAnimation: token ? false : true
        loginAnimation: true,
      });
    });
  },
  signOut() {
    localStorage.removeItem('login');
  },

  enviarCodigoAleatorioSMS({ commit }, { codigo, usuario, isProd }) {
    return new Promise(async (resolve) => {
      const config = Vue.prototype.$storeConfig.config();
      const urlBase = isProd ? config.VUE_APP_PROD_WS_URL : config.VUE_APP_DEV_WS_URL;
      const api = new Vue.prototype.$httpClient();
      const url = `${urlBase}/login/v1/logins/senha/obterTokenRecuperarSenhaViaSMS`;
      commit('setLoading', true);
      const resposta = await api.send(url, 'POST', { codigo, usuario });
      commit('setLoading', false);
      if ([200, 201].includes(resposta.status)) {
        resolve({
          status: 200,
          msg: 'Código válido!',
          retorno: resposta.data.retorno,
        });
      } else {
        resolve({
          status: resposta.status || 500,
          msg: resposta.data.mensagem || 'Você pode estar off-line. Tente novamente mais tarde.',
        });
      }
    });
  },

  solicitarCodigoAutenticacaoTemporaria({ commit }, { usuario, isProd }) {
    return new Promise(async (resolve) => {
      const config = Vue.prototype.$storeConfig.config();
      const urlBase = isProd ? config.VUE_APP_PROD_WS_URL : config.VUE_APP_DEV_WS_URL;
      const api = new Vue.prototype.$httpClient();
      const url = `${urlBase}/login/v1/autenticacaoTemporaria/enviarNotificacaoToken`;
      const resposta = await api.send(url, 'POST', { usuario });
      if ([200, 201].includes(resposta.status)) {
        resolve({
          status: 200,
          msg: 'Autenticação enviada com sucesso!',
          retorno: resposta.data.retorno,
        });
      } else {
        resolve({
          status: resposta.status || 500,
          msg: resposta.data.mensagem || 'Você pode estar off-line. Tente novamente mais tarde.',
        });
      }
    });
  },

  verificaCodigoAutenticacaoTemporaria({ commit }, { codigo, usuario, isProd }) {
    return new Promise(async (resolve) => {
      const config = Vue.prototype.$storeConfig.config();
      const urlBase = isProd ? config.VUE_APP_PROD_WS_URL : config.VUE_APP_DEV_WS_URL;
      const api = new Vue.prototype.$httpClient();
      const url = `${urlBase}/login/v1/autenticacaoTemporaria/verificar`;
      const resposta = await api.send(url, 'POST', { codigo, usuario });
      if ([200, 201].includes(resposta.status)) {
        resolve({
          status: 200,
          msg: 'Código válido!',
          retorno: resposta.data.retorno,
        });
      } else {
        resolve({
          status: resposta.status || 500,
          msg: resposta.data.mensagem || 'Você pode estar off-line. Tente novamente mais tarde.',
        });
      }
    });
  },

  verificaTelefoneContatoLogin({ commit }, { telefoneContato, isProd }) {
    return new Promise(async (resolve) => {
      const config = Vue.prototype.$storeConfig.config();
      const urlBase = isProd ? config.VUE_APP_PROD_WS_URL : config.VUE_APP_DEV_WS_URL;
      const api = new Vue.prototype.$httpClient();
      const url = `${urlBase}/login/v1/logins/verificarTelefoneContato/${telefoneContato}`;
      const resposta = await api.send(url, 'GET', null);
      if ([200, 201].includes(resposta.status)) {
        resolve({
          status: 200,
          msg: resposta.data.mensagem,
          retorno: resposta.data.retorno,
        });
      } else {
        resolve({
          status: resposta.status || 500,
          msg: resposta.data.mensagem || 'Você pode estar off-line. Tente novamente mais tarde.',
          retorno: resposta.data.retorno || null,
        });
      }
    });
  },
};

// Mutations
const mutations = {
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },
  setLoading(state, data) {
    state.loading = data;
  },

  setTempoRestante(state, data) {
    state.tempoRestante = data;
  },
};

// Module exports
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
