<template>
  <div class="pill-input-group">
    <div class="base-pill-input" :class="[disabled ? 'disabled' : '']">
      <slot name="left-middle-pill">
        <div class="left-middle-pill" :class="[pillColors, pillSize]" :style="leftMiddlePillRootColor
          ? `background-color: ${leftMiddlePillRootColor} !important;`
          : ''
          " v-if="leftMiddlePill">
          <i :class="[icons, pillIconColors]" :style="leftMiddlePillIconRootColor
            ? `color: ${leftMiddlePillIconRootColor} !important;`
            : ''
            " v-if="icons"></i>
          <span class="title" v-if="title">{{ title }}</span>
        </div>
      </slot>

      <input v-if="mask" :id="id" :value="value" v-on="listeners" v-mask="mask" :masked="masked" :type="type"
        :class="[{ valid: valid }, inputClasses] + ' pill-input'" :placeholder="placeholder"
        :autocomplete="autocomplete ? 'on' : 'off'" :disabled="disabled" :readonly="readonly" />

      <input v-else-if="min" :id="id" :type="type" v-on="listeners"
        :class="[{ valid: valid }, inputClasses] + ' pill-input'" :value="value" :min="min" :max="max"
        :placeholder="placeholder" :autocomplete="autocomplete ? 'on' : 'off'" :disabled="disabled"
        :readonly="readonly" />

      <input v-else-if="formatMoney" v-money="moneyLib" :id="id" :type="type" v-on="listeners" :class="[{ valid: valid }, inputClasses] +
        ' pill-input ' +
        'placeholder-primary'
        " :value="value" :placeholder="placeholder" :autocomplete="autocomplete ? 'on' : 'off'" :disabled="disabled"
        :readonly="readonly" />

      <input v-else :list="dataList ? listId : ''" :id="id" :type="type" v-on="listeners" :class="[{ valid: valid }, inputClasses] +
        ' pill-input ' +
        'placeholder-primary'
        " :value="value" :placeholder="placeholder" :autocomplete="autocomplete ? 'on' : 'off'" :disabled="disabled"
        :readonly="readonly" />

      <datalist v-if="dataList" :id="listId">
        <option v-for="option in listOptions" :key="option">
          {{ option }}
        </option>
      </datalist>

      <slot name="pill-input-right-validation">
        <div class="pill-input-right-validation" v-if="leftMiddlePill && iconValidation"
          v-tooltip.top="errorMessageValidation">
          <i :class="[iconValidation, getValidation]"></i>
        </div>
      </slot>

      <slot name="right-middle-pill">
        <div class="right-middle-pill" :class="[pillColors, pillSize]" v-if="rightMiddlePill">
          <i :class="[icons, pillIconColors]" v-if="icons"></i>
          <span class="title" v-if="title">{{ title }}</span>
        </div>
      </slot>

      <slot name="right-pill">
        <div class="right-pill" :class="[pillColors[0]]" v-if="rightPill">
          <div class="capsule-left">
            <i :class="[icons[0], pillIconColors[0]]" v-if="icons"></i>
            <span class="title" v-if="title">{{ title }}</span>
          </div>
          <div :class="[
            search ? 'capsule-right-search' : 'capsule-right',
            pillColors[1]
          ]">
            <i :class="[icons[1], pillIconColors[1]]" v-if="icons"></i>
            <span class="title" v-if="title">{{ title }}</span>
          </div>
        </div>
      </slot>

      <!-- <slot name="label">
				<label :for="id" v-if="label" :class="labelClasses">
					{{ label }}
					<span v-if="required">*</span>
				</label>
			</slot> -->
    </div>
  </div>
</template>

<script>
import { VMoney } from 'v-money'
import { mask, masked } from 'vue-the-mask'

export default {
  directives: { mask, masked, money: VMoney },

  name: 'BasePillInput',

  inheritAttrs: false,

  data: () => ({
    focused: false,
    hasValue: false
  }),

  props: {
    id: {
      type: String,
      required: true,
      description: 'ID of the input so we can create a label for that input'
    },

    autocomplete: {
      type: Boolean,
      required: false,
      default: false,
      description: 'autocomplete attribute of the input tag(HTML 5 tag)'
    },

    readonly: {
      type: Boolean,
      required: false,
      default: false,
      description:
        'Propriedade que define se um input deve ou não ser de somente leitura.'
    },

    label: {
      type: String,
      required: false,
      description: 'Label that goes above the input'
    },

    leftMiddlePill: {
      type: Boolean,
      required: false,
      description: 'Pill at left input'
    },

    leftMiddlePillRootColor: {
      type: String,
      required: false,
      default: '',
      description:
        'Propriedade que define alguma cor capaz de sobrescrever a cor declarada na pílula'
    },

    leftMiddlePillIconRootColor: {
      type: String,
      required: false,
      default: '',
      description:
        'Propriedade que define alguma cor capaz de sobrescrever a cor do ícone declarada na pílula'
    },

    leftPill: {
      type: Boolean,
      required: false,
      description: 'Pill at left input'
    },

    rightMiddlePill: {
      type: Boolean,
      required: false,
      description: 'Pill at rigth input'
    },

    rightPill: {
      type: Boolean,
      required: false,
      description: 'Pill at rigth input'
    },

    title: {
      type: String,
      required: false,
      default: '',
      description: 'Text at pills'
    },

    icons: {
      type: [String, Array],
      required: false,
      description: 'Icons classes at pills'
    },

    labelClasses: {
      type: Array,
      required: false,
      description: 'Label classes'
    },

    inputClasses: {
      type: Array,
      required: false,
      description: 'Input classes'
    },

    pillColors: {
      type: Array,
      required: false,
      description: 'Pill classes'
    },

    pillIconColors: {
      type: Array,
      required: false,
      description:
        'Propriedade que define as cores dos ícones de cada pill, respectivamente, de acordo com as cores do elemento pai'
    },

    pillSize: {
      type: Array,
      required: false,
      description: 'Pill classes'
    },

    placeholder: {
      type: String,
      required: false,
      default: '',
      description: 'Input placeholder'
    },

    type: {
      type: String,
      required: false,
      default: 'text',
      description: 'Input type'
    },

    value: {
      type: [String, Number],
      description: 'Input value',
      default: ''
    },

    required: {
      type: Boolean,
      required: false,
      default: false,
      description:
        'Is this input required? If so we will add a * after the label'
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
      description:
        'Propriedade que define se um input deve ou não estar desabilitado.'
    },

    mask: {
      type: [String, Array],
      description: 'Mask that the input will have'
    },

    masked: {
      type: Boolean,
      default: false,
      description:
        'Boolean that will say if the value of the input will be saved masked or not'
    },

    valid: {
      type: Boolean,
      default: false,
      description: 'Boolean that we will use to check if the input is now valid'
    },

    min: {
      type: String,
      default: '',
      description: 'The min date'
    },

    max: {
      type: String,
      default: '',
      description: 'The max date'
    },

    search: {
      type: Boolean,
      default: false,
      required: false,
      description: 'Boolean to pill with serach styles'
    },

    iconValidation: {
      type: String,
      required: false,
      description: 'ícone que indica o status da validaaçãao do input'
    },

    dataList: {
      type: [Boolean],
      required: false,
      description: 'Propriedade que define se existe lista a ser utilizada'
    },
    listId: {
      type: [String],
      required: false,
      description: 'Propriedade que define o id da lista a ser utilizada'
    },
    listOptions: {
      type: [Array],
      required: false,
      description: 'Pilha de dados da lista'
    },
    formatMoney: {
      type: Boolean,
      required: false,
      default: false,
      description:
        'Propriedade que define se será utilizado input compatível com v-money.'
    },
    moneyLib: {
      type: Object,
      required: false,
      description: 'Objeto com parâmetros para o v-money'
    },
    errorMessageValidation: {
      type: String,
      required: false,
      default: '',
      description:
        'Propriedade que define uma mensagem, tooltip, para validações.'
    },
    defaultValue: {
      type: String,
      required: false,
      default: '',
      description:
        'Propriedade que define o valor padrão, ou previamente selecionado, para um input.'
    }
  },

  // beforeMount() {
  //   if(this.value) console.log(this.value)
  // },

  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: this.updateValue,
        focus: this.onFocus
      }
    },
    getValidation() {
      return this.hasValue || this.defaultValue
        ? this.valid
          ? 'valid'
          : 'invalid'
        : 'initial'
    }
  },

  methods: {
    updateValue(event) {
      let value =
        event.target.value && !this.masked && this.mask
          ? event.target.value.replace(/\D/g, '')
          : event.target.value

      this.hasValue = value ? true : false

      this.$emit('input', value)
    },

    onFocus(value) {
      this.focused = true
      this.$emit('focus', value)
    }
  }
}
</script>

<style lang="scss" src="./style.scss"></style>
