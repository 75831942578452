import Vue from 'vue'

// Object initial state
const initialState = () => ({
  docFiscaisPendentes: [],
  totalRegistros: 0,
  carregando: false
})

// State object
const state = initialState()

// Getter object
const getters = {
  getTotalizador(state) {
    return state.totalRegistros
  },

  getLoader(state) {
    return state.carregando
  },

  getDocFiscaisPendentes(state) {
    return state.docFiscaisPendentes
  }
}

// Actions
const actions = {
  clearState({ commit }) {
    commit('RESET')
  },

  fetchDocFiscaisPendentes({ commit }, { campaign, paginacao, profile }) {
    return new Promise(async resolve => {
      const api = new Vue.prototype.$httpClient()
      const url = `/documentoFiscal/v1/documentosFiscaisPendentes/${campaign.ano}/${campaign.identificacao}/${paginacao.porPagina}/${paginacao.pagina}/${profile}`
      const res = await api.send(url, 'GET', null, undefined, false)

      if ([200, 201].includes(res.status)) {
        commit('setDocFiscaisPendentes', res.data.retorno.retorno)
        commit('setTotalizador', res.data.retorno.total)
        resolve({
          status: 200,
          msg: 'Lista de produtos obtida com sucesso!',
          total: res.data.retorno.total
        })
      } else if ([404].includes(res.status)) {
        commit('setDocFiscaisPendentes', [])
        resolve({ status: 404, msg: 'Não foi encontrado produtos!' })
      }
    })
  },

  obterDocumentoFiscalPendente(_, { campanha, chaveAcesso }) {
    return new Promise(async resolve => {
      let res

      try {
        const api = new Vue.prototype.$httpClient()
        const url = `/documentoFiscal/v1/documentosFiscaisPendentes/${campanha.ano}/${campanha.identificacao}/${chaveAcesso}`

        res = await api.send(url, 'GET', null, undefined, false)

        if (res.status == 200) {
          resolve({
            status: 200,
            msg: 'Documento fiscal pendente obtido com sucesso!',
            retorno: res.data.retorno
          })
        } else if (res.status == 404) {
          resolve({
            status: 404,
            msg: 'Documento fiscal pendente não encontrado com esta chave',
            retorno: null
          })
        } else {
          resolve({
            status: res.status,
            msg:
              res.data.mensagem ||
              'Ocorreu um erro desconhecido durante a sua requisição',
            retorno: null
          })
        }
      } catch (error) {
        console.log('errooooooooooooooo')
        console.log(error)
      }
    })
  },

  addDocFiscalPendente(_, { campaign, documentoFiscalPendente }) {
    return new Promise(async resolve => {
      const api = new Vue.prototype.$httpClient()
      const url = `/documentoFiscal/v1/documentosFiscaisPendentes/${campaign.ano}/${campaign.identificacao}`
      const res = await api.send(
        url,
        'POST',
        { documentoFiscalPendente },
        undefined,
        false
      )

      let mensagemRetorno

      if ([201, 200].includes(res.data.status)) {
        let ios = typeof cordova != 'undefined' && cordova.platformId == 'ios'

        mensagemRetorno = ios
          ? 'Documento fiscal em aprovação. Acesse "documentos fiscais pendentes" para acompanhar o andamento'
          : 'Documento fiscal em aprovação, aguarde.'
      } else {
        mensagemRetorno = res.data.mensagem
      }

      resolve({
        status: res.data.status,
        msg: mensagemRetorno,
        retorno: res.data.retorno
      })
    })
  }
}

// Mutations
const mutations = {
  RESET(state) {
    const newState = initialState()
    Object.keys(newState).forEach(key => {
      state[key] = newState[key]
    })
  },

  setDocFiscaisPendentes(state, data) {
    state.docFiscaisPendentes = data
  },

  setTotalizador(state, data) {
    state.totalRegistros = data
  },

  switchCarregando(state, data) {
    state.carregando = data
  }
}

// Exporting store module
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
