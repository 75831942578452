import Vue from 'vue';

// Object initial state
const initialState = () => ({
  produtos: [],
  segmentosSetores: [],
  totalRegistros: 0,
  totalPaginas: 0,
  carregando: false,
});

// State object
const state = initialState();

// Getter object
const getters = {
  getProdutos(state) {
    return state.produtos;
  },
  getSegmentosSetores(state) {
    return state.segmentoSetores;
  },
  getTotalizador(state) {
    return state.totalRegistros;
  },
  getTotalPaginas(state) {
    return state.totalPaginas;
  },
  getCarregando(state) {
    state.carregando;
  },
};

// Actions
const actions = {
  clearState({ commit }) {
    commit('RESET');
  },

  fetchProdutos({ commit }, { campaign, descricao, codigo, segmentacao, setorLoja, fidelidade, categoria, destaque }) {
    commit('setCarregando', true);
    return new Promise(async resolve => {
      const api = new Vue.prototype.$httpClient();
      const url = `/produto/v1/produtos/listarFiltrados`;
      const res = await api.send(url, 'POST', {
        campanha: campaign,
        codigo: codigo,
        descricao: descricao,
        segmentacao: segmentacao,
        setorLoja: setorLoja,
        fidelidade: fidelidade,
        categoria: categoria,
        destaque: destaque,
      });

      if ([200, 201].includes(res.status)) {
        commit('setProdutos', res.data.retorno.retorno);
        commit('setCarregando', false);
        resolve({
          status: 200,
          msg: 'Lista de produtos obtida com sucesso!',
        });
      } else if ([404].includes(res.status)) {
        commit('setProdutos', []);
        commit('setCarregando', false);
        resolve({ status: 404, msg: 'Não foi encontrado produtos!' });
      }
    });
  },

  fetchProdutosVitrine({ commit }, { paginacao, campanha, fidelidade, categoria, destaque }) {
    commit('setCarregando', true);
    return new Promise(async resolve => {
      const api = new Vue.prototype.$httpClient();
      const params = { fidelidade };
      if (categoria) {
        params.categoria = categoria;
      }
      if (destaque) {
        params.destaque = destaque;
      }

      const url = `/produto/v1/produtos/${campanha.ano}/${campanha.identificacao}/${paginacao.totalPorPagina}/${paginacao.paginaAtual}`;
      const res = await api.send(url, 'GET', params);
      if ([200, 201].includes(res.status)) {
        commit('setProdutos', res.data.retorno.retorno);
        commit('setTotalizador', res.data.retorno.total);
        commit('setTotalPaginas', res.data.retorno.paginas);
        commit('setCarregando', false);
        resolve({
          status: 200,
          msg: 'Lista de produtos obtida com sucesso!',
        });
      } else if ([404].includes(res.status)) {
        commit('setCarregando', false);
        commit('setProdutos', []);
        resolve({ status: 404, msg: 'Não foi encontrado produtos!' });
      }
    });
  },

  fetchSegmentoSetores({ commit }, { campanha }) {
    return new Promise(async resolve => {
      const api = new Vue.prototype.$httpClient();
      const url = `/produto/v1/segmentosSetores/${campanha.ano}/${campanha.identificacao}`;
      const res = await api.send(url, 'GET');

      if ([200].includes(res.status)) {
        commit('setObterSegmentosSetores', res.data.retorno.segmentos);
        resolve({
          status: 200,
          msg: 'Lista de segmentos e setores obtida com sucesso!',
        });
      } else if ([404].includes(res.status)) {
        commit('setObterSegmentosSetores', []);
        resolve({ status: 404, msg: 'Não foi encontrado segmentos e setores!' });
      }
    });
  },

  fetchListagemProdutos({ commit }, { campanha, paginacao }) {
    return new Promise(async resolve => {
      const requisitarProdutos = async (atual, total) => {
        const api = new Vue.prototype.$httpClient();
        const url = `/produto/v1/produtos/${campanha.ano}/${campanha.identificacao}/${total}/${atual}`;
        let req = await api.send(url, 'GET');

        return req.data;
      };

      const adicionarProdutos = (produtos = []) => {
        commit('setAdicionarMaisProdutos', produtos);
      };

      const dispararErro = () => {
        return resolve({ status: 404, msg: 'Não foi encontrado produtos!' });
      };

      const dispararSucesso = () => {
        return resolve({
          status: 200,
          msg: 'Lista de produtos obtida com sucesso!',
        });
      };

      const executarRequisicoes = async (paginaAtual = 1) => {
        const res = await requisitarProdutos(paginaAtual, paginacao.totalPorPagina);

        if ([200].includes(res.status)) {
          adicionarProdutos(res.retorno.retorno);

          // Recursão...
          return paginaAtual < res.retorno.paginas ? executarRequisicoes(paginaAtual + 1) : dispararSucesso();
        } else {
          return dispararErro();
        }
      };

      // Iniciando
      executarRequisicoes();
    });
  },
};

// Mutations
const mutations = {
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach(key => {
      state[key] = newState[key];
    });
  },

  setProdutos(state, data) {
    state.produtos = data;
  },

  setAdicionarMaisProdutos(state, data) {
    state.produtos = [...state.produtos, ...data];
  },
  setObterSegmentosSetores(state, data) {
    state.segmentoSetores = data;
  },
  setTotalizador(state, data) {
    state.totalRegistros = data;
  },
  setTotalPaginas(state, data) {
    state.totalPaginas = data;
  },
  setCarregando(state, data) {
    state.carregando = data;
  },
};

// Exporting store module
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
