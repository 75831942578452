const SignupKey  = 'signupURL';

const initialState = () => ({
  signupURLStorage: {
    cpf: '',
    nome: '',
    email: '',
    phone: ''
  },
});
  
  const state = initialState();
  
  const getters = {
    getSignupData(state) {
      return state.signupURLStorage;
    },
  };
  
  const actions = {
    clearState({ commit }) {
      commit('RESET')
    },
    saveSignupData({
      commit
    }, {
      cpf,
      nome,
      email,
      phone
    }) {
      commit('setSignupData', {
        cpf,
        nome,
        email,
        phone
      });
    },
  };
  
  const mutations = {
    RESET(state) {
      const newState = initialState()
      Object.keys(newState).forEach(key => {
        state[key] = newState[key]
      })
    },

    setSignupData(state, data) {
      state.signupURLStorage = data;
      localStorage.setItem(SignupKey, JSON.stringify(data));
    },

    deleteSignupData(){
      localStorage.removeItem(SignupKey);
    }
  };
  
  export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
  };